import * as React from 'react';
import {createTheme} from "@mui/material/styles";
import Container from '@mui/material/Container';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from "@mui/material/useMediaQuery";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Location from "../../Components/Location";
import {FormHelperText} from "@mui/material";
import BootstrapDialog from "../../Components/BootstrapDialog";
import BootstrapDialogTitle from "../../Components/BootstrapDialogTitle";
import Waiting from "../../Components/Waiting";
import MercureContainedButton from "../../Components/MercureContainedButton";

const housings = JSON.parse(localStorage.getItem('housings'));

export default function AddReceipt(props) {
    const theme = createTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currency, setCurrency] = React.useState('€');
    const [filter, setFilter] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [paymentDate, setPaymentDate] = React.useState(null);
    const [creationDate, setCreationDate] = React.useState(dayjs(new Date()));
    const [rentalAmount, setRentalAmount] = React.useState(null);
    const [chargesAmount, setChargesAmount] = React.useState(null);
    const [apl, setApl] = React.useState(null);
    const [another, setAnother] = React.useState(null);
    const [paymentMode, setPaymentMode] = React.useState('');
    const [city, setCity] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [startDateErrorMessage, setStartDateErrorMessage] = React.useState('');
    const [endDateErrorMessage, setEndDateErrorMessage] = React.useState('');
    const [creationDateErrorMessage, setCreationDateErrorMessage] = React.useState('');
    const [paymentDateErrorMessage, setPaymentDateErrorMessage] = React.useState('');
    const [tenantErrorMessage, setTenantErrorMessage] = React.useState('');
    const [rentalAmountErrorMessage, setRentalAmountErrorMessage] = React.useState('');
    const [chargesAmountErrorMessage, setChargesAmountErrorMessage] = React.useState('');
    const [cityErrorMessage, setCityErrorMessage] = React.useState('');
    const [paymentModeErrorMessage, setPaymentModeErrorMessage] = React.useState('');
    const requiredField = 'Ce champ est requis';

    const onFilterChange = (event, value) => {
        if (value !== null) {
            setTenantErrorMessage('');
            setFilter(value);
            const found = housings && housings.find((housing) => housing.id === value.idHousing);
            setCurrency(found ? found.currency : '€');
            return;
        }

        setTenantErrorMessage(requiredField);
    }

    const onStartDateChange = (event, value) => {
        if (value === null) {
            setStartDateErrorMessage(requiredField);
            return;
        }

        setStartDateErrorMessage('');
        setStartDate(dayjs(value.toLocaleString()));
    }

    const onEndDateChange = (event, value) => {
        if (value === null) {
            setEndDateErrorMessage(requiredField);
            return;
        }

        setEndDateErrorMessage('');
        setEndDate(dayjs(value.toLocaleString()));
    }

    const onPaymentDateChange = (event, value) => {
        if (value === null) {
            setPaymentDateErrorMessage(requiredField);
            return;
        }

        setPaymentDateErrorMessage('');
        setPaymentDate(dayjs(value.toLocaleString()));
    }

    const onCreationDateChange = (event, value) => {
        if (value === null) {
            setCreationDateErrorMessage(requiredField);
            return;
        }

        setCreationDateErrorMessage('');
        setCreationDate(dayjs(value.toLocaleString()));
    }

    const onRentalAmountChange = (event) => {
        if (event.target.value !== null) {
            setRentalAmountErrorMessage('');
        } else {
            setRentalAmountErrorMessage(requiredField);
        }

        setRentalAmount(event.target.value);
    }

    const onChargesAmountChange = (event) => {
        if (event.target.value !== null) {
            setChargesAmountErrorMessage('');
        } else {
            setChargesAmountErrorMessage(requiredField);
        }

        setChargesAmount(event.target.value);
    }

    const onAplChange = (event) => {
        setApl(event.target.value);
    }

    const onAnotherChange = (event) => {
        setAnother(event.target.value);
    }

    const onPaymentModeChange = (event, value) => {
        if (event.target.value) {
            setPaymentModeErrorMessage('');
            setPaymentMode(event.target.value);
            return;
        }

        setPaymentModeErrorMessage(requiredField);
    }

    const onCityChange = (event, value) => {
        if (value === null) {
            setCityErrorMessage(requiredField);
        } else {
            setCityErrorMessage('');
        }

        setCity(value.description);
    }

    async function saveReceipt() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            props.handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            props.handleSeverity('error');
            props.handleOpenAlert();
            return;
        }

        if ('' === user.firstName || '' === user.lastName) {
            props.handleMessage("Votre profil est incomplet, merci de renseigner votre prénom et/ou votre nom");
            props.handleSeverity('error');
            props.handleOpenAlert();
            return;
        }

        if (filter === null) {
            setTenantErrorMessage(requiredField);
            return;
        }

        if (startDate === null) {
            setStartDateErrorMessage(requiredField);
            return;
        }

        if (endDate === null) {
            setEndDateErrorMessage(requiredField);
            return;
        }

        if (rentalAmount === '') {
            setRentalAmountErrorMessage(requiredField);
            return;
        }

        if (chargesAmount === '') {
            setChargesAmountErrorMessage(requiredField);
            return;
        }

        if (paymentDate === null) {
            setPaymentDateErrorMessage(requiredField);
            return;
        }

        if (paymentMode === '') {
            setPaymentModeErrorMessage(requiredField);
            return;
        }

        if (city === null) {
            setCityErrorMessage(requiredField);
            //return;
        }

        if (creationDate === null) {
            setCreationDateErrorMessage(requiredField);
            return;
        }

        // Loading
        setLoading(true);

        let data = {};
        data.idUser = user.id;
        data.idTenant = filter.id;
        data.startDate = startDate && dayjs(startDate.toLocaleString()).format('YYYY-MM-DD');
        data.endDate = endDate && dayjs(endDate.toLocaleString()).format('YYYY-MM-DD');
        data.paymentDate = paymentDate && dayjs(paymentDate.toLocaleString()).format('YYYY-MM-DD');
        data.creationDate = dayjs(creationDate.toLocaleString()).format('YYYY-MM-DD');
        data.rentalAmount = rentalAmount;
        data.chargesAmount = chargesAmount;
        data.apl = apl;
        data.another = another;
        data.paymentMode = paymentMode;
        data.city = city;
        data.returnUrl = window.location.href;

        await fetch(process.env.REACT_APP_API_HOST + '/receipt/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    // Save filter in local storage
                    localStorage.setItem('receiptTenantFilter', JSON.stringify(filter));
                    localStorage.setItem('receiptTenantYear', JSON.stringify(dayjs(startDate.toLocaleString()).format('YYYY')));

                    // Hide dialog
                    props.onClose();

                    // Save data in local storage for upload
                    localStorage.setItem('envelopeId', JSON.stringify(response.envelopeId));
                    localStorage.setItem('documentId', JSON.stringify(response.id));
                    localStorage.setItem('fileName', JSON.stringify(response.fileName));

                    // Hide waiting
                    setLoading(false);

                    // Redirect
                    window.location.href = response.redirect_url;
                })
            } else if (result.status === 403) {
                result.json().then(response => {
                    setLoading(false);

                    props.handleMessage(response);
                    props.handleSeverity('error');
                    props.handleOpenAlert();
                })
            } else {
                console.log(result);
                setLoading(false);

                props.handleMessage("Votre quittance n'a pas pu être créée suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);

            props.handleMessage("Votre quittance n'a pas pu être créée suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    const handleCloseWaiting = () => {
        setLoading(false);
    };

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Créer une quittance
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Container component="" maxWidth="xs" tabIndex={-1}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    options={props.tenants}
                                    id="tenant"
                                    autoHighlight
                                    value={filter}
                                    onChange={(event, newValue) => {
                                        onFilterChange(event, newValue)
                                    }}
                                    getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName}` : ''}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{mr: 2, flexShrink: 0}} {...props}>
                                            {option ? `${option.firstName} ${option.lastName}` : ''}
                                        </Box>
                                    )}
                                    renderInput={(params) => <TextField required {...params} label="Sélectionner un locataire"
                                                                        fullWidth error={tenantErrorMessage !== ""}
                                                                        helperText= {tenantErrorMessage}/>}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        required
                                        label="Date de début"
                                        id="startDate"
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate}
                                        onChange={(newValue) => {
                                            onStartDateChange(null, newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth required error={startDateErrorMessage !== ""}
                                                                            helperText= {startDateErrorMessage}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        required
                                        label="Date de fin"
                                        id="endDate"
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}
                                        onChange={(newValue) => {
                                            onEndDateChange(null, newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth required error={endDateErrorMessage !== ""}
                                                                            helperText= {endDateErrorMessage}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="Montant du loyer (hors charges)"
                                    id="rentalAmount"
                                    fullWidth
                                    type="number"
                                    value={rentalAmount}
                                    onChange={onRentalAmountChange}
                                    error={rentalAmountErrorMessage !== ""}
                                    helperText= {rentalAmountErrorMessage}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="Montant des charges"
                                    id="chargesAmount"
                                    fullWidth
                                    type="number"
                                    value={chargesAmount}
                                    onChange={onChargesAmountChange}
                                    error={chargesAmountErrorMessage !== ""}
                                    helperText= {chargesAmountErrorMessage}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Aide au logement (APL)"
                                    id="apl"
                                    fullWidth
                                    type="number"
                                    value={apl}
                                    onChange={onAplChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Autre"
                                    id="another"
                                    fullWidth
                                    type="number"
                                    value={another}
                                    onChange={onAnotherChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        label="Date de paiement"
                                        id="paymentDate"
                                        inputFormat="DD/MM/YYYY"
                                        value={paymentDate}
                                        onChange={(newValue) => {
                                            onPaymentDateChange(null, newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth required error={paymentDateErrorMessage !== ""}
                                                                            helperText= {paymentDateErrorMessage}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth required error={paymentModeErrorMessage !== ''}>
                                    <InputLabel id="paymentMode">Mode de réglement</InputLabel>
                                    <Select
                                        labelId="paymentMode"
                                        id="paymentMode"
                                        label="Mode de réglement"
                                        value={paymentMode}
                                        onChange={(event, newValue) => onPaymentModeChange(event, newValue)}
                                    >
                                        <MenuItem value={1}>Carte bancaire</MenuItem>
                                        <MenuItem value={2}>Chèque</MenuItem>
                                        <MenuItem value={3}>Espèces</MenuItem>
                                        <MenuItem value={4}>Virement bancaire</MenuItem>
                                        <MenuItem value={5}>Prélèvement</MenuItem>
                                        <MenuItem value={6}>Autre</MenuItem>
                                    </Select>
                                    <FormHelperText>{paymentModeErrorMessage}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Location label="Fait à"
                                          required="required"
                                          value={city}
                                          onChange={(event, newValue) => onCityChange(event, newValue)}
                                          noOptionsText="Aucune ville saisie"
                                          error={cityErrorMessage !== ""}
                                          helperText= {cityErrorMessage}
                                          />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        label="Le"
                                        required
                                        id="creationDate"
                                        inputFormat="DD/MM/YYYY"
                                        value={creationDate}
                                        onChange={(newValue) => {
                                            onCreationDateChange(null, newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth required error={creationDateErrorMessage !== ""}
                                                                            helperText= {creationDateErrorMessage}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <MercureContainedButton
                            label="Valider"
                            onClick={saveReceipt}
                            disabled={loading} />
                    </Box>
                </DialogActions>
            </BootstrapDialog>
            {loading && (
                <Waiting loading={loading} title="Signature électronique" message="Merci de patienter, vous allez être rédirigé dans quelques instants..."/>
            )}
        </div>
    )
}