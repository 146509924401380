import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import DialogActions from "@mui/material/DialogActions";
import MercureContainedButton from "../../Components/MercureContainedButton";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import CustomTransferList from "../../Components/CustomTransferList";
import IOSSwitch from "../../Components/IOSSwitch";
import {DateTimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import 'dayjs/locale/fr';
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

function dataToMove(items, ids) {
    let data = [];
    for (const item of items) {
        for (const id of ids) {
            if (item.id === id) {
                data.push(item);
            }
        }
    }

    return data;
}

function dataToKeep(items, ids) {
    for (const id of ids) {
        for (const item of items) {
            if (item.id === id) {
                items.splice(items.indexOf(item), 1);
                break;
            }
        }
    }

    return items;
}

export default function UpdateRaise(props) {
    const [open, setOpen] = React.useState(false);
    const descriptionElementRef = React.useRef(null);
    const [name, setName] = React.useState(props.raise && props.raise.name ? props.raise.name : '');
    const [type, setType] = React.useState(props.raise && props.raise.type ? props.raise.type : null);
    const [smsSenderMessage, setSmsSenderMessage] = React.useState(props.raise && props.raise.smsSenderMessage ? props.raise.smsSenderMessage : '');
    const [smsMessage, setSmsMessage] = React.useState(props.raise && props.raise.smsMessage ? props.raise.smsMessage : '');
    const [mailMessage, setMailMessage] = React.useState(props.raise && props.raise.mailMessage ? props.raise.mailMessage : '');
    const [mailSubject, setMailSubject] = React.useState(props.raise && props.raise.mailSubject ? props.raise.mailSubject : '');
    const [raiseDate, setRaiseDate] = React.useState(props.raise && props.raise.raiseDate ? props.raise.raiseDate : '');
    const [active, setActive] = React.useState(props.raise && props.raise.active ? props.raise.active : null);
    const [showSmsMessage, setShowSmsMessage] = React.useState(props.raise && props.raise.smsMessage !== '');
    const [showMailMessage, setShowMailMessage] = React.useState(props.raise && props.raise.mailMessage !=='');
    const SMS_SENDER_CHARACTER_LIMIT = 11;
    const SMS_CHARACTER_LIMIT = 160;
    const MAIL_CHARACTER_LIMIT = 1024;
    let [tenants, setTenants] = React.useState(props.raise && props.raise.tenants ? props.raise.tenants : []);
    let [tenantsToRaise, setTenantsToRaise] = React.useState(props.raise && props.raise.tenantsToRaise ? props.raise.tenantsToRaise : []);
    const [smsDateTime, setSmsDateTime] = React.useState(props.raise && props.raise.smsDateTime ? dayjs(props.raise.smsDateTime).toLocaleString() : null);
    const [nameErrorMessage, setNameErrorMessage] = React.useState('');
    const [raiseDateErrorMessage, setRaiseDateErrorMessage] = React.useState('');
    const [smsMessageErrorMessage, setSmsMessageErrorMessage] = React.useState('');
    const [mailSubjectErrorMessage, setMailSubjectErrorMessage] = React.useState('');
    const [mailMessageErrorMessage, setMailMessageErrorMessage] = React.useState('');
    const requiredField = 'Ce champ est requis';
    const [checked, setChecked] = React.useState([]);
    let [leftChecked, setLeftChecked] = React.useState(0);
    let [rightChecked, setRightChecked] = React.useState(0);

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        const values = items.map(item => item.id);
        if (numberOfChecked(values) === items.length) {
            setChecked(not(checked, values));
        } else {
            setChecked(union(checked, values));
        }
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheck = React.useCallback((value) => {
        return checked.indexOf(value) !== -1;
    }, [checked]);

    const handleHeaderCheck = React.useCallback((items) => {
        const values = items.map(item => item.id);
        const num = numberOfChecked(values);
        return num === items.length && items.length !== 0;
    }, [checked, handleToggle, handleCheck/*, rightItems*/]);

    const indeterminate = React.useCallback((items) => {
        const values = items.map(item => item.id);
        const num = numberOfChecked(values);
        return num !== items.length && num !== 0
    }, [checked, handleToggle, handleCheck]);

    const headerCheckedNumber = React.useCallback((items) => {
        const values = items.map(item => item.id);
        return numberOfChecked(values);
    }, [checked, handleToggle, handleCheck]);

    const handleCheckedRight = React.useCallback((itemsLeft, itemsRight) => {
        const newLeftItemsId = itemsLeft.map(l => l.id);
        const newLeftChecked = intersection(checked, newLeftItemsId);
        const itemsToMove = dataToMove(itemsLeft, newLeftChecked);
        const itemsToKeep = dataToKeep(itemsLeft, newLeftChecked);

        setTenantsToRaise(itemsToMove.concat(itemsRight));
        setTenants(itemsToKeep);
        setLeftChecked(newLeftChecked);
    }, [checked]);

    const handleCheckedLeft = React.useCallback((itemsLeft, itemsRight) => {
        const newRightItemsId = itemsRight.map(r => r.id);
        const newRightChecked = intersection(checked, newRightItemsId);
        const itemsToMove = dataToMove(itemsRight, newRightChecked);
        const itemsToKeep = dataToKeep(itemsRight, newRightChecked);

        setTenants(itemsToMove.concat(itemsLeft));
        setTenantsToRaise(itemsToKeep);
        setRightChecked(newRightChecked);
    }, [checked]);

    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

    }, [open]);

    async function updateRaise(){
        let data = {};
        data.idUser = props.raise.idUser;

        if (name === '') {
            return;
        }

        data.name = name;
        data.type = type;
        data.raiseDate = raiseDate;
        data.smsSenderMessage = smsSenderMessage;
        data.smsMessage = smsMessage;
        data.smsDateTime = smsDateTime && dayjs(smsDateTime.toLocaleString()).format('YYYY-MM-DD hh:mm a');
        data.mailSubject = mailSubject;
        data.mailMessage = mailMessage;
        data.active = active;
        data.tenants = tenantsToRaise;
        //console.log('data', data); return;

        await fetch(process.env.REACT_APP_API_HOST + '/tenant/raise/update/' + props.raise.id, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 204) {
                // Show Alert
                props.raiseUpdated();
            }
        }).catch((error) => {
            console.log(error);
            props.raiseNotUpdated();
        });
    }

    const onNameChange = (e) => {
        if (e.target.value === '') {
            setNameErrorMessage(requiredField);
        } else {
            setNameErrorMessage('');
        }

        setName(e.target.value);
    }

    const onSmsMessageChange = (e) => {
        if (e.target.value === '') {
            setSmsMessageErrorMessage(requiredField);
        } else {
            setSmsMessageErrorMessage('');
        }

        setSmsMessage(e.target.value);
    }

    const onSmsSenderMessageChange = (e) => {
        setSmsSenderMessage(e.target.value);
    }

    const onMailMessageChange = (e) => {
        if (e.target.value === '') {
            setMailMessageErrorMessage(requiredField);
        } else {
            setMailMessageErrorMessage('');
        }

        setMailMessage(e.target.value);
    }

    const onSmsCheckChange = (e) => {
        if (e.target.checked) {
            setShowSmsMessage(true);
        } else {
            setShowSmsMessage(false);
        }
    }

    const onMailCheckChange = (e) => {
        if (e.target.checked) {
            setShowMailMessage(true)
        } else {
            setShowMailMessage(false)
        }
    }

    const onTypeChange = (e) => {
        setType(e.target.value);
    }

    const onRaiseDateChange = (e) => {
        if (e.target.value === '') {
            setRaiseDateErrorMessage(requiredField);
        } else {
            setRaiseDateErrorMessage('');
        }

        setRaiseDate(e.target.value);
    }

    const onActiveChange = (e) => {
        setActive(e.target.checked);
    }

    const onEmailSubjectChange = (e) => {
        if (e.target.value === '') {
            setMailSubjectErrorMessage(requiredField);
        } else {
            setMailSubjectErrorMessage('');
        }

        setMailSubject(e.target.value);
    }

    const setItemsToRaise = (items) => {
        setTenantsToRaise(items);
    }

    const handleDateTime = (event, value) => {
        setSmsDateTime(dayjs(value.toLocaleString()));
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier une relance
                </Typography>
            </Box>
            <Divider />
            <Container maxWidth="md" ref={descriptionElementRef} tabIndex={-1} sx={{ mb: 3 }}>
                <Grid container spacing={2} alignItems="center" sx={{ pt: 3, pb: 2 }}>
                    <Grid item xs={12}>
                        <TextField
                            onChange={(e) => onNameChange(e)}
                            required
                            label="Nom de la relance"
                            value={name}
                            fullWidth
                            error={nameErrorMessage !== ""}
                            helperText={nameErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel component="legend">Mode de relance</FormLabel>
                            <div>
                                <FormControlLabel value="sms" control={<Checkbox size="small" checked={smsMessage !== ''} onChange={(e) => onSmsCheckChange(e)}/>} label="Par SMS" />
                                <FormControlLabel value="mail" control={<Checkbox size="small" checked={mailMessage !== ''} onChange={(e) => onMailCheckChange(e)}/>} label="Par e-mail" />
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="type">Type de relance</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="type"
                                name="type"
                                value={type}
                                onChange={(e) => onTypeChange(e)}
                            >
                                <FormControlLabel value="manual" control={<Radio />} label="Manuelle" />
                                <FormControlLabel value="auto" control={<Radio />} label="Automatique" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {type === 'auto' &&
                        <Grid item xs={12}>
                            <TextField
                                id="raiseDate"
                                label="Relance au"
                                type="number"
                                onChange={(e) => onRaiseDateChange(e)}
                                fullWidth
                                error={raiseDateErrorMessage !== ""}
                                helperText={raiseDateErrorMessage}
                                value={raiseDate}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start"> du mois</InputAdornment>,
                                }}
                            />
                        </Grid>
                    }
                    {type === 'auto' &&
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    onChange={(e) => onActiveChange(e)}
                                    control={<IOSSwitch sx={{ m: 1 }} checked={active} />}
                                    label="Activer la relance"
                                />
                            </FormGroup>
                        </Grid>
                    }
                    {showSmsMessage &&
                        <Grid item xs={12}>
                            <TextField
                                label="Expéditeur du SMS"
                                inputProps={{
                                    maxlength: SMS_SENDER_CHARACTER_LIMIT
                                }}
                                helperText={`${smsSenderMessage.length}/${SMS_SENDER_CHARACTER_LIMIT}`}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                value={smsSenderMessage}
                                placeholder="SCI DUPONT"
                                onChange={(e) => onSmsSenderMessageChange(e)}
                            />
                        </Grid>
                    }
                    {showSmsMessage &&
                        <Grid item xs={12}>
                            <TextField
                                label="Message du SMS"
                                inputProps={{
                                    maxlength: SMS_CHARACTER_LIMIT
                                }}
                                helperText={`${smsMessage.length}/${SMS_CHARACTER_LIMIT} ${smsMessageErrorMessage}`}
                                margin="normal"
                                variant="outlined"
                                rows={4}
                                multiline
                                fullWidth
                                value={smsMessage}
                                placeholder=" Bonjour,
                                Sauf erreur de notre part, nous n'avons pas reçu le paiement de votre loyer pour le mois en cours.
                                Merci de faire le nécessaire rapidement.
                                Cordialement."
                                required
                                onChange={(e) => onSmsMessageChange(e)}
                                error={smsMessageErrorMessage !== ""}
                            />
                        </Grid>
                    }
                    {showSmsMessage &&
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DateTimePicker label="Date et heure d'envoi du SMS"
                                                inputFormat="DD/MM/YYYY hh:mm a"
                                                value={smsDateTime}
                                                onChange={(newValue) => {
                                                    handleDateTime(null, newValue)
                                                }}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>

                        </Grid>
                    }
                    {showMailMessage &&
                        <Grid item xs={12}>
                            <TextField
                                label="Sujet de l'e-mail"
                                placeholder="Le règlement de votre loyer"
                                onChange={(e) => onEmailSubjectChange(e)}
                                fullWidth
                                value={mailSubject}
                                error={mailSubjectErrorMessage !== ""}
                                helperText={mailSubjectErrorMessage}
                                required />
                        </Grid>
                    }
                    {showMailMessage &&
                        <Grid item xs={12}>
                            <TextField
                                label="Message de l'e-mail"
                                inputProps={{
                                    maxlength: MAIL_CHARACTER_LIMIT
                                }}
                                helperText={`${mailMessage.length}/${MAIL_CHARACTER_LIMIT} ${mailMessageErrorMessage}`}
                                error={mailMessageErrorMessage !== ""}
                                margin="normal"
                                variant="outlined"
                                rows={10}
                                multiline
                                fullWidth
                                placeholder=" Bonjour,
                                Sauf erreur de notre part, nous n'avons pas reçu le paiement de votre loyer pour le mois en cours.
                                Merci de faire le nécessaire rapidement.
                                Cordialement."
                                required
                                value={mailMessage}
                                onChange={(e) => onMailMessageChange(e)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} justifyContent="center" alignItems="center">
                        <FormControl fullWidth>
                            <FormLabel>Locataire.s concerné.s par la relance</FormLabel>
                            <CustomTransferList leftTitle="Sélectionné.s"
                                                rightTitle="A relancer"
                                                leftItems={tenants}
                                                rightItems={tenantsToRaise}
                                                setItemsToRaise={setItemsToRaise}
                                                handleToggleAll={handleToggleAll}
                                                handleToggle={handleToggle}
                                                numberOfChecked={numberOfChecked}
                                                handleCheck={handleCheck}
                                                handleHeaderCheck={handleHeaderCheck}
                                                headerCheckedNumber={headerCheckedNumber}
                                                indeterminate={indeterminate}
                                                leftChecked={leftChecked}
                                                rightChecked={rightChecked}
                                                handleCheckedRight={handleCheckedRight}
                                                handleCheckedLeft={handleCheckedLeft}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
            <Divider/>
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                    <MercureContainedButton
                        onClick={updateRaise}
                        label="Valider" />
                </Box>
            </DialogActions>
        </div>
    )
}