import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import HouseIcon from '@mui/icons-material/House';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DescriptionIcon from '@mui/icons-material/Description';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TimelineIcon from '@mui/icons-material/Timeline';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BusinessIcon from '@mui/icons-material/Business';
import { Link } from 'react-router-dom';
import AssignmentIcon from "@mui/icons-material/Assignment";

const categories = [
    {
        id: 'Overview',
        children: [
            { id: 'Dashboard', icon: <BarChartIcon />, path: '/overview', index: 0, grandChildren: [ { id: 'Dashboard', index: 0 }] },
        ],
    },
    {
        id: 'Biens immobiliers',
        children: [
            { id: 'Gérer mes biens', icon: <HouseIcon />, path: '/housing', index: 10, grandChildren: [{ id: 'Gérer mes biens', index: 0 }] },
            /*{ id: 'Assurer mes biens', icon: <HouseIcon />, path: '/housing', index: 10, grandChildren: [{ id: 'Assurer mes biens', index: 0 }] },*/
            { id: 'Gérer mes copropriétaires', icon: <PeopleAltIcon />, path: '/housing/coowner', index: 20, grandChildren: [
                    { id: 'Personne physique', path: '/housing/coowner', index: 0 },
                    { id: 'Personne morale', path: '/housing/coowner/corporation', index: 1 }
                ]
            },
            { id: 'Gérer mes entreprises', icon: <BusinessIcon />, path: '/housing/corporation', index: 30, grandChildren: [{ id: 'Gérer mes entreprises', index: 0 }] }
        ],
    },
    {
        id: 'Locataires',
        children: [
            { id: 'Gérer mes locataires', icon: <PeopleIcon />, path: '/tenant', index: 40, grandChildren: [
                { id: 'Personne physique', path: '/tenant', index: 0 },
                { id: 'Personne morale', path: '/tenant/corporation', index: 1 }
                ]
            },
            { id: 'Relancer mes locataires', icon: <NotificationsActiveIcon />, path: '/tenant/raise', index: 50, grandChildren: [{ id: 'Relancer mes locataires', path: '/tenant/raise', index: 0 }] },
        ],
    },
    {
        id: 'Documents',
        children: [
            { id: 'Quittances de loyer', icon: <ReceiptLongIcon />, path: '/receipt', index: 60, grandChildren: [
                { id: 'Personne physique', path: '/receipt', index: 0 },
                { id: 'Personne morale', path: '/receipt/corporation', index: 1 }
                ]
            },
            { id: 'Contrats de bail', icon: <AssignmentIcon />, path: '/contract', index: 70, grandChildren: [
                    { id: 'Personne physique', path: '/contract', index: 0 },
                    { id: 'Personne morale', path: '/contract/corporation', index: 1 }
                ] },
            //{ id: 'États des lieux', icon: <InventoryIcon />, index: 80, grandChildren: [{ id: 'États des lieux', index: 0 }] },
            { id: 'Factures', icon: <ReceiptIcon />, path: '/invoice', index: 90, grandChildren: [{ id: 'Factures', index: 0 }] },
        ],
    }/*,
    {
        id: 'Comptabilité',
        children: [
            { id: 'Encaisser un loyer', icon: <PaymentIcon />, index: 100, grandChildren: [{ id: 'Encaisser un loyer', index: 0 }] },
            { id: 'Gérer mes dépenses', icon: <TimelineIcon />, index: 110, grandChildren: [{ id: 'Gérer mes dépenses', index: 0 }] },
        ],
    },*/
];

const item = {
    py: '2px',
    px: 3,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
      bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255, 255, 255, 0.1) inset',
    py: 1.5,
    px: 3,
};

export default function Navigator(props) {
    const { ...other } = props;
    

    return (
        <Drawer variant="permanent" { ...props.PaperProps} { ...props.sx} >
            <List disablePadding>
                <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
                    Terra
                </ListItem>

                {categories.map(({ id, children }) => (
                    <Box key={id} sx={{ bgcolor: '#101F33' }}>
                        <ListItem sx={{ py: 2, px: 3 }}>
                            <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
                        </ListItem>
                        {children.map(({ id: childId, icon, path, index, grandChildren }) => (
                            <ListItem disablePadding key={childId}>
                                <ListItemButton selected={props.selectedIndex === index}
                                                sx={item}
                                                component={Link}
                                                to={path}
                                                onClick={(event) => {
                                                    props.handleTitle(id);
                                                    props.handleSubtitle(childId, grandChildren);
                                                    props.handleListItemClick(event, index);
                                                }}>
                                    <ListItemIcon>{icon}</ListItemIcon>
                                    <ListItemText>{childId}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                ))}
            </List>
        </Drawer>
    );
}