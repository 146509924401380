import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function RentalCorporation(props) {
    const [currency, setCurrency] = React.useState('€');

    const [chargesModeOptions, setChargesModeOptions] = React.useState([
        'Pas de charge',
        'Réel avec régularisation annuelle',
        'Forfait'
    ]);

    const {
        // Data
        filter,
        rentalDuration,
        startRentalDate,
        rentalAmount,
        chargesAmount,
        guaranteeAmount,
        paymentFrequency,
        chargeMode,
        rentalTerm,
        paymentMode,
        cancellableContract,
        restrictedActivities,
        destinationType,
        activitiesNature,
        additionalActivities,
        rentSubjectToVat,
        franchise,
        franchiseEndDate,
        rentalRevision,
        rentalIndex,
        guaranteeRentalNumber,
        supportedCharges,
        otherSupportedCharges,
        supportedChargesOptions,
        showOtherSupportedChargesOptions,

        // Error
        tenantErrorMessage,
        startDateErrorMessage,
        chargeModeErrorMessage,
        rentalAmountErrorMessage,
        chargesAmountErrorMessage,
        guaranteeAmountErrorMessage,
        rentalTermErrorMessage,
        paymentModeErrorMessage,
        rentalDurationErrorMessage,
        cancellableContractErrorMessage,
        paymentFrequencyErrorMessage,
        franchiseEndDateErrorMessage,
        rentalRevisionErrorMessage,
        guaranteeRentalNumberErrorMessage
    } = props.data;

    const DESTINATION_TYPE_CHARACTER_LIMIT = 512;
    const ACTIVITIES_NATURE_CHARACTER_LIMIT = 512;
    const OTHER_SUPPORTED_CHARGES_CHARACTER_LIMIT = 1024;

    return (
        <div>
            <Box component="form" noValidate>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Autocomplete
                            noOptionsText="Sélectionner le.s locataire.s"
                            multiple
                            options={props.tenants}
                            id="tenant"
                            autoHighlight
                            filterSelectedOptions
                            value={filter}
                            onChange={(event, newValue) => {
                                props.onRentalChange(event, newValue, 'tenant')
                            }}
                            getOptionLabel={(option) => option ? `${option.name}` : ''}
                            renderInput={(params) => <TextField required {...params} label="Sélectionner le.s locataire.s"
                                                                fullWidth error={tenantErrorMessage !== ""}
                                                                helperText={tenantErrorMessage}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Durée de la location"
                            id="rentalDuration"
                            fullWidth
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            value={rentalDuration}
                            error={rentalDurationErrorMessage !== ""}
                            helperText= {rentalDurationErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                required
                                label="Date de début de location"
                                id="startRentalDate"
                                inputFormat="DD/MM/YYYY"
                                value={startRentalDate}
                                onChange={(newValue) => {
                                    props.onRentalChange(null, newValue, 'startRentalDate')
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth required error={startDateErrorMessage !== ""}
                                                                    helperText= {startDateErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={cancellableContractErrorMessage !== ''}>
                            <InputLabel id="cancellableContract">Contrat résiliable</InputLabel>
                            <Select
                                labelId="cancellableContract"
                                id="cancellableContract"
                                label="Contrat résiliable"
                                value={cancellableContract}
                                onChange={(event) => props.onRentalChange(event, 'cancellableContract')}
                            >
                                <MenuItem value={1}>Tous les 3 ans (cas le plus fréquent - bail 3-6-9)</MenuItem>
                                <MenuItem value={2}>Uniquement après une durée ferme de 9 ans (cas soumis à conditions)</MenuItem>
                            </Select>
                            <FormHelperText>{cancellableContractErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="restrictedActivities">Le bail est-il limité à certaines activités ?</FormLabel>
                            <RadioGroup
                                aria-labelledby="restrictedActivities"
                                name="restrictedActivities"
                                value={restrictedActivities}
                                onChange={(event) => props.onRentalChange(event)}
                            >
                                <FormControlLabel value="all" control={<Radio />} label="Le bail est tous usages" />
                                <FormControlLabel value="restricted" control={<Radio />} label="Le bail est limité à certaines activités" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {restrictedActivities === 'restricted' &&
                        <Grid item xs={12}>
                            <TextField
                                inputProps={{
                                    maxlength: DESTINATION_TYPE_CHARACTER_LIMIT
                                }}
                                fullWidth
                                label="Type de destination"
                                id="destinationType"
                                autoComplete="family-name"
                                value={destinationType}
                                onChange={props.onRentalChange}
                                rows={3}
                                multiline
                                helperText={`${destinationType.length}/${DESTINATION_TYPE_CHARACTER_LIMIT}`}
                            />
                        </Grid>
                    }
                    {restrictedActivities === 'restricted' &&
                        <Grid item xs={12}>
                            <TextField
                                inputProps={{
                                    maxlength: ACTIVITIES_NATURE_CHARACTER_LIMIT
                                }}
                                fullWidth
                                label="Nature des activités"
                                id="activitiesNature"
                                autoComplete="family-name"
                                value={activitiesNature}
                                onChange={props.onRentalChange}
                                rows={3}
                                multiline
                                helperText={`${activitiesNature.length}/${ACTIVITIES_NATURE_CHARACTER_LIMIT}`}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={additionalActivities} name="additionalActivities" onChange={(event) => props.onRentalChange(event)} />}
                            label="Le locataire peut toutefois ajouter des activités complémentaires accessoires"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="rentSubjectToVat">Le loyer est-il soumis à la TVA ?</FormLabel>
                            <RadioGroup
                                aria-labelledby="rentSubjectToVat"
                                name="rentSubjectToVat"
                                value={rentSubjectToVat}
                                onChange={(event) => props.onRentalChange(event)}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Oui (obligatoire si les locaux sont équipés ou aménagés par le propriétaire et qu'il n'est pas en franchise en base de TVA)" />
                                <FormControlLabel value="no" control={<Radio />} label="Non" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Montant du loyer annuel (hors charges et hors taxes)"
                            id="rentalAmount"
                            fullWidth
                            type="number"
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            value={rentalAmount}
                            error={rentalAmountErrorMessage !== ""}
                            helperText= {rentalAmountErrorMessage}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Montant de la provision trimestrielle pour charges"
                            id="chargesAmount"
                            fullWidth
                            type="number"
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            value={chargesAmount}
                            error={chargesAmountErrorMessage !== ""}
                            helperText= {chargesAmountErrorMessage}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Montant du dépôt de garantie (hors taxes et hors charges)"
                            id="guaranteeAmount"
                            fullWidth
                            type="number"
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            value={guaranteeAmount}
                            error={guaranteeAmountErrorMessage !== ""}
                            helperText= {guaranteeAmountErrorMessage}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Nombre de loyers correspondant"
                            id="guaranteeRentalNumber"
                            fullWidth
                            type="number"
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            value={guaranteeRentalNumber}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={paymentModeErrorMessage !== ''}>
                            <InputLabel id="paymentMode">Mode de règlement</InputLabel>
                            <Select
                                labelId="paymentMode"
                                id="paymentMode"
                                label="Mode de réglement"
                                value={paymentMode}
                                onChange={(event) => props.onRentalChange(event, 'paymentMode')}
                            >
                                <MenuItem value={1}>Carte bancaire</MenuItem>
                                <MenuItem value={2}>Chèque</MenuItem>
                                <MenuItem value={3}>Espèces</MenuItem>
                                <MenuItem value={4}>Virement bancaire</MenuItem>
                                <MenuItem value={5}>Prélèvement</MenuItem>
                                <MenuItem value={6}>Autre</MenuItem>
                            </Select>
                            <FormHelperText>{paymentModeErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="chargeMode"
                            disablePortal
                            options={chargesModeOptions}
                            value={chargeMode}
                            autoHighlight
                            onChange={(event, newValue) => {
                                props.onRentalChange(event, newValue, 'chargeMode')
                            }}
                            renderInput={(params) => <TextField required {...params} label="Mode de récupération des charges"
                                                                fullWidth
                                                                error={chargeModeErrorMessage !== ""}
                                                                helperText= {chargeModeErrorMessage}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={rentalTermErrorMessage !== ''}>
                            <InputLabel id="rentalTerm">Le loyer est payé</InputLabel>
                            <Select
                                labelId="rentalTerm"
                                id="rentalTerm"
                                label="Le loyer est payé"
                                value={rentalTerm}
                                onChange={(event) => props.onRentalChange(event, 'rentalTerm')}
                            >
                                <MenuItem value={1}>D'avance</MenuItem>
                                <MenuItem value={2}>En fin de période</MenuItem>
                            </Select>
                            <FormHelperText>{rentalTermErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={paymentFrequencyErrorMessage !== ''}>
                            <InputLabel id="paymentFrequency">Fréquence de paiement</InputLabel>
                            <Select
                                labelId="paymentFrequency"
                                id="paymentFrequency"
                                label="Fréquence de paiement"
                                value={paymentFrequency}
                                onChange={(event) => props.onRentalChange(event, 'paymentFrequency')}
                            >
                                <MenuItem value={1}>Tous les mois</MenuItem>
                                <MenuItem value={2}>Tous les trimestres</MenuItem>
                                <MenuItem value={3}>Tous les ans</MenuItem>
                            </Select>
                            <FormHelperText>{paymentFrequencyErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={franchise} name="franchise" onChange={(event) => props.onRentalChange(event)} />}
                            label="Le bailleur accorde une franchise (pas de paiement au départ pendant une certaine période)"
                        />
                    </Grid>
                    {franchise &&
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DatePicker
                                    label="Date de fin de franchise"
                                    required
                                    id="franchiseEndDate"
                                    inputFormat="DD/MM/YYYY"
                                    value={franchiseEndDate}
                                    onChange={(newValue) => {
                                        props.onRentalChange(null, newValue, 'franchiseEndDate')
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                                                        fullWidth
                                                                        required
                                                                        error={franchiseEndDateErrorMessage !== ""}
                                                                        helperText={franchiseEndDateErrorMessage}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={rentalRevisionErrorMessage !== ''}>
                            <InputLabel id="rentalRevision">Révision du loyer</InputLabel>
                            <Select
                                labelId="rentalRevision"
                                id="rentalRevision"
                                label="Révision du loyer"
                                value={rentalRevision}
                                onChange={(event) => props.onRentalChange(event, 'rentalRevision')}
                            >
                                <MenuItem value={1}>Le loyer est révisé tous les ans (cas le plus fréquent)</MenuItem>
                                <MenuItem value={2}>Le loyer est révisé tous les 3 ans</MenuItem>
                            </Select>
                            <FormHelperText>{rentalRevisionErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="rentalIndex">Quel est l'indice des loyers à appliquer ?</FormLabel>
                            <RadioGroup
                                aria-labelledby="rentalIndex"
                                name="rentalIndex"
                                value={rentalIndex}
                                onChange={(event) => props.onRentalChange(event)}
                            >
                                <Tooltip title="Indice des loyers commerciaux">
                                    <FormControlLabel value="ilc" control={<Radio />} label="ILC - les locaux sont à usage commercial" />
                                </Tooltip>
                                <Tooltip title="Indice des loyers des activités tertiaires">
                                    <FormControlLabel value="ilat" control={<Radio />} label="ILAT - les locaux sont à usage exclusif de bureau, plateforme logistique, activité industrielle et entrepôt" />
                                </Tooltip>
                                </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="supportedCharges">Charges locatives supportées par le bailleur</FormLabel>
                            <RadioGroup
                                aria-labelledby="supportedCharges"
                                name="supportedCharges"
                                value={supportedCharges}
                                onChange={(event) => props.onRentalChange(event)}
                            >
                                <Tooltip title="Les grosses réparations, les réparations liées à la vétusté et toutes les charges que la loi impose au bailleur de supporter (ainsi, le bailleur supportera notamment les impôts, notamment la contribution économique territoriale, taxes et redevances dont le redevable légal est le bailleur ou le propriétaire du local ou de l'immeuble).
L'ensemble des autres charges seront refacturées au locataire.">
                                    <FormControlLabel value="min" control={<Radio />} label="Le bailleur ne supporte que le minimum légal composé principalement des grosses réparations, etc. (cas le plus fréquent)" />
                                </Tooltip>
                                <FormControlLabel value="more" control={<Radio />} label="Le bailleur supporte plus de charges que le minimum légal" />
                                </RadioGroup>
                        </FormControl>
                    </Grid>
                    {supportedCharges === 'more' &&
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="supportedChargesOptions"
                                value={supportedChargesOptions}
                                options={supportedChargesOptionsList}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                noOptionsText="Sélectionner les charges supportées par le bailleur"
                                onChange={(event, newValue) => {
                                    props.onRentalChange(event, newValue, 'supportedChargesOptions')
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.title}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth label="Charges supportées par le bailleur en plus du minimum légal" />
                                )}
                            />
                        </Grid>
                    }
                    {showOtherSupportedChargesOptions === true &&
                        <Grid item xs={12}>
                            <TextField
                                inputProps={{
                                    maxlength: OTHER_SUPPORTED_CHARGES_CHARACTER_LIMIT
                                }}
                                fullWidth
                                label="Autres charges non présentes dans la liste"
                                id="otherSupportedCharges"
                                autoComplete="family-name"
                                value={otherSupportedCharges}
                                onChange={props.onRentalChange}
                                rows={3}
                                multiline
                                helperText={`${otherSupportedCharges.length}/${OTHER_SUPPORTED_CHARGES_CHARACTER_LIMIT}`}
                            />
                        </Grid>
                    }
                </Grid>
            </Box>
        </div>
    )
}

const supportedChargesOptionsList = [
    { title: 'Frais de consommation de chauffage, eau, climatisation, électricité, et tous autres fluides', value: 1980 },
    { title: 'Abonnement et frais des fluides', value: 1981 },
    { title: "Les menues réparations portant sur l'immeuble et les équipements", value: 1982 },
    { title: "Les travaux d’amélioration, de modernisation, de réfection et de remplacement d'équipements", value: 1983 },
    { title: "Les travaux liés à la force majeure", value: 1984 },
    { title: "Les travaux liés aux vices cachés", value: 1985 },
    { title: "Les travaux de ravalement intégral", value: 1986 },
    { title: "La pose d'un compteur individuel d'eau, de gaz ou d'électricité", value: 1987},
    { title: "Les honoraires techniques et les frais d'études préalables", value: 1988 },
    { title: "Les honoraires des architectes, des bureaux d'étude, de contrôle, etc.", value: 1989 },
    { title: "L'ensemble des diagnostics, notamment environnementaux, performance énergétique", value: 1990 },
    { title: "Le renouvellement des réseaux communs", value: 1991},
    { title: "Les frais de nettoyage, d'entretien des locaux loués et de l'immeuble", value: 1992 },
    { title: "Les frais d'entretien et de maintenance ainsi que l’exploitation des appareils de production d’énergie, de chauffage et de climatisation", value: 1993 },
    { title: "Les frais d'entretien et de maintenance des appareils de levage (ascenseurs, monte-charges, etc.), des groupes électrogènes, des installations électriques, des canalisations, etc.", value: 1994},
    { title: "Les frais d'acquisition et de renouvellement de la décoration végétale s’il en existe ou de petit mobilier, ainsi que les frais d’entretien des espaces verts intérieurs et extérieurs, etc.", value: 1995 },
    { title: "Toutes les dépenses de fonctionnement des services généraux des Lieux Loués et de l’ensemble immobilier", value: 1996 },
    { title: "L'acquisition et le renouvellement du matériel et de l'outillage nécessaire à la gestion et à l'exploitation", value: 1997 },
    { title: "Les travaux divers de mise en conformité", value: 1998 },
    { title: "Les primes d'assurance et de responsabilité civile", value: 1999 },
    { title: "Les honoraires et frais de courtage", value: 2000 },
    { title: "L’impôt foncier", value: 2001 },
    { title: "Les autres taxes locales ou contributions", value: 2002 },
    { title: "Les taxes sur les bureaux, les locaux commerciaux, les locaux de stockage et les surfaces de stationnement applicables en Ile-de-France", value: 2003 },
    { title: "Tous impôts, taxes, redevances, contributions et charges assis ou à asseoir sur les locaux ou l’immeuble", value: 2004 },
    { title: "Les frais d'élimination et de tri des déchets communs", value: 2005 },
    { title: "Les frais de main-d’œuvre, salaires, charges sociales et fiscales du personnel affecté à l’immeuble ou à l'ensemble immobilier", value: 2006 },
    { title: "La rémunération des entreprises extérieures affectées à différentes tâches", value: 2007 },
    { title: "Les frais relatifs au service d'un standard téléphonique et de toutes autres installations de télécommunications", value: 2008 },
    { title: "La rémunération du ou des mandataires chargés de la gestion, de la maintenance et de l’administration des ensembles immobiliers et des Locaux Loués", value: 2009 },
    { title: "Les frais de diagnostics et contrôles de toute nature portant sur l’ensemble immobilier", value: 2010 },
    { title: "Les charges issues de l'existence d'une association syndicale, groupement ou autre", value: 2011 },
    { title: "Autres charges spécifiques non visées dans cette liste", value: 2012 },
];