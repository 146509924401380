import * as React from 'react';
import Container from '@mui/material/Container';
import DialogActions from '@mui/material/DialogActions';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import Box from "@mui/material/Box";
import {REQUIRED_FIELD} from "../../Consts";
import Waiting from "../../Components/Waiting";
import MercureContainedButton from "../../Components/MercureContainedButton";
import Stepper from "@mui/material/Stepper";
import ColorlibConnector from "../../Components/ColorlibConnector";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ColorlibStepIcon from "../../Components/ColorlibStepIcon";
import Typography from "@mui/material/Typography";
import Rental from "./Rental";
import MercureOutlinedButton from "../../Components/MercureOutlinedButton";
import Possession from "./Possession";
import Divider from "@mui/material/Divider";

let contract = [];
const steps = ['Location', 'Bien'];

function getStepContent(step, rental, possession, tenants, onRentalChange, onPossessionChange, showInsurance) {
    switch (step) {
        case 0:
            return <Rental data={rental} tenants={tenants} onRentalChange={onRentalChange} />;
        case 1:
            return <Possession data={possession} onPossessionChange={onPossessionChange} showInsurance={showInsurance} />;
        default:
            throw new Error('Unknown step');
    }
}

export default function UpdateContract(props) {
    const [loading, setLoading] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const descriptionElementRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [tenants, setTenants] = React.useState([]);
    const [showInsurance, setShowInsurance] = React.useState(false);

    // Rental
    const [rental, setRental] = React.useState({
        // Data
        filter: [],
        rentalType: props.contract && props.contract.rentalType ? props.contract.rentalType : null,
        rentalDuration: props.contract && props.contract.rentalDuration ? props.contract.rentalDuration : "",
        startRentalDate: props.contract && props.contract.startRentalDate ? props.contract.startRentalDate : null,
        rentalAmount: props.contract && props.contract.rentalAmount ? props.contract.rentalAmount : "",
        chargesAmount: props.contract && props.contract.chargesAmount ? props.contract.chargesAmount : "",
        guaranteeAmount: props.contract && props.contract.guaranteeAmount ? props.contract.guaranteeAmount : "",
        paymentDay: props.contract && props.contract.paymentDay ? props.contract.paymentDay : "",
        chargeMode: props.contract && props.contract.chargeMode ? props.contract.chargeMode : "",
        rentalTerm: props.contract && props.contract.rentalTerm ? props.contract.rentalTerm : "",
        paymentMode: props.contract && props.contract.paymentMode ? props.contract.paymentMode : "",

        // Error
        tenantErrorMessage: "",
        startDateErrorMessage: '',
        rentalTypeErrorMessage: '',
        chargeModeErrorMessage: '',
        rentalAmountErrorMessage: '',
        chargesAmountErrorMessage: '',
        guaranteeAmountErrorMessage: '',
        rentalTermErrorMessage: '',
        paymentModeErrorMessage: '',
        rentalDurationErrorMessage: ''
    });

    const [possession, setPossession] = React.useState({
        // Data
        insurance: props.contract && props.contract.insurance ? props.contract.insurance : false,
        lastRentalOption: props.contract && props.contract.lastRentalOption ? props.contract.lastRentalOption : null,
        lastRentalAmount: props.contract && props.contract.lastRentalAmount ? props.contract.lastRentalAmount : "",
        lastPaymentDate: props.contract && props.contract.lastPaymentDate ? props.contract.lastPaymentDate : null,
        lastRevisionDate: props.contract && props.contract.lastRevisionDate ? props.contract.lastRevisionDate : null,
        attic: props.contract && props.contract.attic ? props.contract.attic : 0,
        veranda: props.contract && props.contract.veranda ? props.contract.veranda : 0,
        balcony: props.contract && props.contract.balcony ? props.contract.balcony : 0,
        washRoom: props.contract && props.contract.washRoom ? props.contract.washRoom : 0,
        cellar: props.contract && props.contract.cellar ? props.contract.cellar : 0,
        garden: props.contract && props.contract.garden ? props.contract.garden : 0,
        parking: props.contract && props.contract.parking ? props.contract.parking : 0,
        court: props.contract && props.contract.court ? props.contract.court : 0,
        pool: props.contract && props.contract.pool ? props.contract.pool : 0,
        jacuzzi: props.contract && props.contract.jacuzzi ? props.contract.jacuzzi : 0,
        entrance: props.contract && props.contract.entrance ? props.contract.entrance : 0,
        clearance: props.contract && props.contract.clearance ? props.contract.clearance : 0,
        dressing: props.contract && props.contract.dressing ? props.contract.dressing : 0,
        garage: props.contract && props.contract.garage ? props.contract.garage : 0,
        city: props.contract && props.contract.city ? props.contract.city : null,
        creationDate: props.contract && props.contract.creationDate ? props.contract.creationDate : dayjs(new Date()).toLocaleString(),

        // Error
        lastRentalAmountErrorMessage: '',
        lastPaymentDateErrorMessage: '',
        lastRevisionDateErrorMessage: '',
        creationDateErrorMessage: '',
        cityErrorMessage: ''
    });

    const getTenants = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/tenant/person/get-by-id-user/' + user.id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    setTenants(response);
                    localStorage.setItem('tenants', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    React.useEffect(() => {
        const lodgers = JSON.parse(localStorage.getItem('tenants'));

        if (null === lodgers) {
            getTenants();
        } else {
            setTenants(lodgers);
        }
    }, []);

    React.useEffect(() => {
        // Set filter
        let filters = [];

        tenants.forEach((tenant) => {
            if (tenant.id === props.contract.idTenant) {
                filters.push(tenant);
            }
        })

        setRental({
            ...rental,
            filter: filters,
            tenantErrorMessage: ''
        });
    }, [tenants]);

    async function updateContract() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        rental.filter && rental.filter.map(item => {
            if (!item.email) {
                props.handleMessage(`Le locataire ${item.firstName} ${item.lastName} ne possède pas d'adresse e-mail`);
                props.handleSeverity('error');
                props.handleOpenAlert();
                return;
            }
        })

        // Loading
        setLoading(true);

        let data = {};
        data.idUser = user.id;
        data.idTenant = rental.filter.map(item => item.id);
        data.name = props.contract.name;

        const rentalErrors = [
            'filter',
            'tenantErrorMessage',
            'startDateErrorMessage',
            'rentalTypeErrorMessage',
            'chargeModeErrorMessage',
            'rentalAmountErrorMessage',
            'chargesAmountErrorMessage',
            'guaranteeAmountErrorMessage',
            'rentalTermErrorMessage',
            'paymentModeErrorMessage',
            'rentalDurationErrorMessage'
        ];

        const possessionErrors = [
            'lastRentalAmountErrorMessage',
            'lastPaymentDateErrorMessage',
            'lastRevisionDateErrorMessage',
            'creationDateErrorMessage',
            'cityErrorMessage'
        ];

        for (let key in contract['rental']) {
            if (rentalErrors.indexOf(key) !== -1) {
                continue;
            }

            data[key] = contract['rental'][key];
        }

        for (let key in contract['possession']) {
            if (possessionErrors.indexOf(key) !== -1) {
                continue;
            }

            data[key] = contract['possession'][key];
        }

        //data.city = 'Sarcelles';
        data.returnUrl = window.location.href;
        //console.log('data', data);return;
        await fetch(process.env.REACT_APP_API_HOST + '/contract/update/' + props.contract.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    // Save filter in local storage
                    localStorage.setItem('contractFilter', JSON.stringify(rental.filter));
                    localStorage.setItem('contractYear', JSON.stringify(dayjs(rental.startRentalDate.toLocaleString()).format('YYYY')));

                    // Hide dialog
                    props.onClose();

                    // Save data in local storage for upload
                    localStorage.setItem('envelopeId', JSON.stringify(response.envelopeId));
                    localStorage.setItem('documentId', JSON.stringify(response.id));
                    localStorage.setItem('fileName', JSON.stringify(response.fileName));

                    // Hide waiting
                    setLoading(false);

                    // Redirect
                    window.location.href = response.redirect_url;
                })
            } else {
                console.log(result);
                setLoading(false);

                props.handleMessage("Votre contrat n'a pas pu être modifié suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);

            props.handleMessage("Votre contrat n'a pas pu être modifié suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    const handleCloseWaiting = () => {
        setLoading(false);
    };

    const onRentalChange = (event, value, id) => {
        if (value !== undefined) {
            if (value === 'rentalTerm') {
                setRental({
                    ...rental,
                    rentalTerm: event ? event.target.value : null,
                    rentalTermErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else if (value === 'paymentMode') {
                setRental({
                    ...rental,
                    paymentMode: event ? event.target.value : null,
                    paymentModeErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else {
                if (id === 'rentalType') {
                    let duration = '';

                    switch (value) {
                        case 'Location nue':
                            setShowInsurance(false);
                            duration = '3 ans';
                            break;

                        case 'Colocation nue':
                            setShowInsurance(true);
                            duration = '3 ans';
                            break;

                        case 'Location meublée':
                            setShowInsurance(false);
                            duration = '1 an';
                            break;

                        case 'Colocation meublée':
                            setShowInsurance(true);
                            duration = '1 an';
                            break;

                        default:
                            setShowInsurance(false);
                            break;
                    }

                    setRental({
                        ...rental,
                        rentalType: value ? value : null,
                        rentalTypeErrorMessage: value ? '' : REQUIRED_FIELD,
                        rentalDuration: duration,
                        rentalDurationErrorMessage: duration ? '' : REQUIRED_FIELD
                    });
                } else if (id === 'startRentalDate') {
                    setRental({
                        ...rental,
                        startRentalDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                        startDateErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else if (id === 'chargeMode') {
                    setRental({
                        ...rental,
                        chargeMode: value ? value : null,
                        chargeModeErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else {
                    setRental({
                        ...rental,
                        filter: value.length > 0 ? value : [],
                        tenantErrorMessage: value.length > 0 ? '' : REQUIRED_FIELD
                    });
                    //console.log('val', value)
                    value && value.map(item => {
                        if (!item.email) {
                            props.handleMessage(`Le locataire ${item.firstName} ${item.lastName} ne possède pas d'adresse e-mail`);
                            props.handleSeverity('error');
                            props.handleOpenAlert();
                        }
                    })
                }
            }
        } else {
            switch (event.target.id) {
                case 'rentalAmount':
                    setRental({
                        ...rental,
                        rentalAmount: event.target.value,
                        rentalAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                    });
                    break;

                case 'rentalDuration':
                    setRental({
                        ...rental,
                        rentalDuration: event.target.value,
                        rentalDurationErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                    });
                    break;

                case 'chargesAmount':
                    setRental({
                        ...rental,
                        chargesAmount: event.target.value,
                        chargesAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                    });
                    break;

                case 'guaranteeAmount':
                    setRental({
                        ...rental,
                        guaranteeAmount: event.target.value,
                        guaranteeAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                    });
                    break;

                case 'paymentDay':
                    setRental({
                        ...rental,
                        paymentDay: event.target.value
                    });
                    break;

                default:
                    break;
            }
        }
    }

    const onPossessionChange = (event, value, id) => {
        //console.log("ev", value)
        if (event) {
            if (event.target.name === 'lastRentalAmount') {
                if (possession.lastRentalOption === 'yes') {
                    setPossession({
                        ...possession,
                        lastRentalAmount: event.target.value,
                        lastRentalAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                    });
                } else {
                    setPossession({
                        ...possession,
                        lastRentalAmount: '',
                        lastRentalAmountErrorMessage: ''
                    });
                }
            } else {
                if (event.target.name === 'insurance') {
                    setPossession({
                        ...possession,
                        [event.target.name]: event.target.checked
                    });
                } else {
                    setPossession({
                        ...possession,
                        [event.target.name]: event.target.value
                    });
                }
            }
        } else {
            if (id) {
                if (id === 'lastPaymentDate') {
                    if (possession.lastRentalOption === 'yes') {
                        setPossession({
                            ...possession,
                            lastPaymentDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                            lastPaymentDateErrorMessage: value ? '' : REQUIRED_FIELD
                        });
                    } else {
                        setPossession({
                            ...possession,
                            lastPaymentDate: null,
                            lastPaymentDateErrorMessage: ''
                        });
                    }
                } else if (id === 'lastRevisionDate') {
                    if (possession.lastRentalOption === 'yes') {
                        setPossession({
                            ...possession,
                            lastRevisionDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                            lastRevisionDateErrorMessage: value ? '' : REQUIRED_FIELD
                        });
                    } else {
                        setPossession({
                            ...possession,
                            lastRevisionDate: null,
                            lastRevisionDateErrorMessage: ''
                        });
                    }
                } else if (id === 'creationDate') {
                    setPossession({
                        ...possession,
                        creationDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                        creationDateErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else {
                    setPossession({
                        ...possession,
                        city: value.description,
                        cityErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                }
            }
        }
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (rental.filter.length === 0) {
                setRental({
                    ...rental,
                    tenantErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    tenantErrorMessage: ''
                });

                let noMailError = false;
                rental.filter.map(item => {
                    if (!item.email) {
                        props.handleMessage(`Le locataire ${item.firstName} ${item.lastName} ne possède pas d'adresse e-mail`);
                        props.handleSeverity('error');
                        props.handleOpenAlert();
                        noMailError = true;
                    }
                })

                if (noMailError) {
                    return;
                }
            }

            if (rental.rentalType === null) {
                setRental({
                    ...rental,
                    rentalTypeErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalTypeErrorMessage: ''
                });
            }

            if (rental.startRentalDate === null) {
                setRental({
                    ...rental,
                    startDateErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    startDateErrorMessage: ''
                });
            }

            if (rental.rentalAmount === '') {
                setRental({
                    ...rental,
                    rentalAmountErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalAmountErrorMessage: ''
                });
            }

            if (rental.chargesAmount === '') {
                setRental({
                    ...rental,
                    chargesAmountErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    chargesAmountErrorMessage: ''
                });
            }

            if (rental.guaranteeAmount === '') {
                setRental({
                    ...rental,
                    guaranteeAmountErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    guaranteeAmountErrorMessage: ''
                });
            }

            if (rental.chargeMode === null || rental.chargeMode === '') {
                setRental({
                    ...rental,
                    chargeModeErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    chargeModeErrorMessage: ''
                });
            }

            if (rental.rentalTerm === null || rental.rentalTerm === '') {
                setRental({
                    ...rental,
                    rentalTermErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalTermErrorMessage: ''
                });
            }

            if (rental.paymentMode === null || rental.paymentMode === '') {
                setRental({
                    ...rental,
                    paymentModeErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    paymentModeErrorMessage: ''
                });
            }

            if (rental.rentalDuration === '') {
                setRental({
                    ...rental,
                    rentalDurationErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalDurationErrorMessage: ''
                });
            }

            // Rental
            let tempRental = {};
            for (let key in rental) {
                tempRental[key] = rental[key];
            }
            contract['rental'] = tempRental;
            localStorage.setItem('contractRental', JSON.stringify(contract['rental']));
        } else if (activeStep === 1) {
            // Possession
            if (possession.lastRentalOption === 'yes') {
                if (possession.lastRentalAmount === '') {
                    setPossession({
                        ...possession,
                        lastRentalAmountErrorMessage: REQUIRED_FIELD
                    });
                    return;
                } else {
                    setPossession({
                        ...possession,
                        lastRentalAmountErrorMessage: ''
                    });
                }

                if (possession.lastPaymentDate === '' || possession.lastPaymentDate === null) {
                    setPossession({
                        ...possession,
                        lastPaymentDateErrorMessage: REQUIRED_FIELD
                    });
                    return;
                } else {
                    setPossession({
                        ...possession,
                        lastPaymentDateErrorMessage: ''
                    });
                }

                if (possession.lastRevisionDate === '' || possession.lastRevisionDate === null) {
                    setPossession({
                        ...possession,
                        lastRevisionDateErrorMessage: REQUIRED_FIELD
                    });
                    return;
                } else {
                    setPossession({
                        ...possession,
                        lastRevisionDateErrorMessage: ''
                    });
                }
            }

            if (possession.city === null) {
                setPossession({
                    ...possession,
                    cityErrorMessage: REQUIRED_FIELD
                });
                //return;
            } else {
                setPossession({
                    ...possession,
                    cityErrorMessage: ''
                });
            }

            if (possession.creationDate === '' || possession.creationDate === null) {
                setPossession({
                    ...possession,
                    creationDateErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setPossession({
                    ...possession,
                    creationDateErrorMessage: ''
                });
            }

            let tempPossession = {};
            for (let key in possession) {
                tempPossession[key] = possession[key];
            }
            contract['possession'] = tempPossession;
            localStorage.setItem('contractPossession', JSON.stringify(contract['possession']));
        }

        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

    }, [open]);

    function setContract(housing) {
        // Rental
        setRental({
            ...rental,
            ...contract['rental']
        });

        // Possession
        setPossession({
            ...possession,
            ...contract['possession']
        });
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier un contrat
                </Typography>
            </Box>
            <Divider />
            <Container component="" maxWidth="xs" ref={descriptionElementRef} tabIndex={-1} sx={{ mb: 3 }}>
                <Stepper alternativeLabel
                         activeStep={activeStep}
                         sx={{ pt: 3, pb: 5 }}
                         connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography>
                            Vous êtes sur le point de créer votre locataire. Cliquer sur le bouton Valider pour confirmer la création de votre locataire ou sur Précédent pour vérifier les données saisies.
                        </Typography>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {getStepContent(activeStep, rental, possession, tenants, onRentalChange, onPossessionChange, showInsurance)}
                    </React.Fragment>
                )}
            </Container>
            <Divider />
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                    {activeStep !== 0 && (
                        <MercureOutlinedButton
                            onClick={handleBack}
                            label="Précédent" />
                    )}

                    {activeStep === steps.length ? (
                        <MercureContainedButton
                            onClick={updateContract}
                            label="Valider" />
                    ) : (
                        <MercureContainedButton
                            onClick={handleNext}
                            label="Suivant" />
                    )}
                </Box>
            </DialogActions>
            {loading && (
                <Waiting loading={loading} title="Signature électronique" message="Merci de patienter, vous allez être rédirigé dans quelques instants..."/>
            )}
        </div>
    )
}