import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function Intro(props) {
	const goTo = (id) => {
		props.handleAnchor(id);
	}

	return (
		<section className="header4 cid-tvJc9i0xwq mbr-fullscreen" id="header4-b">
		    <div className="container-fluid">
		        <div className="row">
		            <div className="content-wrap">
		                <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-3 display-1"><strong>Logiciel de gestion des biens immobiliers&nbsp;</strong></h1>
		                <Box component="form" noValidate sx={{ ml: 3 }}>
		                	<Button color="secondary" variant="contained" size="large" onClick={() => goTo('about')}>En savoir plus</Button>
		                </Box>
		            </div>
		        </div>
		    </div>
		</section>
	)
}

export default Intro;