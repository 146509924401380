import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import MercureContainedButton from "./MercureContainedButton";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StyledMenu from "./StyledMenu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import ListItemText from "@mui/material/ListItemText";
import SendIcon from "@mui/icons-material/Send";
import AppBar from "@mui/material/AppBar";
import * as React from "react";

export default function CustomSearchBar(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [renderOption, setRenderOption] = React.useState(props.renderOption);
    const openMenu = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" color="default" elevation={0} sx={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}>
            <Toolbar>
                <Grid container spacing={2} alignItems="center" sx={{mt: 1, mb: 2}}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Année"
                                id="receiptYear"
                                inputFormat="YYYY"
                                maxDate={props.maxDate}
                                views={['year']}
                                value={props.year}
                                onChange={(newValue) => {
                                    props.onYearChange(null, newValue)
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            disablePortal
                            options={props.options}
                            id="tenant"
                            autoHighlight
                            value={props.filter}
                            onChange={(event, newValue) => {
                                props.onFilterChange(event, newValue)
                            }}
                            getOptionLabel={(option) => option && props.renderOption === 'familyName' ? `${option.firstName} ${option.lastName}` : `${option.name}`}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{mr: 2, flexShrink: 0}} {...props}>
                                    {renderOption === 'familyName' ? `${option.firstName} ${option.lastName}` : `${option.name}`}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label={props.label}
                                                                fullWidth/>}
                        />
                    </Grid>
                    <Grid item>
                        <MercureContainedButton label="Chercher"
                                                disableElevation
                                                disabled={props.buttonDisabled}
                                                onClick={props.onClick} />
                    </Grid>
                    <Grid item>
                        <IconButton aria-label="Plus"
                                    onClick={handleOpenMenu}
                                    aria-controls={openMenu ? 'menuButton' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMenu ? 'true' : undefined}>
                            <MoreVertIcon />
                        </IconButton>
                        <StyledMenu
                            id="menuButton"
                            MenuListProps={{
                                'aria-labelledby': 'menuButton'
                            }}
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={() => {props.handleMenu(); handleCloseMenu();}} disableRipple>
                                <ListItemIcon>
                                    <AddIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Ajouter</ListItemText>
                            </MenuItem>
                            {/*<MenuItem onClick={() => {props.handleMenu(); handleCloseMenu();}} disableRipple>
                                <ListItemIcon>
                                    <SendIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Génération & envoi automatique</ListItemText>
                            </MenuItem>*/}
                        </StyledMenu>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}