import * as React from 'react';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import FLAT from "./Images/flat.jpg";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "./Components/Alert";
import {useNavigate} from "react-router-dom";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";
import LinearProgress from "@mui/material/LinearProgress";
import validator from "validator";

const theme = createTheme();

export default function ForgottenPassword() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [token, setToken] = React.useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [capColor, setCapColor] = React.useState("#FF0000");
    const [lowColor, setLowColor] = React.useState("#FF0000");
    const [numberColor, setNumberColor] = React.useState("#FF0000");
    const [lengthColor, setLengthColor] = React.useState("#FF0000");
    const [specialCharacterColor, setSpecialCharacterColor] = React.useState("#FF0000");
    const [showProgressBar, setShowProgressBar] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordLevelMessage, setPasswordLevelMessage] = React.useState('');
    const [email, setEmail] = React.useState("");
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const toSignIn = () => {
        navigate('/sign-in');
    }

    const onEmailChange = (e) => {
        let email = e.target.value;

        if (email != '') {
            if (validator.isEmail(email)) {
                setEmailErrorMessage("");
            } else {
                setEmailErrorMessage("L'adresse e-mail saisie est invalide");
            }
        } else {
            setEmailErrorMessage("");
        }

        setEmail(email);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        let confirm = formData.get('confirm');
        let data = {};
        data.email = email;
        data.password = formData.get('password');
        data.token = token;

        if (data.password !== confirm) {
            setMessage("Votre mot de passe n'a pas été correctement confirmé.");
            handleOpen();
            return;
        }

        // Disable button
        setLoading(true);
        
        fetch(process.env.REACT_APP_API_HOST + '/user/forgotten-password', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            console.log('result', result);
            // Enabling button
            setLoading(false);

            if (result.status === 204) {
                navigate('/updated-password');
            } else if (result.status === 400) {
                setMessage("Une erreur s'est produite lors de la validation du recaptacha. Merci de reessayer.");
                handleOpen();
            } else if (result.status === 404) {
                setMessage("L'adresse e-mail saisie n'existe pas. Merci de corriger");
                handleOpen();
            } else {
                console.log('else', result);
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setMessage("Une erreur s'est produite lors de la modification de votre mot de passe.");
            handleOpen();
        });

        setRefreshReCaptcha(r => !r);
    };

    const handleVerify = React.useCallback((token) => {
        setToken(token);
    });

    const handlePassword = (passwordValue) => {
        const strengthChecks = {
            length: 0,
            hasUpperCase: false,
            hasLowerCase: false,
            hasDigit: false,
            hasSpecialChar: false,
        };

        strengthChecks.length = passwordValue.length >= 8;
        strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
        strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
        strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
        strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

        if (true === strengthChecks.hasUpperCase) {
            setCapColor("#047C0D");
        } else {
            setCapColor("#FF0000");
        }

        if (true === strengthChecks.hasLowerCase) {
            setLowColor("#047C0D");
        } else {
            setLowColor("#FF0000");
        }

        if (true === strengthChecks.length) {
            setLengthColor("#047C0D");
        } else {
            setLengthColor("#FF0000");
        }

        if (true === strengthChecks.hasDigit) {
            setNumberColor("#047C0D");
        } else {
            setNumberColor("#FF0000");
        }

        if (true === strengthChecks.hasSpecialChar) {
            setSpecialCharacterColor("#047C0D");
        } else {
            setSpecialCharacterColor("#FF0000");
        }

        let verifiedList = Object.values(strengthChecks).filter((value) => value);
        let strength =
            verifiedList.length === 5
                ? "Fort"
                : verifiedList.length >= 2
                    ? "Moyen"
                    : "Faible";

        setPassword(passwordValue);
        setProgress((verifiedList.length / 5) * 100);

        if (passwordValue) {
            setPasswordLevelMessage(strength);
            setShowProgressBar(true);
        } else {
            setPasswordLevelMessage('');
            setShowProgressBar(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{height: '100vh'}}>
                <CssBaseline/>
                <Grid item
                    xs={false}
                    sm={4}
                    md={8}
                    sx={{
                        backgroundImage: `url(${FLAT})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: "100vh"
                    }}
                />
                <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Modifier son mot de passe
                        </Typography>
                        <Grid component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        value={email}
                                        label="E-mail"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        error={emailErrorMessage !== ""}
                                        helperText= {emailErrorMessage}
                                        onChange={onEmailChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type="password"
                                        autoComplete="password"
                                        value={password}
                                        onChange={(event) => {
                                            handlePassword(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <LinearProgress variant="determinate" value={progress} color="inherit"/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 13, fontWeight: "bold"}}>
                                        Votre mot de passe doit contenir au moins :
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={capColor}>
                                        - Une majuscule
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={lowColor}>
                                        - Une minuscule
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={specialCharacterColor}>
                                        - Un caractère spécial
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={numberColor}>
                                        - Un chiffre
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={lengthColor}>
                                        - 8 caractères
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="confirm"
                                        label="Confirmer le mot de passe"
                                        type="password"
                                        autoComplete="password"
                                    />
                                </Grid>
                            </Grid>

                            <GoogleReCaptcha onVerify={handleVerify} refreshReCaptcha={refreshReCaptcha}/>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                sx={{mt: 3, mb: 2}}
                                size="large"
                            >
                                Valider
                            </Button>
                            <Grid container>
                                <Grid item>
                                    <Link onClick={toSignIn} variant="body2">
                                        {"Se connecter"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress />
            </Backdrop>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
}