import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Confirm from './Confirm';
import Home from './Home/Home';
import Paperbase from './Paperbase/Paperbase';
import { createBrowserHistory } from "history";
import { Layout } from './Layout';
import Corporation from './Account/Corporation';
import { Corporation as HousingCorporation } from './Housing/Housing/Corporation';
import TenantCorporation from './Tenant/Tenant/TenantCorporation';
import PersonalData from './Account/PersonalData';
import Housing from './Housing/Housing/Housing';
import Tenant from './Tenant/Tenant/Tenant';
import Raise from './Tenant/Raise/Raise';
import Dashboard from "./Overview/Dashboard";
import Receipt from "./Documents/Receipt/Receipt";
import Coowner from "./Housing/Coowner/Coowner";
import CoownerCorporation from "./Housing/Coowner/CoownerCorporation";
import {createTheme} from "@mui/material/styles";
import DocumentTheme from "./Themes/DocumentTheme";
import PaperbaseTheme from "./Themes/PaperbaseTheme";
import TenantTheme from "./Themes/TenantTheme";
import HousingTheme from "./Themes/HousingTheme";
import Contract from "./Documents/Contract/Contract";
import ReceiptCorporation from "./Documents/Receipt/ReceiptCorporation";
import ContractCorporation from "./Documents/Contract/ContractCorporation";
import OverviewTheme from "./Themes/OverviewTheme";
import AccountingTheme from "./Themes/AccountingTheme";
import Invoice from "./Documents/Invoice/Invoice";
import Activation from "./Activation";
import Feature from "./Home/Feature";
import About from "./Home/About";
import Contact from "./Home/Contact";
import ForgottenPassword from "./ForgottenPassword";
import UpdatedPassword from "./UpdatedPassword";

const history = createBrowserHistory();
const documentTheme = createTheme(PaperbaseTheme, DocumentTheme);
const tenantTheme = createTheme(PaperbaseTheme, TenantTheme);
const housingTheme = createTheme(PaperbaseTheme, HousingTheme);
const overviewTheme = createTheme(PaperbaseTheme, OverviewTheme);
//const accountingTheme = createTheme(PaperbaseTheme, AccountingTheme);

class App extends React.Component {
    render() {
        return(
            <React.Fragment>           
                <Router history={history}>
                    <Layout>
                        <Routes>
                            <Route path="/" element={<Home/>} />
                            <Route path="/#feat" element={<Feature/>} />
                            <Route path="/#about" element={<About/>} />
                            <Route path="/#contact" element={<Contact/>} />
                            <Route path="/sign-in" element={<SignIn/>} />
                            <Route path="/sign-up" element={<SignUp/>} />
                            <Route path="/forgotten-password" element={<ForgottenPassword/>} />
                            <Route path="/updated-password" element={<UpdatedPassword/>} />
                            <Route path="/confirm" element={<Confirm/>} />
                            <Route path="/activation" element={<Activation/>} />
                            <Route path="/overview" element={<Paperbase theme={overviewTheme} content={<Dashboard />} />} />
                            <Route path="/account" element={<Paperbase history={history} content={<PersonalData />} />} />
                            <Route path="/account/corporation" element={<Paperbase history={history} content={<Corporation />} />} />
                            <Route path="/account/personal-data" element={<Paperbase history={history} content={<PersonalData />} />} />
                            <Route path="/housing" element={<Paperbase theme={housingTheme} content={<Housing />} />} />
                            <Route path="/housing/corporation" element={<Paperbase theme={housingTheme} content={<HousingCorporation />} />} />
                            <Route path="/housing/coowner" element={<Paperbase theme={housingTheme} content={<Coowner />} />} />
                            <Route path="/housing/coowner/corporation" element={<Paperbase theme={housingTheme} content={<CoownerCorporation />} />} />
                            <Route path="/tenant" element={<Paperbase theme={tenantTheme} content={<Tenant />} />} />
                            <Route path="/tenant/corporation" element={<Paperbase theme={tenantTheme} content={<TenantCorporation />} />} />
                            <Route path="/tenant/raise" element={<Paperbase theme={tenantTheme} content={<Raise />} />} />
                            <Route path="/receipt" element={<Paperbase theme={documentTheme} content={<Receipt />} />} />
                            <Route path="/receipt/corporation" element={<Paperbase theme={documentTheme} content={<ReceiptCorporation />} />} />
                            <Route path="/contract" element={<Paperbase theme={documentTheme} content={<Contract />} />} />
                            <Route path="/contract/corporation" element={<Paperbase theme={documentTheme} content={<ContractCorporation />} />} />
                            <Route path="/invoice" element={<Paperbase theme={documentTheme} content={<Invoice />} />} />
                        </Routes>
                    </Layout>
                </Router>
            </React.Fragment>
        )
    }
}

export default App;
