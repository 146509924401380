import React, { useEffect } from 'react';
import Header from './Header';
import Feature from './Feature';
import About from './About';
import Intro from './Intro';
import Contact from './Contact';
import Footer from './Footer';
import {useLocation, useNavigate} from 'react-router-dom';

function Home() {
	const navigate = useNavigate();
	const { pathname, hash, key } = useLocation();

	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} else {
			setTimeout(() => {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
				}
			}, 0);
		}
	}, [pathname, hash, key]); // do this on route change

	const handleAnchor = (id) => {
		navigate('/#' + id);
	}

	return (
		<div>
			<Header handleAnchor={handleAnchor} showMenu={true}/>
			<Intro handleAnchor={handleAnchor} />
			<Feature />
			<About />
			<Contact />
			<Footer handleAnchor={handleAnchor} showMenu={true}/>
		</div>
	)
}

export default Home;