import * as React from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from "@mui/material/Snackbar";
import Drawer from '@mui/material/Drawer';
import Confirm from "../../Components/Confirm";
import ListSubheader from '@mui/material/ListSubheader';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Alert from "../../Components/Alert";
import AddHousingCorporation from "./AddHousingCorporation";
import SearchBar from "../../Components/SearchBar";
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import UpdateHousingCorporation from "./UpdateHousingCorporation";
import Divider from "@mui/material/Divider";

export function Corporation() {
    let [corporations, setHousingsCorporation] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const title = 'Attention';
    const confirmMessage = "Etes-vous sûr de vouloir supprimer cette entreprise ?";
    let [selectedCorporation, setSelectedHousing] = React.useState();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleMessage = (message) => {
        setMessage(message);
    };

    const handleSeverity = (severity) => {
        setSeverity(severity);
    };

    const getHousingsCorporation = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/housing/corporation/get/user/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousingsCorporation(response);
                    localStorage.setItem('corporations', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    React.useEffect(() => {
        const possessions = JSON.parse(localStorage.getItem('corporations'));

        if (null === possessions || possessions.length === 0) {
            getHousingsCorporation();
        } else {
            setHousingsCorporation(possessions);
        }
    }, []);

    const toggleDrawer = (open, corporation) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (open) {
            setSelectedHousing(corporation);
        } else {
            setSelectedHousing();
        }

        setOpenDrawer(open);
    }

    const corporationUpdated = () => {
        setOpenDrawer(false);
        setSelectedHousing();
        getHousingsCorporation();
        handleMessage("Vos données ont été modifiées");
        handleSeverity('success');
        handleOpenAlert();
    }

    const corporationDeleted = () => {
        setSelectedHousing();
        getHousingsCorporation();
        handleMessage("Votre entreprise a été supprimée");
        handleSeverity('success');
        handleOpenAlert();
    }

    const corporationNotUpdated = () => {
        setOpenDrawer(false);
        setSelectedHousing();
        handleMessage("Vos données n'ont pas pu être modifiées suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const corporationNotDeleted = () => {
        handleMessage("Votre entreprise n'a pas pu être supprimée suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const duplicate = React.useCallback((corporation) => {
        let data = {};

        for (let key in corporation) {
            if ('id' === key || 'createdAt' === key || 'updatedAt' === key || 'housingName' === key) {
                continue;
            }

            data[key] = corporation[key];
        }

        //console.log('data',data);return
        fetch(process.env.REACT_APP_API_HOST + '/housing/corporation/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                // Show Alert
                handleMessage("Votre entreprise a été dupliquée");
                handleSeverity('success');
                handleOpenAlert();

                // Refresh data
                getHousingsCorporation();
            }
        }).catch((error) => {
            console.log(error);
            handleMessage("Votre entreprise n'a pas pu être dupliquée suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        });
    }, []);

    const toggleConfirm = (open, corporation) => (event) => {
        if (open) {
            setSelectedHousing(corporation);
        } else {
            setSelectedHousing();
        }

        setOpenConfirm(open);
    }

    const anchorPosition = 'right';

    const onFilterChange = (event) => {
        setFilter(event.target.value);
    }

    const filteredHousings = corporations.filter(corporation => {
        if (filter === '') {
            return corporation;
        } else {
            return (corporation.name && corporation.name.toLowerCase().includes(filter.toLowerCase())) ||
                (corporation.address && corporation.address.toLowerCase().includes(filter.toLowerCase()))
        }
    })

    return (
        <div>
            <Paper sx={{maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
                {open === true && (
                    <AddHousingCorporation open={open} onClose={handleClose} refresh={getHousingsCorporation} handleOpenAlert={handleOpenAlert} handleMessage={handleMessage} handleSeverity={handleSeverity}/>
                )}
                <SearchBar placeholder="Chercher une entreprise" onFilterChange={onFilterChange} buttonLabel="Ajouter une entreprise" onButtonClick={handleOpen} onRefresh={getHousingsCorporation}/>

                {filteredHousings.length === 0 ? (
                    <Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
                        Vous n'avez aucune entreprise
                    </Typography>
                ) : (
                    <List aria-labelledby="nested-list-subheader"
                          subheader={
                              <ListSubheader component="div" id="nested-list-subheader">
                                  {filteredHousings.length} entreprise.s
                              </ListSubheader>
                          }
                    >
                        {filteredHousings && filteredHousings.map && filteredHousings.map((corporation, index) =>
                            <div>
                                <ListItem key={corporation.id}
                                          secondaryAction={
                                              <Grid>
                                                  <Tooltip title="Modifier">
                                                      <IconButton edge="end" aria-label="edit" onClick={toggleDrawer(true, corporation)}>
                                                          <EditIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Dupliquer">
                                                      <IconButton edge="end" aria-label="duplicate" onClick={() => duplicate(corporation)}>
                                                          <ContentCopyIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Supprimer">
                                                      <IconButton edge="end" aria-label="delete" color="error" onClick={toggleConfirm(true, corporation)}>
                                                          <DeleteIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                              </Grid>
                                          }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BusinessRoundedIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id={corporation.id}
                                                  primary={corporation.name}
                                                  secondary={
                                                      <React.Fragment>
                                                          <Typography variant="subtitle1">
                                                              {corporation.address}
                                                          </Typography>
                                                      </React.Fragment>
                                                  }
                                    />
                                </ListItem>
                                {
                                    ((index === 0 && filteredHousings.length > 1) || index < filteredHousings.length - 1) && <Divider variant="inset" component="li" />
                                }
                            </div>
                        )}
                    </List>
                )}

                <Drawer anchor={anchorPosition}
                        PaperProps={{
                            sx: {backgroundColor: "#ffffff"}
                        }}
                        open={openDrawer}
                        onClose={toggleDrawer(false)}>
                    <UpdateHousingCorporation corporation={selectedCorporation} corporationUpdated={corporationUpdated} corporationNotUpdated={corporationNotUpdated}/>
                </Drawer>
            </Paper>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>

            <Confirm open={openConfirm}
                     title={title}
                     message={confirmMessage}
                     onClose={toggleConfirm(false)}
                     selected={selectedCorporation}
                     type="housing-corporation"
                     titleBackgroundColor="#ff9800"
                     corporationDeleted={corporationDeleted}
                     corporationNotDeleted={corporationNotDeleted}
                     titleColor="white"/>
        </div>
    );
}