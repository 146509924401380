import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import GeneralData from './GeneralData';
import Equipments from "./Equipments";
import Leasing from "./Leasing";
import Divider from '@mui/material/Divider';
import dayjs from "dayjs";
import ColorlibConnector from "../../Components/ColorlibConnector";
import ColorlibStepIcon from "../../Components/ColorlibStepIcon";
import MercureContainedButton from "../../Components/MercureContainedButton";
import MercureOutlinedButton from "../../Components/MercureOutlinedButton";
import DialogActions from "@mui/material/DialogActions";

let housing = [];
const steps = ['Informations générales', 'Equipements', 'Types de bail'];

function getStepContent(step, generalData, equipments, leasing, onGeneralDataChange, onEquipmentsChange, onLeasingChange) {
    switch (step) {
        case 0:
            return <GeneralData data={generalData} onGeneralDataChange={onGeneralDataChange} />;
        case 1:
            return <Equipments data={equipments} onEquipmentsChange={onEquipmentsChange} />;
        case 2:
            return <Leasing data={leasing} onLeasingChange={onLeasingChange} />;
        default:
            throw new Error('Unknown step');
    }
}

export default function UpdateHousing(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('body');
    const descriptionElementRef = React.useRef(null);

    // General Data
    const [generalData, setGeneralData] = React.useState({
        name: props.housing ? props.housing.name : null,
        type: props.housing ? props.housing.type : null,
        boughtAt: props.housing && props.housing.boughtAt ? dayjs().year(props.housing.boughtAt) : null,
        pieces: props.housing ? props.housing.pieces : null,
        area: props.housing ? props.housing.area : null,
        floor: props.housing ? props.housing.floor : null,
        door: props.housing ? props.housing.door : null,
        address: props.housing ? props.housing.address : null,
        regime: props.housing ? props.housing.regime : null,
        currency: props.housing ? props.housing.currency : null,
        nbBatch: props.housing ? props.housing.nbBatch : null,
        batchName: props.housing ? props.housing.batchName : null,
        numBatch: props.housing ? props.housing.numBatch : null,
        thousandths: props.housing ? props.housing.thousandths : null,
        constructionYear: props.housing && props.housing.constructionYear ? dayjs().year(props.housing.constructionYear) : null,
        technologicalRisks: props.housing ? props.housing.technologicalRisks : null
    });

    // Equipments
    const [equipments, setEquipments] = React.useState({
        heating: props.housing ? props.housing.heating : null,
        water: props.housing ? props.housing.water : null,
        counters: props.housing ? props.housing.counters : null,
        cellar: props.housing ? props.housing.cellar : null,
        parking: props.housing ? props.housing.parking : null,
        balcony: props.housing ? props.housing.balcony : null,
        elevator: props.housing ? props.housing.elevator : null,
        fiber: props.housing ? props.housing.fiber : null,
        playground: props.housing ? props.housing.playground : null,
        guardian: props.housing ? props.housing.guardian : null,
        antenna: props.housing ? props.housing.antenna : null,
        bikeRoom: props.housing ? props.housing.bikeRoom : null,
        intercom: props.housing ? props.housing.intercom : null,
        greenArea: props.housing ? props.housing.greenArea : null,
        washRoom: props.housing ? props.housing.washRoom : null,
        fridge: props.housing ? props.housing.fridge : null,
        freezer: props.housing ? props.housing.freezer : null,
        court: props.housing ? props.housing.court : null,
        pool: props.housing ? props.housing.pool : null,
        hotTub: props.housing ? props.housing.hotTub : null,
        sauna: props.housing ? props.housing.sauna : null,
        chimney: props.housing ? props.housing.chimney : null,
        kitchenFurniture: props.housing ? props.housing.kitchenFurniture : null,
        oven: props.housing ? props.housing.oven : null,
        microwave: props.housing ? props.housing.microwave : null,
        dishWasher: props.housing ? props.housing.dishWasher : null,
        hotPlates: props.housing ? props.housing.hotPlates : null,
        hood: props.housing ? props.housing.hood : null,
        alarm: props.housing ? props.housing.alarm : null,
    });

    // Leasing
    const [leasing, setLeasing] = React.useState({
        leasing: props.housing ? props.housing.leasing : null,
        commercial: props.housing ? props.housing.commercial : null,
        dwelling: props.housing ? props.housing.dwelling : null
    });

    const onGeneralDataChange = (event, value, id) => {
        if (event) {
            switch (event.target.id) {
                case 'name':
                    setGeneralData({
                        ...generalData,
                        name: event.target.value
                    });
                    break;

                case 'area':
                    setGeneralData({
                        ...generalData,
                        area: event.target.value
                    });
                    break;

                case 'pieces':
                    setGeneralData({
                        ...generalData,
                        pieces: event.target.value
                    });
                    break;

                case 'floor':
                    setGeneralData({
                        ...generalData,
                        floor: event.target.value
                    });
                    break;

                case 'door':
                    setGeneralData({
                        ...generalData,
                        door: event.target.value
                    });
                    break;

                case 'nbBatch':
                    setGeneralData({
                        ...generalData,
                        nbBatch: event.target.value
                    });
                    break;

                case 'batchName':
                    setGeneralData({
                        ...generalData,
                        batchName: event.target.value
                    });
                    break;

                case 'numBatch':
                    setGeneralData({
                        ...generalData,
                        numBatch: event.target.value
                    });
                    break;

                case 'thousandths':
                    setGeneralData({
                        ...generalData,
                        thousandths: event.target.value
                    });
                    break;

                default:
                    let ids = event.target.id.split('-');

                    if (ids[0] === 'type') {
                        setGeneralData({
                            ...generalData,
                            type: value.label
                        });
                    } else if (ids[0] === 'regime') {
                        setGeneralData({
                            ...generalData,
                            regime: value.label
                        });
                    } else if (ids[0] === 'currency') {
                        setGeneralData({
                            ...generalData,
                            currency: value.label
                        });
                    } else {
                        if (event.target.name === 'technologicalRisks') {
                            setGeneralData({
                                ...generalData,
                                [event.target.name]: event.target.value
                            });
                        } else if (null !== value) {
                            //console.log(value);
                            setGeneralData({
                                ...generalData,
                                address: value.description
                            });
                        }
                    }

                    break;
            }
        } else {
            if (id === 'boughtAt') {
                setGeneralData({
                    ...generalData,
                    boughtAt: value ? dayjs(value.toLocaleString()).format('YYYY') : null
                });
            } else if (id === 'constructionYear') {
                setGeneralData({
                    ...generalData,
                    constructionYear: dayjs(value.toLocaleString()).format('YYYY')
                });
            }
        }
    }

    const onEquipmentsChange = (event, value) => {
        let ids = event.target.id.split('-');

        if (ids[0] === 'heating') {
            setEquipments({
                ...equipments,
                heating: value.label
            });
        } else if (ids[0] === 'water') {
            setEquipments({
                ...equipments,
                water: value.label
            });
        } else if (ids[0] === 'counters') {
            setEquipments({
                ...equipments,
                counters: value.label
            });
        } else {
            setEquipments({
                ...equipments,
                [event.target.name]: event.target.checked
            });
        }
    }

    const onLeasingChange = (event) => {
        if (event.target.name === 'leasing') {
            setLeasing({
                ...leasing,
                [event.target.name]: event.target.checked
            });
        } else {
            setLeasing({
                ...leasing,
                [event.target.name]: event.target.value
            });
        }
    };

    const handleNext = () => {
        if (activeStep == 0) {
            // General data
            let tempGeneralData = {};
            for (let key in generalData) {
                tempGeneralData[key] = generalData[key];
            }
            housing['generalData'] = tempGeneralData;
        } else if (activeStep == 1) {
            // Equipments
            let tempEquipments = {};
            for (let key in equipments) {
                tempEquipments[key] = equipments[key];
            }
            housing['equipments'] = tempEquipments;
        } else if (activeStep == 2) {
            // Leasing
            let tempLeasing = {};
            for (let key in leasing) {
                tempLeasing[key] = leasing[key];
            }
            housing['leasing'] = tempLeasing;
        }

        setActiveStep(activeStep + 1);
    };

    async function saveHousing() {
        let data = {};
        data.idUser = props.housing.idUser;
        const id = props.housing.id;

        for (let key in housing['generalData']) {
            data[key] = housing['generalData'][key];
        }

        for (let key in housing['equipments']) {
            data[key] = housing['equipments'][key];
        }

        for (let key in housing['leasing']) {
            data[key] = housing['leasing'][key];
        }

        //console.log('data', data);//return
        await fetch(process.env.REACT_APP_API_HOST + '/housing/update/' + id, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 204) {
                props.housingUpdated();
            }
        }).catch((error) => {
            console.log(error);
            props.housingNotUpdated();
        });
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier un bien immobilier
                </Typography>
            </Box>
            <Divider />
            <Container maxWidth="sm" sx={{ mb: 4 }}>
                <Stepper alternativeLabel
                         activeStep={activeStep}
                         sx={{ pt: 3, pb: 5 }}
                         connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography>
                            Vous êtes sur le point de modifier votre bien immobilier. Cliquer sur le bouton Valider pour confirmer la modification de votre bien ou sur Précédent pour vérifier les données saisies.
                        </Typography>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {getStepContent(activeStep, generalData, equipments, leasing, onGeneralDataChange, onEquipmentsChange, onLeasingChange)}
                    </React.Fragment>
                )}
            </Container>
            <Divider />
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                    {activeStep !== 0 && (
                        <MercureOutlinedButton
                            label="Précédent"
                            onClick={handleBack} />
                    )}

                    {activeStep === steps.length ? (
                        <MercureContainedButton
                            onClick={saveHousing}
                            label="Valider" />
                    ) : (
                        <MercureContainedButton
                            onClick={handleNext}
                            label="Suivant" />
                    )}
                </Box>
            </DialogActions>
        </div>
    );
}