import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {createTheme, styled} from '@mui/material/styles';
import 'react-phone-number-input/style.css';
import Location from './../../Components/Location';
import Snackbar from '@mui/material/Snackbar';
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from "@mui/material/Autocomplete";
import BootstrapDialogTitle from "../../Components/BootstrapDialogTitle";
import BootstrapDialog from "../../Components/BootstrapDialog";
import Alert from "../../Components/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import MercureContainedButton from "../../Components/MercureContainedButton";
import Typography from "@mui/material/Typography";
import validator from "validator";

const theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});

export default function AddHousingCorporation(props) {
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [siren, setSiren] = React.useState(null);
    const [siret, setSiret] = React.useState(null);
    const [iban, setIban] = React.useState('');
    const [vatNumber, setVatNumber] = React.useState('');
    const [address, setAddress] = React.useState(null);
    const [name, setName] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [type, setType] = React.useState("");
    const [nameErrorMessage, setNameErrorMessage] = React.useState('');
    const [addressErrorMessage, setAddressErrorMessage] = React.useState('');
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState('');
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState('');
    const [housingErrorMessage, setHousingErrorMessage] = React.useState('');
    const [email, setEmail] = React.useState("");
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const requiredField = 'Ce champ est requis';
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    let [housings, setHousings] = React.useState([]);
    let [housing, setHousing] = React.useState(null);
    const [openAutoComplete, setOpenAutoComplete] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = openAutoComplete && options && options.length === 0;

    const SIREN_CHARACTER_LIMIT = 9;
    const SIRET_CHARACTER_LIMIT = 14;
    const IBAN_CHARACTER_LIMIT = 34;
    const VAT_NUMBER_CHARACTER_LIMIT = 13;

    const onHousingChange = (event, value) => {
        if (null != value) {
            setHousingErrorMessage('');
            setHousing(value);
            return;
        }

        setHousingErrorMessage(requiredField);
    }

    const onAddressChange = (event, value) => {
        setAddress(value.description);
    }

    const handleSirenChange = (event) => {
        setSiren(event.target.value);
    };

    const handleSiretChange = (event) => {
        setSiret(event.target.value);
    };

    const handleIbanChange = (event) => {
        setIban(event.target.value);
    };

    const handleVatNumberChange = (event) => {
        setVatNumber(event.target.value);
    };

    const onFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const onLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const onNameChange = (event, value) => {
        setOptions([]);

        if (value) {
            setNameErrorMessage('');
            setName(value.toUpperCase());
            return;
        }

        setNameErrorMessage(requiredField);
    };

    const onEmailChange = (e) => {
        let email = e.target.value;

        if (email != '') {
            if (validator.isEmail(email)) {
                setEmailErrorMessage("");
            } else {
                setEmailErrorMessage("L'adresse e-mail saisie est invalide");
            }
        } else {
            setEmailErrorMessage("");
        }

        setEmail(email);
    };

    const onValueChange = (event, value) => {
        if (value) {
            let beautifyAddress = '';
            let numberAddress = value.adresseEtablissement.numeroVoieEtablissement ? value.adresseEtablissement.numeroVoieEtablissement : '';
            let additionalAddress = null !== value.adresseEtablissement.complementAdresseEtablissement ? value.adresseEtablissement.complementAdresseEtablissement + ' ' : '';
            let typeAddress = null !== value.adresseEtablissement.typeVoieEtablissement ? value.adresseEtablissement.typeVoieEtablissement + ' ' : '';

            beautifyAddress = numberAddress + ' ' + additionalAddress + typeAddress + value.adresseEtablissement.libelleVoieEtablissement + ' ' + value.adresseEtablissement.codePostalEtablissement + ' ' + value.adresseEtablissement.libelleCommuneEtablissement
            setSiren(value.siren);
            setAddress(beautifyAddress);
        } else {
            setSiren(null);
            setAddress('');
        }
    }

    const onTypeChange = (event) => {
        if (event.target.value) {
            setType(event.target.value);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    async function saveCorporation() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        if (name === '') {
            setNameErrorMessage(requiredField);
            return;
        }

        if (address === '') {
            setAddressErrorMessage(requiredField);
            return;
        }
        
        if (firstName === '') {
            setFirstNameErrorMessage(requiredField);
            return;
        }

        if (lastName === '') {
            setLastNameErrorMessage(requiredField);
            return;
        }

        if (email === '') {
            setEmailErrorMessage(requiredField);
            return;
        }

        if (housing.length === 0) {
            setHousingErrorMessage(requiredField);
            return;
        }

        const data = {};
        data.idUser = user.id;
        data.siren = siren;
        data.siret = siret;
        data.iban = iban;
        data.vatNumber = vatNumber;
        data.name = name;
        data.firstName = firstName;
        data.lastName = lastName;
        data.email = email;
        data.type = type;
        data.address = address;
        data.idHousing = housing.map(item => item.id);
        //console.log('data', data); return

        await fetch(process.env.REACT_APP_API_HOST + '/housing/corporation/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    //console.log('response', response);
                    // Hide dialog
                    props.onClose();

                    // Alert
                    props.handleMessage("L'entreprise a été ajoutée");
                    props.handleSeverity('success');
                    props.handleOpenAlert();

                    // Remove coowners from localstorage
                    localStorage.removeItem('housingsCorporation');

                    // Refresh data
                    props.refresh();
                })
            } else {
                console.log(result);
                props.handleMessage("L'entreprise n'a pas pu être ajoutée suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);

            props.handleMessage("L'entreprise n'a pas pu être ajoutée suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (name && name.length > 3) {
            setOptions([]);
            (async () => {
                await fetch(process.env.REACT_APP_API_HOST + '/insee/get/name/' + name, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(result => {
                    if (result.status === 200) {
                        result.json().then(response => {
                            //console.log('response', response);
                            if (active) {
                                setOptions([...response]);
                            }
                        })
                    } else {
                        console.log(result);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            })();
        }

        return () => {
            active = false;
        };
    }, [loading, name]);

    React.useEffect(() => {
        let possessions = JSON.parse(localStorage.getItem('housings'));

        if (null === possessions) {
            getHousingsByIdUser();
        } else {
            setHousings(possessions);
        }
    }, []);

    React.useEffect(() => {
        if (!openAutoComplete) {
            setOptions([]);
        }
    }, [openAutoComplete]);

    function getHousingsByIdUser() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/housing/get/user/id/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousings(response);
                    localStorage.setItem('housings', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Ajouter une entreprise
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Container component="" maxWidth="xs" tabIndex={-1}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Autocomplete
                                    autoComplete="given-name"
                                    freeSolo
                                    open={openAutoComplete}
                                    fullWidth
                                    label="Nom de l'entreprise"
                                    autoFocus
                                    onOpen={() => {
                                        setOpenAutoComplete(true);
                                    }}
                                    onClose={() => {
                                        setOpenAutoComplete(false);
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        onNameChange(event, newInputValue)
                                    }}
                                    onChange={(event, newValue) => {
                                        onValueChange(event, newValue);
                                    }}
                                    getOptionLabel={(option) => option !== undefined && option.uniteLegale !== undefined ? option.uniteLegale.denominationUniteLegale : ''}
                                    options={options}
                                    loading={loading}
                                    filterOptions={(x) => x}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.siret}>
                                                <Grid container alignItems="center">
                                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                        <Box component="span" sx={{ fontWeight: option.highlight ? 'bold' : 'regular' }}>
                                                            {option.uniteLegale.denominationUniteLegale} - {option.siret}
                                                        </Box>
                                                        <Typography variant="subtitle1" color="text.secondary">
                                                            {option.adresseEtablissement.numeroVoieEtablissement && option.adresseEtablissement.numeroVoieEtablissement} {option.adresseEtablissement.complementAdresseEtablissement && option.adresseEtablissement.complementAdresseEtablissement} {option.adresseEtablissement.typeVoieEtablissement} {option.adresseEtablissement.libelleVoieEtablissement} {option.adresseEtablissement.codePostalEtablissement} {option.adresseEtablissement.libelleCommuneEtablissement}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Nom de l'entreprise"
                                            error={nameErrorMessage !== ""}
                                            helperText= {nameErrorMessage}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="secondary" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Forme juridique"
                                    name="type"
                                    autoComplete="family-name"
                                    value={type}
                                    onChange={onTypeChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                           autoComplete="given-name"
                                           name="siren"
                                           label="Siren"
                                           value={siren}
                                           inputProps={{
                                               maxlength: SIREN_CHARACTER_LIMIT
                                           }}
                                           onChange={handleSirenChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                           autoComplete="given-name"
                                           name="siret"
                                           label="Siret"
                                           inputProps={{
                                               maxlength: SIRET_CHARACTER_LIMIT
                                           }}
                                           value={siret}
                                           onChange={handleSiretChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                           autoComplete="given-name"
                                           name="iban"
                                           label="IBAN"
                                           inputProps={{
                                               maxlength: IBAN_CHARACTER_LIMIT
                                           }}
                                           value={iban}
                                           onChange={handleIbanChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                           autoComplete="given-name"
                                           name="vatNumber"
                                           label="TVA intracommunautaire"
                                           inputProps={{
                                               maxlength: VAT_NUMBER_CHARACTER_LIMIT
                                           }}
                                           value={vatNumber}
                                           onChange={handleVatNumberChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Location
                                    name="address"
                                    label="Adresse"
                                    value={address}
                                    noOptionsText="Aucune adresse saisie"
                                    error={addressErrorMessage !== ""}
                                    helperText={addressErrorMessage}
                                    onChange={onAddressChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                           autoComplete="given-name"
                                           name="firstName"
                                           label="Prénom du gérant"
                                           error={firstNameErrorMessage !== ""}
                                           helperText={firstNameErrorMessage}
                                           value={firstName}
                                           required
                                           onChange={onFirstNameChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                           autoComplete="given-name"
                                           name="lastName"
                                           label="Nom du gérant"
                                           error={lastNameErrorMessage !== ""}
                                           helperText={lastNameErrorMessage}
                                           required
                                           value={lastName}
                                           onChange={onLastNameChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={email}
                                    label="E-mail du gérant"
                                    name="email"
                                    autoComplete="email"
                                    error={emailErrorMessage !== ""}
                                    helperText= {emailErrorMessage}
                                    required
                                    onChange={onEmailChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="housing"
                                    noOptionsText="Sélectionner le.s bien.s géré.s par l'entreprise"
                                    multiple
                                    options={housings}
                                    getOptionLabel={(option) => option.name}
                                    filterSelectedOptions
                                    autoHighlight
                                    onChange={(event, newValue) => {
                                        {onHousingChange(event, newValue)}
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Bien.s géré.s par l'entreprise"
                                            required
                                            error={housingErrorMessage !== ""}
                                            helperText={housingErrorMessage}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <MercureContainedButton
                            onClick={saveCorporation}
                            label="Valider" />
                    </Box>
                </DialogActions>
            </BootstrapDialog>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}