import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {createTheme, styled} from '@mui/material/styles';
import 'react-phone-number-input/style.css';
import Location from './../../Components/Location';
import Snackbar from '@mui/material/Snackbar';
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from "@mui/material/Autocomplete";
import BootstrapDialogTitle from "../../Components/BootstrapDialogTitle";
import BootstrapDialog from "../../Components/BootstrapDialog";
import Alert from "../../Components/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import MercureContainedButton from "../../Components/MercureContainedButton";

const theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});

export default function AddCoownerCorporation(props) {
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [siren, setSiren] = React.useState('');
    const [address, setAddress] = React.useState(null);
    const [name, setName] = React.useState("");
    const [type, setType] = React.useState("");
    const [nameErrorMessage, setNameErrorMessage] = React.useState('');
    const [addressErrorMessage, setAddressErrorMessage] = React.useState('');
    const [housingErrorMessage, setHousingErrorMessage] = React.useState('');
    const requiredField = 'Ce champ est requis';
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    let [housings, setHousings] = React.useState([]);
    let [housing, setHousing] = React.useState(null);
    const [openAutoComplete, setOpenAutoComplete] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [option, setOption] = React.useState();
    const loading = openAutoComplete && options && options.length === 0;

    const onHousingChange = (event, value) => {
        if (null != value) {
            setHousingErrorMessage('');
            setHousing(value);
            return;
        }

        setHousingErrorMessage(requiredField);
    }

    const onAddressChange = (event, value) => {
        setAddress(value.description);
    }

    const handleSirenChange = (event) => {
        setSiren(event.target.value);
    };

    const onNameChange = (event, value) => {
        setOptions([]);

        if (value) {
            setNameErrorMessage('');
            setName(value.toUpperCase());

            let uniteLegale = {};
            let data = {};
            uniteLegale.denominationUniteLegale = value.toUpperCase();
            data.uniteLegale = uniteLegale;
            setOption(data);
            setSiren('');
            return;
        }

        setNameErrorMessage(requiredField);
    };

    const onValueChange = (event, value) => {
        if (value) {
            let address = '';
            let number = value.adresseEtablissement.numeroVoieEtablissement ? value.adresseEtablissement.numeroVoieEtablissement : '';
            let additionalAddress = null !== value.adresseEtablissement.complementAdresseEtablissement ? value.adresseEtablissement.complementAdresseEtablissement + ' ' : '';

            address = number + ' ' + additionalAddress + value.adresseEtablissement.typeVoieEtablissement + ' ' + value.adresseEtablissement.libelleVoieEtablissement + ' ' + value.adresseEtablissement.codePostalEtablissement + ' ' + value.adresseEtablissement.libelleCommuneEtablissement
            setSiren(value.siren);
            setAddress(address);
        } else {
            setSiren('');
            setAddress('');
        }
    }

    const onTypeChange = (event) => {
        if (event.target.value) {
            setAddressErrorMessage('');
            setType(event.target.value);
            return;
        }

        setAddressErrorMessage(requiredField);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    async function saveCoowner() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        if (name === '') {
            setNameErrorMessage(requiredField);
            return;
        }

        if (address === '') {
            setAddressErrorMessage(requiredField);
            return;
        }

        if (housing === null) {
            setHousingErrorMessage(requiredField);
            return;
        }

        const data = {};
        data.idUser = user.id;
        data.siren = siren;
        data.name = name;
        data.type = type;
        data.address = address;
        data.idHousing = housing.id;
        //console.log('data', data);return

        await fetch(process.env.REACT_APP_API_HOST + '/coowner/corporation/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    //console.log('response', response);
                    // Hide dialog
                    props.onClose();

                    // Alert
                    props.handleMessage("Le copropriétaire a été ajouté");
                    props.handleSeverity('success');
                    props.handleOpenAlert();

                    // Remove coowners from localstorage
                    localStorage.removeItem('coowners');

                    // Refresh data
                    props.refresh();
                })
            } else {
                console.log(result);
                props.handleMessage("Le copropriétaire n'a pas pu être ajouté suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);

            props.handleMessage("Le copropriétaire n'a pas pu être ajouté suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (name && name.length > 3) {
            setOptions([]);
            (async () => {
                await fetch(process.env.REACT_APP_API_HOST + '/insee/get/name/' + name, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(result => {
                    if (result.status === 200) {
                        result.json().then(response => {
                            if (active) {
                                setOptions([...response]);
                            }
                        })
                    } else {
                        console.log(result);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            })();
        }

        return () => {
            active = false;
        };
    }, [loading, name]);

    React.useEffect(() => {
        let possessions = JSON.parse(localStorage.getItem('housings'));

        if (null === possessions) {
            getHousingsByIdUser();
        } else {
            setHousings(possessions);
        }
    }, []);

    React.useEffect(() => {
        if (!openAutoComplete) {
            setOptions([]);
        }
    }, [openAutoComplete]);

    function getHousingsByIdUser() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/housing/get/user/id/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousings(response);
                    localStorage.setItem('housings', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Ajouter un copropriétaire
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Container component="" maxWidth="xs" tabIndex={-1}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Autocomplete
                                    autoComplete="given-name"
                                    freeSolo
                                    open={openAutoComplete}
                                    fullWidth
                                    label="Nom de l'entreprise"
                                    autoFocus
                                    onOpen={() => {
                                        setOpenAutoComplete(true);
                                    }}
                                    value={option}
                                    onClose={() => {
                                        setOpenAutoComplete(false);
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        onNameChange(event, newInputValue)
                                    }}
                                    onChange={(event, newValue) => {
                                        onValueChange(event, newValue);
                                    }}
                                    getOptionLabel={(option) => option !== undefined && option.uniteLegale !== undefined ? option.uniteLegale.denominationUniteLegale : ''}
                                    options={options}
                                    loading={loading}
                                    filterOptions={(x) => x}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.siret}>
                                                <Grid container alignItems="center">
                                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                        <Box component="span" sx={{ fontWeight: option.highlight ? 'bold' : 'regular' }}>
                                                            {option.uniteLegale.denominationUniteLegale} - {option.siret}
                                                        </Box>
                                                        <Typography variant="subtitle1" color="text.secondary">
                                                            {option.adresseEtablissement.numeroVoieEtablissement} {option.adresseEtablissement.complementAdresseEtablissement} {option.adresseEtablissement.typeVoieEtablissement} {option.adresseEtablissement.libelleVoieEtablissement} {option.adresseEtablissement.codePostalEtablissement} {option.adresseEtablissement.libelleCommuneEtablissement}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Nom de l'entreprise"
                                            error={nameErrorMessage !== ""}
                                            helperText= {nameErrorMessage}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="secondary" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Forme juridique"
                                    name="type"
                                    autoComplete="family-name"
                                    value={type}
                                    onChange={onTypeChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                           autoComplete="given-name"
                                           name="siren"
                                           label="Siren"
                                           value={siren}
                                           onChange={handleSirenChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Location
                                    required="required"
                                    name="address"
                                    label="Adresse"
                                    value={address}
                                    noOptionsText="Aucune adresse saisie"
                                    error={addressErrorMessage !== ""}
                                    helperText={addressErrorMessage}
                                    onChange={onAddressChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="housing"
                                    noOptionsText="Sélectionner le bien concerné par la copropriété"
                                    onChange={(event, newValue) => {
                                        {onHousingChange(event, newValue)}
                                    }}
                                    value={housing && housing.name ? housing.name : ""}
                                    isOptionEqualToValue={(option, value) =>
                                        value === undefined || value === "" || option.name === value
                                    }
                                    options={housings}
                                    autoHighlight
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{mr: 2, flexShrink: 0}} {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                                   label="Bien concerné par la copropriété"
                                                   InputProps={{
                                                       ...params.InputProps,
                                                       type: 'new-password',
                                                   }}
                                                   required
                                                   error={housingErrorMessage !== ""}
                                                   helperText= {housingErrorMessage}
                                                   fullWidth/>}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <MercureContainedButton onClick={saveCoowner} label="Valider" />
                    </Box>
                </DialogActions>
            </BootstrapDialog>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}