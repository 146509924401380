import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import INTER_TWO from "./Images/inter-2.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {useNavigate} from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "./Components/Alert";

const theme = createTheme();

export default function SignIn() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(() => !!localStorage.getItem('remember'));

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    React.useEffect(() => {
        const remember = localStorage.getItem('remember');

        if (remember) {
            // Disable button
            /*setLoading(true);

            let data = {};
            data.username = localStorage.getItem('username');
            data.password = localStorage.getItem('password');
            data._remember_me = remember;

            // Sign in
            login(data);*/
            //handleSubmit();
        }
    }, []);

    const handleSubmit = (event) => {
        // Disable button
        setLoading(true);

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        let data = {};
        data.username = formData.get('email');
        data.password = formData.get('password');
        data._remember_me = isChecked;
        //console.log('data', data)

        localStorage.setItem('remember', isChecked);

        if (isChecked) {
            localStorage.setItem('username', data.username);
            localStorage.setItem('password', data.password);
        }

        login(data);
    };

    const login = (data) => {
        fetch(process.env.REACT_APP_API_HOST + '/user/sign-in', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            //console.log('result', result);
            // Enabling button
            setLoading(false);

            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response', response);
                    localStorage.setItem('currentUser', JSON.stringify(response));
                    navigate('/overview');
                })
            } else if (result.status === 401 || result.status === 403) {
                result.json().then(response => {
                    setMessage(response.error);
                    handleClick();
                })
            } else {
                result.json().then(response => {
                    console.log('else', response);
                })
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setMessage("Une erreur s'est produite lors de la connexion à votre compte.");
            handleClick();
        });
    }

    const toSignUp = () => {
        navigate('/sign-up');
    }

    const toForgottenPassword = () => {
        navigate('/forgotten-password');
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{height: '100vh'}}>
                <CssBaseline/>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={8}
                    sx={{
                        backgroundImage: `url(${INTER_TWO})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: "100vh"
                    }}
                />
                <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Se connecter
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary"
                                                   name="remember"
                                                   checked={isChecked}
                                                   onChange={(e) => setIsChecked(e.target.checked)}/>}
                                label="Se souvenir de moi"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                sx={{mt: 3, mb: 2}}
                                size="large"
                            >
                                Se connecter
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link onClick={toForgottenPassword} variant="body2">
                                        Mot de passe oublié ?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link onClick={toSignUp} variant="body2">
                                        {"Pas encore de compte ? En créer"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress />
            </Backdrop>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
}