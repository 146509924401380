import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import PersonalData from "../Account/PersonalData";
import Snackbar from "@mui/material/Snackbar";
import Alert from "../Components/Alert";

function Header(props) {
    const {onDrawerToggle} = props.onDrawerToggle;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showAccount, setShowAccount] = React.useState(false);
    const open = Boolean(anchorEl);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const navigate = useNavigate();

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const showMessage = (msg) => {
        setMessage(msg);
    };

    const showSeverity = (svt) => {
        setSeverity(svt);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (id) => {
        setAnchorEl(null);

        if (id === 'account') {
            setShowAccount(true);
        } else {
            logout();
        }
    };

    async function logout() {
        await fetch(process.env.REACT_APP_API_HOST + '/user/logout/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            // Logout
            localStorage.removeItem('currentUser');
            localStorage.removeItem('username');
            localStorage.removeItem('password');

            navigate('/');
        }).catch((error) => {
            console.log(error);
        });
    }

    const onTabChange = (event, newValue) => {
        props.handleTabValue(newValue);

        const path = props.subtitle.find((subtitle) => subtitle.index === newValue).path;
        navigate(path);
    };

    const handleCloseAccount = () => setShowAccount(false);

    return (
        <React.Fragment>
            <Menu id="menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                      'aria-labelledby': 'basic-button',
                  }}
                  PaperProps={{
                      elevation: 0,
                      sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                          },
                          '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          },
                      },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem id="account" onClick={e => handleClose(e.target.id)}>
                    <Avatar />Mon compte
                </MenuItem>
                <MenuItem id="logout" onClick={e => handleClose(e.target.id)}>
                    <Avatar>
                        <Logout fontSize="small" />
                    </Avatar>
                    Déconnexion
                </MenuItem>
            </Menu>

            <AppBar position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid sx={{display: {sm: 'none', xs: 'block'}}} item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onDrawerToggle}
                                edge="start"
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item xs/>
                        <Grid item>
                            <Tooltip title="Alerts • No alerts">
                                <IconButton color="inherit">
                                    <NotificationsIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" onClick={handleClick}>
                                <AccountCircleIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <AppBar
                component="div"
                position="static"
                elevation={0}
                sx={{zIndex: 0, paddingLeft: 3}}
            >
                <Toolbar>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs>
                            <Typography color="inherit" variant="h5" component="h1">
                                {props.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <AppBar position="static" elevation={0} sx={{zIndex: 0, paddingLeft: 3}}>
                <Tabs value={props.value} onChange={onTabChange} textColor="inherit" aria-label="header">
                    {(props.subtitle.map((subtitle) =>
                        <Tab id={subtitle.id} label={subtitle.id} aria-controls={subtitle.id} />
                    ))}
                </Tabs>
            </AppBar>

            {showAccount &&
                <PersonalData open={showAccount}
                              onClose={handleCloseAccount}
                              showMessage={showMessage}
                              showSeverity={showSeverity}
                              handleOpenAlert={handleOpenAlert}
                />
            }

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

Header.propTypes = {
    onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;