import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Location from "../../Components/Location";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";

export default function Possession(props) {
    const [currency, setCurrency] = React.useState('€');

    const {
        // Data
        insurance,
        lastRentalOption,
        lastRentalAmount,
        lastPaymentDate,
        lastRevisionDate,
        attic,
        veranda,
        balcony,
        washRoom,
        cellar,
        garden,
        parking,
        court,
        pool,
        jacuzzi,
        entrance,
        clearance,
        dressing,
        garage,
        city,
        creationDate,

        // Error
        lastRentalAmountErrorMessage,
        lastPaymentDateErrorMessage,
        lastRevisionDateErrorMessage,
        creationDateErrorMessage,
        cityErrorMessage
    } = props.data;

    return (
        <div>
            <Box component="form" noValidate>
                <Grid container spacing={2} alignItems="center">
                    {props.showInsurance &&
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={insurance} name="insurance" onChange={(event) => props.onPossessionChange(event)} />}
                                label="Avez-vous souscrit une assurance pour le compte des colocataires ?"
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="lastRentalOption">Le bien a-t-il été loué au cours des 18 derniers mois ?</FormLabel>
                            <RadioGroup
                                aria-labelledby="lastRentalOption"
                                row
                                name="lastRentalOption"
                                value={lastRentalOption}
                                onChange={(event) => props.onPossessionChange(event)}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Oui" />
                                <FormControlLabel value="no" control={<Radio />} label="Non" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {lastRentalOption === 'yes' &&
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Montant du dernier loyer (hors charges)"
                                name="lastRentalAmount"
                                fullWidth
                                type="number"
                                onChange={(event) => props.onPossessionChange(event)}
                                value={lastRentalAmount}
                                error={lastRentalAmountErrorMessage !== ""}
                                helperText= {lastRentalAmountErrorMessage}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                    inputProps: {
                                        min: 0
                                    }
                                }}
                            />
                        </Grid>
                    }
                    {lastRentalOption === 'yes' &&
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DatePicker
                                    required
                                    label="Date du dernier versement"
                                    id="lastPaymentDate"
                                    inputFormat="DD/MM/YYYY"
                                    value={lastPaymentDate}
                                    onChange={(newValue) => {
                                        props.onPossessionChange(null, newValue, 'lastPaymentDate')
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth required error={lastPaymentDateErrorMessage !== ""}
                                                                        helperText= {lastPaymentDateErrorMessage}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                    }
                    {lastRentalOption === 'yes' &&
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DatePicker
                                    required
                                    label="Date de la dernière révision"
                                    id="lastRevisionDate"
                                    inputFormat="DD/MM/YYYY"
                                    value={lastRevisionDate}
                                    onChange={(newValue) => {
                                        props.onPossessionChange(null, newValue, 'lastRevisionDate')
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth required error={lastRevisionDateErrorMessage !== ""}
                                                                        helperText= {lastRevisionDateErrorMessage}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormLabel id="lastRentalOption">Nombre de dépendances à usage privatif</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Grenier"
                            type="number"
                            name="attic"
                            value={attic}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Veranda"
                            type="number"
                            name="veranda"
                            value={veranda}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Balcon"
                            type="number"
                            name="balcony"
                            value={balcony}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Buanderie"
                            type="number"
                            name="washRoom"
                            value={washRoom}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Cave"
                            type="number"
                            name="cellar"
                            value={cellar}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Jardin"
                            type="number"
                            name="garden"
                            value={garden}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Parking"
                            type="number"
                            name="parking"
                            value={parking}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Cour"
                            type="number"
                            name="court"
                            value={court}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Piscine"
                            type="number"
                            name="pool"
                            value={pool}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Jacuzzi"
                            type="number"
                            name="jacuzzi"
                            value={jacuzzi}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Entrée"
                            type="number"
                            name="entrance"
                            value={entrance}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Dégagement"
                            type="number"
                            name="clearance"
                            value={clearance}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Dressing"
                            type="number"
                            name="dressing"
                            value={dressing}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Garage"
                            type="number"
                            name="garage"
                            value={garage}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Location label="Fait à"
                                  required="required"
                                  id="city"
                                  value={city}
                                  onChange={(event, newValue) => props.onPossessionChange(null, newValue, 'city')}
                                  noOptionsText="Aucune ville saisie"
                                  error={cityErrorMessage !== ""}
                                  helperText= {cityErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Le"
                                required
                                id="creationDate"
                                inputFormat="DD/MM/YYYY"
                                value={creationDate}
                                onChange={(newValue) => {
                                    props.onPossessionChange(null, newValue, 'creationDate')
                                }}
                                renderInput={(params) => <TextField {...params}
                                                                    fullWidth
                                                                    required
                                                                    error={creationDateErrorMessage !== ""}
                                                                    helperText={creationDateErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

            </Box>
        </div>
    )
}