import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Terms from "./Terms";
import Privacy from "./Privacy";
import Alert from "../Components/Alert";
import Snackbar from "@mui/material/Snackbar";
import validator from "validator";
import {REQUIRED_FIELD} from "../Consts";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

function Contact() {
	const [openTerms, setOpenTerms] = React.useState(false);
	const [openPrivacy, setOpenPrivacy] = React.useState(false);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState('');
	const [severity, setSeverity] = React.useState('');
	const [firstName, setFirstName] = React.useState('');
	const [lastName, setLastName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
	const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState("");
	const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState("");
	const [messageErrorMessage, setMessageErrorMessage] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const MESSAGE_CHARACTER_LIMIT = 1024;

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert(false);
	};

	const handleOpenAlert = () => {
		setOpenAlert(true);
	};

	const handleAlertMessage = (alertMessage) => {
		setAlertMessage(alertMessage);
	};

	const handleSeverity = (severity) => {
		setSeverity(severity);
	};

	const handleOpenTerms = () => setOpenTerms(true);
	const handleCloseTerms = () => setOpenTerms(false);
	const handleOpenPrivacy = () => setOpenPrivacy(true);
	const handleClosePrivacy = () => setOpenPrivacy(false);
	const handleFirstNameChange = (event) => {
		if ('' === event.target.value) {
			setFirstNameErrorMessage(REQUIRED_FIELD);
		} else {
			setFirstNameErrorMessage('');
		}

		setFirstName(event.target.value);
	}
	const handleLastNameChange = (event) => {
		if ('' === event.target.value) {
			setLastNameErrorMessage(REQUIRED_FIELD);
		} else {
			setLastNameErrorMessage('');
		}

		setLastName(event.target.value);
	}
	const onMessageChange = (event) => {
		if ('' === event.target.value) {
			setMessageErrorMessage(REQUIRED_FIELD);
		} else {
			setMessageErrorMessage('');
		}

		setMessage(event.target.value);
	}
	const onEmailChange = (e) => {
		let email = e.target.value;

		if (email != '') {
			if (validator.isEmail(email)) {
				setEmailErrorMessage("");
			} else {
				setEmailErrorMessage("L'adresse e-mail saisie est invalide");
			}
		} else {
			setEmailErrorMessage("");
		}

		setEmail(email);
	};
	const sendMessage = () => {
		if ('' === firstName) {
			setFirstNameErrorMessage(REQUIRED_FIELD);
			return;
		}

		if ('' === lastName) {
			setLastNameErrorMessage(REQUIRED_FIELD);
			return;
		}

		if ('' === email) {
			setEmailErrorMessage(REQUIRED_FIELD);
			return;
		} else {
			if (!validator.isEmail(email)) {
				setEmailErrorMessage("L'adresse e-mail saisie est invalide");
				return;
			}
		}

		if ('' === message) {
			setMessageErrorMessage(REQUIRED_FIELD);
			return;
		}

		setLoading(true);

		let data = {};
		data.firstName = firstName;
		data.lastName = lastName;
		data.email = email;
		data.message = message;

		fetch(process.env.REACT_APP_API_HOST + '/contact/send', {
			method: 'POST',
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		}).then(result => {
			//console.log('result', result);
			// Enabling button
			setLoading(false);

			if (result.status === 200) {
				// Reset data
				setFirstName('');
				setLastName('');
				setEmail('');
				setMessage('');

				handleSeverity('success');
				setAlertMessage("Votre message a été envoyé");
				handleOpenAlert();
			} else {
				handleSeverity('error');
				setAlertMessage("Une erreur s'est produite lors de l'envoi de votre message");
				handleOpenAlert();
			}
		}).catch((error) => {
			console.log(error);
			setLoading(false);
			handleSeverity('error');
			setAlertMessage("Une erreur s'est produite lors de l'envoi de votre message");
			handleOpenAlert();
		});
	};

	return (
		<section className="form6 cid-tvJiARrNsm w-100" id="contact">
			{openTerms === true &&
				<Terms open={openTerms}
					   onClose={handleCloseTerms} />
			}

			{openPrivacy === true &&
				<Privacy open={openPrivacy}
					   	onClose={handleClosePrivacy} />
			}

		    <div className="container">
		        <div className="mbr-section-head">
		            <h3 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2"><strong>Contact</strong></h3>
		        </div>
		        <div className="row justify-content-center mt-4">
		            <div className="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
		                <form className="mbr-form form-with-styler mx-auto" data-form-title="Form Name">
		                    <div className="dragArea row">
		                        <div className="col-lg-12 col-md-12 col-sm-12 form-group" data-for="firstName">
		                            <TextField required
											   fullWidth id="firstName"
											   label="Prenom"
											   name="firstName"
											   autoComplete="firstName"
											   error={firstNameErrorMessage !== ""}
											   helperText={firstNameErrorMessage}
											   value={firstName}
											   onChange={handleFirstNameChange}/>
		                        </div>
		                        <div className="col-lg-12 col-md-12 col-sm-12 form-group" data-for="lastName">
		                        	<TextField required
											   fullWidth id="lastName"
											   label="Nom"
											   name="lastName"
											   autoComplete="lastName"
											   error={lastNameErrorMessage !== ""}
											   helperText={lastNameErrorMessage}
											   value={lastName}
											   onChange={handleLastNameChange}/>
		                        </div>
		                        <div className="col-lg-12 col-md-12 col-sm-12 form-group" data-for="email">
		                            <TextField required
											   fullWidth
											   id="email"
											   label="E-mail"
											   name="email"
											   error={emailErrorMessage !== ""}
											   helperText={emailErrorMessage}
											   value={email}
											   onChange={onEmailChange}/>
		                        </div>
		                        <div className="col-lg-12 col-md-12 col-sm-12 form-group" data-for="message">
		                        	<TextField required
											   fullWidth
											   id="message"
											   label="Message"
											   name="message"
											   error={messageErrorMessage !== ""}
											   helperText={`${message.length}/${MESSAGE_CHARACTER_LIMIT} ${messageErrorMessage}`}
											   value={message}
											   inputProps={{
												   maxLength: MESSAGE_CHARACTER_LIMIT
											   }}
											   rows={10}
											   multiline
											   onChange={onMessageChange}/>
		                        </div>
		                        
		                        <div className="col-auto mbr-section-btn align-center">
		                            <Button onClick={() => sendMessage()} fullWidth color="secondary" variant="contained" size="large">Envoyer</Button>
		                        </div>
		                    </div>
		                	<span className="gdpr-block">
								<label>
									<span className="textGDPR display-7" style={{ color: '#a7a7a7' }}>
										<input type="checkbox" name="gdpr" id="gdpr-form6-f" checked disabled/>En continuant, vous acceptez nos <a style={{ color: '#149dcc' }} onClick={() => handleOpenTerms()}>CGU</a> et <a style={{ color: '#149dcc'}} onClick={() => handleOpenPrivacy()}>notre politique de protection des données et de la vie privée</a>.
									</span>
								</label>
							</span>
						</form>
		            </div>
		        </div>
		    </div>

			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
			>
				<CircularProgress />
			</Backdrop>

			<Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
				<Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
					{alertMessage}
				</Alert>
			</Snackbar>
		</section>
	)
}

export default Contact;