import * as React from 'react';
import Notice from "./Notice";

function Footer(props) {
	const [show, setShow] = React.useState(false);
	const goTo = (id) => {
		props.handleAnchor(id);
	}

	const showLegalNotice = () => {
		setShow(true);
	}
	const hideLegalNotice = () => {
		setShow(false);
	}

	return (
		<section className="footer3 cid-tuSoZUG3OT" id="footer3-5">
			{show === true &&
				<Notice open={show}
					   	onClose={hideLegalNotice} />
			}

		    <div className="container">
		        <div className="media-container-row align-center mbr-white">
					{props.showMenu && props.showMenu !== false &&
						<div className="row row-links">
							<ul className="foot-menu">
								<li className="foot-menu-item mbr-fonts-style display-7">
									<a className="text-white" onClick={() => goTo('')}>Accueil</a>
								</li>
								<li className="foot-menu-item mbr-fonts-style display-7">
									<a className="text-white" onClick={() => goTo('services')}>Services</a>
								</li>
								<li className="foot-menu-item mbr-fonts-style display-7">
									<a className="text-white" onClick={() => goTo('contact')}>Contact</a>&nbsp; &nbsp; &nbsp;
								</li>
							</ul>
						</div>
					}
					<div className="row row-links">
		                <ul className="foot-menu">
		                	<li className="foot-menu-item mbr-fonts-style display-7">
		                        <a className="text-white" onClick={() => showLegalNotice('')}>Mentions légales</a>
		                    </li>
		                </ul>
		            </div>
		            {/*<div className="row social-row">
		                <div className="social-list align-right pb-2">
			                <div className="soc-item">
		                        <a href="https://twitter.com/mobirise" target="_blank">
		                            <span className="socicon-twitter socicon mbr-iconfont mbr-iconfont-social"></span>
		                        </a>
		                    </div>
		                    <div className="soc-item">
		                        <a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
		                            <span className="socicon-facebook socicon mbr-iconfont mbr-iconfont-social"></span>
		                        </a>
		                    </div>
		                    <div className="soc-item">
		                        <a href="https://www.youtube.com/c/mobirise" target="_blank">
		                            <span className="socicon-youtube socicon mbr-iconfont mbr-iconfont-social"></span>
		                        </a>
		                    </div>
		                    <div className="soc-item">
		                        <a href="https://instagram.com/mobirise" target="_blank">
		                            <span className="socicon-instagram socicon mbr-iconfont mbr-iconfont-social"></span>
		                        </a>
		                    </div>
		                </div>
			        </div>*/}
		            <div className="row row-copirayt">
		                <p className="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
		                    © Copyright 2024 Terra. Tous droits réservés.
		                </p>
		            </div>
		        </div>
		    </div>
		</section>
	)
}

export default Footer;