import * as React from 'react';
import Container from '@mui/material/Container';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Location from "../../Components/Location";
import {FormHelperText} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Waiting from "../../Components/Waiting";
import MercureContainedButton from "../../Components/MercureContainedButton";
import DialogActions from "@mui/material/DialogActions";

const housings = JSON.parse(localStorage.getItem('housings'));

export default function UpdateReceipt(props) {
    const [currency, setCurrency] = React.useState('€');
    const [tenants, setTenants] = React.useState([]);
    const [tenant, setTenant] = React.useState(null);
    const [startDate, setStartDate] = React.useState(props.receipt && props.receipt.startDate ? dayjs(props.receipt.startDate).toLocaleString() : null);
    const [endDate, setEndDate] = React.useState(props.receipt && props.receipt.endDate ? dayjs(props.receipt.endDate).toLocaleString() : null);
    const [paymentDate, setPaymentDate] = React.useState(props.receipt && props.receipt.paymentDate ? dayjs(props.receipt.paymentDate).toLocaleString() : null);
    const [creationDate, setCreationDate] = React.useState(dayjs(new Date()));
    const [rentalAmount, setRentalAmount] = React.useState(props.receipt && props.receipt.rentalAmount ? props.receipt.rentalAmount : null);
    const [chargesAmount, setChargesAmount] = React.useState(props.receipt && props.receipt.chargesAmount ? props.receipt.chargesAmount : null);
    const [apl, setApl] = React.useState(props.receipt && props.receipt.apl ? props.receipt.apl : null);
    const [another, setAnother] = React.useState(props.receipt && props.receipt.another ? props.receipt.another : null);
    const [paymentMode, setPaymentMode] = React.useState(props.receipt && props.receipt.paymentMode ? props.receipt.paymentMode : '');
    const [city, setCity] = React.useState(props.receipt && props.receipt.city ? props.receipt.city : null);
    const [loading, setLoading] = React.useState(false);
    const [startDateErrorMessage, setStartDateErrorMessage] = React.useState('');
    const [endDateErrorMessage, setEndDateErrorMessage] = React.useState('');
    const [creationDateErrorMessage, setCreationDateErrorMessage] = React.useState('');
    const [paymentDateErrorMessage, setPaymentDateErrorMessage] = React.useState('');
    const [tenantErrorMessage, setTenantErrorMessage] = React.useState('');
    const [rentalAmountErrorMessage, setRentalAmountErrorMessage] = React.useState('');
    const [chargesAmountErrorMessage, setChargesAmountErrorMessage] = React.useState('');
    const [cityErrorMessage, setCityErrorMessage] = React.useState('');
    const [paymentModeErrorMessage, setPaymentModeErrorMessage] = React.useState('');
    const requiredField = 'Ce champ est requis';
    const descriptionElementRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const getTenants = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        localStorage.removeItem('tenants');

        fetch(process.env.REACT_APP_API_HOST + '/tenant/person/get-by-id-user/' + user.id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setTenants(response);
                    setTenant(response.find(lodger => lodger.id === props.receipt.idTenant));
                    localStorage.setItem('tenants', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    React.useEffect(() => {
        let lodgers = JSON.parse(localStorage.getItem('tenants'));

        if (null === lodgers) {
            getTenants();
        } else {
            setTenants(lodgers);
            setTenant(lodgers.find(lodger => lodger.id === props.receipt.idTenant));
        }
    }, [getTenants]);

    const onFilterChange = (event, value) => {
        if (value !== null) {
            setTenantErrorMessage('');
            setTenant(value);
            const found = housings && housings.find((housing) => housing.id === value.idHousing);
            setCurrency(found ? found.currency : '€');
            return;
        }

        setTenantErrorMessage(requiredField);
    }

    const onStartDateChange = (event, value) => {
        if (value === null) {
            setStartDateErrorMessage(requiredField);
            return;
        }

        setStartDateErrorMessage('');
        setStartDate(dayjs(value.toLocaleString()));
    }

    const onEndDateChange = (event, value) => {
        if (value === null) {
            setEndDateErrorMessage(requiredField);
            return;
        }

        setEndDateErrorMessage('');
        setEndDate(dayjs(value.toLocaleString()));
    }

    const onPaymentDateChange = (event, value) => {
        if (value === null) {
            setPaymentDateErrorMessage(requiredField);
            return;
        }

        setPaymentDateErrorMessage('');
        setPaymentDate(dayjs(value.toLocaleString()));
    }

    const onCreationDateChange = (event, value) => {
        if (value === null) {
            setCreationDateErrorMessage(requiredField);
            return;
        }

        setCreationDateErrorMessage('');
        setCreationDate(dayjs(value.toLocaleString()));
    }

    const onRentalAmountChange = (event) => {
        if (event.target.value !== null) {
            setRentalAmountErrorMessage('');
        } else {
            setRentalAmountErrorMessage(requiredField);
        }

        setRentalAmount(event.target.value);
    }

    const onChargesAmountChange = (event) => {
        if (event.target.value !== null) {
            setChargesAmountErrorMessage('');
        } else {
            setChargesAmountErrorMessage(requiredField);
        }

        setChargesAmount(event.target.value);
    }

    const onAplChange = (event) => {
        setApl(event.target.value);
    }

    const onAnotherChange = (event) => {
        setAnother(event.target.value);
    }

    const onPaymentModeChange = (event, value) => {
        if (event.target.value) {
            setPaymentModeErrorMessage('');
            setPaymentMode(event.target.value);
            return;
        }

        setPaymentModeErrorMessage(requiredField);
    }

    const onCityChange = (event, value) => {
        if (value === null) {
            setCityErrorMessage(requiredField);
        } else {
            setCityErrorMessage('');
        }

        setCity(value.description);
    }

    async function update() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        if (tenant === null) {
            setTenantErrorMessage(requiredField);
            return;
        }

        if (startDate === null) {
            setStartDateErrorMessage(requiredField);
            return;
        }

        if (endDate === null) {
            setEndDateErrorMessage(requiredField);
            return;
        }

        if (rentalAmount === '') {
            setRentalAmountErrorMessage(requiredField);
            return;
        }

        if (chargesAmount === '') {
            setChargesAmountErrorMessage(requiredField);
            return;
        }

        if (paymentDate === null) {
            setPaymentDateErrorMessage(requiredField);
            return;
        }

        if (paymentMode === '') {
            setPaymentModeErrorMessage(requiredField);
            return;
        }

        if (city === null) {
            setCityErrorMessage(requiredField);
            return;
        }

        if (creationDate === null) {
            setCreationDateErrorMessage(requiredField);
            return;
        }

        // Loading
        setLoading(true);

        let data = {};
        data.idUser = user.id;
        data.idTenant = tenant.id;
        data.startDate = startDate && dayjs(startDate.toLocaleString()).format('YYYY-MM-DD');
        data.endDate = endDate && dayjs(endDate.toLocaleString()).format('YYYY-MM-DD');
        data.paymentDate = paymentDate && dayjs(paymentDate.toLocaleString()).format('YYYY-MM-DD');
        data.creationDate = dayjs(creationDate.toLocaleString()).format('YYYY-MM-DD');
        data.rentalAmount = rentalAmount;
        data.chargesAmount = chargesAmount;
        data.apl = apl;
        data.another = another;
        data.paymentMode = paymentMode;
        data.city = city;
        data.name = props.receipt.name;
        data.returnUrl = window.location.href;

        //console.log('data', data);//
        //return

        await fetch(process.env.REACT_APP_API_HOST + '/receipt/update/' + props.receipt.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    //console.log("response", response)
                    // Save filter in local storage
                    localStorage.setItem('receiptTenantFilter', JSON.stringify(tenant));

                    // Hide drawer
                    props.onClose();

                    // Save data in local storage for upload
                    localStorage.setItem('envelopeId', JSON.stringify(response.envelopeId));
                    localStorage.setItem('documentId', JSON.stringify(tenant.id));
                    localStorage.setItem('fileName', JSON.stringify(response.fileName));

                    // Hide waiting
                    setLoading(false);
                    window.location.href = response.redirect_url;
                })
            } else {
                console.log(result);
                setLoading(false);

                props.handleMessage("Votre quittance n'a pas pu être modifiée suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);

            props.handleMessage("Votre quittance n'a pas pu être modifiée suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    const handleCloseWaiting = () => {
        setLoading(false);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier une quittance
                </Typography>
            </Box>
            <Divider />
            <Container component="" maxWidth="xs" ref={descriptionElementRef} tabIndex={-1} sx={{ mb: 3 }}>
                <Grid container spacing={2} alignItems="center" sx={{ pt: 3, pb: 2 }}>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            options={tenants}
                            id="tenant"
                            autoHighlight
                            value={tenant}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined || value === "" || option.id === value.id
                            }
                            onChange={(event, newValue) => {
                                onFilterChange(event, newValue)
                            }}
                            getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName}` : ''}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{mr: 2, flexShrink: 0}} {...props}>
                                    {option ? `${option.firstName} ${option.lastName}` : ''}
                                </Box>
                            )}
                            renderInput={(params) => <TextField required {...params} label="Sélectionner un locataire"
                                                                fullWidth error={tenantErrorMessage !== ""}
                                                                helperText= {tenantErrorMessage}/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                required
                                label="Date de début"
                                id="startDate"
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                onChange={(newValue) => {
                                    onStartDateChange(null, newValue)
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth required error={startDateErrorMessage !== ""}
                                                                    helperText= {startDateErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                required
                                label="Date de fin"
                                id="endDate"
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                onChange={(newValue) => {
                                    onEndDateChange(null, newValue)
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth required error={endDateErrorMessage !== ""}
                                                                    helperText= {endDateErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Montant du loyer (hors charges)"
                            id="rentalAmount"
                            fullWidth
                            type="number"
                            value={rentalAmount}
                            onChange={onRentalAmountChange}
                            error={rentalAmountErrorMessage !== ""}
                            helperText= {rentalAmountErrorMessage}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Montant des charges"
                            id="chargesAmount"
                            fullWidth
                            type="number"
                            value={chargesAmount}
                            onChange={onChargesAmountChange}
                            error={chargesAmountErrorMessage !== ""}
                            helperText= {chargesAmountErrorMessage}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Aide au logement (APL)"
                            id="apl"
                            fullWidth
                            type="number"
                            value={apl}
                            onChange={onAplChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Autre"
                            id="another"
                            fullWidth
                            type="number"
                            value={another}
                            onChange={onAnotherChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Date de paiement"
                                id="paymentDate"
                                inputFormat="DD/MM/YYYY"
                                value={paymentDate}
                                onChange={(newValue) => {
                                    onPaymentDateChange(null, newValue)
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth required error={paymentDateErrorMessage !== ""}
                                                                    helperText= {paymentDateErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={paymentModeErrorMessage !== ''}>
                            <InputLabel id="paymentMode">Mode de réglement</InputLabel>
                            <Select
                                labelId="paymentMode"
                                id="paymentMode"
                                label="Mode de réglement"
                                value={paymentMode}
                                onChange={(event, newValue) => onPaymentModeChange(event, newValue)}
                            >
                                <MenuItem value={1}>Carte bancaire</MenuItem>
                                <MenuItem value={2}>Chèque</MenuItem>
                                <MenuItem value={3}>Espèces</MenuItem>
                                <MenuItem value={4}>Virement bancaire</MenuItem>
                                <MenuItem value={5}>Prélèvement</MenuItem>
                                <MenuItem value={6}>Autre</MenuItem>
                            </Select>
                            <FormHelperText>{paymentModeErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Location label="Fait à"
                                  required="required"
                                  value={city}
                                  onChange={(event, newValue) => onCityChange(event, newValue)}
                                  noOptionsText="Aucune ville saisie"
                                  error={cityErrorMessage !== ""}
                                  helperText= {cityErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Le"
                                required
                                id="creationDate"
                                inputFormat="DD/MM/YYYY"
                                value={creationDate}
                                onChange={(newValue) => {
                                    onCreationDateChange(null, newValue)
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth required error={creationDateErrorMessage !== ""}
                                                                    helperText= {creationDateErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Container>
            <Divider />
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                    <MercureContainedButton
                        onClick={update}
                        label="Valider"
                        disabled={loading} />
                </Box>
            </DialogActions>
            {loading && (
                <Waiting loading={loading} title="Signature électronique" message="Merci de patienter, vous allez être rédirigé dans quelques instants..."/>
            )}
        </div>
    )
}