import * as React from 'react';
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import Identity from "./Identity";
import Rental from "./Rental";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import validator from "validator";
import Divider from "@mui/material/Divider";
import ColorlibConnector from "../../Components/ColorlibConnector";
import ColorlibStepIcon from "../../Components/ColorlibStepIcon";

const steps = ['Identité', 'Location'];

function getStepContent(step, identity, rental, onIdentityChange, onRentalChange) {
    switch (step) {
        case 0:
            return <Identity data={identity} onIdentityChange={onIdentityChange} />;
        case 1:
            return <Rental data={rental} onRentalChange={onRentalChange} />;
        default:
            throw new Error('Unknown step');
    }
}

let tenant = [];

export default function UpdateTenant(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const descriptionElementRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const [identity, setIdentity] = React.useState({
        civility: props.tenant ? props.tenant.civility : "",
        firstName: props.tenant ? props.tenant.firstName : "",
        lastName: props.tenant ? props.tenant.lastName : "",
        phonePrefix: props.tenant ? props.tenant.phonePrefix : 33,
        phonePrefixLabel: props.tenant ? props.tenant.phonePrefixLabel : "France",
        phoneCode: props.tenant ? props.tenant.phoneCode : "fr",
        phone: props.tenant && props.tenant.phone ? props.tenant.phone : "",
        mobilePhonePrefix: props.tenant ? props.tenant.mobilePhonePrefix : 33,
        mobilePhonePrefixLabel: props.tenant ? props.tenant.mobilePhonePrefixLabel : "France",
        mobilePhoneCode: props.tenant ? props.tenant.mobilePhoneCode : "fr",
        mobilePhone: props.tenant && props.tenant.mobilePhone ? props.tenant.mobilePhone : "",
        email: props.tenant ? props.tenant.email : "",
        emailErrorMessage: "",
        declaration: props.tenant ? props.tenant.declaration : true
    });

    // Rental
    const [rental, setRental] = React.useState({
        housing: props.tenant ? props.tenant.housing : null,
        rentalAmount: props.tenant && props.tenant.rentalAmount !== 0 ? props.tenant.rentalAmount : "",
        chargesAmount: props.tenant && props.tenant.chargesAmount !== 0 ? props.tenant.chargesAmount : "",
        apl: props.tenant && props.tenant.apl !== 0 ? props.tenant.apl : "",
        signDate: props.tenant && props.tenant.signDate ? props.tenant.signDate : null,
        startRentalDate: props.tenant && props.tenant.startRentalDate ? props.tenant.startRentalDate : null,
        paymentDay: props.tenant && props.tenant.paymentDay !== 0 ? props.tenant.paymentDay : ""
    });

    const onIdentityChange = (event, value) => {
        if (event) {
            switch (event.target.id) {
                case 'firstName':
                    setIdentity({
                        ...identity,
                        firstName: event.target.value
                    });
                    break;

                case 'lastName':
                    setIdentity({
                        ...identity,
                        lastName: event.target.value
                    });
                    break;

                case 'phone':
                    setIdentity({
                        ...identity,
                        phone: event.target.value
                    });
                    break;

                case 'mobilePhone':
                    setIdentity({
                        ...identity,
                        mobilePhone: event.target.value
                    });
                    break;

                case 'declaration':
                    setIdentity({
                        ...identity,
                        declaration: value
                    });
                    break;

                case 'email':
                    let email = event.target.value;

                    if (email !== '') {
                        if (validator.isEmail(email)) {
                            setIdentity({
                                ...identity,
                                emailErrorMessage: "",
                                email: email
                            });
                        } else {
                            setIdentity({
                                ...identity,
                                emailErrorMessage: "L'adresse e-mail saisie est invalide",
                                email: email
                            });
                        }
                    } else {
                        setIdentity({
                            ...identity,
                            emailErrorMessage: "",
                            email: email
                        });
                    }
                    break;

                default:
                    if (event.target.name === 'civility') {
                        setIdentity({
                            ...identity,
                            civility: event.target.value
                        });
                    } else {
                        let ids = event.target.id.split('-');

                        if (ids[0] === 'phonePrefix') {
                            setIdentity({
                                ...identity,
                                phonePrefix: (value) ? value.phone : 33,
                                phonePrefixLabel: (value) ? value.label : 'France',
                                phoneCode: (value) ? value.code : 'fr'
                            });
                        } else {
                            setIdentity({
                                ...identity,
                                mobilePhonePrefix: (value) ? value.phone : 33,
                                mobilePhonePrefixLabel: (value) ? value.label : 'France',
                                mobilePhoneCode: (value) ? value.code : 'fr'
                            });
                        }
                    }

                    break;
            }
        }
    }
    const onRentalChange = (event, value) => {
        if (value !== undefined) {
            if (value === 'signDate') {
                setRental({
                    ...rental,
                    signDate: (event) ? dayjs(event.toLocaleString()).format('YYYY-MM-DD') : null
                });
            } else if (value === 'startRentalDate') {
                setRental({
                    ...rental,
                    startRentalDate: (event) ? dayjs(event.toLocaleString()).format('YYYY-MM-DD') : null
                });
            } else {
                setRental({
                    ...rental,
                    housing: (value) ? value : null
                });
            }
        } else {
            switch (event.target.id) {
                case 'rentalAmount':
                    setRental({
                        ...rental,
                        rentalAmount: event.target.value
                    });
                    break;

                case 'chargesAmount':
                    setRental({
                        ...rental,
                        chargesAmount: event.target.value
                    });
                    break;

                case 'paymentDay':
                    setRental({
                        ...rental,
                        paymentDay: event.target.value
                    });
                    break;

                case 'apl':
                    setRental({
                        ...rental,
                        apl: event.target.value
                    });
                    break;

                default:
                    break;
            }
        }
    }

    const handleNext = () => {
        if (activeStep == 0) {
            // Identity
            let tempIdentity = {};
            for (let key in identity) {
                tempIdentity[key] = identity[key];
            }
            tenant['identity'] = tempIdentity;
            localStorage.setItem('identity', JSON.stringify(tenant['identity']));
        } else if (activeStep == 1) {
            // Rental
            let tempRental = {};
            for (let key in rental) {
                tempRental[key] = rental[key];
            }
            tenant['rental'] = tempRental;
            localStorage.setItem('rental', JSON.stringify(tenant['rental']));
        }

        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    async function saveTenant() {
        let data = {};
        const id = props.tenant.id;

        for (let key in tenant['identity']) {
            if ('emailErrorMessage' === key) {
                continue;
            }

            data[key] = tenant['identity'][key];
        }

        for (let key in tenant['rental']) {
            if ('housing' === key) {
                data.idHousing = tenant['rental'][key]['id'];

                continue;
            }

            data[key] = tenant['rental'][key];
        }
        //console.log(data);return
        await fetch(process.env.REACT_APP_API_HOST + '/tenant/person/update/' + id, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 204) {
                props.tenantUpdated();
            }
        }).catch((error) => {
            console.log(error);
            props.tenantNotUpdated();
        });
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier un locataire
                </Typography>
            </Box>
            <Divider />
            <Container component="" maxWidth="xs" ref={descriptionElementRef} tabIndex={-1} sx={{ mb: 3 }}>
                <Stepper alternativeLabel
                         activeStep={activeStep}
                         sx={{ pt: 3, pb: 5 }}
                         connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography>
                            Vous êtes sur le point de modifier votre locataire. Cliquer sur le bouton Valider pour confirmer la modification de votre locataire ou sur Précédent pour vérifier les données saisies.
                        </Typography>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {getStepContent(activeStep, identity, rental, onIdentityChange, onRentalChange)}
                    </React.Fragment>
                )}
            </Container>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                    <Button variant="outlined"
                            color="secondary"
                            onClick={handleBack} sx={{ mt: 3, ml: 1, mr: 1, mb: 3 }}>
                        Précédent
                    </Button>
                )}

                {activeStep === steps.length ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={saveTenant}
                        sx={{ mt: 3, ml: 1, mr: 3, mb: 3 }}
                    >
                        Valider
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1, mr: 3, mb: 3 }}
                    >
                        Suivant
                    </Button>
                )}
            </Box>
        </div>
    );
}