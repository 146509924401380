import * as React from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import LocalParkingRoundedIcon from '@mui/icons-material/LocalParkingRounded';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import GarageRoundedIcon from '@mui/icons-material/GarageRounded';
import FactoryRoundedIcon from '@mui/icons-material/FactoryRounded';
import CoffeeRoundedIcon from '@mui/icons-material/CoffeeRounded';
import ChairRoundedIcon from '@mui/icons-material/ChairRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import CropLandscapeRoundedIcon from '@mui/icons-material/CropLandscapeRounded';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import AddHousing from "./AddHousing";
import Snackbar from "@mui/material/Snackbar";
import Drawer from '@mui/material/Drawer';
import UpdateHousing from "./UpdateHousing";
import Confirm from "../../Components/Confirm";
import ListSubheader from '@mui/material/ListSubheader';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Alert from "../../Components/Alert";
import SearchBar from "../../Components/SearchBar";
import Divider from "@mui/material/Divider";

export default function Housing() {
    let [housings, setHousings] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const title = 'Attention';
    const confirmMessage = "Etes-vous sûr de vouloir supprimer ce bien ?";
    let [selectedHousing, setSelectedHousing] = React.useState();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleMessage = (message) => {
        setMessage(message);
    };

    const handleSeverity = (severity) => {
        setSeverity(severity);
    };

    const getHousings = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/housing/get/user/id/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousings(response);
                    localStorage.setItem('housings', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [selectedHousing]);

    React.useEffect(() => {
        //localStorage.removeItem('housings');
        const possessions = JSON.parse(localStorage.getItem('housings'));

        if (null === possessions) {
            getHousings();
        } else {
            setHousings(possessions);
        }
    }, [getHousings]);

    const handleIcon = (type) => {
        switch (type) {
            case 'Maison':
                return <HouseRoundedIcon/>;

            case 'Appartement':
                return <ChairRoundedIcon/>;

            case 'Entrepôt':
                return <WarehouseRoundedIcon/>;

            case 'Garage/Box/Cave':
                return <GarageRoundedIcon/>;

            case 'Loft':
                return <FactoryRoundedIcon/>;

            case 'Place de parking':
                return <LocalParkingRoundedIcon/>;

            case 'Local commercial':
                return <CoffeeRoundedIcon/>

            case 'Immeuble':
                return <ApartmentRoundedIcon/>;

            case 'Terrain':
                return <CropLandscapeRoundedIcon/>;

            default:
                return <HelpRoundedIcon/>;
        }
    }

    const toggleDrawer = (open, housing) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (open) {
            setSelectedHousing(housing);
        } else {
            setSelectedHousing();
        }

        setOpenDrawer(open);
    }

    const housingUpdated = () => {
        setOpenDrawer(false);
        setSelectedHousing();
        getHousings();
        handleMessage("Vos données ont été modifiées");
        handleSeverity('success');
        handleOpenAlert();
    }

    const housingDeleted = () => {
        setSelectedHousing();
        getHousings();
        handleMessage("Votre bien a été supprimé");
        handleSeverity('success');
        handleOpenAlert();
    }

    const housingNotUpdated = () => {
        setOpenDrawer(false);
        setSelectedHousing();
        handleMessage("Vos données n'ont pas pu être modifiées suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const housingNotDeleted = () => {
        handleMessage("Votre bien n'a pas pu être supprimé suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const duplicate = React.useCallback((housing) => {
        let data = {};

        for (let key in housing) {
            data[key] = housing[key];
        }

        //console.log('data',data);return
        fetch(process.env.REACT_APP_API_HOST + '/housing/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    // Show Alert
                    handleMessage("Votre bien a été dupliqué");
                    handleSeverity('success');
                    handleOpenAlert();

                    // Refresh data
                    getHousings();
                })
            }
        }).catch((error) => {
            console.log(error);
            handleMessage("Votre bien n'a pas pu être dupliqué suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        });
    }, []);

    const toggleConfirm = (open, housing) => (event) => {
        if (open) {
            setSelectedHousing(housing);
        } else {
            setSelectedHousing();
        }

        setOpenConfirm(open);
    }

    const anchorPosition = 'right';

    const onFilterChange = (event) => {
        setFilter(event.target.value);
    }

    const filteredHousings = housings.filter(housing => {
        if (filter === '') {
            return housing;
        } else {
            return (housing.name && housing.name.toLowerCase().includes(filter.toLowerCase())) || (housing.address && housing.address.toLowerCase().includes(filter.toLowerCase()))
        }
    })

    return (
        <div>
            <Paper sx={{maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
                {open === true && (
                    <AddHousing open={open} onClose={handleClose} refresh={getHousings} handleOpenAlert={handleOpenAlert} handleMessage={handleMessage} handleSeverity={handleSeverity}/>
                )}
                <SearchBar placeholder="Chercher un bien" onFilterChange={onFilterChange} buttonLabel="Ajouter un bien" onButtonClick={handleOpen} onRefresh={getHousings}/>

                {filteredHousings.length === 0 ? (
                    <Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
                        Vous n'avez aucun bien immobilier
                    </Typography>
                ) : (
                    <List aria-labelledby="nested-list-subheader"
                          subheader={
                              <ListSubheader component="div" id="nested-list-subheader">
                                  {filteredHousings.length} bien.s immobilier.s
                              </ListSubheader>
                          }
                    >
                        {filteredHousings && filteredHousings.map && filteredHousings.map((housing, index) =>
                            <div>
                                <ListItem key={housing.id}
                                    secondaryAction={
                                        <Grid>
                                            <Tooltip title="Modifier">
                                                <IconButton edge="end" aria-label="edit" onClick={toggleDrawer(true, housing)}>
                                                    <EditIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Dupliquer">
                                                <IconButton edge="end" aria-label="duplicate" onClick={() => duplicate(housing)}>
                                                    <ContentCopyIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Supprimer">
                                                <IconButton edge="end" aria-label="delete" color="error" onClick={toggleConfirm(true, housing)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{bgcolor: 'primary.main'}}>
                                            {handleIcon(housing.type)}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id={housing.id}
                                                  primary={housing.name}
                                                  secondary={
                                                      <React.Fragment>
                                                          <Typography variant="subtitle1">
                                                              {housing.address}
                                                          </Typography>
                                                      </React.Fragment>
                                                  }
                                    />
                                </ListItem>
                                {
                                    ((index === 0 && filteredHousings.length > 1) || index < filteredHousings.length - 1) && <Divider variant="inset" component="li" />
                                }
                            </div>
                        )}
                    </List>
                )}

                <Drawer anchor={anchorPosition}
                        PaperProps={{
                            sx: {backgroundColor: "#ffffff"}
                        }}
                        open={openDrawer}
                        onClose={toggleDrawer(false)}>
                    <UpdateHousing housing={selectedHousing} housingUpdated={housingUpdated} housingNotUpdated={housingNotUpdated} />
                </Drawer>
            </Paper>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>

            <Confirm open={openConfirm}
                     title={title}
                     message={confirmMessage}
                     onClose={toggleConfirm(false)}
                     selected={selectedHousing}
                     type="housing"
                     titleBackgroundColor="#ff9800"
                     housingDeleted={housingDeleted}
                     housingNotDeleted={housingNotDeleted}
                     titleColor="white"/>
        </div>
    );
}