import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import PropTypes from "prop-types";
import * as React from "react";
import ColorlibStepIconRoot from "./ColorlibStepIconRoot";

export default function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <InfoIcon />,
        2: <SettingsIcon />,
        3: <LocalActivityIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};
