import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {createTheme} from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import 'react-phone-number-input/style.css';
import Location from './../../Components/Location';
import validator from "validator";
import IconButton from '@mui/material/IconButton';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MercureContainedButton from "../../Components/MercureContainedButton";
import DialogActions from "@mui/material/DialogActions";

const theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 3 }} component="h1" variant="h4" {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function UpdateCoowner(props) {
    const [civility, setCivility] = React.useState(props.coowner && props.coowner.civility ? props.coowner.civility : "");
    const [address, setAddress] = React.useState(props.coowner && props.coowner.address ? props.coowner.address : null);
    const [firstName, setFirstName] = React.useState(props.coowner && props.coowner.firstName);
    const [lastName, setLastName] = React.useState(props.coowner && props.coowner.lastName);
    const [email, setEmail] = React.useState(props.coowner && props.coowner.email ? props.coowner.email : "");
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState('');
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState('');
    const [housingErrorMessage, setHousingErrorMessage] = React.useState('');
    const requiredField = 'Ce champ est requis';
    const [open, setOpen] = React.useState(false);
    let [housings, setHousings] = React.useState([]);
    let [housing, setHousing] = React.useState(props.coowner && props.coowner.name);

    const onEmailChange = (e) => {
        let email = e.target.value;

        if (email != '') {
            if (validator.isEmail(email)) {
                setEmailErrorMessage("");
            } else {
                setEmailErrorMessage("L'adresse e-mail saisie est invalide");
            }
        } else {
            setEmailErrorMessage("");
        }

        setEmail(email);
    };

    const onHousingChange = (event, value) => {
        if (null != value) {
            setHousingErrorMessage('');
            setHousing(value);
            return;
        }

        setHousingErrorMessage(requiredField);
    }

    const onAddressChange = (event, value) => {
        setAddress(value.description);
    }

    const handleCivilityChange = (event) => {
        setCivility(event.target.value);
    };

    const onFirstNameChange = (event) => {
        if (event.target.value) {
            setFirstNameErrorMessage('');
            setFirstName(event.target.value);
            return;
        }

        setFirstNameErrorMessage(requiredField);
    };

    const onLastNameChange = (event) => {
        if (event.target.value) {
            setLastNameErrorMessage('');
            setLastName(event.target.value);
            return;
        }

        setLastNameErrorMessage(requiredField);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    async function updateCoowner() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        if (firstName === '') {
            setFirstNameErrorMessage(requiredField);
            return;
        }

        if (lastName === '') {
            setLastNameErrorMessage(requiredField);
            return;
        }

        if (housing === null) {
            setHousingErrorMessage(requiredField);
            return;
        }

        const data = {};
        data.idUser = user.id;
        data.civility = civility;
        data.firstName = firstName;
        data.lastName = lastName;
        data.email = email;
        data.address = address;
        data.idHousing = housing.id;

        await fetch(process.env.REACT_APP_API_HOST + '/coowner/person/update/' + props.coowner.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 204) {
                props.coownerUpdated();
            } else {
                console.log(result);
                props.coownerNotUpdated();
            }
        }).catch((error) => {
            console.log(error);
            props.coownerNotUpdated();
        });
    }

    React.useEffect(() => {
        let possessions = JSON.parse(localStorage.getItem('housings'));

        if (null === possessions) {
            getHousingsByIdUser();
        } else {
            setHousings(possessions);
        }
     }, []);

    function getHousingsByIdUser() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/housing/get/user/id/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousings(response);
                    localStorage.setItem('housings', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier un copropriétaire
                </Typography>
            </Box>
            <Divider />
            <Container component="" maxWidth="xs" tabIndex={-1} sx={{ mb: 3, pt: 3, pb: 1 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="civility">Civilité</InputLabel>
                            <Select
                                labelId="civility"
                                value={civility}
                                name="civility"
                                label="Civilité"
                                onChange={handleCivilityChange}
                            >
                                <MenuItem value={1}>Madame</MenuItem>
                                <MenuItem value={2}>Mademoiselle</MenuItem>
                                <MenuItem value={3}>Monsieur</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            autoComplete="given-name"
                            name="firstName"
                            fullWidth
                            label="Prenom"
                            autoFocus
                            value={firstName}
                            onChange={onFirstNameChange}
                            error={firstNameErrorMessage !== ""}
                            helperText= {firstNameErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Nom"
                            name="lastName"
                            autoComplete="family-name"
                            value={lastName}
                            onChange={onLastNameChange}
                            required
                            error={lastNameErrorMessage !== ""}
                            helperText= {lastNameErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            value={email}
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            error={emailErrorMessage !== ""}
                            helperText= {emailErrorMessage}
                            onChange={onEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Location
                            name="address"
                            label="Adresse"
                            value={address}
                            noOptionsText="Aucune adresse saisie"
                            onChange={onAddressChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="housing"
                            noOptionsText="Sélectionner le bien concerné par la copropriété"
                            onChange={(event, newValue) => {
                                {onHousingChange(event, newValue)}
                            }}
                            value={housing}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined || value === "" || option.name === value
                            }
                            options={housings}
                            autoHighlight
                            getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{mr: 2, flexShrink: 0}} {...props}>
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Bien concerné par la copropriété"
                                           InputProps={{
                                               ...params.InputProps,
                                               type: 'new-password',
                                           }}
                                           required
                                           error={housingErrorMessage !== ""}
                                           helperText= {housingErrorMessage}
                                           fullWidth/>}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Divider />
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
                    <MercureContainedButton onClick={updateCoowner} label="Valider"/>
                </Box>
            </DialogActions>
        </div>
    );
}