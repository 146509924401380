import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Stack from '@mui/material/Stack';
import MercureContainedButton from "./MercureContainedButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#ffffff',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Confirm(props) {
    const validate = React.useCallback(() => {
        let route = '';
        let data = {};

        switch (props.type) {
            case 'housing':
                route = '/housing/delete';
                break;

            case 'tenant':
                route = '/tenant/person/delete';
                break;

            case 'tenant-corporation':
                route = '/tenant/corporation/delete';
                break;

            case 'coowner':
                route = '/coowner/person/delete';
                break;

            case 'coowner-corporation':
                route = '/coowner/corporation/delete';
                break;

            case 'receipt':
                data.name = props.selected.name;
                route = '/receipt/delete';
                break;

            case 'raise':
                data.name = props.selected.name;
                route = '/tenant/raise/delete';
                break;

            case 'housing-corporation':
                data.name = props.selected.name;
                route = '/housing/corporation/delete';
                break;

            case 'contract':
                data.name = props.selected.name;
                route = '/contract/delete';
                break;

            case 'contract-corporation':
                data.name = props.selected.name;
                route = '/contract/corporation/delete';
                break;

            case 'invoice':
                data.name = props.selected.name;
                route = '/invoice/delete';
                break;

            default:
                break;
        }

        data.id = props.selected.id;
        //console.log('data', data.id)
        fetch(process.env.REACT_APP_API_HOST + route, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 204) {
                if (props.type === 'housing') {
                    props.housingDeleted();
                } else if (props.type === 'tenant') {
                    props.tenantDeleted();
                } else if (props.type === 'tenant-corporation') {
                    props.tenantDeleted();
                } else if (props.type === 'coowner') {
                    props.coownerDeleted();
                } else if (props.type === 'coowner-corporation') {
                    props.coownerDeleted();
                } else if (props.type === 'receipt') {
                    props.receiptDeleted();
                } else if (props.type === 'raise') {
                    props.raiseDeleted();
                } else if (props.type === 'housing-corporation') {
                    props.corporationDeleted();
                } else if (props.type === 'contract') {
                    props.contractDeleted();
                } else if (props.type === 'contract-corporation') {
                    props.contractDeleted();
                } else if (props.type === 'invoice') {
                    props.invoiceDeleted();
                }

                props.onClose();
            } else {
                console.log(result)
                itemNotDeleted(props.type);
            }
        }).catch((error) => {
            console.log(error);
            itemNotDeleted(props.type);
            
        });
    }, [props.open, props.selected]);

    const itemNotDeleted = (type) => {
        if (type === 'housing') {
            props.housingNotDeleted();
        } else if (type === 'tenant') {
            props.tenantNotDeleted();
        } else if (type === 'tenant-corporation') {
            props.tenantNotDeleted();
        }else if (type === 'coowner') {
            props.coownerNotDeleted();
        } else if (type === 'receipt') {
            props.receiptNotDeleted();
        } else if (type === 'raise') {
            props.raiseNotDeleted();
        } else if (type === 'housing-corporation') {
            props.corporationNotDeleted();
        } else if (type === 'contract') {
            props.contractNotDeleted();
        } else if (type === 'contract-corporation') {
            props.contractNotDeleted();
        } else if (type === 'invoice') {
            props.invoiceNotDeleted();
        }
    }

    return (
        <div>
            <BootstrapDialog
                onClose={props.onClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth="sm"
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title"
                                      onClose={props.onClose}>
                    {props.title}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <WarningRoundedIcon fontSize="large"/>
                        <Typography variant="body1">
                            {props.message}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
                        <MercureContainedButton onClick={() => validate()} label="Confirmer" />
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}