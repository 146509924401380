import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import Location from "../../Components/Location";

export default function Lease(props) {
    const [openAutoComplete, setOpenAutoComplete] = React.useState(false);
    const [nameErrorMessage, setNameErrorMessage] = React.useState('');
    const [housingErrorMessage, setHousingErrorMessage] = React.useState('');
    let [housings, setHousings] = React.useState([]);
    const ACTIVITY_CHARACTER_LIMIT = 1024;

    const {
        siren,
        activity,
        siret,
        name,
        housing,
        type,
        signDate,
        startRentalDate,
        paymentDay,
        options,
        option,
        hqAddress,
        rcsCity,
        addressErrorMessage,
        rcsCityErrorMessage
    } = props.data;

    let loading = openAutoComplete && options && options.length === 0;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (name && name.length > 3) {
            (async () => {
                await fetch(process.env.REACT_APP_API_HOST + '/insee/get/name/' + name, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(result => {
                    if (result.status === 200) {
                        console.log('200', result);
                        result.json().then(response => {
                            //console.log('response', response);
                            if (active) {
                                props.onLeaseChange(response, 'options')
                            }
                        })
                    } else if (result.status === 500) {
                        console.log('500', result)
                        //props.onLeaseChange([], 'options');
                    } else {
                        //props.onLeaseChange([], 'options');
                        console.log('error', result);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            })();
        }

        return () => {
            active = false;
        };
    }, [loading, name]);

    React.useEffect(() => {
        if (siren !== '') {
            getDataBySiren();
        }

        let possessions = JSON.parse(localStorage.getItem('housings'));

        if (null === possessions) {
            getHousingsByIdUser();
        } else {
            setHousings(possessions);
        }
    }, []);

    React.useEffect(() => {
        if (!openAutoComplete) {
            props.onLeaseChange([], 'options');
        }
    }, [openAutoComplete]);

    async function getDataBySiren() {
        await fetch(process.env.REACT_APP_API_HOST + '/insee/get/siren/' + props.data.siren, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            console.log('res', result);
            if (result.status === 200) {
                result.json().then(response => {
                    props.handleOption(response[0])
                })
            } else {
                let data = {};
                let uniteLegale = {};
                uniteLegale.denominationUniteLegale = name;
                data.uniteLegale = uniteLegale;
                props.handleOption(data);
                //console.log(result);
            }
        }).catch((error) => {
            //console.log(error);
        });
    }

    function getHousingsByIdUser() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/housing/get/user/id/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousings(response);
                    localStorage.setItem('housings', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div>
            <Container maxWidth="xs" tabIndex={-1}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Autocomplete
                            id="name"
                            autoComplete="given-name"
                            freeSolo
                            open={openAutoComplete}
                            fullWidth
                            label="Nom de l'entreprise"
                            autoFocus
                            value={option}
                            isOptionEqualToValue={(option) => option.siret === siret}
                            onOpen={() => {
                                setOpenAutoComplete(true);
                            }}
                            onClose={() => {
                                setOpenAutoComplete(false);
                            }}
                            onInputChange={(event, newInputValue) => {
                                props.onLeaseChange(newInputValue, 'inputName')
                            }}
                            onChange={(event, newValue) => {
                                props.onLeaseChange(newValue, 'valueName');
                            }}
                            getOptionLabel={(option) => (option !== undefined && option.uniteLegale !== undefined) ? option.uniteLegale.denominationUniteLegale : ''}
                            options={options}
                            loading={loading}
                            filterOptions={(x) => x}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.siret}>
                                        <Grid container alignItems="center">
                                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                <Box component="span" sx={{ fontWeight: option.highlight ? 'bold' : 'regular' }}>
                                                    {option.uniteLegale.denominationUniteLegale} - {option.siret}
                                                </Box>
                                                <Typography variant="subtitle1" color="text.secondary">
                                                    {option.adresseEtablissement.numeroVoieEtablissement} {option.adresseEtablissement.complementAdresseEtablissement} {option.adresseEtablissement.typeVoieEtablissement} {option.adresseEtablissement.libelleVoieEtablissement} {option.adresseEtablissement.codePostalEtablissement} {option.adresseEtablissement.libelleCommuneEtablissement}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    label="Nom de l'entreprise"
                                    error={nameErrorMessage !== ""}
                                    helperText= {nameErrorMessage}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="secondary" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Forme juridique"
                            id="type"
                            autoComplete="family-name"
                            value={type}
                            onChange={props.onLeaseChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Location label="Adresse du siège social"
                                  required="required"
                                  name="hqAddress"
                                  value={hqAddress}
                                  onChange={(event, newValue) => props.onLeaseChange(null, newValue, 'hqAddress')}
                                  noOptionsText="Aucune adresse saisie"
                                  error={addressErrorMessage !== ""}
                                  helperText= {addressErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   autoComplete="given-name"
                                   id="siren"
                                   label="Siren"
                                   value={siren}
                                   onChange={props.onLeaseChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Location label="Ville du RCS"
                                  required="required"
                                  name="rcsCity"
                                  value={rcsCity}
                                  onChange={(event, newValue) => props.onLeaseChange(null, newValue, 'rcsCity')}
                                  noOptionsText="Aucune ville saisie"
                                  error={rcsCityErrorMessage !== ""}
                                  helperText= {rcsCityErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            inputProps={{
                                maxlength: ACTIVITY_CHARACTER_LIMIT
                            }}
                            fullWidth
                            label="Activité exercée"
                            id="activity"
                            autoComplete="family-name"
                            value={activity}
                            onChange={props.onLeaseChange}
                            rows={4}
                            multiline
                            helperText={`${activity.length}/${ACTIVITY_CHARACTER_LIMIT}`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="housing"
                            noOptionsText="Sélectionner le bien concerné par la location"
                            onChange={(event, newValue) => {
                                {props.onLeaseChange(event, newValue)}
                            }}
                            value={housing && housing.name ? housing.name : ""}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined || value === "" || option.name === value
                            }
                            options={housings}
                            autoHighlight
                            getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{mr: 2, flexShrink: 0}} {...props}>
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Bien concerné par la location"
                                           InputProps={{
                                               ...params.InputProps,
                                               type: 'new-password',
                                           }}
                                           required
                                           error={housingErrorMessage !== ""}
                                           helperText= {housingErrorMessage}
                                           fullWidth/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Date de signature du contrat"
                                value={signDate}
                                id="signDate"
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    props.onLeaseChange(newValue, "signDate");
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Date de début de location"
                                value={startRentalDate}
                                id="startRentalDate"
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    props.onLeaseChange(newValue, "startRentalDate");
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="paymentDay"
                            label="Jour d'encaissement de loyer"
                            value={paymentDay}
                            type="number"
                            onChange={props.onLeaseChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Le </InputAdornment>,
                                endAdornment: <InputAdornment position="end"> du mois</InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}