import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import ListSubheader from "@mui/material/ListSubheader";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import SearchBar from "../../Components/SearchBar";
import AddRaise from "./AddRaise";
import Snackbar from "@mui/material/Snackbar";
import Alert from "../../Components/Alert";
import Confirm from "../../Components/Confirm";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Drawer from "@mui/material/Drawer";
import UpdateRaise from "./UpdateRaise";
import FormControlLabel from "@mui/material/FormControlLabel";
import IOSSwitch from "../../Components/IOSSwitch";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Divider from "@mui/material/Divider";

export default function Raise() {
    let [raises, setRaises] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [openAddRaise, setOpenAddRaise] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const title = 'Attention';
    const confirmMessage = "Etes-vous sûr de vouloir supprimer définitivement cette relance ?\nCette action est irréversible.";
    let [selectedRaise, setSelectedRaise] = React.useState();
    const anchorPosition = 'right';
    const [openDrawer, setOpenDrawer] = React.useState(false);
    
    const [values, setValues] = React.useState({
        name: ""
    });

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    async function getRaises() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        let id = user.id;
        await fetch(process.env.REACT_APP_API_HOST + '/tenant/raise/get/user/' + id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    // Remove data from cache
                    localStorage.removeItem('raises');
                    //console.log('response', response);
                    // Refresh data
                    setRaises(response);

                    // Save data
                    localStorage.setItem('raises', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    React.useEffect(() => {
        const raises = JSON.parse(localStorage.getItem('raises'));
        
        if (null === raises) {
            getRaises();
        } else {
            setRaises(raises);
        }
    }, []);

    const onFilterChange = (event) => {
        setFilter(event.target.value);
    }

    const filteredRaises= raises && raises.filter(raise => {
        if (filter === '') {
            return raise;
        } else {
            return raise.name && raise.name.toLowerCase().includes(filter);
        }
    });

    const handleClick = () => {
        setOpenAddRaise(true);
    }

    const handleCloseAddRaise = () => {
        setOpenAddRaise(false);
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleMessage = (message) => {
        setMessage(message);
    };

    const handleSeverity = (severity) => {
        setSeverity(severity);
    };

    const toggleConfirm = (open, raise) => (event) => {
        if (open) {
            setSelectedRaise(raise);
        } else {
            setSelectedRaise();
        }

        setOpenConfirm(open);
    }

    const raiseUpdated = () => {
        setOpenDrawer(false);
        setSelectedRaise();
        getRaises();
        handleMessage("Vos données ont été modifiées");
        handleSeverity('success');
        handleOpenAlert();
    }

    const raiseReplayed = () => {
        setOpenDrawer(false);
        setSelectedRaise();
        handleMessage("La relance a été rejouée");
        handleSeverity('success');
        handleOpenAlert();
    }

    const raiseDeleted = () => {
        setSelectedRaise();
        getRaises();
        handleMessage("La relance a été supprimée");
        handleSeverity('success');
        handleOpenAlert();
    }

    const raiseNotUpdated = () => {
        setOpenDrawer(false);
        setSelectedRaise();
        handleMessage("Vos données n'ont pas pu être modifiées suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const raiseNotDeleted = () => {
        handleMessage("La relance n'a pas pu être supprimée suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const handlingRequiredField = (message) => {
        handleMessage(message);
        handleSeverity('error');
        handleOpenAlert();
    }

    const toggleDrawer = (open, raise) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (open) {
            setSelectedRaise(raise);
        } else {
            setSelectedRaise();
        }

        setOpenDrawer(open);
    }

    const closeDrawer = () => {
        setOpenDrawer(false);
    }

    async function handleActive(active, id) {
        let data = {};
        data.active = active;

        await fetch(process.env.REACT_APP_API_HOST + '/tenant/raise/update/' + id, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 204) {
                // Refresh data
                raiseUpdated();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    async function handleReplay(id) {
        await fetch(process.env.REACT_APP_API_HOST + '/tenant/raise/replay/' + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 204) {
                // Refresh data
                raiseReplayed();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const duplicate = React.useCallback((raise) => {
        let data = {};

        for (let key in raise) {
            if ('id' === key || 'createdAt' === key || 'updatedAt' === key || 'tenantsToRaise' === key) {
                continue;
            }

            if ('tenants' === key) {
                data[key] = raise.tenantsToRaise;
                continue;
            }

            data[key] = raise[key];
        }

        //console.log('data', data);return
        fetch(process.env.REACT_APP_API_HOST + '/tenant/raise/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                // Show Alert
                handleMessage("Votre relance a été dupliquée");
                handleSeverity('success');
                handleOpenAlert();

                // Refresh data
                getRaises();
            }
        }).catch((error) => {
            console.log(error);
            handleMessage("Votre relance n'a pas pu être dupliquée suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        });
    }, []);

    return (
        <div>
            <Paper sx={{maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
                {openAddRaise &&
                    <AddRaise open={openAddRaise} onClose={handleCloseAddRaise} handleOpenAlert={handleOpenAlert} handleMessage={handleMessage} handleSeverity={handleSeverity} refresh={getRaises} handlingRequiredField={handlingRequiredField} />
                }
                
                <SearchBar onFilterChange={(e) => onFilterChange(e)} buttonLabel="Ajouter une relance" placeholder="Chercher une relance" onButtonClick={handleClick} onRefresh={getRaises} />
                
                {filteredRaises && filteredRaises.length === 0 ? (
                    <Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
                        Vous n'avez aucune relance
                    </Typography>
                ) : (
                    <List aria-labelledby="nested-list-subheader"
                          subheader={
                              <ListSubheader component="div" id="nested-list-subheader">
                                  {filteredRaises && filteredRaises.length} relance.s
                              </ListSubheader>
                          }
                    >
                        {filteredRaises && filteredRaises.map && filteredRaises.map((raise, index) =>
                            <div>
                                <ListItem key={raise.id}
                                          secondaryAction={
                                              <Grid>
                                                  <Tooltip title="Modifier">
                                                      <IconButton edge="end" aria-label="edit" onClick={toggleDrawer(true, raise)}>
                                                          <EditIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Dupliquer">
                                                      <IconButton edge="end" aria-label="duplicate" onClick={() => duplicate(raise)}>
                                                          <ContentCopyIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  {raise.type === 'auto' &&
                                                      <Tooltip title={raise.active ? "Mettre en pause" : "Activer"}>
                                                          <FormControlLabel
                                                              sx={{ mr:0 }}
                                                              label=""
                                                              onChange={(e) => handleActive(!raise.active, raise.id)}
                                                              control={<IOSSwitch sx={{ ml:3 }} checked={raise.active} />}
                                                          />
                                                      </Tooltip>
                                                  }
                                                  {raise.type === 'manual' &&
                                                      <Tooltip title="Rejouer">
                                                          <IconButton edge="end" aria-label="replay" onClick={(e) => handleReplay(raise.id)}>
                                                              <PlayCircleIcon/>
                                                          </IconButton>
                                                      </Tooltip>
                                                  }
                                                  <Tooltip title="Supprimer">
                                                      <IconButton edge="end" aria-label="delete" color="error" onClick={toggleConfirm(true, raise)}>
                                                          <DeleteIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                              </Grid>
                                          }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <NotificationsActiveIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id={raise.id}
                                                  primary={raise.name}
                                    />
                                </ListItem>
                                {
                                    ((index === 0 && filteredRaises.length > 1) || index < filteredRaises.length - 1) && <Divider variant="inset" component="li" />
                                }
                            </div>
                        )}
                    </List>
                )}

                <Drawer anchor={anchorPosition}
                        open={openDrawer}
                        PaperProps={{
                            sx: {backgroundColor: "#ffffff"}
                        }}
                        onClose={toggleDrawer(false)}>
                    <UpdateRaise raise={selectedRaise} raiseUpdated={raiseUpdated}
                                 raiseNotUpdated={raiseNotUpdated}
                                 onClose={closeDrawer}
                                 handleOpenAlert={handleOpenAlert}
                                 handleMessage={handleMessage}
                                 handleSeverity={handleSeverity}
                                 handlingRequiredField={handlingRequiredField} />
                </Drawer>
            </Paper>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>

            <Confirm open={openConfirm}
                     title={title}
                     message={confirmMessage}
                     onClose={toggleConfirm(false)}
                     selected={selectedRaise}
                     type="raise"
                     titleBackgroundColor="#ff9800"
                     raiseDeleted={raiseDeleted}
                     raiseNotDeleted={raiseNotDeleted}
                     titleColor="white"/>
        </div>
    );
}