import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import AddReceipt from "./AddReceipt";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from '@mui/material/ListItem';
import Avatar from "@mui/material/Avatar";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {useSearchParams} from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import Slide from "@mui/material/Slide";
import StyledMenu from "../../Components/StyledMenu";
import Alert from "../../Components/Alert";
import Waiting from "../../Components/Waiting";
import Confirm from "../../Components/Confirm";
import Drawer from "@mui/material/Drawer";
import UpdateReceipt from "./UpdateReceipt";
import MercureContainedButton from "../../Components/MercureContainedButton";
import Divider from "@mui/material/Divider";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Receipt(props) {
    let maxYear = new Date().setFullYear(new Date().getFullYear());
    let maxDate = dayjs(maxYear).toLocaleString();
    const [receipts, setReceipts] = React.useState([]);
    const [tenants, setTenants] = React.useState(null);
    const [filter, setFilter] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [openReceipt, setOpenReceipt] = React.useState(false);
    const [receiptYear, setReceiptYear] = React.useState(dayjs(new Date()).format('YYYY'));
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [waitingTitle, setWaitingTitle] = React.useState('Sauvegarde');
    const [waitingMessage, setWaitingMessage] = React.useState('La sauvegarde de votre document est en cours, merci de patienter encore quelques instants...');
    const [loading, setLoading] = React.useState(false);
    const title = 'Attention';
    const confirmMessage = "Etes-vous sûr de vouloir supprimer définitivement cette quittance ?\nCette action est irréversible.";
    const [openConfirm, setOpenConfirm] = React.useState(false);
    let [selectedReceipt, setSelectedReceipt] = React.useState();
    const anchorPosition = 'right';
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onFilterChange = (event, value) => {
        setFilter(value);
    }

    const getTenants = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        localStorage.removeItem('tenants');
        let id = user.id;

        fetch(process.env.REACT_APP_API_HOST + '/tenant/person/get-by-id-user/' + id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    console.log('response ', response);
                    setTenants(response);
                    localStorage.setItem('tenants', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const getReceipts = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        let data = {};
        data.idUser = user.id;
        data.idTenant = filter && filter.id;
        data.year = receiptYear;
        //console.log('getReceipts', data);//return;
        const url = process.env.REACT_APP_API_HOST + '/receipt/get';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    console.log('response', response);
                    setReceipts(response);
                    localStorage.setItem('receipts', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [filter, receiptYear]);

    const refreshData = React.useCallback((tenant, year) => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        let data = {};
        data.idUser = user.id;
        data.idTenant = tenant.id;
        data.year = year;
        //console.log('refreshData', data);//return;
        const url = process.env.REACT_APP_API_HOST + '/receipt/get';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response', result);
                    setReceipts(response);
                    localStorage.setItem('receipts', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    React.useEffect(() => {
        const lodgers = JSON.parse(localStorage.getItem('tenants'));

        if (null === lodgers) {
            getTenants();
        } else {
            setTenants(lodgers);
        }
    }, []);

    // Move file to S3
    React.useEffect(() => {
        // Refresh header
        props.handleTitle("Documents");
        props.handleSubtitle("", [
            { id: 'Personne physique', path: '/receipt', index: 0 },
            { id: 'Personne morale', path: '/receipt/corporation', index: 1 }
            ]
        );

        // Highlight Nav
        props.handleListItemClick(null, 60);

        const fileName = JSON.parse(localStorage.getItem('fileName'));
        const envelopeId = JSON.parse(localStorage.getItem('envelopeId'));
        const documentId = JSON.parse(localStorage.getItem('documentId'));

        if (null !== fileName && null !== documentId && null !== envelopeId) {
            setLoading(true);
            let data = {};
            data.fileName = fileName;
            data.envelopeId = envelopeId;
            data.documentId = documentId.toString();
            const url= process.env.REACT_APP_API_HOST + '/receipt/upload';

            fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(result => {
                if (result.status === 201) {
                    // Remove data from local storage
                    localStorage.removeItem('fileName');
                    localStorage.removeItem('envelopeId');
                    localStorage.removeItem('documentId');

                    // Hide waiting
                    setLoading(false);

                    // Show alert
                    handleMessage("Votre quittance a été créée");
                    handleSeverity('success');
                    handleOpenAlert();

                    // Get tenant filter
                    const receiptTenantFilter = JSON.parse(localStorage.getItem('receiptTenantFilter'));
                    if (receiptTenantFilter !== null) {
                        setFilter(receiptTenantFilter);
                    }

                    // Get year filter
                    const receiptTenantYear = JSON.parse(localStorage.getItem('receiptTenantYear'));
                    if (receiptTenantYear !== null) {
                        setReceiptYear(receiptTenantYear);
                    }

                    // Refresh page
                    refreshData(receiptTenantFilter, receiptTenantYear);
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    }, []);

    const handleSelectMenu = (type) => {
        handleCloseMenu();
    }

    const handleOpenReceipt = () => {
        handleCloseMenu();
        setOpenReceipt(true);
    }

    const handleCloseReceipt = () => setOpenReceipt(false);

    const onReceiptYearChange = (event, value) => {
        setReceiptYear(dayjs(value.toLocaleString()).format('YYYY'));
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleMessage = (message) => {
        setMessage(message);
    };

    const handleSeverity = (severity) => {
        setSeverity(severity);
    };

    const filteredReceipts = receipts.filter(receipt => {
        //console.log('fil', filter, receipt)
        if (filter === null) {
            return receipt;
        } else {
            return (filter && receipt.idTenant === filter.id);
        }
    })

    function forceDownload(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        document.body.appendChild(a);

        // Hide loading before downloading
        setLoading(false);

        // Download
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    // Download from S3
    const download = (receipt) => (event) => {
        // Loading
        setWaitingTitle('Téléchargement');
        setWaitingMessage('Le téléchargement de votre document est en cours, merci de patienter quelques instants...')
        setLoading(true);

        let data = {};
        data.fileName = receipt.name;
        const url= process.env.REACT_APP_API_HOST + '/receipt/download/s3';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            //console.log('result', result);
            if (result.status === 200) {
                result.blob().then(blob => forceDownload(blob, receipt.name))
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const resend = (receipt) => (event) => {
        setWaitingMessage("Votre quittance est en cours d'achéminement, merci de patienter quelques instants...");
        setWaitingTitle("Envoi");
        setLoading(true);

        let data = {};
        data.fileName = receipt.name;
        data.idTenant = receipt.idTenant;
        const url= process.env.REACT_APP_API_HOST + '/receipt/send';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            //console.log('result', result);
            if (result.status === 204) {
                setLoading(false);
                handleMessage("La quittance a été envoyée");
                handleSeverity('success');
                handleOpenAlert();
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            handleMessage("La quittance n'a pas pu être envoyée suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        })
    }

    const toggleConfirm = (open, receipt) => (event) => {
        if (open) {
            setSelectedReceipt(receipt);
        } else {
            setSelectedReceipt();
        }

        setOpenConfirm(open);
    }

    const receiptDeleted = () => {
        setSelectedReceipt();
        getReceipts();
        handleMessage("La quittance a été supprimée");
        handleSeverity('success');
        handleOpenAlert();
    }

    const receiptNotDeleted = () => {
        handleMessage("La quittance n'a pas pu être supprimée suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const toggleDrawer = (open, receipt) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (open) {
            /*if (housings && housings.length !== 0) {
                coowner.housing = housings.find((housing) => housing.id === coowner.idHousing);
            }*/
            setSelectedReceipt(receipt);
        } else {
            setSelectedReceipt();
        }

        setOpenDrawer(open);
    }

    const closeDrawer = () => {
        setOpenDrawer(false);
    }

    const receiptUpdated = () => {
        setOpenDrawer(false);
        setSelectedReceipt();
        getReceipts();
        handleMessage("Vos données ont été modifiées");
        handleSeverity('success');
        handleOpenAlert();
    }

    const receiptNotUpdated = () => {
        setOpenDrawer(false);
        setSelectedReceipt();
        handleMessage("Vos données n'ont pas pu être modifiées suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    return (
        <div>
            <Paper sx={{maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
                {openReceipt === true && (
                    <AddReceipt open={openReceipt} onClose={handleCloseReceipt} tenants={tenants} handleOpenAlert={handleOpenAlert} handleMessage={handleMessage} handleSeverity={handleSeverity}/>
                )}
                <AppBar position="static" color="default" elevation={0} sx={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center" sx={{mt: 1, mb: 2}}>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        label="Année"
                                        id="receiptYear"
                                        inputFormat="YYYY"
                                        maxDate={maxDate}
                                        views={['year']}
                                        value={receiptYear}
                                        onChange={(newValue) => {
                                            onReceiptYearChange(null, newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs>
                                <Autocomplete
                                    disablePortal
                                    options={tenants}
                                    id="tenant"
                                    autoHighlight
                                    value={filter}
                                    onChange={(event, newValue) => {
                                        onFilterChange(event, newValue)
                                    }}
                                    getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName}` : ''}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{mr: 2, flexShrink: 0}} {...props}>
                                            {option ? `${option.firstName} ${option.lastName}` : ''}
                                        </Box>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Sélectionner un locataire"
                                                                        fullWidth/>}
                                />
                            </Grid>
                            <Grid item>
                                <MercureContainedButton label="Chercher" disableElevation onClick={getReceipts} />
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="Plus"
                                            onClick={handleOpenMenu}
                                            aria-controls={openMenu ? 'menuButton' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openMenu ? 'true' : undefined}>
                                    <MoreVertIcon />
                                </IconButton>
                                <StyledMenu
                                    id="menuButton"
                                    MenuListProps={{
                                        'aria-labelledby': 'menuButton'
                                    }}
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={() => handleOpenReceipt()} disableRipple>
                                        <ListItemIcon>
                                            <AddIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Nouvelle quittance</ListItemText>
                                    </MenuItem>
                                    {/*
                                        <MenuItem onClick={() => handleOpenReceipt()} disableRipple>
                                            <ListItemIcon>
                                                <SendIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>Génération & envoi automatique</ListItemText>
                                        </MenuItem>
                                    */}
                                    
                                </StyledMenu>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                {filteredReceipts && filteredReceipts.length === 0 ? (
                    <Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
                        Lancer la recherche pour afficher les quittances
                    </Typography>
                ) : (
                    <List subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {filteredReceipts && filteredReceipts.length} quittance.s
                        </ListSubheader>
                    }>
                        {filteredReceipts && filteredReceipts.map && filteredReceipts.map((receipt, index) =>
                            <div>
                                <ListItem key={receipt.id}
                                          secondaryAction={
                                              <Grid>
                                                  <Tooltip title="Modifier">
                                                      <IconButton edge="end" aria-label="edit" onClick={toggleDrawer(true, receipt)}>
                                                          <EditIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Télécharger">
                                                      <IconButton edge="end" aria-label="download" onClick={download(receipt)}>
                                                          <DownloadIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Renvoyer par mail">
                                                      <IconButton edge="end" aria-label="resend" onClick={resend(receipt)}>
                                                          <EmailIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Supprimer">
                                                      <IconButton edge="end" aria-label="delete" color="error" onClick={toggleConfirm(true, receipt)}>
                                                          <DeleteIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                              </Grid>
                                          }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ReceiptLongIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id={receipt.id}
                                                  primary={receipt.name}
                                                  secondary={
                                                      <React.Fragment>
                                                          <Typography variant="subtitle1">
                                                              {`Du ${dayjs(receipt.startDate).format('DD/MM/YYYY')} au ${dayjs(receipt.endDate).format('DD/MM/YYYY')}`}
                                                          </Typography>
                                                      </React.Fragment>
                                                  }
                                    />
                                </ListItem>
                                {
                                    ((index === 0 && filteredReceipts.length > 1) || index < filteredReceipts.length - 1) && <Divider variant="inset" component="li" />
                                }
                            </div>
                        )}
                    </List>
                )}

                {loading && (
                    <Waiting loading={loading} title={waitingTitle} message={waitingMessage} />
                )}

                <Drawer anchor={anchorPosition}
                        open={openDrawer}
                        PaperProps={{
                            sx: {backgroundColor: "#ffffff"}
                        }}
                        onClose={toggleDrawer(false)}>
                    <UpdateReceipt receipt={selectedReceipt} receiptUpdated={receiptUpdated} receiptNotUpdated={receiptNotUpdated} onClose={closeDrawer} handleOpenAlert={handleOpenAlert} handleMessage={handleMessage} handleSeverity={handleSeverity} />
                </Drawer>
            </Paper>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>

            <Confirm open={openConfirm}
                     title={title}
                     message={confirmMessage}
                     onClose={toggleConfirm(false)}
                     selected={selectedReceipt}
                     type="receipt"
                     titleBackgroundColor="#ff9800"
                     receiptDeleted={receiptDeleted}
                     receiptNotDeleted={receiptNotDeleted}
                     titleColor="white"/>

        </div>
    )
}