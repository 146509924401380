import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {createTheme} from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import 'react-phone-number-input/style.css';
import validator from "validator";
import Snackbar from '@mui/material/Snackbar';
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from "@mui/material/Autocomplete";
import BootstrapDialogTitle from "../../Components/BootstrapDialogTitle";
import BootstrapDialog from "../../Components/BootstrapDialog";
import Alert from "../../Components/Alert";
import MercureContainedButton from "../../Components/MercureContainedButton";
import Location from "../../Components/Location";
import {REQUIRED_FIELD} from "../../Consts";

const theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});

export default function AddCoowner(props) {
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [civility, setCivility] = React.useState('');
    const [address, setAddress] = React.useState(null);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState('');
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState('');
    const [housingErrorMessage, setHousingErrorMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    let [housings, setHousings] = React.useState([]);
    let [housing, setHousing] = React.useState(null);

    const onEmailChange = (e) => {
        let email = e.target.value;

        if (email !== '') {
            if (validator.isEmail(email)) {
                setEmailErrorMessage("");
            } else {
                setEmailErrorMessage("L'adresse e-mail saisie est invalide");
            }
        } else {
            setEmailErrorMessage("");
        }

        setEmail(email);
    };

    const onHousingChange = (event, value) => {
        if (null != value) {
            setHousingErrorMessage('');
        } else {
            setHousingErrorMessage(REQUIRED_FIELD);
        }

        setHousing(value);
    }

    const onAddressChange = (event, value) => {
        setAddress(value.description);
    }

    const handleCivilityChange = (event) => {
        setCivility(event.target.value);
    };

    const onFirstNameChange = (event) => {
        let fname = event.target.value;

        if (fname !== '') {
            setFirstNameErrorMessage('');
        } else {
            setFirstNameErrorMessage(REQUIRED_FIELD);
        }

        setFirstName(fname);
    };

    const onLastNameChange = (event) => {
        let lname = event.target.value;

        if (lname !== '') {
            setLastNameErrorMessage('');
        } else {
            setLastNameErrorMessage(REQUIRED_FIELD);
        }

        setLastName(lname);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    async function saveCoowner() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        if (firstName === '') {
            setFirstNameErrorMessage(REQUIRED_FIELD);
            return;
        }

        if (lastName === '') {
            setLastNameErrorMessage(REQUIRED_FIELD);
            return;
        }

        if (housing === null) {
            setHousingErrorMessage(REQUIRED_FIELD);
            return;
        }

        const data = {};
        data.idUser = user.id;
        data.civility = civility;
        data.firstName = firstName;
        data.lastName = lastName;
        data.email = email;
        data.address = address;
        data.idHousing = housing.id;

        await fetch(process.env.REACT_APP_API_HOST + '/coowner/person/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    //console.log('response', response);
                    // Hide dialog
                    props.onClose();

                    // Alert
                    props.handleMessage("Le copropriétaire a été ajouté");
                    props.handleSeverity('success');
                    props.handleOpenAlert();

                    // Remove coowners from localstorage
                    localStorage.removeItem('coowners');

                    // Refresh data
                    props.refresh();
                })
            } else {
                console.log(result);
                props.handleMessage("Le copropriétaire n'a pas pu être ajouté suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);

            props.handleMessage("Le copropriétaire n'a pas pu être ajouté suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    React.useEffect(() => {
        let possessions = JSON.parse(localStorage.getItem('housings'));

        if (null === possessions) {
            getHousingsByIdUser();
        } else {
            setHousings(possessions);
        }
     }, []);

    function getHousingsByIdUser() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/housing/get/user/id/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousings(response);
                    localStorage.setItem('housings', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Ajouter un copropriétaire
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Container component="" maxWidth="xs" tabIndex={-1}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="civility">Civilité</InputLabel>
                                    <Select
                                        labelId="civility"
                                        value={civility}
                                        name="civility"
                                        label="Civilité"
                                        onChange={handleCivilityChange}
                                    >
                                        <MenuItem value={1}>Madame</MenuItem>
                                        <MenuItem value={2}>Mademoiselle</MenuItem>
                                        <MenuItem value={3}>Monsieur</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    autoComplete="given-name"
                                    name="firstName"
                                    fullWidth
                                    label="Prenom"
                                    autoFocus
                                    value={firstName}
                                    onChange={onFirstNameChange}
                                    error={firstNameErrorMessage !== ""}
                                    helperText= {firstNameErrorMessage}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nom"
                                    name="lastName"
                                    autoComplete="family-name"
                                    value={lastName}
                                    onChange={onLastNameChange}
                                    required
                                    error={lastNameErrorMessage !== ""}
                                    helperText= {lastNameErrorMessage}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={email}
                                    label="E-mail"
                                    name="email"
                                    autoComplete="email"
                                    error={emailErrorMessage !== ""}
                                    helperText= {emailErrorMessage}
                                    onChange={onEmailChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Location
                                    name="address"
                                    label="Adresse"
                                    value={address}
                                    noOptionsText="Aucune adresse saisie"
                                    onChange={onAddressChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="housing"
                                    noOptionsText="Sélectionner le bien concerné par la copropriété"
                                    onChange={(event, newValue) => {
                                        {onHousingChange(event, newValue)}
                                    }}
                                    value={housing && housing.name ? housing.name : ""}
                                    isOptionEqualToValue={(option, value) =>
                                        value === undefined || value === "" || option.name === value
                                    }
                                    options={housings}
                                    autoHighlight
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{mr: 2, flexShrink: 0}} {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                                   label="Bien concerné par la copropriété"
                                                   InputProps={{
                                                       ...params.InputProps,
                                                       type: 'new-password',
                                                   }}
                                                   required
                                                   error={housingErrorMessage !== ""}
                                                   helperText= {housingErrorMessage}
                                                   fullWidth/>}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <MercureContainedButton
                            onClick={saveCoowner}
                            label="Valider" />
                    </Box>
                </DialogActions>
            </BootstrapDialog>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}