import * as React from 'react';
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip, Inject } from '@syncfusion/ej2-react-charts';
import { red } from '@mui/material/colors';
import Container from "@mui/material/Container";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';

export default function Dashboard() {
    const [distributionByType, setDistributionByType] = React.useState([]);
    const [distributionByBoughtAt, setDistributionByBoughtAt] = React.useState([]);
    const [addressCoordinates, setAddressCoordinates] = React.useState([]);
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const defaultCenter = {lat: 48.973789, lng: 2.374030}

    React.useEffect(() => {
        if (null === user) {
            return;
        }

        getAddressCoordinates();
        getDistributionByType();
        getDistributionByBoughtAt();
    }, []);

    async function getAddressCoordinates() {
        await fetch(process.env.REACT_APP_API_HOST + '/housing/get/address/coordinates/id/' + user.id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response', response);
                    setAddressCoordinates(response);
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    async function getDistributionByType() {
        await fetch(process.env.REACT_APP_API_HOST + '/housing/get-distribution-by-type/' + user.id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ' + response);
                    setDistributionByType(response);
                })
            }
        }).catch((error) => {
            //console.log(error);
        });
    }

    async function getDistributionByBoughtAt() {
        await fetch(process.env.REACT_APP_API_HOST + '/housing/get-distribution-by-boughtAt/' + user.id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ' + response);
                    setDistributionByBoughtAt(response);
                })
            }
        }).catch((error) => {
            //console.log(error);
        });
    }

    return (
        <Container component="main">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                    LG
                                </Avatar>
                            }
                            title="Localisation géographique"
                        />
                        <CardContent>
                            <div style={{ height: '100vh', width: '100%' }}>
                                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                                    <Map defaultCenter={defaultCenter} defaultZoom={5}>
                                        {
                                            addressCoordinates && addressCoordinates.map((address) =>
                                                <Marker position={address} />
                                            )
                                        }
                                    </Map>
                                </APIProvider>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                    RT
                                </Avatar>
                            }
                            title="Répartition par type de bien"
                        />
                        <CardContent>
                            <AccumulationChartComponent
                                ref={pie => pie = pie}
                                legendSettings={{visible: true}}
                                enableSmartLabels={true}
                                enableAnimation={true}
                                tooltip={{ enable: true }}>
                                <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]}/>
                                <AccumulationSeriesCollectionDirective>
                                    <AccumulationSeriesDirective dataSource={distributionByType} xName='type' yName='nb' innerRadius='40%' dataLabel={{
                                        visible: true, position: 'Outside', name: 'x'
                                    }}>
                                    </AccumulationSeriesDirective>
                                </AccumulationSeriesCollectionDirective>
                            </AccumulationChartComponent>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                    RA
                                </Avatar>
                            }
                            title="Répartition par année d'acquisition du bien"
                        />
                        <CardContent>
                            <AccumulationChartComponent
                                ref={pie => pie = pie}
                                legendSettings={{visible: true}}
                                enableSmartLabels={true}
                                enableAnimation={true}
                                tooltip={{ enable: true }}>
                                <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]}/>
                                <AccumulationSeriesCollectionDirective>
                                    <AccumulationSeriesDirective dataSource={distributionByBoughtAt} xName='boughtAt' yName='nb' innerRadius='40%' dataLabel={{
                                        visible: true, position: 'Outside', name: 'x'
                                    }}>
                                    </AccumulationSeriesDirective>
                                </AccumulationSeriesCollectionDirective>
                            </AccumulationChartComponent>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}