import * as React from 'react';
import Box from "@mui/material/Box";
import {createTheme, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import Identity from "./Identity";
import Rental from "./Rental";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import validator from "validator";
import MercureContainedButton from "../../Components/MercureContainedButton";
import MercureOutlinedButton from "../../Components/MercureOutlinedButton";
import BootstrapDialog from "../../Components/BootstrapDialog";
import BootstrapDialogTitle from "../../Components/BootstrapDialogTitle";
import ColorlibConnector from "../../Components/ColorlibConnector";
import ColorlibStepIcon from "../../Components/ColorlibStepIcon";

const steps = ['Identité', 'Location'];

function getStepContent(step, identity, rental, onIdentityChange, onRentalChange) {
    switch (step) {
        case 0:
            return <Identity data={identity} onIdentityChange={onIdentityChange} />;
        case 1:
            return <Rental data={rental} onRentalChange={onRentalChange} />;
        default:
            throw new Error('Unknown step');
    }
}

let tenant = [];

export default function AddTenant(props) {
    const theme = createTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const descriptionElementRef = React.useRef(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = React.useState(false);

    const [identity, setIdentity] = React.useState({
        civility: "",
        firstName: "",
        lastName: "",
        phonePrefix: 33,
        phonePrefixLabel: "France",
        phoneCode: "fr",
        phone: "",
        mobilePhonePrefix: 33,
        mobilePhonePrefixLabel: "France",
        mobilePhoneCode: "fr",
        mobilePhone: "",
        email: "",
        emailErrorMessage: "",
        declaration: true
    });

    // Rental
    const [rental, setRental] = React.useState({
        housing: null,
        rentalAmount: "",
        chargesAmount: "",
        apl: "",
        signDate: null,
        startRentalDate: null,
        paymentDay: ""
    });

    const onIdentityChange = (event, value) => {
        if (event) {
            switch (event.target.id) {
                case 'firstName':
                    setIdentity({
                        ...identity,
                        firstName: event.target.value
                    });
                    break;

                case 'lastName':
                    setIdentity({
                        ...identity,
                        lastName: event.target.value
                    });
                    break;

                case 'phone':
                    setIdentity({
                        ...identity,
                        phone: event.target.value
                    });
                    break;

                case 'mobilePhone':
                    setIdentity({
                        ...identity,
                        mobilePhone: event.target.value
                    });
                    break;

                case 'declaration':
                    setIdentity({
                        ...identity,
                        declaration: value
                    });
                    break;

                case 'email':
                    let email = event.target.value;

                    if (email !== '') {
                        if (validator.isEmail(email)) {
                            setIdentity({
                                ...identity,
                                emailErrorMessage: "",
                                email: email
                            });
                        } else {
                            setIdentity({
                                ...identity,
                                emailErrorMessage: "L'adresse e-mail saisie est invalide",
                                email: email
                            });
                        }
                    } else {
                        setIdentity({
                            ...identity,
                            emailErrorMessage: "",
                            email: email
                        });
                    }
                    break;

                default:
                    if (event.target.name === 'civility') {
                        setIdentity({
                            ...identity,
                            civility: event.target.value
                        });
                    } else {
                        let ids = event.target.id.split('-');

                        if (ids[0] === 'phonePrefix') {
                            setIdentity({
                                ...identity,
                                phonePrefix: (value) ? value.phone : 33,
                                phonePrefixLabel: (value) ? value.label : 'France',
                                phoneCode: (value) ? value.code : 'fr'
                            });
                        } else {
                            setIdentity({
                                ...identity,
                                mobilePhonePrefix: (value) ? value.phone : 33,
                                mobilePhonePrefixLabel: (value) ? value.label : 'France',
                                mobilePhoneCode: (value) ? value.code : 'fr'
                            });
                        }
                    }

                    break;
            }
        }
    }
    const onRentalChange = (event, value) => {
        if (value !== undefined) {
            if (value === 'signDate') {
                setRental({
                    ...rental,
                    signDate: (event) ? dayjs(event.toLocaleString()).format('YYYY-MM-DD') : null
                });
            } else if (value === 'startRentalDate') {
                setRental({
                    ...rental,
                    startRentalDate: (event) ? dayjs(event.toLocaleString()).format('YYYY-MM-DD') : null
                });
            } else {
                setRental({
                    ...rental,
                    housing: (value) ? value : null
                });
            }
        } else {
            switch (event.target.id) {
                case 'rentalAmount':
                    setRental({
                        ...rental,
                        rentalAmount: event.target.value
                    });
                    break;

                case 'chargesAmount':
                    setRental({
                        ...rental,
                        chargesAmount: event.target.value
                    });
                    break;

                case 'paymentDay':
                    setRental({
                        ...rental,
                        paymentDay: event.target.value
                    });
                    break;

                case 'apl':
                    setRental({
                        ...rental,
                        apl: event.target.value
                    });
                    break;

                default:
                    break;
            }
        }
    }

    const handleNext = () => {
        if (activeStep == 0) {
            // Identity
            let tempIdentity = {};
            for (let key in identity) {
                tempIdentity[key] = identity[key];
            }
            tenant['identity'] = tempIdentity;
            localStorage.setItem('identity', JSON.stringify(tenant['identity']));
        } else if (activeStep == 1) {
            // Rental
            let tempRental = {};
            for (let key in rental) {
                tempRental[key] = rental[key];
            }
            tenant['rental'] = tempRental;
            localStorage.setItem('rental', JSON.stringify(tenant['rental']));
        }

        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

        localStorage.removeItem('identity');
        localStorage.removeItem('rental');

        tenant['identity'] = JSON.parse(localStorage.getItem('identity'));
        tenant['rental'] = JSON.parse(localStorage.getItem('rental'));
        
        if (null !== tenant) {
            setTenant(tenant);
        }

    }, [open]);
    
    function setTenant(tenant) {
        // Identity
        setIdentity({
            ...identity,
            ...tenant['identity']
        });

        // Rental
        setRental({
            ...rental,
            ...tenant['rental']
        });
    }

    async function saveTenant() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        let data = {};
        data.idUser = user.id;

        for (let key in tenant['identity']) {
            if ('emailErrorMessage' === key) {
                continue;
            }

            data[key] = tenant['identity'][key];
        }

        for (let key in tenant['rental']) {
            if ('housing' === key && null !== tenant['rental'][key]) {
                data.idHousing = tenant['rental'][key]['id'];

                continue;
            }

            data[key] = tenant['rental'][key];
        }

        await fetch(process.env.REACT_APP_API_HOST + '/tenant/person/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    // Show Alert
                    props.onClose();
                    props.handleMessage("Vos données ont été sauvegardées");
                    props.handleSeverity('success');
                    props.handleOpenAlert();

                    // Refresh data
                    props.refresh();
                    localStorage.removeItem('identity');
                    localStorage.removeItem('rental');
                })
            }
        }).catch((error) => {
            console.log(error);
            props.handleMessage("Vos données n'ont pas pu être sauvegardées suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Ajouter un locataire
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Container component="" maxWidth="xs" ref={descriptionElementRef} tabIndex={-1}>
                        <Stepper alternativeLabel
                                 activeStep={activeStep}
                                 sx={{ pt: 3, pb: 5 }}
                                 connector={<ColorlibConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography>
                                    Vous êtes sur le point de créer votre locataire. Cliquer sur le bouton Valider pour confirmer la création de votre locataire ou sur Précédent pour vérifier les données saisies.
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep, identity, rental, onIdentityChange, onRentalChange)}
                            </React.Fragment>
                        )}
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        {activeStep !== 0 && (
                            <MercureOutlinedButton
                                onClick={handleBack}
                                label="Précédent" />
                        )}

                        {activeStep === steps.length ? (
                            <MercureContainedButton
                                onClick={saveTenant}
                                label="Valider" />
                        ) : (
                            <MercureContainedButton
                                onClick={handleNext}
                                label="Suivant" />
                        )}
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}