import React from 'react';
import { Container } from 'react-bootstrap';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const Layout = (props) => (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
        <Container fluid="true">
            {props.children}
        </Container>
    </GoogleReCaptchaProvider>
)