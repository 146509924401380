import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {createTheme} from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Country from './../Components/Country';
import Phone from './../Components/Phone';
import 'react-phone-number-input/style.css';
import Location from './../Components/Location';
import dayjs from 'dayjs';
import validator from "validator";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import BootstrapDialogTitle from "../Components/BootstrapDialogTitle";
import MercureContainedButton from "../Components/MercureContainedButton";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import BootstrapDialog from "../Components/BootstrapDialog";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PersonalData(props) {
    const theme = createTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    let maxYear = new Date().setFullYear(new Date().getFullYear() - 18);
    let maxDate = dayjs(maxYear).toLocaleString();

    const [civility, setCivility] = React.useState(null);
    const [maritalStatus, setMaritalStatus] = React.useState(null);
    const [birthDate, setBirthDate] = React.useState(maxDate);
    const [birthPlace, setBirthPlace] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [phonePrefixLabel, setPhonePrefixLabel] = React.useState('France');
    const [phonePrefix, setPhonePrefix] = React.useState(33);
    const [phoneCode, setPhoneCode] = React.useState('fr');
    const [phone, setPhone] = React.useState("");
    const [mobilePhonePrefix, setMobilePhonePrefix] = React.useState(33);
    const [mobilePhoneCode, setMobilePhoneCode] = React.useState('fr');
    const [mobilePhonePrefixLabel, setMobilePhonePrefixLabel] = React.useState('France');
    const [mobilePhone, setMobilePhone] = React.useState("");
    const [citizenship, setCitizenship] = React.useState(null);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState(null);
    const [showPassword, setShowPassword] = React.useState(false);
    const [waitingUpdating, setWaitingUpdating] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onEmailChange = (e) => {
        let email = e.target.value;

        if (email != '') {
            if (validator.isEmail(email)) {
                setEmailErrorMessage("");
            } else {
                setEmailErrorMessage("L'adresse e-mail saisie est invalide");
            }
        } else {
            setEmailErrorMessage("");
        }

        setEmail(email);
    };

    const onPasswordChange = (e) => {
        let password = e.target.value;
        const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

        if (!passwordRegex.test(password)) {
            setPasswordErrorMessage("Le mot de passe doit contenir au moins 8 caractères dont au moins une majuscule, une miniscule et un caractère spécial")
        } else {
            setPasswordErrorMessage(null);
        }

        setPassword(password);
    };

    const handleCivilityChange = (event) => {
        setCivility(event.target.value);
    };

    const onFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const onLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const onPhoneChange = (event) => {
        setPhone(event.target.value);
    }

    const onMobilePhoneChange = (event) => {
        setMobilePhone(event.target.value);
    }

    const handleMaritalStatusChange = (event) => {
        setMaritalStatus(event.target.value);
    }

    const handlePhonePrefixLabelChange = (event, value) => {
        setPhonePrefix(value.phone);
        setPhoneCode(value.code);
        setPhonePrefixLabel(value.label);
    }

    const handleMobilePhonePrefixLabelChange = (event, value) => {
        setMobilePhonePrefix(value.phone);
        setMobilePhoneCode(value.code);
        setMobilePhonePrefixLabel(value.label);
    }

    const onBirthPlaceChange = (event, value) => {
        if (null != value) {
            setBirthPlace(value.description);
        }
    }

    const onAddressChange = (event, value) => {
        if (null != value) {
            setAddress(value.description);
        }
    }

    const onCitizenshipChange = (event, value) => {
        if (null != value) {
            //setCitizenship(value.label);
            setCitizenship(value);
        }
    }

    function setCurrentUser(user) {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);

        if (user.civility) {
            setCivility(user.civility);
        }

        if (user.maritalStatus) {
            setMaritalStatus(user.maritalStatus);
        }

        if (user.birthDate) {
            setBirthDate(user.birthDate);
        }

        if (user.birthPlace) {
            setBirthPlace(user.birthPlace);
        }

        if (user.phone) {
            const phones = user.phone.split(' ');
            setPhonePrefix(phones[0].replace('+', ''));
            setPhone(phones[1]);
            setPhoneCode(user.phoneCode);
            setPhonePrefixLabel(user.phonePrefixLabel);
        }

        if (user.mobilePhone) {
            const mobilePhones = user.mobilePhone.split(' ');
            setMobilePhonePrefix(mobilePhones[0].replace('+', ''));
            setMobilePhone(mobilePhones[1]);
            setMobilePhoneCode(user.mobilePhoneCode);
            setMobilePhonePrefixLabel(user.mobilePhonePrefixLabel);
        }

        setAddress(user.address);
        setCitizenship(user.citizenship);
    }

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null == user) {
            getCurrentUser();
        } else {
            setCurrentUser(user);
        }
    }, []);

    async function getCurrentUser() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        await fetch(process.env.REACT_APP_API_HOST + '/user/get/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    setCurrentUser(response);
                    localStorage.setItem('currentUser', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const updateAccount = (event) => {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const data = {};
        data.civility = civility;
        data.maritalStatus = maritalStatus;

        if (birthDate)
            data.birthDate = dayjs(birthDate.toLocaleString()).format('YYYY-MM-DD');

        data.birthPlace = birthPlace;
        data.firstName = firstName;
        data.lastName = lastName;
        data.email = email;

        if ('' != password) {
            data.password = password;
        }

        data.phone = '+' + phonePrefix + ' ' + phone;
        data.phonePrefixLabel = phonePrefixLabel;
        data.phoneCode = phoneCode;
        data.mobilePhone = '+' + mobilePhonePrefix + ' ' + mobilePhone;
        data.mobilePhonePrefixLabel = mobilePhonePrefixLabel;
        data.mobilePhoneCode = mobilePhoneCode;
        data.address = address;

        if (citizenship)
            data.citizenship = citizenship.label;
        //console.log('citizenship', citizenship);return;

        fetch(process.env.REACT_APP_API_HOST + '/user/update/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 200) {
                //console.log('ok');
                props.showMessage('Vos données ont été mises à jour');
                props.showSeverity('success');
                props.handleOpenAlert();

                result.json().then(response => {
                    //console.log('response', response);
                    localStorage.setItem('currentUser', JSON.stringify(response));
                })

                props.onClose();
            }
        }).catch((error) => {
            //console.log(error);
            props.showMessage("Vos données n'ont pas pu être mises à jour suite à une erreur");
            props.showSeverity('error');
            props.handleOpenAlert();
        });
    };

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Mon compte
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Container component="main" maxWidth="xs">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    fullWidth
                                    label="Prenom"
                                    value={firstName}
                                    onChange={onFirstNameChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nom"
                                    name="lastName"
                                    autoComplete="family-name"
                                    value={lastName}
                                    onChange={onLastNameChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="civility" shrink>Civilité</InputLabel>
                                    <Select
                                        labelId="civility"
                                        value={civility}
                                        name="civility"
                                        label="Civilité"
                                        notched
                                        onChange={handleCivilityChange}
                                    >
                                        <MenuItem value={1}>Madame</MenuItem>
                                        <MenuItem value={2}>Mademoiselle</MenuItem>
                                        <MenuItem value={3}>Monsieur</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="maritalStatus" shrink>Situation maritale</InputLabel>
                                    <Select
                                        labelId="maritalStatus"
                                        value={maritalStatus}
                                        name="maritalStatus"
                                        label="Situation maritale"
                                        notched
                                        onChange={handleMaritalStatusChange}
                                    >
                                        <MenuItem value={1}>Marié.e</MenuItem>
                                        <MenuItem value={2}>Célibataire</MenuItem>
                                        <MenuItem value={3}>Divorcé.e</MenuItem>
                                        <MenuItem value={4}>Veuf.ve</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={email}
                                    label="E-mail"
                                    name="email"
                                    autoComplete="email"
                                    error={emailErrorMessage !== ""}
                                    helperText= {emailErrorMessage}
                                    onChange={onEmailChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title="Laisser ce champ vide si vous ne souhaitez pas modifier le mot de passe">
                                    <TextField
                                        fullWidth
                                        name="password"
                                        label="Mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete="new-password"
                                        error={passwordErrorMessage !== null}
                                        helperText={passwordErrorMessage}
                                        onChange={onPasswordChange}
                                        value={password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        label="Date de naissance"
                                        value={birthDate}
                                        name="birthDate"
                                        inputFormat="DD/MM/YYYY"
                                        maxDate={maxDate}
                                        onChange={(newValue) => {
                                            setBirthDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Location
                                    name="birthPlace"
                                    value={birthPlace}
                                    label="Lieu de naissance"
                                    noOptionsText="Aucune ville saisie"
                                    onChange={onBirthPlaceChange}/>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Phone
                                    name="phonePrefixLabel"
                                    value={phonePrefixLabel}
                                    onChange={handlePhonePrefixLabelChange}/>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <TextField
                                    name="phone"
                                    fullWidth
                                    label="Téléphone fixe"
                                    type="number"
                                    value={phone}
                                    onChange={onPhoneChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+{phonePrefix}</InputAdornment>,
                                        endAdornment: <InputAdornment position="end"><img alt={phoneCode} src={`https://flagcdn.com/w40/${phoneCode.toLowerCase()}.png`}/></InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Phone
                                    name="mobilePhonePrefixLabel"
                                    value={mobilePhonePrefixLabel}
                                    onChange={handleMobilePhonePrefixLabelChange}/>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <TextField
                                    name="mobilePhone"
                                    fullWidth
                                    label="Téléphone mobile"
                                    type="number"
                                    value={mobilePhone}
                                    onChange={onMobilePhoneChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+{mobilePhonePrefix}</InputAdornment>,
                                        endAdornment: <InputAdornment position="end"><img alt={mobilePhoneCode} src={`https://flagcdn.com/w40/${mobilePhoneCode.toLowerCase()}.png`}/></InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Location
                                    name="address"
                                    label="Adresse"
                                    value={address}
                                    noOptionsText="Aucune adresse saisie"
                                    onChange={onAddressChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Country
                                    name="citizenship"
                                    value={citizenship}
                                    onChange={onCitizenshipChange}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <MercureContainedButton
                            label="Modifier"
                            onClick={updateAccount}
                            disabled={waitingUpdating} />
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}