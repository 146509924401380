import {createTheme} from "@mui/material/styles";
import MercureTheme from "../Components/MercureTheme";

const TenantTheme = createTheme(MercureTheme,{
    components: {
        MuiButton: {
            defaultProps: {
                color: 'secondary'
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: ({ theme }) =>
                    theme.unstable_sx({
                        bgcolor: theme.palette.secondary.main,
                    }),
            },
        },
        MuiAppBar: {
            defaultProps: {
                color: 'secondary'
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ theme }) =>
                    theme.unstable_sx({
                        bgcolor: theme.palette.secondary.main,
                        color: theme.palette.common.white
                    }),
            },
        },
        MuiIconButton: {
            defaultProps: {
                color: 'secondary'
            },
        },
    }
});

export default TenantTheme;