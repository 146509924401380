import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import Confirm from "../../Components/Confirm";
import Drawer from "@mui/material/Drawer";
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import AddCoownerCorporation from "./AddCoownerCorporation";
import Alert from "../../Components/Alert";
import UpdateCoownerCorporation from "./UpdateCoownerCorporation";
import SearchBar from "../../Components/SearchBar";
import Divider from "@mui/material/Divider";

export default function CoownerCorporation(props) {
    let [coowners, setCoowners] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const anchorPosition = 'right';
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const title = 'Attention';
    const confirmMessage = "Etes-vous sûr de vouloir supprimer définitivement ce copropriétaire ?\nCette action est irréversible.";
    let [selectedCoowner, setSelectedCoowner] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openCoowner, setOpenCoowner] = React.useState(false);
    const [openCoownerCorporation, setOpenCoownerCorporation] = React.useState(false);
    const [openUpdateCoowner, setOpenUpdateCoowner] = React.useState(false);

    const handleOpenCoowner = () => {
        setOpenCoownerCorporation(true);
    }

    const handleCloseCoowner = () => {
        setOpenCoowner(false);
    }

    const handleCloseCoownerCorporation = () => {
        setOpenCoownerCorporation(false);
    }

    const handleOpenUpdateCoowner = () => {
        setOpenUpdateCoowner(true);
    }

    const handleCloseUpdateCoowner = () => setOpenUpdateCoowner(false);
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleMessage = (message) => {
        setMessage(message);
    };

    const handleSeverity = (severity) => {
        setSeverity(severity);
    };

    const getCoowners = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        let id = user.id;
        
        fetch(process.env.REACT_APP_API_HOST + '/coowner/corporation/get/user/id/' + id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setCoowners(response);
                    localStorage.setItem('coownersCorporation', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);
    
    React.useEffect(() => {
        const partOwners = JSON.parse(localStorage.getItem('coownersCorporation'));

        if (null === partOwners) {
            getCoowners();
        } else {
            setCoowners(partOwners);
        }
    }, [getCoowners]);

    const coownerUpdated = () => {
        setOpenDrawer(false);
        setSelectedCoowner();
        getCoowners();
        handleMessage("Vos données ont été modifiées");
        handleSeverity('success');
        handleOpenAlert();
    }

    const coownerDeleted = () => {
        setSelectedCoowner();
        getCoowners();
        handleMessage("Le copropriétaire a été supprimé");
        handleSeverity('success');
        handleOpenAlert();
    }

    const coownerNotUpdated = () => {
        setOpenDrawer(false);
        setSelectedCoowner();
        handleMessage("Vos données n'ont pas pu être modifiées suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const coownerNotDeleted = () => {
        handleMessage("Le copropriétaire n'a pas pu être supprimé suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const onFilterChange = (event) => {
        setFilter(event.target.value);
    }

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const filteredCoowners = coowners.filter(coowner => {
        if (filter === '') {
            return coowner;
        } else {
            return (coowner.name && coowner.name.toLowerCase().includes(filter.toLowerCase())) ||
                (coowner.address && coowner.address.toLowerCase().includes(filter.toLowerCase())) ||
                (coowner.housingName && coowner.housingName.toLowerCase().includes(filter.toLowerCase()))
        }
    })

    const toggleConfirm = (open, coowner) => (event) => {
        if (open) {
            setSelectedCoowner(coowner);
        } else {
            setSelectedCoowner();
        }

        setOpenConfirm(open);
    }

    const duplicate = React.useCallback((coowner) => {
        let data = {};

        for (let key in coowner) {
            if ('id' === key || 'createdAt' === key || 'updatedAt' === key || 'housingName' === key) {
                continue;
            }

            data[key] = coowner[key];
        }

        fetch(process.env.REACT_APP_API_HOST + '/coowner/corporation/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                // Show Alert
                handleMessage("Le copropriétaire a été dupliqué");
                handleSeverity('success');
                handleOpenAlert();

                // Remove data from localStorage
                localStorage.removeItem('coownersCorporation');

                // Refresh data
                getCoowners();
            }
        }).catch((error) => {
            console.log(error);
            handleMessage("Le copropriétaire n'a pas pu être dupliqué suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        });
    }, []);

    const toggleDrawer = (open, coowner) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        const housings = JSON.parse(localStorage.getItem('housings'));

        if (open) {
            if (housings && housings.length !== 0) {
                coowner.housing = housings.find((housing) => housing.id === coowner.idHousing);
            }
            setSelectedCoowner(coowner);
        } else {
            setSelectedCoowner();
        }

        setOpenDrawer(open);
    }
    
    return (
        <div>
            <Paper sx={{maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
                {openCoownerCorporation && (
                    <AddCoownerCorporation open={openCoownerCorporation} refresh={getCoowners} onClose={handleCloseCoownerCorporation} handleOpenAlert={handleOpenAlert} handleMessage={handleMessage} handleSeverity={handleSeverity}/>
                )}

                <SearchBar placeholder="Chercher un copropriétaire" onFilterChange={onFilterChange} buttonLabel="Ajouter un copropriétaire" onButtonClick={handleOpenCoowner} onRefresh={getCoowners}/>

                {filteredCoowners && filteredCoowners.length === 0 ? (
                    <Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
                        Lancer la recherche pour afficher les copropriétaires
                    </Typography>
                ) : (
                    <List subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {filteredCoowners && filteredCoowners.length} copropriétaire.s
                        </ListSubheader>
                    }>
                        {filteredCoowners && filteredCoowners.map && filteredCoowners.map((coowner, index) =>
                            <div>
                                <ListItem key={coowner.id}
                                          secondaryAction={
                                              <Grid>
                                                  <Tooltip title="Modifier">
                                                      <IconButton edge="end" aria-label="edit" onClick={toggleDrawer(true, coowner)}>
                                                          <EditIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Dupliquer">
                                                      <IconButton edge="end" aria-label="duplicate" onClick={() => duplicate(coowner)}>
                                                          <ContentCopyIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Supprimer">
                                                      <IconButton edge="end" aria-label="delete" color="error" onClick={toggleConfirm(true, coowner)}>
                                                          <DeleteIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                              </Grid>
                                          }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BusinessRoundedIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id={coowner.id}
                                                  primary={`${coowner.name}`}
                                                  secondary={
                                                      <React.Fragment>
                                                          <Typography variant="subtitle1">
                                                              {coowner.housingName}
                                                          </Typography>
                                                          <Typography variant="subtitle2">
                                                              {coowner.address}
                                                          </Typography>
                                                      </React.Fragment>
                                                  }
                                    />
                                </ListItem>
                                {
                                    ((index === 0 && filteredCoowners.length > 1) || index < filteredCoowners.length - 1) && <Divider variant="inset" component="li" />
                                }
                            </div>
                        )}
                    </List>
                )}

                <Drawer anchor={anchorPosition}
                        PaperProps={{
                            sx: {backgroundColor: "#ffffff"}
                        }}
                        open={openDrawer}
                        onClose={toggleDrawer(false)}>
                    <UpdateCoownerCorporation coowner={selectedCoowner} coownerUpdated={coownerUpdated} coownerNotUpdated={coownerNotUpdated}/>
                </Drawer>
            </Paper>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>

            <Confirm open={openConfirm}
                     title={title}
                     message={confirmMessage}
                     onClose={toggleConfirm(false)}
                     selected={selectedCoowner}
                     type="coowner-corporation"
                     titleBackgroundColor="#ff9800"
                     coownerDeleted={coownerDeleted}
                     coownerNotDeleted={coownerNotDeleted}
                     titleColor="white"/>

        </div>
    )
}