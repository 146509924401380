import * as React from 'react';
import BootstrapDialogTitle from "../Components/BootstrapDialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {createTheme} from "@mui/material/styles";
import BootstrapDialog from "../Components/BootstrapDialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from "@mui/material/Box";
import MercureContainedButton from "../Components/MercureContainedButton";

export default function Terms(props) {
    const theme = createTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Conditions générales d'utilisation
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <b>Préambule</b><br/>
                    Les présentes conditions générales d'utilisation sont conclues entre :<br/>
                    - le gérant du site internet, ci-après désigné « l’Éditeur »,<br/>
                    - toute personne souhaitant accéder au site et à ses services, ci-après appelé « l’Utilisateur
                    ».<br/><br/>

                    <b>Article 1 - Principes</b><br/>
                    Les présentes conditions générales d'utilisation ont pour objet l'encadrement juridique de
                    l’utilisation de l'application Terra et de ses services<br/><br/>
                    L'application est un service de :<br/>
                    <ul>
                        <li>La société GAUSSMSQUARE</li>
                        <li>située 5 Rue Jean Prouvé, 95200 Sarcelles, France</li>
                        <li>URL : www.terra-saas.fr</li>
                        <li>e-mail : contact@terra-saas.fr</li>
                        <li>téléphone : 0679754870</li>
                    </ul>
                    Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur, et son accès au
                    site vaut acceptation de ces conditions.<br/><br/>

                    <b>Article 2 - Évolution et durée des CGU</b><br/>
                    Les présentes conditions générales d'utilisation sont conclues pour une durée indéterminée. Le
                    contrat produit ses effets à l'égard de l'Utilisateur à compter du début de l’utilisation du
                    service.<br/>
                    L'application Terra se réserve le droit de modifier les clauses de ces conditions générales
                    d’utilisation à tout moment et sans justification.<br/><br/>

                    <b>Article 3 - Accès à l'application</b><br/>
                    Tout Utilisateur ayant accès à internet peut accéder gratuitement et depuis n’importe où à
                    l'application. Les frais supportés par l’Utilisateur pour y accéder (connexion internet, matériel
                    informatique, etc.) ne sont pas à la charge de l’Éditeur.<br/>
                    L'application et ses différents services peuvent être interrompus ou suspendus par l’Éditeur,
                    notamment à l’occasion d’une maintenance, sans obligation de préavis ou de justification.<br/>
                    L'Utilisateur de l'application a accès aux services suivants : service de gestion des biens
                    immobiliers.<br/><br/>

                    <b>Article 4 - Responsabilités</b><br/>
                    La responsabilité de l'Éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou
                    interruption de fonctionnement, empêchant l'accès à l'application ou à une de ses
                    fonctionnalités.<br/>
                    Le matériel de connexion à l'application utilisée est sous l'entière responsabilité de l'Utilisateur
                    qui doit prendre toutes les mesures appropriées pour protéger le matériel et les données notamment
                    d'attaques virales par Internet. L'utilisateur est par ailleurs le seul responsable des sites et
                    données qu'il consulte.<br/><br/>
                    L'Éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à l'encontre de
                    l'Utilisateur :<br/>
                    - du fait de l'usage de l'application ou de tout service accessible<br/>
                    - du fait du non-respect par l'Utilisateur des présentes conditions générales.<br/><br/>

                    L'Éditeur n'est pas responsable des dommages causés à l'Utilisateur, à des tiers et/ou à
                    l'équipement de l'Utilisateur du fait de sa connexion ou de son utilisation de l'application et
                    l'Utilisateur renonce à toute action contre l'Éditeur de ce fait.<br/>
                    Si l'Éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de l'utilisation
                    de l'application par l'Utilisateur, il pourra retourner contre lui pour obtenir indemnisation de
                    tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.<br/><br/>

                    <b>Article 5 - Propriété intellectuelle</b><br/>
                    Tous les documents techniques, produits, photographies, textes, logos, dessins, vidéos, etc., sont
                    soumis à des droits d'auteur et sont protégés par le Code de la propriété intellectuelle. Lorsqu'ils
                    sont remis à nos clients, ils demeurent la propriété exclusive de GAUSSMSQUARE, seul titulaire des
                    droits de propriété intellectuelle sur ces documents, qui doivent lui être rendus à sa demande.<br/>
                    Nos clients s'engagent à ne faire aucun usage de ces documents, susceptible de porter atteinte aux
                    droits de propriété industrielle ou intellectuelle du fournisseur et s'engagent à ne les divulguer à
                    aucun tiers, en dehors d'une autorisation expresse et préalable donnée par l'Editeur.<br/><br/>

                    <b>Article 6 - Liens hypertextes</b><br/>
                    La mise en place par l'Utilisateur de tous liens hypertextes vers tout ou partie du site est
                    strictement interdite, sauf autorisation préalable et écrite de l'Éditeur, sollicitée par courriel à
                    l'adresse suivante : contact@terra-saas.fr<br/>
                    L'Éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce
                    soit sa décision. Dans le cas où l'Éditeur accorderait son autorisation, celle-ci n'est dans tous
                    les cas que temporaire et pourra être retirée à tout moment, sans obligation de justification à la
                    charge de l'Éditeur.<br/>
                    Dans tous les cas, tout lien devra être retiré sur simple demande de l'Éditeur.<br/>
                    Toute information accessible via un lien vers d'autres sites n'est pas sous le contrôle de l'Éditeur
                    qui décline toute responsabilité quant à leur contenu.<br/><br/>

                    <b>Article 7 - Protection des données personnelles</b><br/>
                    <b>Données collectées</b><br/>
                    Les données à caractère personnel qui sont collectées sur ce site sont les suivantes :<br/>
                    <b>- ouverture de compte :</b> lors de la création du compte de l'utilisateur : e-mail<br/>
                    <b>- connexion :</b> lors de la connexion de l'utilisateur à l'application, celui-ci enregistre,
                    notamment, ses nom, prénom, données de connexion, d'utilisation, de localisation et ses données
                    relatives au paiement;<br/>
                    <b>- profil :</b> l'utilisation des prestations prévues sur l'application permet de renseigner un
                    profil, pouvant comprendre une adresse et un numéro de téléphone;<br/>
                    <b>- paiement :</b> dans le cadre du paiement des produits et prestations proposés sur l'application,
                    celle-ci n'enregistre pas des données financières relatives au compte bancaire ou à la carte de
                    crédit de l'utilisateur;<br/>
                    <b>- communication :</b> lorsque l'application est utilisée pour communiquer avec d'autres membres,
                    les données concernant les communications de l'utilisateur font l'objet d'une conservation
                    temporaire;<br/>
                    <b>- cookies :</b> les cookies sont utilisés, dans le cadre de l'utilisation de l'application.
                    L'utilisateur a la possibilité de désactiver les cookies à partir des paramètres de son
                    navigateur.<br/><br/>

                    <b>Utilisation des données personnelles</b><br/>
                    Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition
                    des services de l'application, leur amélioration et le maintien d'un environnement sécurisé. Plus
                    précisément, les utilisations sont les suivantes :<br/>
                    - accès et utilisation de l'application par l'utilisateur;<br/>
                    - gestion du fonctionnement et optimisation de l'application;<br/>
                    - organisation des conditions d'utilisation des services de paiement;<br/>
                    - vérification, identification et authentification des données transmises par l'utilisateur;<br/>
                    - proposition à l'utilisateur de la possibilité de communiquer avec d'autres utilisateurs de
                    l'application;<br/>
                    - mise en oeuvre d'une assistance utilisateurs;<br/>
                    - personnalisation des services en affichant des publicités en fonction de l'historique de
                    navigation de l'utilisateur, selon ses préférences;<br/>
                    - prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et
                    gestion des incidents de sécurité;<br/>
                    - gestion des éventuels litiges avec les utilisateurs;<br/>
                    - envoi d'informations commerciales et publicitaires, en fonction des préférences de
                    l'utilisateur.<br/><br/>

                    <b>Partage des données personnelles avec des tiers</b><br/>
                    Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants
                    :<br/>
                    - lorsque l'Utilisateur utilise les services de paiement, pour la mise en oeuvre de ces services,
                    l'application est en relation avec des sociétés bancaires et financières tierces avec lesquelles
                    elle a passé des contrats;<br/>
                    - lorsque l'Utilisateur publie, dans les zones de commentaires libres de l'application, des
                    informations accessibles au public;<br/>
                    - lorsque l'Utilisateur autorise le site web d'un tiers à accéder à ses données;<br/>
                    - lorsque l'application recourt aux services de prestataires pour fournir l'assistance utilisateurs,
                    la publicité et les services de paiement. Ces prestataires disposent d'un accès limité aux données
                    de l'utilisateur, dans le cadre de l'exécution de ces prestations, et ont une obligation
                    contractuelle de les utiliser en conformité avec les dispositions de la réglementation applicable en
                    matière de protection des données à caractère personnel;<br/>
                    - si la loi l'exige, l'application peut effectuer la transmission de données pour donner suite aux
                    réclamations présentées contre l'application et se conformer aux procédures administratives et
                    judiciaires;<br/>
                    - si l'application est impliquée dans une opération de fusion, acquisition, cession d'actifs ou
                    procédure de redressement judiciaire, elle pourra être amenée à céder ou partager tout ou partie de
                    ses actifs, y compris les données à caractère personnel. Dans ce cas, les utilisateurs seraient
                    informés, avant que les données à caractère personnel ne soient transférées à une tierce
                    partie.<br/><br/>

                    <b>Sécurité et confidentialité</b><br/>
                    L'application met en oeuvre des mesures organisationnelles, techniques, logicielles et physiques en
                    matière de sécurité du numérique pour protéger les données personnelles contre les altérations,
                    destructions et accès non autorisés. Toutefois, il est à signaler qu'internet n'est pas un
                    environnement complètement sécurisé et l'application ne peut pas garantir la sécurité de la
                    transmission ou du stockage des informations sur internet.<br/><br/>

                    <b>Mise en oeuvre des droits des utilisateurs</b><br/>
                    En application de la réglementation applicable aux données à caractère personnel, les utilisateurs
                    disposent des droits suivants, qu'ils peuvent exercer en faisant leur demande à l'adresse suivante :
                    contact@terra-saas.fr.<br/>
                    <ul>
                        <li>le droit d’accès : ils peuvent exercer leur droit d'accès, pour connaître les données
                            personnelles les concernant. Dans ce cas, avant la mise en œuvre de ce droit, l'application
                            peut demander une preuve de l'identité de l'utilisateur afin d'en vérifier l'exactitude.
                        </li>
                        <li>le droit de rectification : si les données à caractère personnel détenues par l'application
                            sont inexactes, ils peuvent demander la mise à jour des informations.
                        </li>
                        <li>le droit de suppression des données : les utilisateurs peuvent demander la suppression de
                            leurs données à caractère personnel, conformément aux lois applicables en matière de
                            protection des données.
                        </li>
                        <li>le droit à la limitation du traitement : les utilisateurs peuvent de demander à
                            l'application de limiter le traitement des données personnelles conformément aux hypothèses
                            prévues par le RGPD.
                        </li>
                        <li>le droit de s’opposer au traitement des données : les utilisateurs peuvent s’opposer à ce
                            que ses données soient traitées conformément aux hypothèses prévues par le RGPD.
                        </li>
                        <li>le droit à la portabilité : ils peuvent réclamer que l'application leur remette les données
                            personnelles qui lui sont fournies pour les transmettre à un nouveau site web.
                        </li>
                    </ul>

                    <b>Évolution de la présente clause</b><br/>
                    L'application se réserve le droit d'apporter toute modification à la présente clause relative à la
                    protection des données à caractère personnel à tout moment. Si une modification est apportée à la
                    présente clause de protection des données à caractère personnel, l'application s'engage à publier la
                    nouvelle version sur son site. L'application informera également les utilisateurs de la modification
                    par messagerie électronique, dans un délai minimum de 15 jours avant la date d'effet. Si
                    l'utilisateur n'est pas d'accord avec les termes de la nouvelle rédaction de la clause de protection
                    des données à caractère personnel, il a la possibilité de supprimer son compte.<br/><br/>

                    <b>Article 8 - Cookies</b><br/>
                    L'application peut collecter automatiquement des informations standards. Toutes les informations
                    collectées indirectement ne seront utilisées que pour suivre le volume, le type et la configuration
                    du trafic utilisant ce site, pour en développer la conception et l'agencement et à d'autres fins
                    administratives et de planification et plus généralement pour améliorer le service que nous vous
                    offrons.<br/><br/>

                    <b>Article 9 - Loi applicable</b><br/>
                    Les présentes conditions générales d'utilisation sont soumises à l'application du droit
                    français.<br/>
                    Si les parties n'arrivent pas à résoudre un litige à l'amiable, le litige sera soumis à la
                    compétence des tribunaux français.
                </DialogContent>
                <DialogActions>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <MercureContainedButton onClick={props.onClose} label="Accepter" />
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}