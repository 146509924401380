import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import MercureContainedButton from "./MercureContainedButton";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import RefreshIcon from "@mui/icons-material/Refresh";
import AppBar from "@mui/material/AppBar";
import * as React from "react";

export default function SearchBar(props) {
    return (
        <AppBar position="static" color="default" elevation={0} sx={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}>
            <Toolbar>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <SearchIcon color="inherit" sx={{display: 'block'}}/>
                    </Grid>
                    <Grid item xs>
                        <TextField fullWidth placeholder={props.placeholder}
                                   InputProps={{
                                       disableUnderline: true,
                                       sx: {fontSize: 'default'},
                                   }}
                                   variant="standard"
                                   onChange={props.onFilterChange}
                        />
                    </Grid>
                    <Grid item>
                        <MercureContainedButton
                            onClick={props.onButtonClick}
                            disableElevation
                            label={props.buttonLabel} />
                    </Grid>
                    <Grid item>
                        <Tooltip title="Rafraîchir">
                            <IconButton onClick={props.onRefresh}>
                                <Avatar>
                                    <RefreshIcon sx={{display: 'block'}}/>
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}