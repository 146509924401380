import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import Box from '@mui/material/Box';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TimelineIcon from '@mui/icons-material/Timeline';

function Feature() {
	return (
		<section className="features1 cid-tvJg6QTY6k" id="services">
		    <div className="container">
		        <div className="row">
		            <div className="col-12 col-lg-9">
		                <h3 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
		                    <strong>Services</strong>
		                </h3>
		                
		            </div>
		        </div>
		        <div className="row">
		            <div className="card col-12 col-md-6 col-lg-3">
		                <div className="card-wrapper">
		                    <div className="card-box align-center">
		                        <Box
				                    sx={{
				                      display: 'flex',
				                      flexDirection: 'column',
				                      alignItems: 'center',
				                      marginBottom: 5
				                    }}
				                >
				                	<Avatar sx={{ bgcolor: 'secondary.main', width: 124, height: 124 }}>
				                        <PeopleAltRoundedIcon sx={{ fontSize: 72 }} />
				                    </Avatar>
				                </Box>
		                        <h5 className="card-title mbr-fonts-style display-7"><strong>Gestion des locataires</strong></h5>
		                        <p className="card-text mbr-fonts-style display-7">Terra vous permet de gérer vos locataires facilement. Vous pourrez les relancer en cas de retard de paiement, créer leurs contrats et leurs quittances de loyer, etc.</p>
		                    </div>
		                </div>
		            </div>
		            <div className="card col-12 col-md-6 col-lg-3">
		                <div className="card-wrapper">
		                    <div className="card-box align-center">
		                        <Box
				                    sx={{
				                      display: 'flex',
				                      flexDirection: 'column',
				                      alignItems: 'center',
				                      marginBottom: 5
				                    }}
				                >
				                	<Avatar sx={{ bgcolor: 'secondary.main', width: 124, height: 124 }}>
				                        <ReceiptLongIcon sx={{ fontSize: 72 }} />
				                    </Avatar>
				                </Box>
		                        <h5 className="card-title mbr-fonts-style display-7"><strong>Gestion des documents</strong></h5>
		                        <p className="card-text mbr-fonts-style display-7">Terra vous permet de créer différents documents inhérents à la gestion d'un bien immobilier : contrat de location, quittances de loyer, factures, etc.</p>
		                    </div>
		                </div>
		            </div>
		            <div className="card col-12 col-md-6 col-lg-3">
		                <div className="card-wrapper">
		                    <div className="card-box align-center">
		                        <Box
				                    sx={{
				                      display: 'flex',
				                      flexDirection: 'column',
				                      alignItems: 'center',
				                      marginBottom: 5
				                    }}
				                >
				                	<Avatar sx={{ bgcolor: 'secondary.main', width: 124, height: 124 }}>
				                        <TimelineIcon sx={{ fontSize: 72 }} />
				                    </Avatar>
				                </Box>
		                        <h5 className="card-title mbr-fonts-style display-7"><strong>Comptabilité</strong></h5>
		                        <p className="card-text mbr-fonts-style display-7">Terra vous offre la possibilité d'assurer une comptabilité de vos dépenses et de vos recettes pour un meilleur suivi de votre gestion immobilière.</p>
		                    </div>
		                </div>
		            </div>
		            
		        </div>
		    </div>
		</section>
	)
}

export default Feature;