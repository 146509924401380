import * as React from 'react';
import {createTheme} from "@mui/material/styles";
import Container from '@mui/material/Container';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import Box from "@mui/material/Box";
import BootstrapDialog from "../../Components/BootstrapDialog";
import BootstrapDialogTitle from "../../Components/BootstrapDialogTitle";
import Waiting from "../../Components/Waiting";
import MercureContainedButton from "../../Components/MercureContainedButton";
import Stepper from "@mui/material/Stepper";
import ColorlibConnector from "../../Components/ColorlibConnector";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ColorlibStepIcon from "../../Components/ColorlibStepIcon";
import Typography from "@mui/material/Typography";
import MercureOutlinedButton from "../../Components/MercureOutlinedButton";
import RentalCorporation from "./RentalCorporation";
import PossessionCorporation from "./PossessionCorporation";
import {REQUIRED_FIELD} from "../../Consts";

let contract = [];
const steps = ['Location', 'Bien'];

function getStepContent(step, rental, possession, tenants, onRentalChange, onPossessionChange) {
    switch (step) {
        case 0:
            return <RentalCorporation data={rental} tenants={tenants} onRentalChange={onRentalChange} />;
        case 1:
            return <PossessionCorporation data={possession} onPossessionChange={onPossessionChange} />;
        default:
            throw new Error('Unknown step');
    }
}

export default function AddContractCorporation(props) {
    const theme = createTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const descriptionElementRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [tenants, setTenants] = React.useState(false);

    // Rental
    const [rental, setRental] = React.useState({
        // Data
        filter: [],
        rentalDuration: "",
        startRentalDate: null,
        rentalAmount: "",
        chargesAmount: "",
        guaranteeAmount: "",
        paymentFrequency: "",
        chargeMode: "",
        rentalTerm: "",
        paymentMode: "",
        cancellableContract: null,
        restrictedActivities: null,
        destinationType: '',
        activitiesNature: '',
        additionalActivities: null,
        rentSubjectToVat: null,
        franchise: null,
        franchiseEndDate: null,
        rentalRevision: '',
        rentalIndex: '',
        guaranteeRentalNumber: '',
        supportedCharges: '',
        supportedChargesOptions: [],
        otherSupportedCharges: '',
        showOtherSupportedChargesOptions: false,

        // Error
        tenantErrorMessage: "",
        startDateErrorMessage: '',
        chargeModeErrorMessage: '',
        rentalAmountErrorMessage: '',
        chargesAmountErrorMessage: '',
        guaranteeAmountErrorMessage: '',
        rentalTermErrorMessage: '',
        paymentModeErrorMessage: '',
        rentalDurationErrorMessage: '',
        cancellableContractErrorMessage: '',
        paymentFrequencyErrorMessage: '',
        franchiseEndDateErrorMessage: '',
        rentalRevisionErrorMessage: '',
        guaranteeRentalNumberErrorMessage: ''
    });

    const [possession, setPossession] = React.useState({
        // Data
        attic: 0,
        veranda: 0,
        balcony: 0,
        washRoom: 0,
        cellar: 0,
        garden: 0,
        parking: 0,
        court: 0,
        pool: 0,
        jacuzzi: 0,
        entrance: 0,
        clearance: 0,
        dressing: 0,
        garage: 0,
        city: null,
        creationDate: dayjs(new Date()).toLocaleString(),

        // Error
        creationDateErrorMessage: '',
        cityErrorMessage: ''
    });

    const getTenants = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/tenant/corporation/get/user/id/' + user.id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    setTenants(response);
                    localStorage.setItem('tenantsCorporation', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    React.useEffect(() => {
        const lodgers = JSON.parse(localStorage.getItem('tenantsCorporation'));

        if (null === lodgers) {
            getTenants();
        } else {
            setTenants(lodgers);
        }
    }, []);

    async function saveContract() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        rental.filter && rental.filter.map(item => {
            if (!item.email) {
                props.handleMessage(`Le gérant de la société ${item.name} ne possède pas d'adresse e-mail`);
                props.handleSeverity('error');
                props.handleOpenAlert();
                return;
            }
        })

        // Loading
        setLoading(true);

        let data = {};
        data.idUser = user.id;
        data.idTenant = rental.filter.map(item => item.id);

        const rentalErrors = [
            'filter',
            'tenantErrorMessage',
            'startDateErrorMessage',
            'chargeModeErrorMessage',
            'rentalAmountErrorMessage',
            'chargesAmountErrorMessage',
            'guaranteeAmountErrorMessage',
            'rentalTermErrorMessage',
            'paymentModeErrorMessage',
            'rentalDurationErrorMessage',
            'cancellableContractErrorMessage',
            'franchiseEndDateErrorMessage',
            'guaranteeRentalNumberErrorMessage',
            'paymentFrequencyErrorMessage',
            'rentalRevisionErrorMessage'
        ];

        const possessionErrors = [
            'creationDateErrorMessage',
            'cityErrorMessage'
        ];

        for (let key in contract['rental']) {
            if (rentalErrors.indexOf(key) !== -1) {
                continue;
            }

            data[key] = contract['rental'][key];
        }

        for (let key in contract['possession']) {
            if (possessionErrors.indexOf(key) !== -1) {
                continue;
            }

            data[key] = contract['possession'][key];
        }

        //data.city = 'Sarcelles';
        data.returnUrl = window.location.href;
        //console.log('data', data);return;
        await fetch(process.env.REACT_APP_API_HOST + '/contract/corporation/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    // Save filter in local storage
                    localStorage.setItem('contractCorporationTenantFilter', JSON.stringify(rental.filter));
                    localStorage.setItem('contractCorporationYearFilter', JSON.stringify(dayjs(rental.startRentalDate.toLocaleString()).format('YYYY')));

                    // Hide dialog
                    props.onClose();

                    // Save data in local storage for upload
                    localStorage.setItem('envelopeId', JSON.stringify(response.envelopeId));
                    localStorage.setItem('documentId', JSON.stringify(response.id));
                    localStorage.setItem('fileName', JSON.stringify(response.fileName));

                    // Hide waiting
                    setLoading(false);

                    // Redirect
                    window.location.href = response.redirect_url;
                })
            } else {
                //console.log(result);
                setLoading(false);

                props.handleMessage("Votre contrat n'a pas pu être créé suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);

            props.handleMessage("Votre contrat n'a pas pu être créé suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    const handleCloseWaiting = () => {
        setLoading(false);
    };

    const onRentalChange = (event, value, id) => {
        if (value !== undefined) {
            if (value === 'rentalTerm') {
                setRental({
                    ...rental,
                    rentalTerm: event ? event.target.value : null,
                    rentalTermErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else if (value === 'paymentMode') {
                setRental({
                    ...rental,
                    paymentMode: event ? event.target.value : null,
                    paymentModeErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else if (value === 'paymentFrequency') {
                setRental({
                    ...rental,
                    paymentFrequency: event ? event.target.value : null,
                    paymentFrequencyErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else if (value === 'rentalRevision') {
                setRental({
                    ...rental,
                    rentalRevision: event ? event.target.value : null,
                    rentalRevisionErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else if (value === 'cancellableContract') {
                setRental({
                    ...rental,
                    cancellableContract: event ? event.target.value : null,
                    cancellableContractErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else {
                if (id === 'startRentalDate') {
                    setRental({
                        ...rental,
                        startRentalDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                        startDateErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else if (id === 'franchiseEndDate') {
                    setRental({
                        ...rental,
                        franchiseEndDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                        franchiseEndDateErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else if (id === 'chargeMode') {
                    setRental({
                        ...rental,
                        chargeMode: value ? value : null,
                        chargeModeErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else if (id === 'supportedChargesOptions') {
                    let showOption = false;
                    //console.log('val', value)
                    value && value.map(item => {
                        if (item.value == 2012) {
                            showOption = true;
                            return;
                        }
                    })

                    setRental({
                        ...rental,
                        supportedChargesOptions: value.length > 0 ? value : [],
                        showOtherSupportedChargesOptions: showOption
                    });
                } else {
                    setRental({
                        ...rental,
                        filter: value.length > 0 ? value : [],
                        tenantErrorMessage: value.length > 0 ? '' : REQUIRED_FIELD
                    });
                    //console.log('val', value)
                    value && value.map(item => {
                        if (!item.email) {
                            props.handleMessage(`Le locataire ${item.firstName} ${item.lastName} ne possède pas d'adresse e-mail`);
                            props.handleSeverity('error');
                            props.handleOpenAlert();
                        }
                    })
                }
            }
        } else {
            //console.log('id', event.target.id)
            if (event.target.id) {
                switch (event.target.id) {
                    case 'rentalAmount':
                        setRental({
                            ...rental,
                            rentalAmount: event.target.value,
                            rentalAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'rentalDuration':
                        setRental({
                            ...rental,
                            rentalDuration: event.target.value,
                            rentalDurationErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'chargesAmount':
                        setRental({
                            ...rental,
                            chargesAmount: event.target.value,
                            chargesAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'guaranteeAmount':
                        setRental({
                            ...rental,
                            guaranteeAmount: event.target.value,
                            guaranteeAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'guaranteeRentalNumber':
                        setRental({
                            ...rental,
                            guaranteeRentalNumber: event.target.value,
                            guaranteeRentalNumberErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'paymentDay':
                        setRental({
                            ...rental,
                            paymentDay: event.target.value
                        });
                        break;

                    case 'destinationType':
                        setRental({
                            ...rental,
                            destinationType: event.target.value
                        });
                        break;

                    case 'activitiesNature':
                        setRental({
                            ...rental,
                            activitiesNature: event.target.value
                        });
                        break;

                    case 'otherSupportedCharges':
                        setRental({
                            ...rental,
                            otherSupportedCharges: event.target.value
                        });
                        break;

                    default:
                        break;
                }
            } else {
                if (event.target.name === 'additionalActivities') {
                    setRental({
                        ...rental,
                        [event.target.name]: event.target.checked
                    });
                } else if (event.target.name === 'franchise') {
                    setRental({
                        ...rental,
                        [event.target.name]: event.target.checked
                    });
                } else {
                    setRental({
                        ...rental,
                        [event.target.name]: event.target.value
                    });
                }
            }
        }
    }

    const onPossessionChange = (event, value, id) => {
        if (event) {
            setPossession({
                ...possession,
                [event.target.name]: event.target.value
            });
        } else {
            if (id) {
                if (id === 'creationDate') {
                    setPossession({
                        ...possession,
                        creationDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                        creationDateErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else {
                    setPossession({
                        ...possession,
                        city: value.description,
                        cityErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                }
            }
        }
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (rental.filter.length === 0) {
                setRental({
                    ...rental,
                    tenantErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    tenantErrorMessage: ''
                });

                let noMailError = false;
                rental.filter.map(item => {
                    if (!item.email) {
                        props.handleMessage(`Le locataire ${item.name} ne possède pas d'adresse e-mail`);
                        props.handleSeverity('error');
                        props.handleOpenAlert();
                        noMailError = true;
                    }
                })

                if (noMailError) {
                    return;
                }
            }

            if (rental.startRentalDate === null) {
                setRental({
                    ...rental,
                    startDateErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    startDateErrorMessage: ''
                });
            }

            if (rental.rentalAmount === '') {
                setRental({
                    ...rental,
                    rentalAmountErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalAmountErrorMessage: ''
                });
            }

            if (rental.chargesAmount === '') {
                setRental({
                    ...rental,
                    chargesAmountErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    chargesAmountErrorMessage: ''
                });
            }

            if (rental.guaranteeAmount === '') {
                setRental({
                    ...rental,
                    guaranteeAmountErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    guaranteeAmountErrorMessage: ''
                });
            }

            if (rental.chargeMode === null || rental.chargeMode === '') {
                setRental({
                    ...rental,
                    chargeModeErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    chargeModeErrorMessage: ''
                });
            }

            if (rental.rentalTerm === null || rental.rentalTerm === '') {
                setRental({
                    ...rental,
                    rentalTermErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalTermErrorMessage: ''
                });
            }

            if (rental.paymentMode === null || rental.paymentMode === '') {
                setRental({
                    ...rental,
                    paymentModeErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    paymentModeErrorMessage: ''
                });
            }

            if (rental.rentalDuration === '') {
                setRental({
                    ...rental,
                    rentalDurationErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalDurationErrorMessage: ''
                });
            }

            // Rental
            let tempRental = {};
            for (let key in rental) {
                tempRental[key] = rental[key];
            }
            contract['rental'] = tempRental;
            localStorage.setItem('contractRentalCorporation', JSON.stringify(contract['rental']));
        } else if (activeStep === 1) {
            // Possession
            if (possession.city === null) {
                setPossession({
                    ...possession,
                    cityErrorMessage: REQUIRED_FIELD
                });
                //return;
            } else {
                setPossession({
                    ...possession,
                    cityErrorMessage: ''
                });
            }

            if (possession.creationDate === '' || possession.creationDate === null) {
                setPossession({
                    ...possession,
                    creationDateErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setPossession({
                    ...possession,
                    creationDateErrorMessage: ''
                });
            }

            let tempPossession = {};
            for (let key in possession) {
                tempPossession[key] = possession[key];
            }
            contract['possession'] = tempPossession;
            localStorage.setItem('contractPossessionCorporation', JSON.stringify(contract['possession']));
        }

        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    React.useEffect(() => {
        //localStorage.removeItem('contractRentalCorporation');
        //localStorage.removeItem('contractPossessionCorporation');

        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

        contract['rental'] = JSON.parse(localStorage.getItem('contractRentalCorporation'));
        contract['possession'] = JSON.parse(localStorage.getItem('contractPossessionCorporation'));

        if (null !== contract) {
            setContract(contract);
        }
    }, [open]);

    function setContract(housing) {
        // Rental
        setRental({
            ...rental,
            ...contract['rental']
        });

        // Possession
        setPossession({
            ...possession,
            ...contract['possession']
        });
    }

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Créer un contrat
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Container component="" maxWidth="xs" tabIndex={-1}>
                        <Stepper alternativeLabel
                                 activeStep={activeStep}
                                 sx={{ pt: 3, pb: 5 }}
                                 connector={<ColorlibConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography>
                                    Vous êtes sur le point de créer votre locataire. Cliquer sur le bouton Valider pour confirmer la création de votre locataire ou sur Précédent pour vérifier les données saisies.
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep, rental, possession, tenants, onRentalChange, onPossessionChange)}
                            </React.Fragment>
                        )}
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        {activeStep !== 0 && (
                            <MercureOutlinedButton
                                onClick={handleBack}
                                label="Précédent" />
                        )}

                        {activeStep === steps.length ? (
                            <MercureContainedButton
                                onClick={saveContract}
                                label="Valider" />
                        ) : (
                            <MercureContainedButton
                                onClick={handleNext}
                                label="Suivant" />
                        )}
                    </Box>
                </DialogActions>
            </BootstrapDialog>
            {loading && (
                <Waiting loading={loading} title="Signature électronique" message="Merci de patienter, vous allez être rédirigé dans quelques instants..."/>
            )}
        </div>
    )
}