import * as React from 'react';
import BootstrapDialogTitle from "../Components/BootstrapDialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {createTheme} from "@mui/material/styles";
import BootstrapDialog from "../Components/BootstrapDialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from "@mui/material/Box";
import MercureContainedButton from "../Components/MercureContainedButton";

export default function Privacy(props) {
    const theme = createTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Politique de protection des données et de la vie privée
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    Terra SaaS est attachée à la protection des données à caractère personnel des ses utilisateurs, qui
                    constituent un outil essentiel au bon fonctionnement de l'application.<br/><br/>
                    La présente politique de protection de la vie privée décrit les types de données à caractère
                    personnel collectées, les usages qui en sont faits, les personnes habilitées à y accéder, ainsi que
                    leur durée de conservation. Elle fournit par ailleurs des informations sur les droits dont disposent
                    les utilisateurs au titre des règles de protection des données.<br/><br/>

                    <b>A. Modalités de collecte et d’utilisation des données à caractère personnel</b><br/><br/>

                    L’utilisation de l'application donne lieu à la collecte de deux types de données à caractère
                    personnel : d’une part, les données que vous fournissez directement, d’autre part, les données
                    générées par vos activités.<br/><br/>

                    1. Données fournies directement par vos soins.<br/><br/>
                    Vous pouvez choisir de fournir des données à caractère personnel lors de votre inscription, de la
                    souscription de lettres d’information en ligne et des achats que vous effectuez.<br/>
                    Ces données nous permettent de vous fournir les services demandés; il
                    peut s’agir de votre nom, votre adresse postale, votre adresse électronique, votre numéro de
                    téléphone.<br/><br/>

                    2. Données générées par vos activités.<br/><br/>
                    Pour assurer le bon fonctionnement et la sécurité de l'application, et mieux appréhender les schémas
                    comportementaux des visiteurs, nous collectons
                    automatiquement les éléments suivants, au fil de votre navigation :<br/><br/>

                    <ul>
                        <li>Informations consignées dans les fichiers journaux, à savoir les pages visitées, la date et
                            l’heure
                            de votre visite et votre adresse IP.
                        </li>
                        <li>Informations sur le matériel et les logiciels utilisés, telles que les spécifications de
                            votre
                            navigateur et votre système d’exploitation.
                        </li>
                    </ul>

                    Ces informations sont collectées au moyen de diverses technologies, notamment via les fichiers de
                    témoins (cookies) – ces petits fichiers texte stockés dans votre navigateur. Si vous êtes
                    enregistré(e) et connecté(e), vos activités de navigation sont liées à vos informations de
                    connexion, ce qui nous permet de personnaliser votre expérience, y compris dans l’optique de vos
                    prochaines visites.<br/><br/>

                    <b>B. Accès et stockage</b><br/><br/>

                    En règle générale, seuls les agents habilités au sein de l’Organisation ont accès à vos données à
                    caractère personnel. Il peut arriver que nous communiquions les données qui vous concernent en
                    dehors de notre organisation, notamment dans les cas suivants :<br/><br/>

                    1. Traitement des paiements : Pour le traitement des paiements en ligne, nous faisons appel à un
                    service tiers,<br/><br/>

                    2. Analytique : Nous faisons appel à des services tiers, Google Analytics (États-Unis), chargés de
                    nous
                    aider à comprendre le comportement de nos visiteurs ; pour ce faire, ils accèdent aux données
                    générées par votre activité et les stockent dans des fichiers de témoins. Ces données sont traitées
                    de sorte que vous ne puissiez être directement identifié(e).<br/><br/>

                    3. Communications électroniques : Nous avons recours à des services tiers, Postmark (États-Unis),
                    pour
                    l’envoi de communications électroniques de masse, telles que des lettres d’information, des alertes
                    plateformes et des notifications d’événements. Ces services conservent les coordonnées que vous
                    fournissez, ainsi que les informations relatives à vos interactions avec ces courriels (ouverture
                    des messages et clics sur les liens, par exemple).<br/><br/>

                    Les données que vous fournissez directement sont en règle générale conservées soit jusqu’à ce que
                    vous vous désabonniez du service demandé. Les données relatives à vos activités sur l'application
                    sont également conservées jusqu'à votre désabonnement.<br/><br/>

                    <b>C. Comment nous contacter ou faire valoir vos droits</b><br/><br/>

                    En vertu des règles en matière de protection des données, vous avez le droit d’accéder aux données à
                    caractère personnel qui vous concernent et de les rectifier, de vous opposer à leur traitement, d’en
                    demander l’effacement et d’obtenir, dans certaines conditions, la portabilité des données. Pour
                    exercer ces droits au titre de votre utilisation de la présente application, veuillez nous faire
                    parvenir un e-mail à l'adresse suivante : contact@terra-saas.fr.
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <MercureContainedButton onClick={props.onClose} label="Accepter" />
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}