import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SearchBar from "../../Components/SearchBar";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Snackbar from "@mui/material/Snackbar";
import Alert from "../../Components/Alert";
import Confirm from "../../Components/Confirm";
import AddTenantCorporation from "./AddTenantCorporation";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import UpdateTenantCorporation from "./UpdateTenantCorporation";
import Divider from "@mui/material/Divider";

export default function TenantCorporation(props) {
    const [openTenantCorporation, setOpenTenantCorporation] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const title = 'Attention';
    const confirmMessage = "Etes-vous sûr de vouloir supprimer définitivement ce locataire ?\nCette action est irréversible.";
    let [selectedTenant, setSelectedTenant] = React.useState();
    let [tenants, setTenants] = React.useState([]);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const anchorPosition = 'right';
    
    const getTenants = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        localStorage.removeItem('tenantsCorporation');
        let id = user.id;

        fetch(process.env.REACT_APP_API_HOST + '/tenant/corporation/get/user/id/' + id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setTenants(response);
                    localStorage.setItem('tenantsCorporation', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [selectedTenant]);
    
    React.useEffect(() => {
        const lodgers = JSON.parse(localStorage.getItem('tenantsCorporation'));

        if (null === lodgers) {
            getTenants();
        } else {
            setTenants(lodgers);
        }
    }, []);

    const filteredTenants = tenants.filter(tenant => {
        if (filter === '') {
            return tenant;
        } else {
            return (tenant.firstName && tenant.firstName.toLowerCase().includes(filter.toLowerCase())) ||
                (tenant.name && tenant.name.toLowerCase().includes(filter.toLowerCase())) ||
                (tenant.lastName && tenant.lastName.toLowerCase().includes(filter.toLowerCase())) ||
                (tenant.mobilePhone && tenant.mobilePhone.toString().includes(filter.toLowerCase()))
        }
    })

    const onFilterChange = (event) => {
        setFilter(event.target.value);
    }

    const handleClick = () => {
        handleOpenTenantCorporation();
    }

    const handleOpenTenantCorporation = () => {
        setOpenTenantCorporation(true);
    }

    const handleCloseTenantCorporation = () => {
        setOpenTenantCorporation(false);
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleMessage = (message) => {
        setMessage(message);
    };

    const handleSeverity = (severity) => {
        setSeverity(severity);
    };

    const duplicate = React.useCallback((tenant) => {
        let data = {};

        for (let key in tenant) {
            if ('id' === key) {
                continue;
            }

            data[key] = tenant[key];
        }

        //console.log('data',data);return
        fetch(process.env.REACT_APP_API_HOST + '/tenant/corporation/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                // Show Alert
                handleMessage("Votre locataire a été dupliqué");
                handleSeverity('success');
                handleOpenAlert();

                // Refresh data
                getTenants();
            }
        }).catch((error) => {
            console.log(error);
            handleMessage("Votre locataire n'a pas pu être dupliqué suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        });
    }, []);

    const tenantUpdated = () => {
        setOpenDrawer(false);
        setSelectedTenant();
        getTenants();
        handleMessage("Vos données ont été modifiées");
        handleSeverity('success');
        handleOpenAlert();
    }

    const tenantDeleted = () => {
        setSelectedTenant();
        getTenants();
        handleMessage("Votre locataire a été supprimé");
        handleSeverity('success');
        handleOpenAlert();
    }

    const tenantNotUpdated = () => {
        setOpenDrawer(false);
        setSelectedTenant();
        handleMessage("Vos données n'ont pas pu être modifiées suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const tenantNotDeleted = () => {
        handleMessage("Votre locataire n'a pas pu être supprimé suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const toggleConfirm = (open, tenant) => (event) => {
        if (open) {
            setSelectedTenant(tenant);
        } else {
            setSelectedTenant();
        }

        setOpenConfirm(open);
    }

    const toggleDrawer = (open, tenant) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        const housings = JSON.parse(localStorage.getItem('housings'));

        if (open) {
            if (housings && housings.length !== 0) {
                tenant.housing = housings.find((housing) => housing.id === tenant.idHousing);
            }
            setSelectedTenant(tenant);
        } else {
            setSelectedTenant();
        }

        setOpenDrawer(open);
    }

    return (
        <div>
            <Paper sx={{maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
                {openTenantCorporation === true && (
                    <AddTenantCorporation open={openTenantCorporation} refresh={getTenants} onClose={handleCloseTenantCorporation} handleOpenAlert={handleOpenAlert} handleMessage={handleMessage} handleSeverity={handleSeverity} />
                )}
                <SearchBar placeholder="Chercher un locataire" onFilterChange={onFilterChange} buttonLabel="Ajouter un locataire" onButtonClick={handleClick} onRefresh={getTenants} />

                {filteredTenants.length === 0 ? (
                    <Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
                        Vous n'avez aucun locataire
                    </Typography>
                ) : (
                    <List aria-labelledby="nested-list-subheader"
                          subheader={
                              <ListSubheader component="div" id="nested-list-subheader">
                                  {filteredTenants.length} locataire.s
                              </ListSubheader>
                          }
                    >
                        {filteredTenants && filteredTenants.map && filteredTenants.map((tenant, index) =>
                            <div>
                                <ListItem key={tenant.id}
                                          secondaryAction={
                                              <Grid>
                                                  <Tooltip title="Modifier">
                                                      <IconButton edge="end" aria-label="edit" onClick={toggleDrawer(true, tenant)}>
                                                          <EditIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Dupliquer">
                                                      <IconButton edge="end" aria-label="duplicate" onClick={() => duplicate(tenant)}>
                                                          <ContentCopyIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Supprimer">
                                                      <IconButton edge="end" aria-label="delete" color="error" onClick={toggleConfirm(true, tenant)}>
                                                          <DeleteIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                              </Grid>
                                          }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BusinessRoundedIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id={tenant.id}
                                                  primary={tenant.type ? `${tenant.type} ${tenant.name}` : `${tenant.name}`}
                                                  secondary={
                                                      <React.Fragment>
                                                          <Typography variant="subtitle1">
                                                              {`${tenant.firstName} ${tenant.lastName}`}
                                                          </Typography>
                                                          <Typography variant="subtitle2">
                                                              {tenant.mobilePhone ? `Mobile : +${tenant.mobilePhonePrefix}${tenant.mobilePhone}` : ''}
                                                          </Typography>
                                                          <Typography variant="subtitle2">
                                                              {tenant.email ? `E-mail : ${tenant.email}` : ''}
                                                          </Typography>
                                                          <Typography variant="subtitle2">
                                                              {tenant.address}
                                                          </Typography>
                                                      </React.Fragment>
                                                  }
                                    />
                                </ListItem>
                                {
                                    ((index === 0 && filteredTenants.length > 1) || index < filteredTenants.length - 1) && <Divider variant="inset" component="li" />
                                }
                            </div>
                        )}
                    </List>
                )}

                <Drawer anchor={anchorPosition}
                        open={openDrawer}
                        PaperProps={{
                            sx: {backgroundColor: "#ffffff"}
                        }}
                        onClose={toggleDrawer(false)}>
                    <UpdateTenantCorporation tenant={selectedTenant} tenantUpdated={tenantUpdated} tenantNotUpdated={tenantNotUpdated} />
                </Drawer>
            </Paper>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>

            <Confirm open={openConfirm}
                     title={title}
                     message={confirmMessage}
                     onClose={toggleConfirm(false)}
                     selected={selectedTenant}
                     type="tenant-corporation"
                     tenantDeleted={tenantDeleted}
                     tenantNotDeleted={tenantNotDeleted}
                     />
        </div>
    )
}