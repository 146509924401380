import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

export default function Rental(props) {
    const [currency, setCurrency] = React.useState('€');

    const [rentalTypes, setRentalTypes] = React.useState([
        'Location nue',
        'Location meublée',
        'Colocation meublée',
        'Colocation nue',
        'Location saisonnière',
        'Location parking / garage / cave',
    ]);

    const [chargesModeOptions, setChargesModeOptions] = React.useState([
        'Pas de charge',
        'Réel avec régularisation annuelle',
        'Forfait'
    ]);

    const {
        // Data
        filter,
        rentalType,
        rentalDuration,
        startRentalDate,
        rentalAmount,
        chargesAmount,
        guaranteeAmount,
        paymentDay,
        chargeMode,
        rentalTerm,
        paymentMode,

        // Error
        tenantErrorMessage,
        startDateErrorMessage,
        rentalTypeErrorMessage,
        chargeModeErrorMessage,
        rentalAmountErrorMessage,
        chargesAmountErrorMessage,
        guaranteeAmountErrorMessage,
        rentalTermErrorMessage,
        paymentModeErrorMessage,
        rentalDurationErrorMessage
    } = props.data;

    return (
        <div>
            <Box component="form" noValidate>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Autocomplete
                            noOptionsText="Sélectionner le.s locataire.s"
                            multiple
                            options={props.tenants}
                            id="tenant"
                            autoHighlight
                            filterSelectedOptions
                            value={filter}
                            onChange={(event, newValue) => {
                                props.onRentalChange(event, newValue, 'tenant')
                            }}
                            getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName}` : ''}
                            renderInput={(params) => <TextField required {...params} label="Sélectionner le.s locataire.s"
                                                                fullWidth error={tenantErrorMessage !== ""}
                                                                helperText={tenantErrorMessage}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="rentalType"
                            disablePortal
                            options={rentalTypes}
                            value={rentalType}
                            autoHighlight
                            onChange={(event, newValue) => {
                                props.onRentalChange(event, newValue, 'rentalType')
                            }}
                            renderInput={(params) => <TextField required {...params} label="Sélectionner le type de location"
                                                                fullWidth error={rentalTypeErrorMessage !== ""}
                                                                helperText= {rentalTypeErrorMessage}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Durée de la location"
                            id="rentalDuration"
                            fullWidth
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            value={rentalDuration}
                            error={rentalDurationErrorMessage !== ""}
                            helperText= {rentalDurationErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                required
                                label="Date de début de location"
                                id="startRentalDate"
                                inputFormat="DD/MM/YYYY"
                                value={startRentalDate}
                                onChange={(newValue) => {
                                    props.onRentalChange(null, newValue, 'startRentalDate')
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth required error={startDateErrorMessage !== ""}
                                                                    helperText= {startDateErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Montant du loyer (hors charges)"
                            id="rentalAmount"
                            fullWidth
                            type="number"
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            value={rentalAmount}
                            error={rentalAmountErrorMessage !== ""}
                            helperText= {rentalAmountErrorMessage}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Montant des charges"
                            id="chargesAmount"
                            fullWidth
                            type="number"
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            value={chargesAmount}
                            error={chargesAmountErrorMessage !== ""}
                            helperText= {chargesAmountErrorMessage}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Montant du dépôt de garantie"
                            id="guaranteeAmount"
                            fullWidth
                            type="number"
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            value={guaranteeAmount}
                            error={guaranteeAmountErrorMessage !== ""}
                            helperText= {guaranteeAmountErrorMessage}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="chargeMode"
                            disablePortal
                            options={chargesModeOptions}
                            value={chargeMode}
                            autoHighlight
                            onChange={(event, newValue) => {
                                props.onRentalChange(event, newValue, 'chargeMode')
                            }}
                            renderInput={(params) => <TextField required {...params} label="Mode de récupération des charges"
                                                                fullWidth
                                                                error={chargeModeErrorMessage !== ""}
                                                                helperText= {chargeModeErrorMessage}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={rentalTermErrorMessage !== ''}>
                            <InputLabel id="rentalTerm">Le loyer est payé pour</InputLabel>
                            <Select
                                labelId="rentalTerm"
                                id="rentalTerm"
                                label="Le loyer est payé pour"
                                value={rentalTerm}
                                onChange={(event) => props.onRentalChange(event, 'rentalTerm')}
                            >
                                <MenuItem value={1}>Le mois à échoir</MenuItem>
                                <MenuItem value={2}>Le mois échu</MenuItem>
                            </Select>
                            <FormHelperText>{rentalTermErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="paymentDay"
                            label="Jour d'encaissement de loyer"
                            value={paymentDay}
                            type="number"
                            onChange={(event) => {
                                props.onRentalChange(event)
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Le </InputAdornment>,
                                endAdornment: <InputAdornment position="end"> du mois</InputAdornment>,
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={paymentModeErrorMessage !== ''}>
                            <InputLabel id="paymentMode">Mode de réglement</InputLabel>
                            <Select
                                labelId="paymentMode"
                                id="paymentMode"
                                label="Mode de réglement"
                                value={paymentMode}
                                onChange={(event) => props.onRentalChange(event, 'paymentMode')}
                            >
                                <MenuItem value={1}>Carte bancaire</MenuItem>
                                <MenuItem value={2}>Chèque</MenuItem>
                                <MenuItem value={3}>Espèces</MenuItem>
                                <MenuItem value={4}>Virement bancaire</MenuItem>
                                <MenuItem value={5}>Prélèvement</MenuItem>
                                <MenuItem value={6}>Autre</MenuItem>
                            </Select>
                            <FormHelperText>{paymentModeErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}