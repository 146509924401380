import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import List from "@mui/material/List";
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from '@mui/material/ListItem';
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import Alert from "../../Components/Alert";
import Waiting from "../../Components/Waiting";
import Confirm from "../../Components/Confirm";
import Drawer from "@mui/material/Drawer";
import CustomSearchBar from "../../Components/CustomSearchBar";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import AddInvoice from "./AddInvoice";
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import UpdateInvoice from "./UpdateInvoice";
import ReceiptIcon from "@mui/icons-material/Receipt";

export default function Invoice(props) {
    let maxYear = new Date().setFullYear(new Date().getFullYear());
    let maxDate = dayjs(maxYear).toLocaleString();
    const [invoices, setInvoices] = React.useState([]);
    const [corporations, setCorporations] = React.useState(null);
    const [filter, setFilter] = React.useState(null);
    const [openInvoice, setOpenInvoice] = React.useState(false);
    const [invoiceYear, setInvoiceYear] = React.useState(dayjs(new Date()).format('YYYY'));
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [waitingTitle, setWaitingTitle] = React.useState('Sauvegarde');
    const [waitingMessage, setWaitingMessage] = React.useState('La sauvegarde de votre facture est en cours, merci de patienter encore quelques instants...');
    const [loading, setLoading] = React.useState(false);
    const title = 'Attention';
    const confirmMessage = "Etes-vous sûr de vouloir supprimer définitivement cette facture ?\nCette action est irréversible.";
    const [openConfirm, setOpenConfirm] = React.useState(false);
    let [selectedInvoice, setSelectedInvoice] = React.useState();
    const anchorPosition = 'right';
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const onFilterChange = (event, value) => {
        setFilter(value);
    }

    const getCorporations = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/housing/corporation/get/user/' + user.id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    console.log('response ', response);
                    setCorporations(response);
                    localStorage.setItem('corporations', JSON.stringify(response));

                    if (response == null) {
                        handleMessage("Vous devez au préalable renseigner une entreprise dans la section Gérer mes entreprises.")
                        handleSeverity('error');
                        handleOpenAlert();
                    }
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const getInvoices = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        let data = {};
        data.idCorporation = filter && filter.id;
        data.idUser = user.id;
        data.year = invoiceYear;
        //console.log('data', data); return;
        const url = process.env.REACT_APP_API_HOST + '/invoice/get';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    console.log('response', response);
                    setInvoices(response);
                    localStorage.setItem('invoices', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [filter, invoiceYear]);

    const refreshData = React.useCallback((sender, year) => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        // Set year
        setInvoiceYear(year);

        // Set filter
        setFilter(sender);

        let data = {};
        data.idCorporation = sender.id;
        data.idUser = user.id;
        data.year = year;
        //console.log('data', data); // return;
        const url = process.env.REACT_APP_API_HOST + '/invoice/get';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    console.log('response', response);
                    setInvoices(response);
                    localStorage.setItem('invoices', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    React.useEffect(() => {
        const enterprises = JSON.parse(localStorage.getItem('corporations'));

        if (null === enterprises || enterprises.length === 0) {
            getCorporations();
        } else {
            setCorporations(enterprises);
        }
    }, []);

    const handleOpenInvoice = () => {
        setOpenInvoice(true);
    }

    const handleCloseInvoice = () => setOpenInvoice(false);

    const onYearChange = (event, value) => {
        setInvoiceYear(dayjs(value.toLocaleString()).format('YYYY'));
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleMessage = (message) => {
        setMessage(message);
    };

    const handleSeverity = (severity) => {
        setSeverity(severity);
    };

    const filteredInvoices = invoices.filter(invoice => {
        if (filter === null) {
            return invoice;
        } else {
            return (filter && invoice.idCorporation === filter.id);
        }
    })

    function forceDownload(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        document.body.appendChild(a);

        // Hide loading before downloading
        setLoading(false);

        // Download
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    // Download from S3
    const download = (invoice) => (event) => {
        // Loading
        setWaitingTitle('Téléchargement');
        setWaitingMessage('Le téléchargement de votre facture est en cours, merci de patienter quelques instants...')
        setLoading(true);

        let data = {};
        data.fileName = invoice.name;
        const url= process.env.REACT_APP_API_HOST + '/invoice/download';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            //console.log('result', result);
            if (result.status === 200) {
                result.blob().then(blob => forceDownload(blob, invoice.name))
            } else {
                // Hide popup
                setWaitingTitle('');
                setWaitingMessage('')
                setLoading(false);

                // Show error message
                handleMessage("La facture n'a pas pu être téléchargée suite à une erreur");
                handleSeverity('error');
                handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);

            // Hide popup
            setWaitingTitle('');
            setWaitingMessage('')
            setLoading(false);

            // Show error message
            handleMessage("La facture n'a pas pu être téléchargée suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        })
    }

    const payed = (invoice) => (event) => {
        let data = {};
        data.isPayed = 1;

        const url= process.env.REACT_APP_API_HOST + '/invoice/update/' + invoice.id;

        fetch(url, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            console.log('result', result);
            if (result.status === 204) {
                handleMessage("La facture a été marquée comme payée");
                handleSeverity('success');
                handleOpenAlert();

                // Refresh
                getInvoices();
            }
        }).catch((error) => {
            console.log(error);
            handleMessage("La facture n'a pas pu être marquée comme payée suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        })
    }

    const resend = (invoice) => (event) => {
        let data = {};
        data.id = invoice.id;

        const url= process.env.REACT_APP_API_HOST + '/invoice/send';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            //console.log('result', result);
            if (result.status === 204) {
                handleMessage("La facture a été renvoyée");
                handleSeverity('success');
                handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);
            handleMessage("La facture n'a pas pu être renvoyée suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        })
    }

    const handleQuantityError = () => {
        handleMessage("La quantité saisie est incorrecte");
        handleSeverity('error');
        handleOpenAlert();
    }

    const handleVatError = () => {
        handleMessage("La TVA saisie est incorrecte");
        handleSeverity('error');
        handleOpenAlert();
    }

    const toggleConfirm = (open, invoice) => (event) => {
        if (open) {
            setSelectedInvoice(invoice);
        } else {
            setSelectedInvoice();
        }

        setOpenConfirm(open);
    }

    const invoiceDeleted = () => {
        setSelectedInvoice();
        getInvoices();
        handleMessage("La facture a été supprimée");
        handleSeverity('success');
        handleOpenAlert();
    }

    const invoiceNotDeleted = () => {
        handleMessage("La facture n'a pas pu être supprimée suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const toggleDrawer = (open, invoice) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (open) {
            setSelectedInvoice(invoice);
        } else {
            setSelectedInvoice();
        }

        setOpenDrawer(open);
    }

    const closeDrawer = () => {
        setOpenDrawer(false);
    }

    const invoiceUpdated = () => {
        setOpenDrawer(false);
        setSelectedInvoice();
        getInvoices();
        handleMessage("Vos données ont été modifiées");
        handleSeverity('success');
        handleOpenAlert();
    }

    const invoiceNotUpdated = () => {
        setOpenDrawer(false);
        setSelectedInvoice();
        handleMessage("Vos données n'ont pas pu être modifiées suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const getMonthLabel = (value) => {
        switch (value.toString().toLowerCase()) {
            case 'january':
                return 0;

            case 'february':
                return 1;

            case 'march':
                return 2;

            case 'april':
                return 3;

            case 'may':
                return 4;

            case 'june':
                return 5;

            case 'july':
                return 6;

            case 'august':
                return 7;

            case 'september':
                return 8;

            case 'october':
                return 9;

            case 'november':
                return 10;

            case 'december':
                return 11;
        }
    }

    function ucFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    return (
        <div>
            <Paper sx={{maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
                {openInvoice === true && (
                    <AddInvoice open={openInvoice}
                                onClose={handleCloseInvoice}
                                options={corporations}
                                refreshData={refreshData}
                                handleQuantityError={handleQuantityError}
                                handleVatError={handleVatError}
                                handleOpenAlert={handleOpenAlert}
                                handleMessage={handleMessage}
                                handleSeverity={handleSeverity}/>
                )}

                <CustomSearchBar year={invoiceYear}
                                 maxDate={maxDate}
                                 onYearChange={onYearChange}
                                 options={corporations}
                                 filter={filter}
                                 onFilterChange={onFilterChange}
                                 onClick={getInvoices}
                                 label="Sélectionner une entreprise"
                                 handleMenu={handleOpenInvoice}/>

                {filteredInvoices && filteredInvoices.length === 0 ? (
                    <Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
                        Lancer la recherche pour afficher les factures
                    </Typography>
                ) : (
                    <List subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {filteredInvoices && filteredInvoices.length} facture.s
                        </ListSubheader>
                    }>
                        {filteredInvoices && filteredInvoices.map && filteredInvoices.map((invoice, index) =>
                            <div>
                                <ListItem key={invoice.id}
                                          secondaryAction={
                                              <Grid>
                                                  <Tooltip title="Modifier">
                                                      <IconButton edge="end" aria-label="edit" onClick={toggleDrawer(true, invoice)}>
                                                          <EditIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Télécharger">
                                                      <IconButton edge="end" aria-label="download" onClick={download(invoice)}>
                                                          <DownloadIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Renvoyer par mail">
                                                      <IconButton edge="end" aria-label="resend" onClick={resend(invoice)}>
                                                          <EmailIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  {invoice.isPayed === 1 ?
                                                      <Tooltip title="Facture payée">
                                                          <IconButton edge="end" aria-label="unpayed">
                                                              <AttachMoneyIcon/>
                                                          </IconButton>
                                                      </Tooltip>
                                                      :
                                                      <Tooltip title="Marquer comme payée">
                                                          <IconButton edge="end" aria-label="payed" onClick={payed(invoice)}>
                                                              <MoneyOffIcon/>
                                                          </IconButton>
                                                      </Tooltip>
                                                  }
                                                  <Tooltip title="Supprimer">
                                                      <IconButton edge="end" aria-label="delete" color="error" onClick={toggleConfirm(true, invoice)}>
                                                          <DeleteIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                              </Grid>
                                          }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ReceiptIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id={invoice.id}
                                                  primary={invoice.name}
                                                  secondary={
                                                      <React.Fragment>
                                                          <Stack direction="row" spacing={1}>
                                                              <Typography variant="subtitle1">
                                                                  {`Facture de ${ucFirst(dayjs().month(getMonthLabel(invoice.month)).locale('fr').format('MMMM'))} ${invoice.year} pour le client ${invoice.clientName}`}
                                                              </Typography>
                                                          </Stack>
                                                      </React.Fragment>
                                                  }
                                    />
                                </ListItem>
                            {
                                ((index === 0 && filteredInvoices.length > 1) || index < filteredInvoices.length - 1) && <Divider variant="inset" component="li" />
                            }
                            </div>
                        )}
                    </List>
                )}

                {loading && (
                    <Waiting loading={loading} title={waitingTitle} message={waitingMessage} />
                )}

                <Drawer anchor={anchorPosition}
                        open={openDrawer}
                        PaperProps={{
                            sx: {backgroundColor: "#ffffff"}
                        }}
                        onClose={toggleDrawer(false)}>
                    <UpdateInvoice invoice={selectedInvoice}
                                   invoiceUpdated={invoiceUpdated}
                                   options={corporations}
                                   invoiceNotUpdated={invoiceNotUpdated}
                                   onClose={closeDrawer}
                                   handleOpenAlert={handleOpenAlert}
                                   handleMessage={handleMessage}
                                   handleSeverity={handleSeverity} />
                </Drawer>
            </Paper>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>

            <Confirm open={openConfirm}
                     title={title}
                     message={confirmMessage}
                     onClose={toggleConfirm(false)}
                     selected={selectedInvoice}
                     type="invoice"
                     titleBackgroundColor="#ff9800"
                     invoiceDeleted={invoiceDeleted}
                     invoiceNotDeleted={invoiceNotDeleted}
                     titleColor="white"/>

        </div>
    )
}