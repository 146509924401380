import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";

export default function Insee(props) {
    const [openAutoComplete, setOpenAutoComplete] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = openAutoComplete && options && options.length === 0;
    const [name, setName] = React.useState(props.name);
    const requiredField = 'Ce champ est requis';

    const onInputChange = (event, value) => {
        setOptions([]);

        if (value) {
            if (props.required) {
                props.helperText('');
            }

            setName(value.toUpperCase());
            props.name(value.toUpperCase());
            return;
        }

        if (props.required) {
            props.helperText(requiredField);
        }
    };

    const onValueChange = (event, value) => {
        if (value) {
            //console.log('val', value);
            let beautifyAddress = '';
            let numberAddress = value.adresseEtablissement.numeroVoieEtablissement ? value.adresseEtablissement.numeroVoieEtablissement : '';
            let additionalAddress = null !== value.adresseEtablissement.complementAdresseEtablissement ? value.adresseEtablissement.complementAdresseEtablissement + ' ' : '';
            let typeAddress = null !== value.adresseEtablissement.typeVoieEtablissement ? value.adresseEtablissement.typeVoieEtablissement + ' ' : '';

            beautifyAddress = numberAddress + ' ' + ucFirst(additionalAddress) + ucFirst(typeAddress) + ucFirst(value.adresseEtablissement.libelleVoieEtablissement) + ' ' + value.adresseEtablissement.codePostalEtablissement + ' ' + ucFirst(value.adresseEtablissement.libelleCommuneEtablissement) ;
            props.address(beautifyAddress);
            props.siret(value.siret);
            props.siren(value.siren);
        } else {
            props.address('');
            props.siret('');
            props.siren('');
        }
    }

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (name && name.length > 3) {
            setOptions([]);
            (async () => {
                await fetch(process.env.REACT_APP_API_HOST + '/insee/get/name/' + name, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(result => {
                    if (result.status === 200) {
                        result.json().then(response => {
                            if (active) {
                                setOptions([...response]);
                            }
                        })
                    } else {
                        console.log(result);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            })();
        }

        return () => {
            active = false;
        };
    }, [loading, name]);

    function ucFirst(string) {
        if (string !== null) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        
        return '';
    }

    return (
        <Autocomplete
            autoComplete="given-data"
            freeSolo
            open={openAutoComplete}
            fullWidth
            label={props.label}
            autoFocus={props.autoFocus}
            onOpen={() => {
                setOpenAutoComplete(true);
            }}
            onClose={() => {
                setOpenAutoComplete(false);
            }}
            onInputChange={(event, newInputValue) => {
                onInputChange(event, newInputValue)
            }}
            onChange={(event, newValue) => {
                onValueChange(event, newValue);
            }}
            getOptionLabel={(option) => option !== undefined && option.uniteLegale !== undefined ? option.uniteLegale.denominationUniteLegale : ''}
            options={options}
            loading={loading}
            filterOptions={(x) => x}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.siret}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Box component="span" sx={{ fontWeight: option.highlight ? 'bold' : 'regular' }}>
                                    {option.uniteLegale.denominationUniteLegale} - {option.siret}
                                </Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    {option.adresseEtablissement.numeroVoieEtablissement && option.adresseEtablissement.numeroVoieEtablissement} {option.adresseEtablissement.complementAdresseEtablissement && option.adresseEtablissement.complementAdresseEtablissement} {option.adresseEtablissement.typeVoieEtablissement} {option.adresseEtablissement.libelleVoieEtablissement} {option.adresseEtablissement.codePostalEtablissement} {option.adresseEtablissement.libelleCommuneEtablissement}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    required={props.required}
                    {...params}
                    label={props.label}
                    error={props.error}
                    helperText={props.helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="secondary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}