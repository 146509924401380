import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from "@mui/material/InputAdornment";

export default function Country(props) {
    return (
        <Autocomplete
            options={countries}
            autoHighlight
            getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
            value={props.value}
            onChange={(event, newValue) => {
                    props.onChange(event, newValue);
                }
            }
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label="Nationalité"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: props.value && props.value.code ? (
                            <InputAdornment position="start">
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${props.value.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${props.value.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                            </InputAdornment>
                        ) : null
                    }}
                />
            )}
        />
    );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
    {code: 'AD', label: 'Andorre', phone: '376'},
    {
        code: 'AE',
        label: 'Emirats Arabes Unis',
        phone: '971',
    },
    {code: 'AF', label: 'Afghanistan', phone: '93'},
    {
        code: 'AG',
        label: 'Antigua et Barbuda',
        phone: '1-268',
    },
    {code: 'AI', label: 'Anguilla', phone: '1-264'},
    {code: 'AL', label: 'Albanie', phone: '355'},
    {code: 'AM', label: 'Arménie', phone: '374'},
    {code: 'AO', label: 'Angola', phone: '244'},
    {code: 'AQ', label: 'Antarctique', phone: '672'},
    {code: 'AR', label: 'Argentine', phone: '54'},
    {code: 'AS', label: 'Samoa américaines', phone: '1-684'},
    {code: 'AT', label: 'Autriche', phone: '43'},
    {
        code: 'AU',
        label: 'Australie',
        phone: '61',
        suggested: true,
    },
    {code: 'AW', label: 'Aruba', phone: '297'},
    {code: 'AX', label: 'Iles Aland', phone: '358'},
    {code: 'AZ', label: 'Azerbaïdjan', phone: '994'},
    {
        code: 'BA',
        label: 'Bosnie',
        phone: '387',
    },
    {code: 'BB', label: 'Barbades', phone: '1-246'},
    {code: 'BD', label: 'Bangladesh', phone: '880'},
    {code: 'BE', label: 'Belgique', phone: '32'},
    {code: 'BF', label: 'Burkina Faso', phone: '226'},
    {code: 'BG', label: 'Bulgarie', phone: '359'},
    {code: 'BH', label: 'Bahreïn', phone: '973'},
    {code: 'BI', label: 'Burundi', phone: '257'},
    {code: 'BJ', label: 'Benin', phone: '229'},
    {code: 'BL', label: 'Saint Barthélémy', phone: '590'},
    {code: 'BM', label: 'Bermudes', phone: '1-441'},
    {code: 'BN', label: 'Brunéi Darussalam', phone: '673'},
    {code: 'BO', label: 'Bolivie', phone: '591'},
    {code: 'BR', label: 'Brésil', phone: '55'},
    {code: 'BS', label: 'Bahamas', phone: '1-242'},
    {code: 'BT', label: 'Bhoutan', phone: '975'},
    {code: 'BV', label: 'Île Bouvet', phone: '47'},
    {code: 'BW', label: 'Botswana', phone: '267'},
    {code: 'BY', label: 'Biélorussie', phone: '375'},
    {code: 'BZ', label: 'Belize', phone: '501'},
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Îles Cocos (Keeling)',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, République Démocratique du',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Centrafrique',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, République du',
        phone: '242',
    },
    {code: 'CH', label: 'Suisse', phone: '41'},
    {code: 'CI', label: "Côte d'Ivoire", phone: '225'},
    {code: 'CK', label: 'Les Îles Cook', phone: '682'},
    {code: 'CL', label: 'Chili', phone: '56'},
    {code: 'CM', label: 'Cameroun', phone: '237'},
    {code: 'CN', label: 'Chine', phone: '86'},
    {code: 'CO', label: 'Colombie', phone: '57'},
    {code: 'CR', label: 'Costa Rica', phone: '506'},
    {code: 'CU', label: 'Cuba', phone: '53'},
    {code: 'CV', label: 'Cap Vert', phone: '238'},
    {code: 'CW', label: 'Curaçao', phone: '599'},
    {code: 'CX', label: "L'île de Noël", phone: '61'},
    {code: 'CY', label: 'Chypre', phone: '357'},
    {code: 'CZ', label: 'République Tchèque', phone: '420'},
    {
        code: 'DE',
        label: 'Allemagne',
        phone: '49',
        suggested: true,
    },
    {code: 'DJ', label: 'Djibouti', phone: '253'},
    {code: 'DK', label: 'Danemark', phone: '45'},
    {code: 'DM', label: 'Dominique', phone: '1-767'},
    {
        code: 'DO',
        label: 'République Dominicaine',
        phone: '1-809',
    },
    {code: 'DZ', label: 'Algérie', phone: '213'},
    {code: 'EC', label: 'Equateur', phone: '593'},
    {code: 'EE', label: 'Estonie', phone: '372'},
    {code: 'EG', label: 'Egypte', phone: '20'},
    {code: 'EH', label: 'Sahara Occidental', phone: '212'},
    {code: 'ER', label: 'Erythrée', phone: '291'},
    {code: 'ES', label: 'Espagne', phone: '34'},
    {code: 'ET', label: 'Ethiopie', phone: '251'},
    {code: 'FI', label: 'Finlande', phone: '358'},
    {code: 'FJ', label: 'Fidji', phone: '679'},
    {
        code: 'FK',
        label: 'Îles Falkland (Malouines)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronésie, États fédérés de',
        phone: '691',
    },
    {code: 'FO', label: 'Ile Féroé', phone: '298'},
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    {code: 'GA', label: 'Gabon', phone: '241'},
    {code: 'GB', label: 'Royaume Uni', phone: '44'},
    {code: 'GD', label: 'Grenade', phone: '1-473'},
    {code: 'GE', label: 'Georgie', phone: '995'},
    {code: 'GF', label: 'Guyane Française', phone: '594'},
    {code: 'GG', label: 'Guernsey', phone: '44'},
    {code: 'GH', label: 'Ghana', phone: '233'},
    {code: 'GI', label: 'Gibraltar', phone: '350'},
    {code: 'GL', label: 'Greenland', phone: '299'},
    {code: 'GM', label: 'Gambie', phone: '220'},
    {code: 'GN', label: 'Guinée', phone: '224'},
    {code: 'GP', label: 'Guadeloupe', phone: '590'},
    {code: 'GQ', label: 'Guinée Equatoriale', phone: '240'},
    {code: 'GR', label: 'Grèce', phone: '30'},
    {
        code: 'GS',
        label: 'Géorgie du Sud et les îles Sandwich du Sud',
        phone: '500',
    },
    {code: 'GT', label: 'Guatemala', phone: '502'},
    {code: 'GU', label: 'Guam', phone: '1-671'},
    {code: 'GW', label: 'Guinée-Bissau', phone: '245'},
    {code: 'GY', label: 'Guyane', phone: '592'},
    {code: 'HK', label: 'Hong Kong', phone: '852'},
    {
        code: 'HM',
        label: 'Île Heard et îles McDonald',
        phone: '672',
    },
    {code: 'HN', label: 'Honduras', phone: '504'},
    {code: 'HR', label: 'Croatie', phone: '385'},
    {code: 'HT', label: 'Haiti', phone: '509'},
    {code: 'HU', label: 'Hongrie', phone: '36'},
    {code: 'ID', label: 'Indonesie', phone: '62'},
    {code: 'IE', label: 'Irlande', phone: '353'},
    {code: 'IL', label: 'Israel', phone: '972'},
    {code: 'IM', label: 'Ile de Man', phone: '44'},
    {code: 'IN', label: 'Inde', phone: '91'},
    {
        code: 'IO',
        label: "Territoire britannique de l'océan Indien",
        phone: '246',
    },
    {code: 'IQ', label: 'Irak', phone: '964'},
    {
        code: 'IR',
        label: 'Iran',
        phone: '98',
    },
    {code: 'IS', label: 'Islande', phone: '354'},
    {code: 'IT', label: 'Italie', phone: '39'},
    {code: 'JE', label: 'Jersey', phone: '44'},
    {code: 'JM', label: 'Jamaïque', phone: '1-876'},
    {code: 'JO', label: 'Jordanie', phone: '962'},
    {
        code: 'JP',
        label: 'Japon',
        phone: '81',
        suggested: true,
    },
    {code: 'KE', label: 'Kenya', phone: '254'},
    {code: 'KG', label: 'Kirghizistan', phone: '996'},
    {code: 'KH', label: 'Cambodge', phone: '855'},
    {code: 'KI', label: 'Kiribati', phone: '686'},
    {code: 'KM', label: 'Comores', phone: '269'},
    {
        code: 'KN',
        label: 'Saint Kitts et Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Corée, République Populaire Démocratique de",
        phone: '850',
    },
    {code: 'KR', label: 'Corée, République de', phone: '82'},
    {code: 'KW', label: 'Koweït', phone: '965'},
    {code: 'KY', label: 'Iles Caïman', phone: '1-345'},
    {code: 'KZ', label: 'Kazakhstan', phone: '7'},
    {
        code: 'LA',
        label: "Laos",
        phone: '856',
    },
    {code: 'LB', label: 'Liban', phone: '961'},
    {code: 'LC', label: 'Sainte Lucie', phone: '1-758'},
    {code: 'LI', label: 'Liechtenstein', phone: '423'},
    {code: 'LK', label: 'Sri Lanka', phone: '94'},
    {code: 'LR', label: 'Libéria', phone: '231'},
    {code: 'LS', label: 'Lesotho', phone: '266'},
    {code: 'LT', label: 'Lithuanie', phone: '370'},
    {code: 'LU', label: 'Luxembourg', phone: '352'},
    {code: 'LV', label: 'Lettonie', phone: '371'},
    {code: 'LY', label: 'Libye', phone: '218'},
    {code: 'MA', label: 'Maroc', phone: '212'},
    {code: 'MC', label: 'Monaco', phone: '377'},
    {
        code: 'MD',
        label: 'Moldavie',
        phone: '373',
    },
    {code: 'ME', label: 'Montenegro', phone: '382'},
    {
        code: 'MF',
        label: 'Saint Martin (Partie Française)',
        phone: '590',
    },
    {code: 'MG', label: 'Madagascar', phone: '261'},
    {code: 'MH', label: 'Iles Marshall', phone: '692'},
    {
        code: 'MK',
        label: 'Macédoine',
        phone: '389',
    },
    {code: 'ML', label: 'Mali', phone: '223'},
    {code: 'MM', label: 'Myanmar', phone: '95'},
    {code: 'MN', label: 'Mongolie', phone: '976'},
    {code: 'MO', label: 'Macao', phone: '853'},
    {
        code: 'MP',
        label: 'Iles Mariana du Nord',
        phone: '1-670',
    },
    {code: 'MQ', label: 'Martinique', phone: '596'},
    {code: 'MR', label: 'Mauritanie', phone: '222'},
    {code: 'MS', label: 'Montserrat', phone: '1-664'},
    {code: 'MT', label: 'Malte', phone: '356'},
    {code: 'MU', label: 'Maurice', phone: '230'},
    {code: 'MV', label: 'Maldives', phone: '960'},
    {code: 'MW', label: 'Malawi', phone: '265'},
    {code: 'MX', label: 'Mexique', phone: '52'},
    {code: 'MY', label: 'Malaisie', phone: '60'},
    {code: 'MZ', label: 'Mozambique', phone: '258'},
    {code: 'NA', label: 'Namibie', phone: '264'},
    {code: 'NC', label: 'Nouvelle Calédonie', phone: '687'},
    {code: 'NE', label: 'Niger', phone: '227'},
    {code: 'NF', label: "l'ile de Norfolk", phone: '672'},
    {code: 'NG', label: 'Nigeria', phone: '234'},
    {code: 'NI', label: 'Nicaragua', phone: '505'},
    {code: 'NL', label: 'Hollande', phone: '31'},
    {code: 'NO', label: 'Norvège', phone: '47'},
    {code: 'NP', label: 'Nepal', phone: '977'},
    {code: 'NR', label: 'Nauru', phone: '674'},
    {code: 'NU', label: 'Niué', phone: '683'},
    {code: 'NZ', label: 'Nouvelle Zélande', phone: '64'},
    {code: 'OM', label: 'Oman', phone: '968'},
    {code: 'PA', label: 'Panama', phone: '507'},
    {code: 'PE', label: 'Pérou', phone: '51'},
    {code: 'PF', label: 'Polynésie Française', phone: '689'},
    {code: 'PG', label: 'Papouasie Nouvelle Guinée', phone: '675'},
    {code: 'PH', label: 'Philippines', phone: '63'},
    {code: 'PK', label: 'Pakistan', phone: '92'},
    {code: 'PL', label: 'Pologne', phone: '48'},
    {
        code: 'PM',
        label: 'Saint Pierre et Miquelon',
        phone: '508',
    },
    {code: 'PN', label: 'Îles Pitcairn', phone: '870'},
    {code: 'PR', label: 'Porto Rico', phone: '1'},
    {
        code: 'PS',
        label: 'Palestine',
        phone: '970',
    },
    {code: 'PT', label: 'Portugal', phone: '351'},
    {code: 'PW', label: 'Palaos', phone: '680'},
    {code: 'PY', label: 'Paraguay', phone: '595'},
    {code: 'QA', label: 'Qatar', phone: '974'},
    {code: 'RE', label: 'Réunion', phone: '262'},
    {code: 'RO', label: 'Roumanie', phone: '40'},
    {code: 'RS', label: 'Serbie', phone: '381'},
    {code: 'RU', label: 'Russie', phone: '7'},
    {code: 'RW', label: 'Rwanda', phone: '250'},
    {code: 'SA', label: 'Arabie Saoudite', phone: '966'},
    {code: 'SB', label: 'Les îles Salomon', phone: '677'},
    {code: 'SC', label: 'Seychelles', phone: '248'},
    {code: 'SD', label: 'Soudan', phone: '249'},
    {code: 'SE', label: 'Suède', phone: '46'},
    {code: 'SG', label: 'Singapour', phone: '65'},
    {code: 'SH', label: 'Sainte Helène', phone: '290'},
    {code: 'SI', label: 'Slovénie', phone: '386'},
    {
        code: 'SJ',
        label: 'Île, Svalbard et Jan Mayen',
        phone: '47',
    },
    {code: 'SK', label: 'Slovaquie', phone: '421'},
    {code: 'SL', label: 'Sierra Leone', phone: '232'},
    {code: 'SM', label: 'Saint Marin', phone: '378'},
    {code: 'SN', label: 'Sénégal', phone: '221'},
    {code: 'SO', label: 'Somalie', phone: '252'},
    {code: 'SR', label: 'Surinam', phone: '597'},
    {code: 'SS', label: 'Sud Soudan', phone: '211'},
    {
        code: 'ST',
        label: 'Sao Tomé et Principe',
        phone: '239',
    },
    {code: 'SV', label: 'El Salvador', phone: '503'},
    {
        code: 'SX',
        label: 'Saint Martin (Partie Néerlandaise)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrie',
        phone: '963',
    },
    {code: 'SZ', label: 'Swaziland', phone: '268'},
    {
        code: 'TC',
        label: 'Îles Turques-et-Caïques',
        phone: '1-649',
    },
    {code: 'TD', label: 'Tchad', phone: '235'},
    {
        code: 'TF',
        label: 'Terres australes françaises',
        phone: '262',
    },
    {code: 'TG', label: 'Togo', phone: '228'},
    {code: 'TH', label: 'Thaïlande', phone: '66'},
    {code: 'TJ', label: 'Tadjikistan', phone: '992'},
    {code: 'TK', label: 'Tokelau', phone: '690'},
    {code: 'TL', label: 'Timor oriental', phone: '670'},
    {code: 'TM', label: 'Turkménistan', phone: '993'},
    {code: 'TN', label: 'Tunisie', phone: '216'},
    {code: 'TO', label: 'Tonga', phone: '676'},
    {code: 'TR', label: 'Turquie', phone: '90'},
    {
        code: 'TT',
        label: 'Trinidad et Tobago',
        phone: '1-868',
    },
    {code: 'TV', label: 'Tuvalu', phone: '688'},
    {
        code: 'TW',
        label: 'Taïwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'Tanzanie',
        phone: '255',
    },
    {code: 'UA', label: 'Ukraine', phone: '380'},
    {code: 'UG', label: 'Ouganda', phone: '256'},
    {
        code: 'US',
        label: 'Etats-Unis',
        phone: '1',
        suggested: true,
    },
    {code: 'UY', label: 'Uruguay', phone: '598'},
    {code: 'UZ', label: 'Ouzbékistan', phone: '998'},
    {
        code: 'VA',
        label: 'Vatican',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent et les Grenadines',
        phone: '1-784',
    },
    {code: 'VE', label: 'Venezuela', phone: '58'},
    {
        code: 'VG',
        label: 'Îles Vierges Britanniques',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'Îles Vierges Américaines',
        phone: '1-340',
    },
    {code: 'VN', label: 'Vietnam', phone: '84'},
    {code: 'VU', label: 'Vanuatu', phone: '678'},
    {code: 'WF', label: 'Wallis et Futuna', phone: '681'},
    {code: 'WS', label: 'Samoa', phone: '685'},
    {code: 'XK', label: 'Kosovo', phone: '383'},
    {code: 'YE', label: 'Yemen', phone: '967'},
    {code: 'YT', label: 'Mayotte', phone: '262'},
    {code: 'ZA', label: 'Afrique du Sud', phone: '27'},
    {code: 'ZM', label: 'Zambie', phone: '260'},
    {code: 'ZW', label: 'Zimbabwe', phone: '263'},
];