import * as React from 'react';
import {createTheme} from "@mui/material/styles";
import Container from '@mui/material/Container';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from "@mui/material/useMediaQuery";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Location from "../../Components/Location";
import {FormHelperText} from "@mui/material";
import BootstrapDialog from "../../Components/BootstrapDialog";
import BootstrapDialogTitle from "../../Components/BootstrapDialogTitle";
import Waiting from "../../Components/Waiting";
import MercureContainedButton from "../../Components/MercureContainedButton";
import Currency from "../../Components/Currency";
import Insee from "../../Components/Insee";
import validator from "validator";

export default function AddInvoice(props) {
    const theme = createTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currency, setCurrency] = React.useState('€');
    const [sender, setSender] = React.useState(null);
    const [clientName, setClientName] = React.useState('');
    const [clientSiret, setClientSiret] = React.useState('');
    const [clientSiren, setClientSiren] = React.useState('');
    const [clientAddress, setClientAddress] = React.useState('');
    const [clientEmail, setClientEmail] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [month, setMonth] = React.useState(dayjs(new Date()));
    const [year, setYear] = React.useState(dayjs(new Date()));
    const [endPaymentDate, setEndPaymentDate] = React.useState(null);
    const [creationDate, setCreationDate] = React.useState(dayjs(new Date()));
    const [price, setPrice] = React.useState(null);
    const [total, setTotal] = React.useState(null);
    const [subTotal, setSubTotal] = React.useState(null);
    const [quantity, setQuantity] = React.useState(1);
    const [vatPercent, setVatPercent] = React.useState(null);
    const [paymentMode, setPaymentMode] = React.useState(4);
    const [city, setCity] = React.useState(null);
    const [contract, setContract] = React.useState('');
    const [waitingLoading, setWaitingLoading] = React.useState(false);
    const [monthErrorMessage, setMonthErrorMessage] = React.useState('');
    const [yearErrorMessage, setEndDateErrorMessage] = React.useState('');
    const [creationDateErrorMessage, setCreationDateErrorMessage] = React.useState('');
    const [paymentDateErrorMessage, setEndPaymentDateErrorMessage] = React.useState('');
    const [priceErrorMessage, setPriceErrorMessage] = React.useState('');
    const [descriptionErrorMessage, setDescriptionErrorMessage] = React.useState('');
    const [cityErrorMessage, setCityErrorMessage] = React.useState('');
    const [paymentModeErrorMessage, setPaymentModeErrorMessage] = React.useState('');
    const requiredField = 'Ce champ est requis';
    const DESCRIPTION_CHARACTER_LIMIT = 1024;
    const [clientAddressErrorMessage, setClientAddressErrorMessage] = React.useState('');
    const [quantityErrorMessage, setQuantityErrorMessage] = React.useState('');
    const [senderErrorMessage, setSenderErrorMessage] = React.useState('');
    const [clientNameErrorMessage, setClientNameErrorMessage] = React.useState('');
    const [vatPercentErrorMessage, setVatPercentErrorMessage] = React.useState('');
    const [clientEmailErrorMessage, setClientEmailErrorMessage] = React.useState('');

    React.useEffect(() => {
        let newSubTotal = null;
        let newTotal = null;

        newSubTotal = quantity * price;

        if (vatPercent > 0) {
            newTotal = (newSubTotal * (vatPercent / 100)) + newSubTotal;
        } else {
            newTotal = newSubTotal;
        }

        setSubTotal(newSubTotal);
        setTotal(newTotal);
    }, [price, quantity, vatPercent]);

    const onMonthChange = (event, value) => {
        if (value === null) {
            setMonthErrorMessage(requiredField);
            return;
        }

        setMonthErrorMessage('');
        setMonth(dayjs(value.toLocaleString()));
    }

    const onYearChange = (event, value) => {
        if (value === null) {
            setEndDateErrorMessage(requiredField);
            return;
        }

        setEndDateErrorMessage('');
        setYear(dayjs(value.toLocaleString()));
    }

    const onEndPaymentDateChange = (event, value) => {
        if (value === null) {
            setEndPaymentDateErrorMessage(requiredField);
            return;
        }

        setEndPaymentDateErrorMessage('');
        setEndPaymentDate(dayjs(value.toLocaleString()));
    }

    const onCreationDateChange = (event, value) => {
        if (value === null) {
            setCreationDateErrorMessage(requiredField);
            return;
        }

        setCreationDateErrorMessage('');
        setCreationDate(dayjs(value.toLocaleString()));
    }

    const onDescriptionChange = (event) => {
        if (event.target.value !== null) {
            setDescriptionErrorMessage('');
        } else {
            setDescriptionErrorMessage(requiredField);
        }

        setDescription(event.target.value);
    }

    const onClientAddressChange = (event) => {
        if (event.target.value !== null) {
            setClientAddressErrorMessage('');
        } else {
            setClientAddressErrorMessage(requiredField);
        }

        setClientAddress(event.target.value);
    }

    const onClientEmailChange = (e) => {
        let email = e.target.value;

        if (email != '') {
            if (validator.isEmail(email)) {
                setClientEmailErrorMessage("");
            } else {
                setClientEmailErrorMessage("L'adresse e-mail saisie est invalide");
            }
        } else {
            setClientEmailErrorMessage("");
        }

        setClientEmail(email);
    };

    const onPriceChange = (event) => {
        if (event.target.value !== null) {
            setPriceErrorMessage('');
        } else {
            setPriceErrorMessage(requiredField);
        }

        setPrice(event.target.value);
    }
    const onContractNameChange = (event) => {
        setContract(event.target.value);
    }

    const onQuantityChange = (event) => {
        setQuantity(event.target.value);

        if (event.target.value == 0 || event.target.value < 0) {
            props.handleQuantityError();
        } else {
            setQuantityErrorMessage('');
        }
    }

    const onVatPercentChange = (event) => {
        setVatPercent(event.target.value);

        if (event.target.value < 0) {
            props.handleVatError();
            setVatPercentErrorMessage("La TVA est erronée.");
        } else {
            setVatPercentErrorMessage('');
        }
    }

    const onPaymentModeChange = (event, value) => {
        if (event.target.value) {
            setPaymentModeErrorMessage('');
            setPaymentMode(event.target.value);
            return;
        }

        setPaymentModeErrorMessage(requiredField);
    }

    const onCityChange = (event, value) => {
        if (value === null) {
            setCityErrorMessage(requiredField);
        } else {
            setCityErrorMessage('');
        }

        setCity(value.description);
    }
    const onCurrencyChange = (event, value) => {
        setCurrency(value.label);
    }

    async function saveInvoice() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        if (sender === null) {
            setSenderErrorMessage(requiredField);
            return;
        }

        if (clientName === undefined) {
            setClientNameErrorMessage(requiredField);
            return;
        }
        //console.log('clientAddress', clientAddress)
        if (clientAddress === '') {
            setClientAddressErrorMessage(requiredField);
            return;
        }

        if (month === null) {
            setMonthErrorMessage(requiredField);
            return;
        }

        if (year === null) {
            setEndDateErrorMessage(requiredField);
            return;
        }

        if (description === '') {
            setDescriptionErrorMessage(requiredField);
            return;
        }

        if (quantity == 0 || quantity < 0) {
            setQuantityErrorMessage(requiredField);
            return;
        }

        if (price === null) {
            setPriceErrorMessage(requiredField);
            return;
        }

        if (vatPercent < 0) {
            setVatPercentErrorMessage("La TVA est erronée.");
            return;
        }

        if (endPaymentDate === null) {
            setEndPaymentDateErrorMessage(requiredField);
            return;
        }

        if (paymentMode === '') {
            setPaymentModeErrorMessage(requiredField);
            return;
        }

        if (city === null) {
            //setCityErrorMessage(requiredField);
            //return;
        }

        if (creationDate === null) {
            setCreationDateErrorMessage(requiredField);
            return;
        }

        // Loading
        setWaitingLoading(true);

        let data = {};
        data.idUser = user.id;
        data.idCorporation = sender.id;
        data.clientName = clientName;
        data.clientAddress = clientAddress;
        data.clientEmail = clientEmail;
        data.month = month && dayjs(month.toLocaleString()).format('MMMM');
        data.year = year && dayjs(year.toLocaleString()).format('YYYY');
        data.endPaymentDate = endPaymentDate && dayjs(endPaymentDate.toLocaleString()).format('YYYY-MM-DD');
        data.creationDate = dayjs(creationDate.toLocaleString()).format('YYYY-MM-DD');
        data.price = price;
        data.quantity = quantity;
        data.vatPercent = vatPercent;
        data.total = total;
        data.subTotal = subTotal;
        data.currency = currency;
        data.description = description;
        data.paymentMode = paymentMode;
        data.contract = contract;
        data.clientSiret = clientSiret;
        data.clientSiren = clientSiren;
        data.city = city;
        //console.log('data', user);return

        await fetch(process.env.REACT_APP_API_HOST + '/invoice/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    localStorage.setItem('invoices', JSON.stringify(response));

                    // Hide dialog
                    props.onClose();

                    // Hide waiting
                    setWaitingLoading(false);

                    // Refresh
                    props.refreshData(sender, data.year);
                })
            } else {
                console.log(result);
                setWaitingLoading(false);

                props.handleMessage("Votre facture n'a pas pu être créée suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);
            setWaitingLoading(false);

            props.handleMessage("Votre facture n'a pas pu être créée suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    const handleCloseWaiting = () => {
        setWaitingLoading(false);
    };

    const setInseeAddress = (value) => {
        if (value) {
            setClientAddressErrorMessage('');
        }
        setClientAddress(value);
    }

    const setInseeName = (value) => {
        setClientName(value);
    }

    const setInseeSiret = (value) => {
        setClientSiret(value);
    }

    const setInseeSiren = (value) => {
        setClientSiren(value);
    }

    const setInseeNameErrorHandler = (value) => {
        setClientNameErrorMessage(value);
    }

    const onSenderChange = (event, value) => {
        if (value) {
            setSenderErrorMessage('');
        }
        setSender(value);
    }

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Créer une facture
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Container component="" maxWidth="xs" tabIndex={-1}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Autocomplete required
                                              options={props.options}
                                              label="Emetteur"
                                              onChange={(event, newValue) => {
                                                  onSenderChange(event, newValue);
                                              }}
                                              getOptionLabel={(option) => option !== undefined && option.name !== undefined ? option.name : ''}
                                              renderOption={(props, option) => {
                                                  return (
                                                      <li {...props} key={option.id}>
                                                          <Grid container alignItems="center">
                                                              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                                  <Box component="span" sx={{ fontWeight: option.highlight ? 'bold' : 'regular' }}>
                                                                      {option.name}
                                                                  </Box>
                                                              </Grid>
                                                          </Grid>
                                                      </li>
                                                  );
                                              }}
                                              renderInput={(params) => (
                                                  <TextField
                                                      required
                                                      {...params}
                                                      label="Emetteur"
                                                      fullWidth
                                                      error={senderErrorMessage !== ""}
                                                      helperText={senderErrorMessage}
                                                  />
                                              )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Insee label="Destinataire"
                                       required="true"
                                       address={setInseeAddress}
                                       name={setInseeName}
                                       siret={setInseeSiret}
                                       siren={setInseeSiren}
                                       error={clientNameErrorMessage !== ""}
                                       helperText={setInseeNameErrorHandler}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="Adresse du destinataire"
                                    id="clientAddress"
                                    fullWidth
                                    value={clientAddress}
                                    onChange={onClientAddressChange}
                                    error={clientAddressErrorMessage !== ""}
                                    helperText= {clientAddressErrorMessage}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email du destinataire"
                                    id="clientEmail"
                                    fullWidth
                                    value={clientEmail}
                                    onChange={onClientEmailChange}
                                    error={clientEmailErrorMessage !== ""}
                                    helperText= {clientEmailErrorMessage}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        required
                                        label="Mois concerné"
                                        id="month"
                                        inputFormat="MMMM"
                                        value={month}
                                        view='month'
                                        onChange={(newValue) => {
                                            onMonthChange(null, newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth required error={monthErrorMessage !== ""}
                                                                            helperText= {monthErrorMessage}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        required
                                        label="Année concernée"
                                        id="year"
                                        inputFormat="YYYY"
                                        value={year}
                                        view='year'
                                        onChange={(newValue) => {
                                            onYearChange(null, newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth required error={yearErrorMessage !== ""}
                                                                            helperText= {yearErrorMessage}/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="Description"
                                    id="description"
                                    fullWidth
                                    rows={5}
                                    multiline
                                    value={description}
                                    onChange={onDescriptionChange}
                                    inputProps={{
                                        maxLength: DESCRIPTION_CHARACTER_LIMIT
                                    }}
                                    error={descriptionErrorMessage !== ""}
                                    helperText={`${description.length}/${DESCRIPTION_CHARACTER_LIMIT} ${descriptionErrorMessage}`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Currency value={currency} onChange={onCurrencyChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="N° de contrat"
                                    id="contract"
                                    fullWidth
                                    value={contract}
                                    onChange={onContractNameChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    label="Quantité"
                                    id="quantity"
                                    fullWidth
                                    type="number"
                                    value={quantity}
                                    onChange={onQuantityChange}
                                    error={quantityErrorMessage !== ""}
                                    helperText= {quantityErrorMessage}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    label="Prix Unitaire HT"
                                    id="price"
                                    fullWidth
                                    type="number"
                                    value={price}
                                    onChange={onPriceChange}
                                    error={priceErrorMessage !== ""}
                                    helperText= {priceErrorMessage}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="TVA"
                                    id="vatPercent"
                                    fullWidth
                                    type="number"
                                    value={vatPercent}
                                    onChange={onVatPercentChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    error={vatPercentErrorMessage !== ''}
                                    helperText={vatPercentErrorMessage}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Sous-total"
                                    id="subTotal"
                                    disabled
                                    fullWidth
                                    type="number"
                                    value={subTotal}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Total"
                                    id="total"
                                    disabled
                                    fullWidth
                                    type="number"
                                    value={total}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        label="Date limite de règlement"
                                        id="endPaymentDate"
                                        inputFormat="DD/MM/YYYY"
                                        value={endPaymentDate}
                                        onChange={(newValue) => {
                                            onEndPaymentDateChange(null, newValue)
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                       fullWidth
                                                       required
                                                       error={paymentDateErrorMessage !== ""}
                                                       helperText= {paymentDateErrorMessage}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth required error={paymentModeErrorMessage !== ''}>
                                    <InputLabel id="paymentMode">Mode de réglement</InputLabel>
                                    <Select
                                        labelId="paymentMode"
                                        id="paymentMode"
                                        label="Mode de réglement"
                                        value={paymentMode}
                                        onChange={(event, newValue) => onPaymentModeChange(event, newValue)}
                                    >
                                        <MenuItem value={1}>Carte bancaire</MenuItem>
                                        <MenuItem value={2}>Chèque</MenuItem>
                                        <MenuItem value={3}>Espèces</MenuItem>
                                        <MenuItem value={4}>Virement bancaire</MenuItem>
                                        <MenuItem value={5}>Prélèvement</MenuItem>
                                        <MenuItem value={6}>Autre</MenuItem>
                                    </Select>
                                    <FormHelperText>{paymentModeErrorMessage}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Location label="Fait à"
                                          required="required"
                                          value={city}
                                          onChange={(event, newValue) => onCityChange(event, newValue)}
                                          noOptionsText="Aucune ville saisie"
                                          error={cityErrorMessage !== ""}
                                          helperText= {cityErrorMessage}
                                          />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                    <DatePicker
                                        label="Le"
                                        required
                                        id="creationDate"
                                        inputFormat="DD/MM/YYYY"
                                        value={creationDate}
                                        onChange={(newValue) => {
                                            onCreationDateChange(null, newValue)
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                       fullWidth
                                                       required
                                                       error={creationDateErrorMessage !== ""}
                                                       helperText= {creationDateErrorMessage}
                                            />
                                    }
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <MercureContainedButton
                            label="Valider"
                            onClick={saveInvoice}
                            disabled={waitingLoading} />
                    </Box>
                </DialogActions>
            </BootstrapDialog>
            {waitingLoading && (
                <Waiting loading={waitingLoading} title="Sauvegarde" message="Merci de patienter, nous sauvegardons votre document..."/>
            )}
        </div>
    )
}