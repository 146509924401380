import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useNavigate} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import INTER_ONE from "./Images/inter-1.jpg";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme();

export default function SignUp() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [token, setToken] = React.useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordLevelMessage, setPasswordLevelMessage] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const [capColor, setCapColor] = React.useState("#FF0000");
    const [lowColor, setLowColor] = React.useState("#FF0000");
    const [numberColor, setNumberColor] = React.useState("#FF0000");
    const [lengthColor, setLengthColor] = React.useState("#FF0000");
    const [specialCharacterColor, setSpecialCharacterColor] = React.useState("#FF0000");
    const [showProgressBar, setShowProgressBar] = React.useState(false);
    const [activeColor, setActiveColor] = React.useState({ color: 'inherit' });

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (event) => {
        // Disable button
        setLoading(true);

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        let data = {};
        data.email = formData.get('email');
        data.password = formData.get('password');
        data.token = token;
        //console.log('data', data);return

        fetch(process.env.REACT_APP_API_HOST + '/user/sign-up', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            //console.log('result', result);
            // Enabling button
            setLoading(false);

            if (result.status === 201) {
                result.json().then(response => {
                    //console.log('response', response);
                    localStorage.setItem('userId', response.id);
                    navigate('/confirm');
                })
            } else if (result.status === 409 || result.status === 403) {
                result.json().then(response => {
                    setMessage(response.error);
                    handleClick();
                })
            } else {
                result.json().then(response => {
                    console.log('else', response);
                })
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setMessage("Une erreur s'est produite lors de la création de votre compte.");
            handleClick();
        });

        setRefreshReCaptcha(r => !r);
    };

    const handleVerify = React.useCallback((token) => {
        setToken(token);
    });

    const handlePassword = (passwordValue) => {
        const strengthChecks = {
            length: 0,
            hasUpperCase: false,
            hasLowerCase: false,
            hasDigit: false,
            hasSpecialChar: false,
        };

        strengthChecks.length = passwordValue.length >= 8;
        strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
        strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
        strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
        strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

        if (true === strengthChecks.hasUpperCase) {
            setCapColor("#047C0D");
        } else {
            setCapColor("#FF0000");
        }

        if (true === strengthChecks.hasLowerCase) {
            setLowColor("#047C0D");
        } else {
            setLowColor("#FF0000");
        }

        if (true === strengthChecks.length) {
            setLengthColor("#047C0D");
        } else {
            setLengthColor("#FF0000");
        }

        if (true === strengthChecks.hasDigit) {
            setNumberColor("#047C0D");
        } else {
            setNumberColor("#FF0000");
        }

        if (true === strengthChecks.hasSpecialChar) {
            setSpecialCharacterColor("#047C0D");
        } else {
            setSpecialCharacterColor("#FF0000");
        }

        let verifiedList = Object.values(strengthChecks).filter((value) => value);
        let strength =
            verifiedList.length === 5
                ? "Fort"
                : verifiedList.length >= 2
                    ? "Moyen"
                    : "Faible";

        setPassword(passwordValue);
        setProgress((verifiedList.length / 5) * 100);

        if (passwordValue) {
            setPasswordLevelMessage(strength);
            setShowProgressBar(true);
        } else {
            setPasswordLevelMessage('');
            setShowProgressBar(false);
        }
    };

    const toSignIn = () => {
        navigate('/sign-in');
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{height: '100vh'}}>
                <CssBaseline/>

                <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            ml: 2,
                            mr: 2
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <AccountCircleIcon/>
                        </Avatar>

                        <Typography component="h1" variant="h5">
                            Créer un compte
                        </Typography>

                        <Grid component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        autoFocus
                                        fullWidth
                                        id="email"
                                        label="E-mail"
                                        name="email"
                                        autoComplete="email"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Mot de passe"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(event) => {
                                            handlePassword(event.target.value);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <LinearProgress variant="determinate" value={progress} color="inherit"/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 13, fontWeight: "bold"}}>
                                        Votre mot de passe doit contenir au moins :
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={capColor}>
                                        - Une majuscule
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={lowColor}>
                                        - Une minuscule
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={specialCharacterColor}>
                                        - Un caractère spécial
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={numberColor}>
                                        - Un chiffre
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" sx={{mr: 3, ml: 3, fontSize: 12}} color={lengthColor}>
                                        - 8 caractères
                                    </Typography>
                                </Grid>
                            </Grid>

                            <GoogleReCaptcha onVerify={handleVerify} refreshReCaptcha={refreshReCaptcha}/>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                color="secondary"
                                size="large"
                                disabled={loading}
                            >
                                Créer
                            </Button>

                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link onClick={toSignIn} variant="body2">
                                        Déjà un compte ? Se connecter
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={false}
                      sm={4}
                      md={8}
                      sx={{
                          backgroundImage: `url(${INTER_ONE})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          height: "100vh"
                      }}
                />
            </Grid>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress />
            </Backdrop>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
}