import {createTheme} from "@mui/material/styles";
import MercureTheme from "../Components/MercureTheme";

const DocumentTheme = createTheme(MercureTheme,{
    components: {
        MuiButton: {
            defaultProps: {
                color: 'success'
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: ({ theme }) =>
                    theme.unstable_sx({
                        bgcolor: theme.palette.success.main,
                    }),
            },
        },
        MuiAppBar: {
            defaultProps: {
                color: 'success'
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ theme }) =>
                    theme.unstable_sx({
                        bgcolor: theme.palette.success.main,
                        color: theme.palette.common.white
                    }),
            },
        },
        MuiIconButton: {
            defaultProps: {
                color: 'success'
            },
        },
        MuiBadge: {
            defaultProps: {
                color: 'success'
            },
        }
    }
});

export default DocumentTheme;