import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function Currency(props) {
    return (
        <Autocomplete
            id={props.id}
            options={currencies}
            autoComplete
            noOptionsText="Sélectionner une monnaie"
            value={props.value}
            onChange={(event, newValue) => {
                props.onChange(event, newValue)
            }}
            renderOption={(props, option) => (
                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                    {option.label}
                </Box>
            )}
            renderInput={(params) => <TextField {...params} label="Monnaie"
                                                fullWidth/>}
        />
    );
}

const currencies = [
    {label: '€', value: 1},
    {label: '£', value: 2},
    {label: '$', value: 3},
    {label: '$CA', value: 4},
    {label: 'CFA', value: 5},
    {label: '¥', value: 6},
    {label: '₽', value: 7},
    {label: 'DKK', value: 8},
    {label: 'SEK', value: 9},
    {label: '₹', value: 10},
    {label: '₪', value: 11},
];