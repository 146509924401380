import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

export default function CardList(props) {
    React.useEffect(() => {

    }, [props]);

    return (
        <Card fullWidth sx={{ mt: 2 }}>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={props.handleToggleAll(props.items)}
                        checked={props.handleHeaderCheck(props.items)}
                        indeterminate={
                            props.indeterminate(props.items)
                        }
                        disabled={props.items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={props.title}
                subheader={`${props.headerCheckedNumber(props.items)}/${props.items.length} sélectionné.s`}
            />
            <Divider />
            <List
                sx={{
                    height: 300,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {props.items && props.items.map((value) => {
                    const labelId= `transfer-list-all-item-${value.id}-label`;

                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            onClick={props.handleToggle(value.id)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={props.handleCheck(value.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.firstName} ${value.lastName}`} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    )
}