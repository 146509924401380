import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Location from "../../Components/Location";
import FormLabel from "@mui/material/FormLabel";

export default function PossessionCorporation(props) {
    const {
        // Data
        attic,
        veranda,
        balcony,
        washRoom,
        cellar,
        garden,
        parking,
        court,
        pool,
        jacuzzi,
        entrance,
        clearance,
        dressing,
        garage,
        city,
        creationDate,

        // Error
        creationDateErrorMessage,
        cityErrorMessage
    } = props.data;

    return (
        <div>
            <Box component="form" noValidate>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <FormLabel id="lastRentalOption">Nombre de dépendances à usage privatif</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Grenier"
                            type="number"
                            name="attic"
                            value={attic}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Veranda"
                            type="number"
                            name="veranda"
                            value={veranda}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Balcon"
                            type="number"
                            name="balcony"
                            value={balcony}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Buanderie"
                            type="number"
                            name="washRoom"
                            value={washRoom}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Cave"
                            type="number"
                            name="cellar"
                            value={cellar}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Jardin"
                            type="number"
                            name="garden"
                            value={garden}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Parking"
                            type="number"
                            name="parking"
                            value={parking}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Cour"
                            type="number"
                            name="court"
                            value={court}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Piscine"
                            type="number"
                            name="pool"
                            value={pool}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Jacuzzi"
                            type="number"
                            name="jacuzzi"
                            value={jacuzzi}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Entrée"
                            type="number"
                            name="entrance"
                            value={entrance}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Dégagement"
                            type="number"
                            name="clearance"
                            value={clearance}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Dressing"
                            type="number"
                            name="dressing"
                            value={dressing}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Garage"
                            type="number"
                            name="garage"
                            value={garage}
                            onChange={(event) => props.onPossessionChange(event)}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Location label="Fait à"
                                  required="required"
                                  id="city"
                                  value={city}
                                  onChange={(event, newValue) => props.onPossessionChange(null, newValue, 'city')}
                                  noOptionsText="Aucune ville saisie"
                                  error={cityErrorMessage !== ""}
                                  helperText= {cityErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Le"
                                required
                                id="creationDate"
                                inputFormat="DD/MM/YYYY"
                                value={creationDate}
                                onChange={(newValue) => {
                                    props.onPossessionChange(null, newValue, 'creationDate')
                                }}
                                renderInput={(params) => <TextField {...params}
                                                                    fullWidth
                                                                    required
                                                                    error={creationDateErrorMessage !== ""}
                                                                    helperText={creationDateErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

            </Box>
        </div>
    )
}