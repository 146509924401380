import * as React from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import 'dayjs/locale/fr';
import InputAdornment from '@mui/material/InputAdornment';

export default function Rental(props) {
    let [housings, setHousings] = React.useState([]);

    const {
        housing,
        rentalAmount,
        chargesAmount,
        apl,
        signDate,
        startRentalDate,
        paymentDay
    } = props.data;

    React.useEffect(() => {
        localStorage.removeItem('housings');
        getHousingsByIdUser();

    }, []);

    function getHousingsByIdUser() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        fetch(process.env.REACT_APP_API_HOST + '/housing/get/user/id/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousings(response);
                    localStorage.setItem('housings', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <Box component="form" noValidate sx={{mt: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        id="housing"
                        noOptionsText="Sélectionner le bien loué par le locataire"
                        onChange={(event, newValue) => {
                            {props.onRentalChange(event, newValue)}
                        }}
                        value={housing && housing.name ? housing.name : ""}
                        isOptionEqualToValue={(option, value) =>
                            value === undefined || value === "" || option.name === value
                        }
                        options={housings}
                        autoHighlight
                        getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{mr: 2, flexShrink: 0}} {...props}>
                                {option.name}
                            </Box>
                        )}
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Bien loué par le locataire"
                                       InputProps={{
                                           ...params.InputProps,
                                           type: 'new-password',
                                       }}
                                       fullWidth/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <DatePicker
                            label="Date de signature du contrat"
                            value={signDate}
                            id="signDate"
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                                props.onRentalChange(newValue, "signDate");
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth/>}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <DatePicker
                            label="Date de début de location"
                            value={startRentalDate}
                            id="startRentalDate"
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                                props.onRentalChange(newValue, "startRentalDate");
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth/>}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="paymentDay"
                        label="Jour d'encaissement de loyer"
                        value={paymentDay}
                        type="number"
                        onChange={props.onRentalChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Le </InputAdornment>,
                            endAdornment: <InputAdornment position="end"> du mois</InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="rentalAmount"
                        label="Montant du loyer hors charges"
                        type="number"
                        value={rentalAmount}
                        onChange={props.onRentalChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="chargesAmount"
                        value={chargesAmount}
                        label="Montant des charges"
                        type="number"
                        onChange={props.onRentalChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="apl"
                        value={apl}
                        label="Montant de l'aide au logement"
                        type="number"
                        onChange={props.onRentalChange}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}