import * as React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Manager from "./Manager";
import ColorlibConnector from "../../Components/ColorlibConnector";
import ColorlibStepIcon from "../../Components/ColorlibStepIcon";
import Lease from "./Lease";
import validator from "validator";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";

function getStepContent(step, identity, lease, onLeaseChange, onIdentityChange, handleOption) {
    switch (step) {
        case 0:
            return <Lease data={lease} onLeaseChange={onLeaseChange} handleOption={handleOption}/>;
        case 1:
            return <Manager data={identity} onIdentityChange={onIdentityChange} />;
        default:
            throw new Error('Unknown step');
    }
}

const steps = ['Location', 'Gérant'];
let tenantCorporation = [];

export default function UpdateTenantCorporation(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const descriptionElementRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const [identity, setIdentity] = React.useState({
        civility: props.tenant ? props.tenant.civility : "",
        firstName: props.tenant ? props.tenant.firstName : "",
        lastName: props.tenant ? props.tenant.lastName : "",
        phonePrefix: props.tenant ? props.tenant.phonePrefix : 33,
        phonePrefixLabel: props.tenant ? props.tenant.phonePrefixLabel : "France",
        phoneCode: props.tenant ? props.tenant.phoneCode : "fr",
        phone: props.tenant ? props.tenant.phone : "",
        mobilePhonePrefix: props.tenant ? props.tenant.mobilePhonePrefix : 33,
        mobilePhonePrefixLabel: props.tenant ? props.tenant.mobilePhonePrefixLabel : "France",
        mobilePhoneCode: props.tenant ? props.tenant.mobilePhoneCode : "fr",
        mobilePhone: props.tenant ? props.tenant.mobilePhone : "",
        email: props.tenant ? props.tenant.email : "",
        emailErrorMessage: "",
        profession: props.tenant ? props.tenant.profession : "",
        address: props.tenant ? props.tenant.address : "",
    });

    const [lease, setLease] = React.useState({
        siren: props.tenant ? props.tenant.siren : "",
        activity: props.tenant ? props.tenant.activity : "",
        siret: props.tenant ? props.tenant.siret : "",
        name: props.tenant ? props.tenant.name : "",
        housing: props.tenant ? props.tenant.housing : null,
        type: props.tenant ? props.tenant.type : "",
        signDate: props.tenant && props.tenant.signDate ? props.tenant.signDate : null,
        startRentalDate: props.tenant && props.tenant.startRentalDate ? props.tenant.startRentalDate : null,
        paymentDay: props.tenant && props.tenant.paymentDay !== 0 ? props.tenant.paymentDay : "",
        options: [],
        option: null,
        hqAddress: props.tenant ? props.tenant.hqAddress : "",
        rcsCity: props.tenant ? props.tenant.rcsCity : ""
    });

    const onIdentityChange = (event, value, id) => {
        if (event) {
            switch (event.target.id) {
                case 'firstName':
                    setIdentity({
                        ...identity,
                        firstName: event.target.value
                    });
                    break;

                case 'lastName':
                    setIdentity({
                        ...identity,
                        lastName: event.target.value
                    });
                    break;

                case 'profession':
                    setIdentity({
                        ...identity,
                        profession: event.target.value
                    });
                    break;

                case 'phone':
                    setIdentity({
                        ...identity,
                        phone: event.target.value
                    });
                    break;

                case 'mobilePhone':
                    setIdentity({
                        ...identity,
                        mobilePhone: event.target.value
                    });
                    break;

                case 'declaration':
                    setIdentity({
                        ...identity,
                        declaration: value
                    });
                    break;

                case 'email':
                    let email = event.target.value;

                    if (email !== '') {
                        if (validator.isEmail(email)) {
                            setIdentity({
                                ...identity,
                                emailErrorMessage: "",
                                email: email
                            });
                        } else {
                            setIdentity({
                                ...identity,
                                emailErrorMessage: "L'adresse e-mail saisie est invalide",
                                email: email
                            });
                        }
                    } else {
                        setIdentity({
                            ...identity,
                            emailErrorMessage: "",
                            email: email
                        });
                    }
                    break;

                default:
                    if (event.target.name === 'civility') {
                        setIdentity({
                            ...identity,
                            civility: event.target.value
                        });
                    } else {
                        let ids = event.target.id.split('-');

                        if (ids[0] === 'phonePrefix') {
                            setIdentity({
                                ...identity,
                                phonePrefix: (value) ? value.phone : 33,
                                phonePrefixLabel: (value) ? value.label : 'France',
                                phoneCode: (value) ? value.code : 'fr'
                            });
                        } else {
                            setIdentity({
                                ...identity,
                                mobilePhonePrefix: (value) ? value.phone : 33,
                                mobilePhonePrefixLabel: (value) ? value.label : 'France',
                                mobilePhoneCode: (value) ? value.code : 'fr'
                            });
                        }
                    }

                    break;
            }
        } else {
            if (id === 'address') {
                setIdentity({
                    ...lease,
                    address: value.description
                });
            }
        }
    }

    const handleOption = (data) => {
        setLease({
            ...lease,
            option: data
        });
    }

    const onLeaseChange = (event, value, id) => {
        if (value !== undefined) {
            if (value === 'signDate') {
                setLease({
                    ...lease,
                    signDate: (event) ? dayjs(event.toLocaleString()).format('YYYY-MM-DD') : null
                });
            } else if (value === 'startRentalDate') {
                setLease({
                    ...lease,
                    startRentalDate: (event) ? dayjs(event.toLocaleString()).format('YYYY-MM-DD') : null
                });
            } else if (value === 'inputName' || value === 'valueName') {
                if (value === 'inputName') {
                    let data = {};
                    let uniteLegale = {}
                    uniteLegale.denominationUniteLegale = event.toUpperCase();
                    data.uniteLegale = uniteLegale;
                    setLease({
                        ...lease,
                        options: [],
                        name: event.toUpperCase(),
                        siret: '',
                        option: data
                    });
                }

                if (value === 'valueName') {
                    if (event) {
                        setLease({
                            ...lease,
                            siren: event.siren,
                            siret: event.siret,
                            option: event
                        });
                    } else {
                        setLease({
                            ...lease,
                            siren: '',
                            siret: ''
                        });
                    }
                }
            } else if (value === 'options') {
                setLease({
                    ...lease,
                    options: event
                });
            } else {
                if (id === 'hqAddress') {
                    setLease({
                        ...lease,
                        hqAddress: value.description
                    });
                } else if (id === 'rcsCity') {
                    setLease({
                        ...lease,
                        rcsCity: value.description
                    });
                } else {
                    setLease({
                        ...lease,
                        housing: (value) ? value : null
                    });
                }
            }
        } else {
            if (event) {
                switch (event.target.id) {
                    case 'type':
                        setLease({
                            ...lease,
                            type: event.target.value.toUpperCase()
                        });
                        break;

                    case 'siren':
                        setLease({
                            ...lease,
                            siren: event.target.value
                        });
                        break;

                    case 'activity':
                        setLease({
                            ...lease,
                            activity: event.target.value
                        });
                        break;

                    case 'paymentDay':
                        setLease({
                            ...lease,
                            paymentDay: event.target.value
                        });
                        break;
                        
                    default:
                        break;
                }
            }
        }
    }

    const handleNext = () => {
        if (activeStep === 0) {
            // Lease
            let tempLease = {};
            for (let key in lease) {
                tempLease[key] = lease[key];
            }
            tenantCorporation['lease'] = tempLease;
            localStorage.setItem('lease', JSON.stringify(tenantCorporation['lease']));
        } else if (activeStep == 1) {
            // Identity
            let tempIdentity = {};
            for (let key in identity) {
                tempIdentity[key] = identity[key];
            }
            tenantCorporation['identity'] = tempIdentity;
            localStorage.setItem('manager', JSON.stringify(tenantCorporation['identity']));
        }

        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    async function updateTenant() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        // TODO required field
        let data = {};
        data.idUser = user.id;

        for (let key in tenantCorporation['lease']) {
            if (key === 'housing') {
                data.idHousing = tenantCorporation['lease'].housing.id;
                continue;
            } else if (key === 'option' || key === 'options' || key === 'siret') {
                continue;
            }

            data[key] = tenantCorporation['lease'][key];
        }

        for (let key in tenantCorporation['identity']) {
            if (key === 'emailErrorMessage') {
                continue;
            }

            data[key] = tenantCorporation['identity'][key];
        }
console.log(data);
        await fetch(process.env.REACT_APP_API_HOST + '/tenant/corporation/update/' + props.tenant.id, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 204) {
                localStorage.removeItem('lease');
                localStorage.removeItem('manager');
                
                props.tenantUpdated();
            }
        }).catch((error) => {
            console.log(error);
            props.tenantNotUpdated();
        });
    }

    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

    }, [open]);

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier un locataire
                </Typography>
            </Box>
            <Divider />
            <Container component="" maxWidth="xs" ref={descriptionElementRef} tabIndex={-1} sx={{ mb: 3 }}>
                <Stepper alternativeLabel
                         activeStep={activeStep}
                         sx={{ pt: 3, pb: 5 }}
                         connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography>
                            Vous êtes sur le point de modifier votre locataire. Cliquer sur le bouton Valider pour confirmer la création de votre locataire ou sur Précédent pour vérifier les données saisies.
                        </Typography>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {getStepContent(activeStep, identity, lease, onLeaseChange, onIdentityChange, handleOption)}
                    </React.Fragment>
                )}
            </Container>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                    <Button variant="outlined"
                            color="secondary"
                            onClick={handleBack} sx={{ mt: 3, ml: 1, mr: 1, mb: 3 }}>
                        Précédent
                    </Button>
                )}

                {activeStep === steps.length ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={updateTenant}
                        sx={{ mt: 3, ml: 1, mr: 3, mb: 3 }}
                    >
                        Valider
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1, mr: 3, mb: 3 }}
                    >
                        Suivant
                    </Button>
                )}
            </Box>
        </div>
    )
}