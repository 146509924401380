import * as React from 'react';
import {createTheme} from "@mui/material/styles";
import Container from '@mui/material/Container';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import Box from "@mui/material/Box";
import {REQUIRED_FIELD} from "../../Consts";
import Waiting from "../../Components/Waiting";
import MercureContainedButton from "../../Components/MercureContainedButton";
import Stepper from "@mui/material/Stepper";
import ColorlibConnector from "../../Components/ColorlibConnector";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ColorlibStepIcon from "../../Components/ColorlibStepIcon";
import Typography from "@mui/material/Typography";
import MercureOutlinedButton from "../../Components/MercureOutlinedButton";
import RentalCorporation from "./RentalCorporation";
import PossessionCorporation from "./PossessionCorporation";
import Divider from "@mui/material/Divider";

let contract = [];
const steps = ['Location', 'Bien'];

function getStepContent(step, rental, possession, tenants, onRentalChange, onPossessionChange) {
    switch (step) {
        case 0:
            return <RentalCorporation data={rental} tenants={tenants} onRentalChange={onRentalChange} />;
        case 1:
            return <PossessionCorporation data={possession} onPossessionChange={onPossessionChange} />;
        default:
            throw new Error('Unknown step');
    }
}

export default function UpdateContractCorporation(props) {
    const theme = createTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const descriptionElementRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [tenants, setTenants] = React.useState([]);

    const supportedChargesOptionsList = [
        { title: 'Frais de consommation de chauffage, eau, climatisation, électricité, et tous autres fluides', value: 1980 },
        { title: 'Abonnement et frais des fluides', value: 1981 },
        { title: "Les menues réparations portant sur l'immeuble et les équipements", value: 1982 },
        { title: "Les travaux d’amélioration, de modernisation, de réfection et de remplacement d'équipements", value: 1983 },
        { title: "Les travaux liés à la force majeure", value: 1984 },
        { title: "Les travaux liés aux vices cachés", value: 1985 },
        { title: "Les travaux de ravalement intégral", value: 1986 },
        { title: "La pose d'un compteur individuel d'eau, de gaz ou d'électricité", value: 1987},
        { title: "Les honoraires techniques et les frais d'études préalables", value: 1988 },
        { title: "Les honoraires des architectes, des bureaux d'étude, de contrôle, etc.", value: 1989 },
        { title: "L'ensemble des diagnostics, notamment environnementaux, performance énergétique", value: 1990 },
        { title: "Le renouvellement des réseaux communs", value: 1991},
        { title: "Les frais de nettoyage, d'entretien des locaux loués et de l'immeuble", value: 1992 },
        { title: "Les frais d'entretien et de maintenance ainsi que l’exploitation des appareils de production d’énergie, de chauffage et de climatisation", value: 1993 },
        { title: "Les frais d'entretien et de maintenance des appareils de levage (ascenseurs, monte-charges, etc.), des groupes électrogènes, des installations électriques, des canalisations, etc.", value: 1994},
        { title: "Les frais d'acquisition et de renouvellement de la décoration végétale s’il en existe ou de petit mobilier, ainsi que les frais d’entretien des espaces verts intérieurs et extérieurs, etc.", value: 1995 },
        { title: "Toutes les dépenses de fonctionnement des services généraux des Lieux Loués et de l’ensemble immobilier", value: 1996 },
        { title: "L'acquisition et le renouvellement du matériel et de l'outillage nécessaire à la gestion et à l'exploitation", value: 1997 },
        { title: "Les travaux divers de mise en conformité", value: 1998 },
        { title: "Les primes d'assurance et de responsabilité civile", value: 1999 },
        { title: "Les honoraires et frais de courtage", value: 2000 },
        { title: "L’impôt foncier", value: 2001 },
        { title: "Les autres taxes locales ou contributions", value: 2002 },
        { title: "Les taxes sur les bureaux, les locaux commerciaux, les locaux de stockage et les surfaces de stationnement applicables en Ile-de-France", value: 2003 },
        { title: "Tous impôts, taxes, redevances, contributions et charges assis ou à asseoir sur les locaux ou l’immeuble", value: 2004 },
        { title: "Les frais d'élimination et de tri des déchets communs", value: 2005 },
        { title: "Les frais de main-d’œuvre, salaires, charges sociales et fiscales du personnel affecté à l’immeuble ou à l'ensemble immobilier", value: 2006 },
        { title: "La rémunération des entreprises extérieures affectées à différentes tâches", value: 2007 },
        { title: "Les frais relatifs au service d'un standard téléphonique et de toutes autres installations de télécommunications", value: 2008 },
        { title: "La rémunération du ou des mandataires chargés de la gestion, de la maintenance et de l’administration des ensembles immobiliers et des Locaux Loués", value: 2009 },
        { title: "Les frais de diagnostics et contrôles de toute nature portant sur l’ensemble immobilier", value: 2010 },
        { title: "Les charges issues de l'existence d'une association syndicale, groupement ou autre", value: 2011 },
        { title: "Autres charges spécifiques non visées dans cette liste", value: 2012 },
    ];

    // Rental
    const [rental, setRental] = React.useState({
        // Data
        filter: [],
        rentalDuration: props.contract && props.contract.rentalDuration ? props.contract.rentalDuration : "",
        startRentalDate: props.contract && props.contract.startRentalDate ? props.contract.startRentalDate : null,
        rentalAmount: props.contract && props.contract.rentalAmount ? props.contract.rentalAmount : "",
        chargesAmount: props.contract && props.contract.chargesAmount ? props.contract.chargesAmount : "",
        guaranteeAmount: props.contract && props.contract.guaranteeAmount ? props.contract.guaranteeAmount : "",
        paymentFrequency: props.contract && props.contract.paymentFrequency ? props.contract.paymentFrequency : "",
        chargeMode: props.contract && props.contract.chargeMode ? props.contract.chargeMode : "",
        rentalTerm: props.contract && props.contract.rentalTerm ? props.contract.rentalTerm : "",
        paymentMode: props.contract && props.contract.paymentMode ? props.contract.paymentMode : "",
        cancellableContract: props.contract && props.contract.cancellableContract ? props.contract.cancellableContract : null,
        restrictedActivities: props.contract && props.contract.restrictedActivities ? props.contract.restrictedActivities : null,
        destinationType: props.contract && props.contract.destinationType ? props.contract.destinationType : '',
        activitiesNature: props.contract && props.contract.activitiesNature ? props.contract.activitiesNature : '',
        additionalActivities: props.contract && props.contract.additionalActivities ? props.contract.additionalActivities : null,
        rentSubjectToVat: props.contract && props.contract.rentSubjectToVat ? props.contract.rentSubjectToVat : null,
        franchise: props.contract && props.contract.franchise ? props.contract.franchise : null,
        franchiseEndDate: props.contract && props.contract.franchiseEndDate ? props.contract.franchiseEndDate : null,
        rentalRevision: props.contract && props.contract.rentalRevision ? props.contract.rentalRevision : '',
        rentalIndex: props.contract && props.contract.rentalIndex ? props.contract.rentalIndex : '',
        guaranteeRentalNumber: props.contract && props.contract.guaranteeRentalNumber ? props.contract.guaranteeRentalNumber : '',
        supportedCharges: props.contract && props.contract.supportedCharges ? props.contract.supportedCharges : '',
        supportedChargesOptions: [],
        otherSupportedCharges: props.contract && props.contract.otherSupportedCharges ? props.contract.otherSupportedCharges : '',
        showOtherSupportedChargesOptions: false,

        // Error
        tenantErrorMessage: "",
        startDateErrorMessage: '',
        chargeModeErrorMessage: '',
        rentalAmountErrorMessage: '',
        chargesAmountErrorMessage: '',
        guaranteeAmountErrorMessage: '',
        rentalTermErrorMessage: '',
        paymentModeErrorMessage: '',
        rentalDurationErrorMessage: '',
        cancellableContractErrorMessage: '',
        paymentFrequencyErrorMessage: '',
        franchiseEndDateErrorMessage: '',
        rentalRevisionErrorMessage: '',
        guaranteeRentalNumberErrorMessage: ''
    });

    const [possession, setPossession] = React.useState({
        // Data
        attic: props.contract && props.contract.attic ? props.contract.attic : 0,
        veranda: props.contract && props.contract.veranda ? props.contract.veranda : 0,
        balcony: props.contract && props.contract.balcony ? props.contract.balcony : 0,
        washRoom: props.contract && props.contract.washRoom ? props.contract.washRoom : 0,
        cellar: props.contract && props.contract.cellar ? props.contract.cellar : 0,
        garden: props.contract && props.contract.garden ? props.contract.garden : 0,
        parking: props.contract && props.contract.parking ? props.contract.parking : 0,
        court: props.contract && props.contract.court ? props.contract.court : 0,
        pool: props.contract && props.contract.pool ? props.contract.pool : 0,
        jacuzzi: props.contract && props.contract.jacuzzi ? props.contract.jacuzzi : 0,
        entrance: props.contract && props.contract.entrance ? props.contract.entrance : 0,
        clearance: props.contract && props.contract.clearance ? props.contract.clearance : 0,
        dressing: props.contract && props.contract.dressing ? props.contract.dressing : 0,
        garage: props.contract && props.contract.garage ? props.contract.garage : 0,
        city: props.contract && props.contract.city ? props.contract.city : null,
        creationDate: props.contract && props.contract.creationDate ? props.contract.creationDate : dayjs(new Date()).toLocaleString(),

        // Error
        creationDateErrorMessage: '',
        cityErrorMessage: ''
    });

    const getTenants = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/tenant/corporation/get/user/id/' + user.id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    setTenants(response);
                    localStorage.setItem('tenantsCorporation', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    React.useEffect(() => {
        const lodgers = JSON.parse(localStorage.getItem('tenantsCorporation'));

        if (null === lodgers) {
            getTenants();
        } else {
            setTenants(lodgers);
        }
    }, []);

    React.useEffect(() => {
        // Set filter
        let filters = [];
        let items = [];
        let found = false;

        tenants.forEach((tenant) => {
            if (tenant.id === props.contract.idTenant) {
                filters.push(tenant);
            }
        });

        supportedChargesOptionsList.forEach((supportedChargesOption) => {
            props.contract.supportedChargesOptions.split(',').forEach((item) => {
                //console.log('d', item, supportedChargesOption)
                if (supportedChargesOption.value == item) {
                    items.push(supportedChargesOption);
                }

                if (item === '2012') {
                    found = true;
                }
            })
        })

        setRental({
            ...rental,
            filter: filters,
            supportedChargesOptions: items,
            showOtherSupportedChargesOptions: found,
            tenantErrorMessage: ''
        });
    }, [tenants]);

    async function updateContract() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        rental.filter && rental.filter.map(item => {
            if (!item.email) {
                props.handleMessage(`Le gérant de la société ${item.name} ne possède pas d'adresse e-mail`);
                props.handleSeverity('error');
                props.handleOpenAlert();
                return;
            }
        })

        // Loading
        setLoading(true);

        let data = {};
        data.idUser = user.id;
        data.idTenant = rental.filter.map(item => item.id);
        data.name = props.contract.name;

        const rentalErrors = [
            'filter',
            'tenantErrorMessage',
            'startDateErrorMessage',
            'chargeModeErrorMessage',
            'rentalAmountErrorMessage',
            'chargesAmountErrorMessage',
            'guaranteeAmountErrorMessage',
            'rentalTermErrorMessage',
            'paymentModeErrorMessage',
            'rentalDurationErrorMessage',
            'cancellableContractErrorMessage',
            'franchiseEndDateErrorMessage',
            'guaranteeRentalNumberErrorMessage',
            'paymentFrequencyErrorMessage',
            'rentalRevisionErrorMessage',
            'showOtherSupportedChargesOptions'
        ];

        const possessionErrors = [
            'creationDateErrorMessage',
            'cityErrorMessage'
        ];

        for (let key in contract['rental']) {
            if (rentalErrors.indexOf(key) !== -1) {
                continue;
            }

            data[key] = contract['rental'][key];
        }

        for (let key in contract['possession']) {
            if (possessionErrors.indexOf(key) !== -1) {
                continue;
            }

            data[key] = contract['possession'][key];
        }

        //data.city = 'Sarcelles';
        data.returnUrl = window.location.href;
        //console.log('data', data);//return;
        await fetch(process.env.REACT_APP_API_HOST + '/contract/corporation/update/' + props.contract.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 201) {
                result.json().then(response => {
                    // Save filter in local storage
                    localStorage.setItem('contractCorporationTenantFilter', JSON.stringify(rental.filter));
                    localStorage.setItem('contractCorporationYearFilter', JSON.stringify(dayjs(rental.startRentalDate.toLocaleString()).format('YYYY')));

                    // Hide dialog
                    props.onClose();

                    // Save data in local storage for upload
                    localStorage.setItem('envelopeId', JSON.stringify(response.envelopeId));
                    localStorage.setItem('documentId', JSON.stringify(response.id));
                    localStorage.setItem('fileName', JSON.stringify(response.fileName));

                    // Hide waiting
                    setLoading(false);

                    // Redirect
                    window.location.href = response.redirect_url;
                })
            } else {
                //console.log(result);
                setLoading(false);

                props.handleMessage("Votre contrat n'a pas pu être créé suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);

            props.handleMessage("Votre contrat n'a pas pu être créé suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    const handleCloseWaiting = () => {
        setLoading(false);
    };

    const onRentalChange = (event, value, id) => {
        if (value !== undefined) {
            if (value === 'rentalTerm') {
                setRental({
                    ...rental,
                    rentalTerm: event ? event.target.value : null,
                    rentalTermErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else if (value === 'paymentMode') {
                setRental({
                    ...rental,
                    paymentMode: event ? event.target.value : null,
                    paymentModeErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else if (value === 'paymentFrequency') {
                setRental({
                    ...rental,
                    paymentFrequency: event ? event.target.value : null,
                    paymentFrequencyErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else if (value === 'rentalRevision') {
                setRental({
                    ...rental,
                    rentalRevision: event ? event.target.value : null,
                    rentalRevisionErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else if (value === 'cancellableContract') {
                setRental({
                    ...rental,
                    cancellableContract: event ? event.target.value : null,
                    cancellableContractErrorMessage: value ? '' : REQUIRED_FIELD
                });
            } else {
                if (id === 'startRentalDate') {
                    setRental({
                        ...rental,
                        startRentalDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                        startDateErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else if (id === 'franchiseEndDate') {
                    setRental({
                        ...rental,
                        franchiseEndDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                        franchiseEndDateErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else if (id === 'chargeMode') {
                    setRental({
                        ...rental,
                        chargeMode: value ? value : null,
                        chargeModeErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else if (id === 'supportedChargesOptions') {
                    let showOption = false;
                    //console.log('val', value)
                    value && value.map(item => {
                        if (item.value == 2012) {
                            showOption = true;
                            return;
                        }
                    })

                    setRental({
                        ...rental,
                        supportedChargesOptions: value.length > 0 ? value : [],
                        showOtherSupportedChargesOptions: showOption
                    });
                } else {
                    setRental({
                        ...rental,
                        filter: value.length > 0 ? value : [],
                        tenantErrorMessage: value.length > 0 ? '' : REQUIRED_FIELD
                    });
                    //console.log('val', value)
                    value && value.map(item => {
                        if (!item.email) {
                            props.handleMessage(`Le locataire ${item.firstName} ${item.lastName} ne possède pas d'adresse e-mail`);
                            props.handleSeverity('error');
                            props.handleOpenAlert();
                        }
                    })
                }
            }
        } else {
            //console.log('id', event.target.id)
            if (event.target.id) {
                switch (event.target.id) {
                    case 'rentalAmount':
                        setRental({
                            ...rental,
                            rentalAmount: event.target.value,
                            rentalAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'rentalDuration':
                        setRental({
                            ...rental,
                            rentalDuration: event.target.value,
                            rentalDurationErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'chargesAmount':
                        setRental({
                            ...rental,
                            chargesAmount: event.target.value,
                            chargesAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'guaranteeAmount':
                        setRental({
                            ...rental,
                            guaranteeAmount: event.target.value,
                            guaranteeAmountErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'guaranteeRentalNumber':
                        setRental({
                            ...rental,
                            guaranteeRentalNumber: event.target.value,
                            guaranteeRentalNumberErrorMessage: event.target.value ? '' : REQUIRED_FIELD
                        });
                        break;

                    case 'paymentDay':
                        setRental({
                            ...rental,
                            paymentDay: event.target.value
                        });
                        break;

                    case 'destinationType':
                        setRental({
                            ...rental,
                            destinationType: event.target.value
                        });
                        break;

                    case 'activitiesNature':
                        setRental({
                            ...rental,
                            activitiesNature: event.target.value
                        });
                        break;

                    case 'otherSupportedCharges':
                        setRental({
                            ...rental,
                            otherSupportedCharges: event.target.value
                        });
                        break;

                    default:
                        break;
                }
            } else {
                if (event.target.name === 'additionalActivities') {
                    setRental({
                        ...rental,
                        [event.target.name]: event.target.checked
                    });
                } else if (event.target.name === 'franchise') {
                    setRental({
                        ...rental,
                        [event.target.name]: event.target.checked
                    });
                } else {
                    setRental({
                        ...rental,
                        [event.target.name]: event.target.value
                    });
                }
            }
        }
    }

    const onPossessionChange = (event, value, id) => {
        if (event) {
            setPossession({
                ...possession,
                [event.target.name]: event.target.value
            });
        } else {
            if (id) {
                if (id === 'creationDate') {
                    setPossession({
                        ...possession,
                        creationDate: value ? dayjs(value.toLocaleString()).format('YYYY-MM-DD') : null,
                        creationDateErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                } else {
                    setPossession({
                        ...possession,
                        city: value.description,
                        cityErrorMessage: value ? '' : REQUIRED_FIELD
                    });
                }
            }
        }
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (rental.filter.length === 0) {
                setRental({
                    ...rental,
                    tenantErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    tenantErrorMessage: ''
                });

                let noMailError = false;
                rental.filter.map(item => {
                    if (!item.email) {
                        props.handleMessage(`Le locataire ${item.name} ne possède pas d'adresse e-mail`);
                        props.handleSeverity('error');
                        props.handleOpenAlert();
                        noMailError = true;
                    }
                })

                if (noMailError) {
                    return;
                }
            }

            if (rental.startRentalDate === null) {
                setRental({
                    ...rental,
                    startDateErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    startDateErrorMessage: ''
                });
            }

            if (rental.rentalAmount === '') {
                setRental({
                    ...rental,
                    rentalAmountErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalAmountErrorMessage: ''
                });
            }

            if (rental.chargesAmount === '') {
                setRental({
                    ...rental,
                    chargesAmountErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    chargesAmountErrorMessage: ''
                });
            }

            if (rental.guaranteeAmount === '') {
                setRental({
                    ...rental,
                    guaranteeAmountErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    guaranteeAmountErrorMessage: ''
                });
            }

            if (rental.chargeMode === null || rental.chargeMode === '') {
                setRental({
                    ...rental,
                    chargeModeErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    chargeModeErrorMessage: ''
                });
            }

            if (rental.rentalTerm === null || rental.rentalTerm === '') {
                setRental({
                    ...rental,
                    rentalTermErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalTermErrorMessage: ''
                });
            }

            if (rental.paymentMode === null || rental.paymentMode === '') {
                setRental({
                    ...rental,
                    paymentModeErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    paymentModeErrorMessage: ''
                });
            }

            if (rental.rentalDuration === '') {
                setRental({
                    ...rental,
                    rentalDurationErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setRental({
                    ...rental,
                    rentalDurationErrorMessage: ''
                });
            }

            // Rental
            let tempRental = {};
            for (let key in rental) {
                tempRental[key] = rental[key];
            }
            contract['rental'] = tempRental;
            localStorage.setItem('contractRentalCorporation', JSON.stringify(contract['rental']));
        } else if (activeStep === 1) {
            // Possession
            if (possession.city === null) {
                setPossession({
                    ...possession,
                    cityErrorMessage: REQUIRED_FIELD
                });
                //return;
            } else {
                setPossession({
                    ...possession,
                    cityErrorMessage: ''
                });
            }

            if (possession.creationDate === '' || possession.creationDate === null) {
                setPossession({
                    ...possession,
                    creationDateErrorMessage: REQUIRED_FIELD
                });
                return;
            } else {
                setPossession({
                    ...possession,
                    creationDateErrorMessage: ''
                });
            }

            let tempPossession = {};
            for (let key in possession) {
                tempPossession[key] = possession[key];
            }
            contract['possession'] = tempPossession;
            localStorage.setItem('contractPossessionCorporation', JSON.stringify(contract['possession']));
        }

        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    React.useEffect(() => {
        localStorage.removeItem('contractRentalCorporation');
        localStorage.removeItem('contractPossessionCorporation');

        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

        contract['rental'] = JSON.parse(localStorage.getItem('contractRentalCorporation'));
        contract['possession'] = JSON.parse(localStorage.getItem('contractPossessionCorporation'));

        if (null !== contract) {
            setContract(contract);
        }
    }, [open]);

    function setContract(housing) {
        // Rental
        setRental({
            ...rental,
            ...contract['rental']
        });

        // Possession
        setPossession({
            ...possession,
            ...contract['possession']
        });
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier un contrat
                </Typography>
            </Box>
            <Divider />
            <Container component="" maxWidth="xs" ref={descriptionElementRef} tabIndex={-1} sx={{ mb: 3 }}>
                <Stepper alternativeLabel
                         activeStep={activeStep}
                         sx={{ pt: 3, pb: 5 }}
                         connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography>
                            Vous êtes sur le point de créer votre locataire. Cliquer sur le bouton Valider pour confirmer la création de votre locataire ou sur Précédent pour vérifier les données saisies.
                        </Typography>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {getStepContent(activeStep, rental, possession, tenants, onRentalChange, onPossessionChange)}
                    </React.Fragment>
                )}
            </Container>
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                    {activeStep !== 0 && (
                        <MercureOutlinedButton
                            onClick={handleBack}
                            label="Précédent" />
                    )}

                    {activeStep === steps.length ? (
                        <MercureContainedButton
                            onClick={updateContract}
                            label="Valider" />
                    ) : (
                        <MercureContainedButton
                            onClick={handleNext}
                            label="Suivant" />
                    )}
                </Box>
            </DialogActions>
            {loading && (
                <Waiting loading={loading} title="Signature électronique" message="Merci de patienter, vous allez être rédirigé dans quelques instants..."/>
            )}
        </div>
    )
}