import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import dayjs from "dayjs";
import List from "@mui/material/List";
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from '@mui/material/ListItem';
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import Alert from "../../Components/Alert";
import Waiting from "../../Components/Waiting";
import Confirm from "../../Components/Confirm";
import Drawer from "@mui/material/Drawer";
import CustomSearchBar from "../../Components/CustomSearchBar";
import Divider from "@mui/material/Divider";
import AddContractCorporation from "./AddContractCorporation";
import UpdateContractCorporation from "./UpdateContractCorporation";
import Stack from "@mui/material/Stack";
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export default function ContractCorporation(props) {
    let maxYear = new Date().setFullYear(new Date().getFullYear());
    let maxDate = dayjs(maxYear).toLocaleString();
    const [contracts, setContracts] = React.useState([]);
    const [tenants, setTenants] = React.useState(null);
    const [filter, setFilter] = React.useState(null);
    const [openContract, setOpenContract] = React.useState(false);
    const [contractYear, setContractYear] = React.useState(dayjs(new Date()).format('YYYY'));
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [waitingTitle, setWaitingTitle] = React.useState('Sauvegarde');
    const [waitingMessage, setWaitingMessage] = React.useState('La sauvegarde de votre contrat est en cours, merci de patienter encore quelques instants...');
    const [loading, setLoading] = React.useState(false);
    const title = 'Attention';
    const confirmMessage = "Etes-vous sûr de vouloir supprimer définitivement ce contrat ?\nCette action est irréversible.";
    const [openConfirm, setOpenConfirm] = React.useState(false);
    let [selectedContract, setSelectedContract] = React.useState();
    const anchorPosition = 'right';
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const onFilterChange = (event, value) => {
        setFilter(value);
    }

    const getTenants = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        let id = user.id;

        fetch(process.env.REACT_APP_API_HOST + '/tenant/corporation/get/user/id/' + id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setTenants(response);
                    localStorage.setItem('tenantsCorporation', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const getContracts = React.useCallback(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        let data = {};
        data.idUser = user.id;
        data.idTenant = filter && filter.id;
        data.year = contractYear;
        //console.log('data', data); return;
        const url = process.env.REACT_APP_API_HOST + '/contract/corporation/get';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response', response);
                    setContracts(response);
                    localStorage.setItem('contractsCorporation', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [filter, contractYear]);

    const refreshData = React.useCallback((tenant, year) => {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();
            return;
        }

        let data = {};
        data.idUser = user.id;
        data.idTenant = tenant[0].id;
        data.year = year;
        //console.log('data', data); // return;
        const url = process.env.REACT_APP_API_HOST + '/contract/corporation/get';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response', response);
                    setContracts(response);
                    localStorage.setItem('contractsCorporation', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    React.useEffect(() => {
        const lodgers = JSON.parse(localStorage.getItem('tenantsCorporation'));

        if (null === lodgers) {
            getTenants();
        } else {
            setTenants(lodgers);
        }
    }, []);

    // Move file to S3
    React.useEffect(() => {
        // Refresh header
        props.handleTitle("Documents");
        props.handleSubtitle("", [
            { id: 'Personne physique', path: '/contract', index: 0 },
            { id: 'Personne morale', path: '/contract/corporation', index: 1 }
            ]
        );
        props.handleTabValue(1);

        // Highlight Nav
        props.handleListItemClick(null, 70);

        const fileName = JSON.parse(localStorage.getItem('fileName'));
        const envelopeId = JSON.parse(localStorage.getItem('envelopeId'));
        const documentId = JSON.parse(localStorage.getItem('documentId'));

        if (null !== fileName && null !== documentId && null !== envelopeId) {
            setLoading(true);
            let data = {};
            data.fileName = fileName;
            data.envelopeId = envelopeId;
            data.documentId = documentId.toString();
            const url= process.env.REACT_APP_API_HOST + '/contract/corporation/upload';

            fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(result => {
                //console.log('result', result);
                if (result.status === 201) {
                    // Remove data from local storage
                    localStorage.removeItem('fileName');
                    localStorage.removeItem('envelopeId');
                    localStorage.removeItem('documentId');

                    // Hide waiting
                    setLoading(false);

                    // Show alert
                    handleMessage("Votre contrat a été créé");
                    handleSeverity('success');
                    handleOpenAlert();

                    // Get tenant filter
                    const contractCorporationTenantFilter = JSON.parse(localStorage.getItem('contractCorporationTenantFilter'));
                    if (contractCorporationTenantFilter !== null) {
                        setFilter(contractCorporationTenantFilter[0]);
                    }

                    // Get year filter
                    const contractCorporationYearFilter = JSON.parse(localStorage.getItem('contractCorporationYearFilter'));
                    if (contractCorporationYearFilter !== null) {
                        setContractYear(contractCorporationYearFilter);
                    }

                    // Refresh page
                    refreshData(contractCorporationTenantFilter, contractCorporationYearFilter);
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    }, []);


    const handleOpenContract = () => {
        setOpenContract(true);
    }

    const handleCloseContract = () => setOpenContract(false);

    const onContractYearChange = (event, value) => {
        setContractYear(dayjs(value.toLocaleString()).format('YYYY'));
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleMessage = (message) => {
        setMessage(message);
    };

    const handleSeverity = (severity) => {
        setSeverity(severity);
    };

    const filteredContracts = contracts.filter(contract => {
        if (filter === null) {
            return contract;
        } else {
            return (filter && contract.idTenant === filter.id);
        }
    })

    function forceDownload(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        document.body.appendChild(a);

        // Hide loading before downloading
        setLoading(false);

        // Download
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    // Download from S3
    const download = (contract) => (event) => {
        // Loading
        setWaitingTitle('Téléchargement');
        setWaitingMessage('Le téléchargement de votre contrat est en cours, merci de patienter quelques instants...')
        setLoading(true);

        let data = {};
        data.fileName = contract.name;
        data.id = contract.id;
        const url= process.env.REACT_APP_API_HOST + '/contract/corporation/download/s3';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            //console.log('result', result);
            if (result.status === 200) {
                result.blob().then(blob => forceDownload(blob, contract.name))
            } else {
                // Hide popup
                setWaitingTitle('');
                setWaitingMessage('')
                setLoading(false);

                // Show error message
                handleMessage("Le contrat n'a pas pu être téléchargé suite à une erreur");
                handleSeverity('error');
                handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);

            // Hide popup
            setWaitingTitle('');
            setWaitingMessage('')
            setLoading(false);

            // Show error message
            handleMessage("Le contrat n'a pas pu être téléchargé suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        })
    }

    const resend = (contract) => (event) => {
        let data = {};
        data.fileName = contract.name;
        data.idTenant = contract.idTenant;
        //console.log(contract);return
        const url= process.env.REACT_APP_API_HOST + '/contract/corporation/send';

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            //console.log('result', result);
            if (result.status === 204) {
                handleMessage("Le contrat a été renvoyé");
                handleSeverity('success');
                handleOpenAlert();
            }
        }).catch((error) => {
            console.log(error);
            handleMessage("Le contrat n'a pas pu être renvoyé suite à une erreur");
            handleSeverity('error');
            handleOpenAlert();
        })
    }

    const toggleConfirm = (open, contract) => (event) => {
        if (open) {
            setSelectedContract(contract);
        } else {
            setSelectedContract();
        }

        setOpenConfirm(open);
    }

    const contractDeleted = () => {
        setSelectedContract();
        getContracts();
        handleMessage("Le contrat a été supprimé");
        handleSeverity('success');
        handleOpenAlert();
    }

    const contractNotDeleted = () => {
        handleMessage("Le contrat n'a pas pu être supprimé suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    const toggleDrawer = (open, contract) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (open) {
            setSelectedContract(contract);
        } else {
            setSelectedContract();
        }

        setOpenDrawer(open);
    }

    const closeDrawer = () => {
        setOpenDrawer(false);
    }

    const contractUpdated = () => {
        setOpenDrawer(false);
        setSelectedContract();
        getContracts();
        handleMessage("Vos données ont été modifiées");
        handleSeverity('success');
        handleOpenAlert();
    }

    const contractNotUpdated = () => {
        setOpenDrawer(false);
        setSelectedContract();
        handleMessage("Vos données n'ont pas pu être modifiées suite à une erreur");
        handleSeverity('error');
        handleOpenAlert();
    }

    return (
        <div>
            <Paper sx={{maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
                {openContract === true && (
                    <AddContractCorporation open={openContract}
                                           onClose={handleCloseContract}
                                           tenants={tenants}
                                           handleOpenAlert={handleOpenAlert}
                                           handleMessage={handleMessage}
                                           handleSeverity={handleSeverity}/>
                )}

                <CustomSearchBar year={contractYear}
                                 maxDate={maxDate}
                                 onYearChange={onContractYearChange}
                                 options={tenants}
                                 filter={filter}
                                 onFilterChange={onFilterChange}
                                 onClick={getContracts}
                                 label="Sélectionner un locataire"
                                 handleMenu={handleOpenContract}/>

                {filteredContracts && filteredContracts.length === 0 ? (
                    <Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
                        Lancer la recherche pour afficher les contrats
                    </Typography>
                ) : (
                    <List subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {filteredContracts && filteredContracts.length} contrat.s
                        </ListSubheader>
                    }>
                        {filteredContracts && filteredContracts.map && filteredContracts.map((contract, index) =>
                            <div>
                                <ListItem key={contract.id}
                                          secondaryAction={
                                              <Grid>
                                                  <Tooltip title="Modifier">
                                                      <IconButton edge="end" aria-label="edit" onClick={toggleDrawer(true, contract)}>
                                                          <EditIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Télécharger">
                                                      <IconButton edge="end" aria-label="download" onClick={download(contract)}>
                                                          <DownloadIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Renvoyer par mail">
                                                      <IconButton edge="end" aria-label="resend" onClick={resend(contract)}>
                                                          <EmailIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Supprimer">
                                                      <IconButton edge="end" aria-label="delete" color="error" onClick={toggleConfirm(true, contract)}>
                                                          <DeleteIcon/>
                                                      </IconButton>
                                                  </Tooltip>
                                              </Grid>
                                          }
                                >
                                    <ListItemAvatar>
                                        {contract.status === 'completed' ?
                                            <Tooltip title="Signé par tous">
                                                <Avatar>
                                                    <AssignmentTurnedInIcon/>
                                                </Avatar>
                                            </Tooltip>
                                            :
                                            <Tooltip title="En cours de signature">
                                                <Avatar>
                                                    <AssignmentIcon/>
                                                </Avatar>
                                            </Tooltip>
                                        }
                                    </ListItemAvatar>
                                    <ListItemText id={contract.id}
                                                  primary={contract.name}
                                                  secondary={
                                                      <React.Fragment>
                                                          <Stack direction="row" spacing={1}>
                                                              <Typography variant="subtitle1">
                                                                  {`Bail commercial - ${contract.rentalDuration} à partir du ${dayjs(contract.startRentalDate).format('DD/MM/YYYY')}`}
                                                              </Typography>
                                                          </Stack>
                                                      </React.Fragment>
                                                  }
                                    />
                                </ListItem>
                            {
                                ((index === 0 && filteredContracts.length > 1) || index < filteredContracts.length - 1) && <Divider variant="inset" component="li" />
                            }
                            </div>
                        )}
                    </List>
                )}

                {loading && (
                    <Waiting loading={loading} title={waitingTitle} message={waitingMessage} />
                )}

                <Drawer anchor={anchorPosition}
                        open={openDrawer}
                        PaperProps={{
                            sx: {backgroundColor: "#ffffff"}
                        }}
                        onClose={toggleDrawer(false)}>
                    <UpdateContractCorporation contract={selectedContract}
                                               contractUpdated={contractUpdated}
                                               contractNotUpdated={contractNotUpdated}
                                               onClose={closeDrawer}
                                               handleOpenAlert={handleOpenAlert}
                                               handleMessage={handleMessage}
                                               handleSeverity={handleSeverity} />
                </Drawer>
            </Paper>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>

            <Confirm open={openConfirm}
                     title={title}
                     message={confirmMessage}
                     onClose={toggleConfirm(false)}
                     selected={selectedContract}
                     type="contract-corporation"
                     titleBackgroundColor="#ff9800"
                     contractDeleted={contractDeleted}
                     contractNotDeleted={contractNotDeleted}
                     titleColor="white"/>

        </div>
    )
}