import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import 'react-phone-number-input/style.css';
import Location from './../../Components/Location';
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MercureContainedButton from "../../Components/MercureContainedButton";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import validator from "validator";

export default function UpdateHousingCorporation(props) {
    const [siren, setSiren] = React.useState(props.corporation && props.corporation.siren ? props.corporation.siren : '');
    const [siret, setSiret] = React.useState(props.corporation && props.corporation.siret ? props.corporation.siret : '');
    const [iban, setIban] = React.useState(props.corporation && props.corporation.iban ? props.corporation.iban : '');
    const [vatNumber, setVatNumber] = React.useState(props.corporation && props.corporation.vatNumber ? props.corporation.vatNumber : '');
    const [address, setAddress] = React.useState(props.corporation && props.corporation.address ? props.corporation.address : null);
    const [name, setName] = React.useState(props.corporation && props.corporation.name);
    const [firstName, setFirstName] = React.useState(props.corporation && props.corporation.firstName);
    const [lastName, setLastName] = React.useState(props.corporation && props.corporation.lastName);
    const [type, setType] = React.useState(props.corporation && props.corporation.type);
    const [nameErrorMessage, setNameErrorMessage] = React.useState('');
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState('');
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState('');
    const [addressErrorMessage, setAddressErrorMessage] = React.useState('');
    const [housingErrorMessage, setHousingErrorMessage] = React.useState('');
    const [email, setEmail] = React.useState(props.corporation && props.corporation.email);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const requiredField = 'Ce champ est requis';
    const [open, setOpen] = React.useState(false);
    let [housings, setHousings] = React.useState([]);
    let [housing, setHousing] = React.useState([]);
    const [openAutoComplete, setOpenAutoComplete] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [item, setItem] = React.useState([]);
    const [loading, setLoading] = React.useState(openAutoComplete && options && options.length === 0);

    const SIREN_CHARACTER_LIMIT = 9;
    const SIRET_CHARACTER_LIMIT = 14;
    const IBAN_CHARACTER_LIMIT = 34;
    const VAT_NUMBER_CHARACTER_LIMIT = 13;

    const onHousingChange = (event, value) => {
        if (null != value) {
            setHousingErrorMessage('');
            setHousing(value);
            return;
        }

        setHousingErrorMessage(requiredField);
    }

    const onAddressChange = (event, value) => {
        setAddress(value.description);
    }

    const handleSirenChange = (event) => {
        setSiren(event.target.value);
    };

    const handleSiretChange = (event) => {
        setSiret(event.target.value);
    };

    const handleIbanChange = (event) => {
        setIban(event.target.value);
    };

    const handleVatNumberChange = (event) => {
        setVatNumber(event.target.value);
    };

    const onNameChange = (event) => {
        if (event && event.target.value) {
            setLoading(true);
            setName(event.target.value.toUpperCase());
        }
    };

    const onValueChange = (event, value) => {
        if (value) {
            let address = '';

            if (null !== value.adresseEtablissement.complementAdresseEtablissement) {
                address = value.adresseEtablissement.numeroVoieEtablissement + ' ' + value.adresseEtablissement.complementAdresseEtablissement + ' ' + value.adresseEtablissement.typeVoieEtablissement + ' ' + value.adresseEtablissement.libelleVoieEtablissement + ' ' + value.adresseEtablissement.codePostalEtablissement + ' ' + value.adresseEtablissement.libelleCommuneEtablissement
            } else {
                address = value.adresseEtablissement.numeroVoieEtablissement + ' ' + value.adresseEtablissement.typeVoieEtablissement + ' ' + value.adresseEtablissement.libelleVoieEtablissement + ' ' + value.adresseEtablissement.codePostalEtablissement + ' ' + value.adresseEtablissement.libelleCommuneEtablissement
            }
            setSiren(value.siren);
            setAddress(address);
        } else {
            setSiren('');
            setAddress('');
        }
    }

    const onTypeChange = (event) => {
        if (event.target.value) {
            setType(event.target.value);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    async function updateCorporation() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        if (name === '') {
            setNameErrorMessage(requiredField);
            return;
        }

        if (address === '') {
            setAddressErrorMessage(requiredField);
            return;
        }

        if (firstName === '') {
            setFirstNameErrorMessage(requiredField);
            return;
        }

        if (lastName === '') {
            setLastNameErrorMessage(requiredField);
            return;
        }

        if (email === '') {
            setEmailErrorMessage(requiredField);
            return;
        }

        if (housing.length === 0) {
            setHousingErrorMessage(requiredField);
            return;
        }

        const data = {};
        let ids = housing.map(element => element.id);
        data.idUser = user.id;
        data.siren = siren;
        data.siret = siret;
        data.iban = iban;
        data.vatNumber = vatNumber;
        data.name = name;
        data.firstName = firstName;
        data.lastName = lastName;
        data.email = email;
        data.type = type;
        data.address = address;
        data.idHousing = ids;
        //console.log('data',data);return

        await fetch(process.env.REACT_APP_API_HOST + '/housing/corporation/update/' + props.corporation.id, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 204) {
                props.corporationUpdated();
            } else {
                console.log(result);
                props.corporationUpdated();
            }
        }).catch((error) => {
            console.log(error);
            props.corporationUpdated();
        });
    }

    async function getDataByName(active) {
        await fetch(process.env.REACT_APP_API_HOST + '/insee/get/name/' + name, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                //console.log('res', result);
                result.json().then(response => {
                    console.log('response', response);
                    if (active) {
                        setLoading(false);
                        setOptions([...response]);
                        setName(name)
                    }
                })
            } else {
                console.log(result);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    async function getDataBySiren() {
        await fetch(process.env.REACT_APP_API_HOST + '/insee/get/siren/' + props.corporation.siren, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                //console.log('res', result);
                result.json().then(response => {
                    setItem(response[0]);
                })
            } else {
                console.log(result);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (name && name.length > 3) {
            setOptions([]);
            getDataByName(active);
        }

        return () => {
            active = false;
        };
    }, [loading, name]);

    React.useEffect(() => {
        getDataBySiren();

        let possessions = JSON.parse(localStorage.getItem('housings'));

        if (null === possessions) {
            getHousingsByIdUser();
        } else {
            setHousings(possessions);
        }

        getSelectedHousings();
     }, []);

    function getHousingsByIdUser() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        fetch(process.env.REACT_APP_API_HOST + '/housing/get/user/id/' + user.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousings(response);
                    localStorage.setItem('housings', JSON.stringify(response));
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    function getSelectedHousings() {
        fetch(process.env.REACT_APP_API_HOST + '/housing/corporation/get/corporation/id/' + props.corporation.id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    //console.log('response ', response);
                    setHousing(response);
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const filterOptions = (options) => {
        let newOptions = [];
        let ids = housing.map(element => element.id);

        options.forEach((option) => {
            if (ids.includes(option.id) === false) {
                newOptions.push(option);
            }
        })

        return newOptions;
    };

    const onFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const onLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const onEmailChange = (e) => {
        let email = e.target.value;

        if (email != '') {
            if (validator.isEmail(email)) {
                setEmailErrorMessage("");
            } else {
                setEmailErrorMessage("L'adresse e-mail saisie est invalide");
            }
        } else {
            setEmailErrorMessage("");
        }

        setEmail(email);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier une entreprise
                </Typography>
            </Box>
            <Divider />
            <Container component="" maxWidth="xs" tabIndex={-1} sx={{ mb: 3, pt: 3, pb: 1 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Autocomplete
                            autoComplete="given-name"
                            freeSolo
                            open={openAutoComplete}
                            fullWidth
                            label="Nom de l'entreprise"
                            autoFocus
                            value={item}
                            isOptionEqualToValue={(option) => option.siret === siret}
                            onOpen={() => {
                                setOpenAutoComplete(true);
                            }}
                            onClose={() => {
                                setOpenAutoComplete(false);
                            }}
                            onInputChange={(event, newInputValue) => {
                                onNameChange(event, newInputValue)
                            }}
                            onChange={(event, newValue) => {
                                onValueChange(event, newValue);
                            }}
                            getOptionLabel={(option) => option !== undefined && option.uniteLegale !== undefined ? option.uniteLegale.denominationUniteLegale : ''}
                            options={options}
                            loading={loading}
                            filterOptions={(x) => x}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.siret}>
                                        <Grid container alignItems="center">
                                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                <Box component="span" sx={{ fontWeight: option.highlight ? 'bold' : 'regular' }}>
                                                    {option.uniteLegale.denominationUniteLegale}
                                                </Box>
                                                <Typography variant="subtitle1" color="text.secondary">
                                                    {option.adresseEtablissement.numeroVoieEtablissement} {option.adresseEtablissement.complementAdresseEtablissement} {option.adresseEtablissement.typeVoieEtablissement} {option.adresseEtablissement.libelleVoieEtablissement} {option.adresseEtablissement.codePostalEtablissement} {option.adresseEtablissement.libelleCommuneEtablissement}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    label="Nom de l'entreprise"
                                    error={nameErrorMessage !== ""}
                                    helperText= {nameErrorMessage}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="secondary" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Forme juridique"
                            name="type"
                            autoComplete="family-name"
                            value={type}
                            onChange={onTypeChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   autoComplete="given-name"
                                   name="siren"
                                   label="Siren"
                                   inputProps={{
                                       maxlength: SIREN_CHARACTER_LIMIT
                                   }}
                                   value={siren}
                                   onChange={handleSirenChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   autoComplete="given-name"
                                   name="siret"
                                   label="Siret"
                                   inputProps={{
                                       maxlength: SIRET_CHARACTER_LIMIT
                                   }}
                                   value={siret}
                                   onChange={handleSiretChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   autoComplete="given-name"
                                   name="iban"
                                   label="IBAN"
                                   inputProps={{
                                       maxlength: IBAN_CHARACTER_LIMIT
                                   }}
                                   value={iban}
                                   onChange={handleIbanChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   autoComplete="given-name"
                                   name="vatNumber"
                                   label="TVA intracommunautaire"
                                   inputProps={{
                                       maxlength: VAT_NUMBER_CHARACTER_LIMIT
                                   }}
                                   value={vatNumber}
                                   onChange={handleVatNumberChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Location
                            required="required"
                            name="address"
                            label="Adresse"
                            value={address}
                            noOptionsText="Aucune adresse saisie"
                            error={addressErrorMessage !== ""}
                            helperText={addressErrorMessage}
                            onChange={onAddressChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   autoComplete="given-name"
                                   name="firstName"
                                   label="Prénom du gérant"
                                   error={firstNameErrorMessage !== ""}
                                   helperText={firstNameErrorMessage}
                                   value={firstName}
                                   required
                                   onChange={onFirstNameChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                                   autoComplete="family-name"
                                   name="lastName"
                                   label="Nom du gérant"
                                   error={lastNameErrorMessage !== ""}
                                   helperText={lastNameErrorMessage}
                                   required
                                   value={lastName}
                                   onChange={onLastNameChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            value={email}
                            label="E-mail du gérant"
                            name="email"
                            autoComplete="email"
                            error={emailErrorMessage !== ""}
                            helperText= {emailErrorMessage}
                            required
                            onChange={onEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="housing"
                            noOptionsText="Sélectionner le.s bien.s géré.s par l'entreprise"
                            multiple
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                                {onHousingChange(event, newValue)}
                            }}
                            value={housing}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined || value === "" || option.name === value
                            }
                            options={housings}
                            filterOptions={filterOptions}
                            autoHighlight
                            getOptionLabel={(option) => option && option.name ? option.name : ''}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Biens.s géré.s par l'entreprise"
                                           required
                                           error={housingErrorMessage !== ""}
                                           helperText= {housingErrorMessage}
                                           fullWidth/>}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Divider />
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
                    <MercureContainedButton onClick={updateCorporation} label="Valider" />
                </Box>
            </DialogActions>
        </div>
    );
}