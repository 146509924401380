import * as React from 'react';
import Header from './Home/Header';
import Footer from './Home/Footer';

function Activation() {
    return (
        <div>
            <Header showMenu={false}/>
            <section className="header7 cid-tRrx3SXQeC mbr-fullscreen" id="header7-j">
                <div className="mbr-overlay"></div>
                <div className="text-right container">
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-8">
                            <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>Compte activé
                                !</strong></h1>
                            <p className="mbr-text mbr-fonts-style display-7">
                                Votre compte est maintenant activé. Connectez-vous pour profiter pleinement de votre
                                outil de gestion immobilière.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer showMenu={false}/>
        </div>
    )
}

export default Activation;