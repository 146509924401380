import * as React from 'react';
import Button from '@mui/material/Button';

export default function MercureContainedButton(props) {
    return (
        <Button fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                size="large"
                disabled={props.disabled}
                onClick={props.onClick}>{props.label}</Button>
    );
}