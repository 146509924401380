import * as React from 'react';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

export default function UpdatedPassword(props) {
    const navigate = useNavigate();

    const toSignIn = () => {
        navigate('/sign-in');
    }

    return (
        <div>
            <section className="header1 cid-ubVsaasZyP mbr-fullscreen" id="header1-m">
                <div className="mbr-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-5">
                            <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>Mot de passe modifié</strong></h1>
                            <p className="mbr-text mbr-fonts-style display-7">
                                Votre mot de passe a été modifié. Vous pouvez dès à présent vous connecter en cliquant
                                sur le bouton ci-dessous :</p>
                            <Button variant="contained" color="secondary" onClick={toSignIn}>Se connecter</Button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}