import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import GeneralData from './GeneralData';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Equipments from "./Equipments";
import Leasing from "./Leasing";
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from "dayjs";
import MercureTheme from "../../Components/MercureTheme";
import BootstrapDialog from "../../Components/BootstrapDialog";
import BootstrapDialogTitle from "../../Components/BootstrapDialogTitle";
import ColorlibConnector from "../../Components/ColorlibConnector";
import ColorlibStepIcon from "../../Components/ColorlibStepIcon";
import MercureContainedButton from "../../Components/MercureContainedButton";
import MercureOutlinedButton from "../../Components/MercureOutlinedButton";

let housing = [];
const steps = ['Informations générales', 'Equipements', 'Types de bail'];

function getStepContent(step, generalData, equipments, leasing, onGeneralDataChange, onEquipmentsChange, onLeasingChange) {
    switch (step) {
        case 0:
            return <GeneralData data={generalData} onGeneralDataChange={onGeneralDataChange} />;
        case 1:
            return <Equipments data={equipments} onEquipmentsChange={onEquipmentsChange} />;
        case 2:
            return <Leasing data={leasing} onLeasingChange={onLeasingChange} />;
        default:
            throw new Error('Unknown step');
    }
}

export default function AddHousing(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('body');
    const descriptionElementRef = React.useRef(null);
    const fullScreen = useMediaQuery(MercureTheme.breakpoints.down('md'));

    // General Data
    const [generalData, setGeneralData] = React.useState({
        name: null,
        type: null,
        boughtAt: null,
        pieces: null,
        area: null,
        floor: null,
        door: null,
        address: null,
        regime: null,
        currency: null,
        nbBatch: null,
        batchName: null,
        numBatch: null,
        thousandths: null,
        constructionYear: null,
        technologicalRisks: null
    });

    // Equipments
    const [equipments, setEquipments] = React.useState({
        heating: null,
        water: null,
        counters: null,
        cellar: false,
        parking: false,
        balcony: false,
        elevator: false,
        fiber: false,
        playground: false,
        guardian: false,
        antenna: false,
        bikeRoom: false,
        intercom: false,
        greenArea: false,
        washRoom: false,
        fridge: false,
        freezer: false,
        court: false,
        pool: false,
        hotTub: false,
        sauna: false,
        chimney: false,
        kitchenFurniture: false,
        oven: false,
        microwave: false,
        dishWasher: false,
        hotPlates: false,
        hood: false,
        alarm: false
    });

    // Leasing
    const [leasing, setLeasing] = React.useState({
        leasing: false,
        commercial: null,
        dwelling: null
    });

    const onGeneralDataChange = (event, value, id) => {
        if (event) {
            switch (event.target.id) {
                case 'name':
                    setGeneralData({
                        ...generalData,
                        name: event.target.value
                    });
                    break;

                case 'area':
                    setGeneralData({
                        ...generalData,
                        area: event.target.value
                    });
                    break;

                case 'pieces':
                    setGeneralData({
                        ...generalData,
                        pieces: event.target.value
                    });
                    break;

                case 'floor':
                    setGeneralData({
                        ...generalData,
                        floor: event.target.value
                    });
                    break;

                case 'door':
                    setGeneralData({
                        ...generalData,
                        door: event.target.value
                    });
                    break;

                case 'nbBatch':
                    setGeneralData({
                        ...generalData,
                        nbBatch: event.target.value
                    });
                    break;

                case 'batchName':
                    setGeneralData({
                        ...generalData,
                        batchName: event.target.value
                    });
                    break;

                case 'numBatch':
                    setGeneralData({
                        ...generalData,
                        numBatch: event.target.value
                    });
                    break;

                case 'thousandths':
                    setGeneralData({
                        ...generalData,
                        thousandths: event.target.value
                    });
                    break;

                default:
                    let ids = event.target.id.split('-');
                    //console.log('id', event.target.name)

                    if (ids[0] === 'type') {
                        setGeneralData({
                            ...generalData,
                            type: value.label
                        });
                    } else if (ids[0] === 'regime') {
                        setGeneralData({
                            ...generalData,
                            regime: value.label
                        });
                    } else if (ids[0] === 'currency') {
                        setGeneralData({
                            ...generalData,
                            currency: value.label
                        });
                    } else {
                        if (event.target.name === 'technologicalRisks') {
                            setGeneralData({
                                ...generalData,
                                [event.target.name]: event.target.value
                            });
                        } else if (null !== value) {
                            setGeneralData({
                                ...generalData,
                                address: value.description
                            });
                        }
                    }
                    break;
            }
        } else {
            if (id === 'boughtAt') {
                setGeneralData({
                    ...generalData,
                    boughtAt: dayjs(value.toLocaleString()).format('YYYY')
                });
            } else if (id === 'constructionYear') {
                setGeneralData({
                    ...generalData,
                    constructionYear: dayjs(value.toLocaleString()).format('YYYY')
                });
            }
        }
    }
    
    const onEquipmentsChange = (event, value) => {
        let ids = event.target.id.split('-');

        if (ids[0] === 'heating') {
            setEquipments({
                ...equipments,
                heating: value.label
            });
        } else if (ids[0] === 'water') {
            setEquipments({
                ...equipments,
                water: value.label
            });
        } else if (ids[0] === 'counters') {
            setEquipments({
                ...equipments,
                counters: value.label
            });
        } else {
            setEquipments({
                ...equipments,
                [event.target.name]: event.target.checked
            });
        }
    }

    const onLeasingChange = (event) => {
        if (event.target.name === 'leasing') {
            setLeasing({
                ...leasing,
                [event.target.name]: event.target.checked
            });
        } else {
            setLeasing({
                ...leasing,
                [event.target.name]: event.target.value
            });
        }
    };

    const handleNext = () => {
        if (activeStep == 0) {
            // General data
            let tempGeneralData = {};
            for (let key in generalData) {
                tempGeneralData[key] = generalData[key];
            }
            housing['generalData'] = tempGeneralData;
            localStorage.setItem('generalData', JSON.stringify(housing['generalData']));
        } else if (activeStep == 1) {
            // Equipments
            let tempEquipments = {};
            for (let key in equipments) {
                tempEquipments[key] = equipments[key];
            }
            housing['equipments'] = tempEquipments;
            localStorage.setItem('equipments', JSON.stringify(housing['equipments']));
        } else if (activeStep == 2) {
            // Leasing
            let tempLeasing = {};
            for (let key in leasing) {
                tempLeasing[key] = leasing[key];
            }
            housing['leasing'] = tempLeasing;
            localStorage.setItem('leasing', JSON.stringify(housing['leasing']));
        }

        setActiveStep(activeStep + 1);
    };

    async function saveHousing() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if (null === user) {
            /*handleMessage("Votre session a expiré. Merci de vous reconnecter avant d'accéder à cette section.");
            handleSeverity('error');
            handleOpenAlert();*/
            return;
        }

        let data = {};
        data.idUser = user.id;

        for (let key in housing['generalData']) {
            data[key] = housing['generalData'][key];
        }

        for (let key in housing['equipments']) {
            data[key] = housing['equipments'][key];
        }

        for (let key in housing['leasing']) {
            data[key] = housing['leasing'][key];
        }
        //console.log('data',data);return;
        await fetch(process.env.REACT_APP_API_HOST + '/housing/save', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 200) {
                result.json().then(response => {
                    // Show Alert
                    props.onClose();
                    props.handleMessage("Vos données ont été sauvegardées");
                    props.handleSeverity('success');
                    props.handleOpenAlert();

                    // Refresh data
                    props.refresh();
                    localStorage.removeItem('generalData');
                    localStorage.removeItem('equipments');
                    localStorage.removeItem('leasing');
                })
            }
        }).catch((error) => {
            console.log(error);
            props.handleMessage("Vos données n'ont pas pu être sauvegardées suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();
        });
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    React.useEffect(() => {
        localStorage.removeItem('generalData');
        localStorage.removeItem('equipments');
        localStorage.removeItem('leasing');

        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

        housing['generalData'] = JSON.parse(localStorage.getItem('generalData'));
        housing['equipments'] = JSON.parse(localStorage.getItem('equipments'));
        housing['leasing'] = JSON.parse(localStorage.getItem('leasing'));

        if (null !== housing) {
            //console.log(housing);
            setHousing(housing);
        }
    }, [open]);

    function setHousing(housing) {
        // General data
        setGeneralData({
            ...generalData,
            ...housing['generalData']
        });

        // Equipments
        setEquipments({
            ...equipments,
            ...housing['equipments']
        });

        // Leasing
        setLeasing({
            ...leasing,
            ...housing['leasing']
        });
    }

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll={scroll}
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Ajouter un bien immobilier
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Container maxWidth="sm" sx={{ mb: 4 }}>
                        <Stepper alternativeLabel
                                 activeStep={activeStep}
                                 sx={{ pt: 3, pb: 5 }}
                                 connector={<ColorlibConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography>
                                    Vous êtes sur le point de créer votre bien immobilier. Cliquer sur le bouton Valider pour confirmer la création de votre bien ou sur Précédent pour vérifier les données saisies.
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep, generalData, equipments, leasing, onGeneralDataChange, onEquipmentsChange, onLeasingChange)}
                            </React.Fragment>
                        )}
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        {activeStep !== 0 && (
                            <MercureOutlinedButton
                                label="Précédent"
                                onClick={handleBack} />
                        )}

                        {activeStep === steps.length ? (
                            <MercureContainedButton
                                label="Valider"
                                onClick={saveHousing} />
                        ) : (
                            <MercureContainedButton
                                label="Suivant"
                                onClick={handleNext} />
                        )}
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}