import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CardList from "./CardList";

export default function CustomTransferList(props) {
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={5}>
                <CardList title={props.leftTitle} items={props.leftItems} handleToggleAll={props.handleToggleAll} handleToggle={props.handleToggle}
                          numberOfChecked={props.numberOfChecked} checked={props.leftChecked} handleCheck={props.handleCheck} handleHeaderCheck={props.handleHeaderCheck}
                          indeterminate={props.indeterminate} headerCheckedNumber={props.headerCheckedNumber} />
            </Grid>
            <Grid item xs={2}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={(e) => props.handleCheckedRight(props.leftItems, props.rightItems)}
                        disabled={props.leftItems.length === 0}
                        aria-label="move selected right items"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={(e) => props.handleCheckedLeft(props.leftItems, props.rightItems)}
                        disabled={props.rightItems.length === 0}
                        aria-label="move selected left items"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <CardList title={props.rightTitle} items={props.rightItems} handleToggleAll={props.handleToggleAll} handleToggle={props.handleToggle}
                          numberOfChecked={props.numberOfChecked} checked={props.rightChecked} handleCheck={props.handleCheck} handleHeaderCheck={props.handleHeaderCheck}
                          indeterminate={props.indeterminate} headerCheckedNumber={props.headerCheckedNumber} />
            </Grid>
        </Grid>
    );
}