import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function Leasing(props) {
    const {
        leasing,
        commercial,
        dwelling } = props.data;

    //const [checked, setChecked] = React.useState(false);

    const handleSwitchChange = (event) => {
        //setChecked(event.target.checked);
    };

    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="-5 0 32 32"><path fill="${encodeURIComponent(
                        '#fff',
                    )}" d="M12.406 14.75c-.094-2.094-.219-3.219-1.469-4.594-1.594-1.781-2.188-3.5-.875-6.156.344 1.781.469 3.375 1.719 4.344s2.281 3.594.625 6.406zm-2.343 0c-.063-1.125-.125-1.688-.813-2.469-.844-.938-1.188-1.844-.469-3.281.188.969.219 1.813.906 2.313s1.281 1.938.375 3.438zm5.656 9.875h5.688c.344 0 .469.25.25.531 0 0-2.219 2.844-5.281 2.844H5.407L.126 25.156c-.219-.281-.125-.531.219-.531H5.97c-.781-.406-1.938-2.188-1.938-4.406v-4.688H17.72v.375a2.32 2.32 0 0 1 1.531-.563c.781 0 2.25.813 2.25 2.219 0 2.031-1.344 2.781-2.125 3.313 0 0-1.469 1.156-2.5 2.5-.344.594-.75 1.063-1.156 1.25zm3.531-8.437c-.5 0-1.125.219-1.531 1.219v2.594c0 .344-.031.75-.094 1.094.688-.688 1.5-1.156 1.5-1.156.5-.344 1.5-1 1.5-2.281.031-.906-.813-1.469-1.375-1.469zm-12.844.375h-.875v1.281h.875v-1.281zm0 2.031h-.875v2.094s.25 2.813 2.031 3.656c-1.094-1.281-1.156-2.75-1.156-3.656v-2.094z"/></svg>')`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
            width: 32,
            height: 32,
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="-4.5 0 32 32"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="m19.469 12.594 3.625 3.313c.438.406.313.719-.281.719h-2.719v8.656c0 .594-.5 1.125-1.094 1.125h-4.719v-6.063c0-.594-.531-1.125-1.125-1.125h-2.969c-.594 0-1.125.531-1.125 1.125v6.063H4.343c-.594 0-1.125-.531-1.125-1.125v-8.656H.53c-.594 0-.719-.313-.281-.719l10.594-9.625c.438-.406 1.188-.406 1.656 0l2.406 2.156V6.719c0-.594.531-1.125 1.125-1.125h2.344c.594 0 1.094.531 1.094 1.125v5.875z"/></svg>')`,
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            borderRadius: 20 / 2,
        },
    }));

    return (
        <div>
            <Box component="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }} alignItems="center" justifyContent="center">
                            <Typography>Local d'habitation</Typography>
                            <MaterialUISwitch id="leasing" name="leasing" checked={leasing} onChange={props.onLeasingChange}/>
                            <Typography>Local commercial</Typography>
                        </Stack>
                    </Grid>
                    {leasing ? (
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="clease">Type de bail</FormLabel>
                                <RadioGroup
                                    aria-labelledby="clease"
                                    name="commercial"
                                    value={commercial}
                                    onChange={props.onLeasingChange}
                                >
                                    <FormControlLabel value="derogatory" control={<Radio />} label="Bail dérogatoire" />
                                    <FormControlLabel value="369" control={<Radio />} label="Bail 3/6/9" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6}>
                            <FormControl>
                                <FormLabel id="dlease">Type de bail</FormLabel>
                                <RadioGroup
                                    aria-labelledby="dlease"
                                    name="dwelling"
                                    value={dwelling}
                                    onChange={props.onLeasingChange}
                                >
                                    <FormControlLabel value="empty" control={<Radio />} label="Bail vide (3 ans)" />
                                    <FormControlLabel value="furniture" control={<Radio />} label="Bail meublé (1 an)" />
                                    <FormControlLabel value="student" control={<Radio />} label="Bail étudiant (9 mois)" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </div>
    );
}