import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './Navigator';
import Header from './Header';

const drawerWidth = 236;

export default function Paperbase(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isSmUp = useMediaQuery(props.theme.breakpoints.up('sm'));
    const [title, setTitle] = React.useState(props.title ? props.title : 'Overview');
    const [subtitle, setSubtitle] = React.useState([]);
    const [tabValue, setTabValue] = React.useState(0);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleTitle = (newTitle) => {
        setTitle(newTitle);
        handleTabValue(0);
    }

    const handleSubtitle = (newSubtitle, grandChildren) => {
        if (grandChildren) {
            setSubtitle(grandChildren)
        } else {
            let subtitles = [{id : newSubtitle}];
            setSubtitle(subtitles);
        }
    }

    const handleTabValue = (value) => {
        if (value) {
            setTabValue(value);
        } else {
            setTabValue(0);
        }
    }

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return (
        <ThemeProvider theme={props.theme}>
            <Box sx={{display: 'flex', minHeight: '100vh'}}>
                <CssBaseline/>
                <Box
                    component="nav"
                    sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{style: {width: drawerWidth}}}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            handleTitle={handleTitle}
                            handleSubtitle={handleSubtitle}
                        />
                    )}

                    <Navigator
                        PaperProps={{style: {width: drawerWidth}}}
                        sx={{display: {sm: 'block', xs: 'none'}}}
                        handleTitle={handleTitle}
                        handleSubtitle={handleSubtitle}
                        selectedIndex={selectedIndex}
                        handleListItemClick={handleListItemClick}
                    />
                </Box>
                <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <Header onDrawerToggle={handleDrawerToggle}
                            title={title}
                            subtitle={subtitle}
                            value={tabValue}
                            handleTabValue={handleTabValue} />
                    <Box component="main" sx={{flex: 1, py: 6, px: 4, bgcolor: '#ffffff'}}>
                        {React.Children.map(props.content, (child, index) =>
                            React.cloneElement(child, {handleTitle: handleTitle, handleSubtitle: handleSubtitle, handleTabValue: handleTabValue, handleListItemClick: handleListItemClick})
                        )}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}