import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import Location from "../../Components/Location";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const types = [
    {label: 'Appartement', value: 1},
    {label: 'Maison', value: 2},
    {label: 'Garage/Box/Cave', value: 3},
    {label: 'Place de parking', value: 4},
    {label: 'Loft', value: 5},
    {label: 'Entrepôt', value: 6},
    {label: 'Local commercial', value: 7},
    {label: 'Immeuble', value: 8},
    {label: 'Terrain', value: 9}
];

const regimes = [
    {label: 'Monopropriété', value: 1},
    {label: 'Copropriété', value: 2}
];

const currencies = [
    {label: '€', value: 1},
    {label: '£', value: 2},
    {label: '$', value: 3},
    {label: '$CA', value: 4},
    {label: 'CFA', value: 5},
    {label: '¥', value: 6},
    {label: '₽', value: 7},
    {label: 'DKK', value: 8},
    {label: 'SEK', value: 9},
    {label: '₹', value: 10},
    {label: '₪', value: 11},
];

export default function GeneralData(props) {
    let maxYear = new Date().setFullYear(new Date().getFullYear());
    let maxDate = dayjs(maxYear).toLocaleString();

    const {
        name,
        type,
        boughtAt,
        pieces,
        area,
        floor,
        door,
        address,
        regime,
        currency,
        nbBatch,
        batchName,
        numBatch,
        thousandths,
        constructionYear,
        technologicalRisks
    } = props.data;

    return (
        <div>
            <Box component="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            fullWidth
                            id="name"
                            label="Nom du bien"
                            autoComplete="nom"
                            value={name}
                            onChange={props.onGeneralDataChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            disablePortal
                            options={types}
                            id="type"
                            value={type}
                            onChange={(event, newValue) => {
                                props.onGeneralDataChange(event, newValue)
                            }}
                            autoHighlight
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label="Type de bien"
                                                                fullWidth/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Année d'acquisition"
                                id="boughtAt"
                                inputFormat="YYYY"
                                maxDate={maxDate}
                                views={['year']}
                                value={boughtAt}
                                onChange={(newValue) => {
                                    props.onGeneralDataChange(null, newValue, 'boughtAt')
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nombre des pièces"
                            id="pieces"
                            type="number"
                            value={pieces}
                            onChange={props.onGeneralDataChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Surface loi Carrez"
                            id="area"
                            fullWidth
                            type="number"
                            value={area}
                            onChange={props.onGeneralDataChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">m²</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Etage"
                            id="floor"
                            type="number"
                            value={floor}
                            onChange={props.onGeneralDataChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Numéro de porte"
                            id="door"
                            value={door}
                            onChange={props.onGeneralDataChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Location id="address"
                                  label="Adresse"
                                  noOptionsText="Aucune adresse saisie"
                                  value={address}
                                  onChange={(event, newValue) => {
                                      props.onGeneralDataChange(event, newValue)
                                  }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="regime"
                            options={regimes}
                            autoComplete
                            noOptionsText="Sélectionner un régime"
                            value={regime}
                            isOptionEqualToValue={(option, value) => option.value === value}
                            onChange={(event, newValue) => {
                                props.onGeneralDataChange(event, newValue)
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label="Régime juridique"
                                                                fullWidth/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="currency"
                            options={currencies}
                            autoComplete
                            noOptionsText="Sélectionner une monnaie"
                            value={currency}
                            onChange={(event, newValue) => {
                                props.onGeneralDataChange(event, newValue)
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label="Monnaie"
                                                                fullWidth/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nombre de lot"
                            id="nbBatch"
                            type="number"
                            value={nbBatch}
                            onChange={props.onGeneralDataChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Désignation de lot"
                            id="batchName"
                            value={batchName}
                            onChange={props.onGeneralDataChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Numéro de lot"
                            id="numBatch"
                            type="number"
                            value={numBatch}
                            onChange={props.onGeneralDataChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nombre des millièmes"
                            id="thousandths"
                            value={thousandths}
                            onChange={props.onGeneralDataChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Année de construction"
                                id="constructionYear"
                                inputFormat="YYYY"
                                maxDate={maxDate}
                                views={['year']}
                                value={constructionYear}
                                onChange={(newValue) => {
                                    props.onGeneralDataChange(null, newValue, 'constructionYear')
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="technologicalRisks">Les locaux ont-ils fait l'objet d'une indemnisation au titre des catastrophes naturelles ou des risques technologiques ?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="technologicalRisks"
                                name="technologicalRisks"
                                value={technologicalRisks}
                                onChange={(event) => props.onGeneralDataChange(event)}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Oui" />
                                <FormControlLabel value="no" control={<Radio />} label="Non" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}