import * as React from 'react';
import Container from '@mui/material/Container';
import DialogActions from '@mui/material/DialogActions';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Location from "../../Components/Location";
import {FormHelperText} from "@mui/material";
import Waiting from "../../Components/Waiting";
import MercureContainedButton from "../../Components/MercureContainedButton";
import Currency from "../../Components/Currency";
import Insee from "../../Components/Insee";
import validator from "validator";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export default function UpdateInvoice(props) {
    const getMonthRank = (value) => {
        switch (value.toLowerCase()) {
            case "january":
                return 0;

            case "february":
                return 1;

            case "march":
                return 2;

            case "april":
                return 3;

            case "may":
                return 4;

            case "june":
                return 5;

            case "july":
                return 6;

            case "august":
                return 7;

            case "september":
                return 8;

            case "october":
                return 9;

            case "november":
                return 10;

            case "december":
                return 11;
        }
    }

    const [currency, setCurrency] = React.useState(props.invoice && props.invoice.currency ? props.invoice.currency : '€');
    const [sender, setSender] = React.useState(null);
    const [clientName, setClientName] = React.useState(props.invoice && props.invoice.clientName ? props.invoice.clientName : '');
    const [clientAddress, setClientAddress] = React.useState(props.invoice && props.invoice.clientAddress ? props.invoice.clientAddress : '');
    const [clientEmail, setClientEmail] = React.useState(props.invoice && props.invoice.clientEmail ? props.invoice.clientEmail : '');
    const [description, setDescription] = React.useState(props.invoice && props.invoice.description ? props.invoice.description : '');
    const [month, setMonth] = React.useState(props.invoice && props.invoice.month ? dayjs().month(getMonthRank(props.invoice.month)) : dayjs(new Date()));
    const [year, setYear] = React.useState(props.invoice && props.invoice.year ? dayjs().year(props.invoice.year) : dayjs(new Date()));
    const [endPaymentDate, setEndPaymentDate] = React.useState(props.invoice && props.invoice.endPaymentDate ? props.invoice.endPaymentDate : null);
    const [creationDate, setCreationDate] = React.useState(props.invoice && props.invoice.creationDate ? props.invoice.creationDate : dayjs(new Date()));
    const [price, setPrice] = React.useState(props.invoice && props.invoice.price ? props.invoice.price : null);
    const [total, setTotal] = React.useState(props.invoice && props.invoice.total ? props.invoice.total : null);
    const [subTotal, setSubTotal] = React.useState(props.invoice && props.invoice.subTotal ? props.invoice.subTotal : null);
    const [quantity, setQuantity] = React.useState(props.invoice && props.invoice.quantity ? props.invoice.quantity : 1);
    const [vatPercent, setVatPercent] = React.useState(props.invoice && props.invoice.vatPercent ? props.invoice.vatPercent : null);
    const [paymentMode, setPaymentMode] = React.useState(props.invoice && props.invoice.paymentMode ? props.invoice.paymentMode : 4);
    const [city, setCity] = React.useState(props.invoice && props.invoice.city ? props.invoice.city : null);
    const [contract, setContract] = React.useState(props.invoice && props.invoice.contract ? props.invoice.contract : '');
    const [waitingLoading, setWaitingLoading] = React.useState(false);
    const [monthErrorMessage, setMonthErrorMessage] = React.useState('');
    const [yearErrorMessage, setEndDateErrorMessage] = React.useState('');
    const [creationDateErrorMessage, setCreationDateErrorMessage] = React.useState('');
    const [paymentDateErrorMessage, setEndPaymentDateErrorMessage] = React.useState('');
    const [priceErrorMessage, setPriceErrorMessage] = React.useState('');
    const [descriptionErrorMessage, setDescriptionErrorMessage] = React.useState('');
    const [cityErrorMessage, setCityErrorMessage] = React.useState('');
    const [paymentModeErrorMessage, setPaymentModeErrorMessage] = React.useState('');
    const requiredField = 'Ce champ est requis';
    const DESCRIPTION_CHARACTER_LIMIT = 1024;
    const [clientAddressErrorMessage, setClientAddressErrorMessage] = React.useState('');
    const [quantityErrorMessage, setQuantityErrorMessage] = React.useState('');
    const [senderErrorMessage, setSenderErrorMessage] = React.useState('');
    const [clientNameErrorMessage, setClientNameErrorMessage] = React.useState('');
    const [vatPercentErrorMessage, setVatPercentErrorMessage] = React.useState('');
    const [clientEmailErrorMessage, setClientEmailErrorMessage] = React.useState('');

    React.useEffect(() => {
        let newSubTotal = null;
        let newTotal = null;

        newSubTotal = quantity * price;

        if (vatPercent > 0) {
            newTotal = (newSubTotal * (vatPercent / 100)) + newSubTotal;
        } else {
            newTotal = newSubTotal;
        }

        setSubTotal(newSubTotal);
        setTotal(newTotal);
    }, [price, quantity, vatPercent]);

    async function getDataBySiren() {
        console.log('sir', props.invoice.siren)
        await fetch(process.env.REACT_APP_API_HOST + '/insee/get/siren/' + props.invoice.siren, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                console.log('res', result);
                result.json().then(response => {
                    setClientName(response[0]);
                })
            } else {
                console.log(result);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    React.useEffect(() => {
        const from = props.options.filter(item => item.id === props.invoice.idCorporation);
        setSender(from[0]);

        getDataBySiren();
    }, []);

    const onMonthChange = (event, value) => {
        if (value === null) {
            setMonthErrorMessage(requiredField);
            return;
        }

        setMonthErrorMessage('');
        setMonth(dayjs(value.toLocaleString()));
    }

    const onYearChange = (event, value) => {
        if (value === null) {
            setEndDateErrorMessage(requiredField);
            return;
        }

        setEndDateErrorMessage('');
        setYear(dayjs(value.toLocaleString()));
    }

    const onEndPaymentDateChange = (event, value) => {
        if (value === null) {
            setEndPaymentDateErrorMessage(requiredField);
            return;
        }

        setEndPaymentDateErrorMessage('');
        setEndPaymentDate(dayjs(value.toLocaleString()));
    }

    const onCreationDateChange = (event, value) => {
        if (value === null) {
            setCreationDateErrorMessage(requiredField);
            return;
        }

        setCreationDateErrorMessage('');
        setCreationDate(dayjs(value.toLocaleString()));
    }

    const onDescriptionChange = (event) => {
        if (event.target.value !== null) {
            setDescriptionErrorMessage('');
        } else {
            setDescriptionErrorMessage(requiredField);
        }

        setDescription(event.target.value);
    }

    const onClientAddressChange = (event) => {
        if (event.target.value !== null) {
            setClientAddressErrorMessage('');
        } else {
            setClientAddressErrorMessage(requiredField);
        }

        setClientAddress(event.target.value);
    }

    const onClientEmailChange = (e) => {
        let email = e.target.value;

        if (email != '') {
            if (validator.isEmail(email)) {
                setClientEmailErrorMessage("");
            } else {
                setClientEmailErrorMessage("L'adresse e-mail saisie est invalide");
            }
        } else {
            setClientEmailErrorMessage("");
        }

        setClientEmail(email);
    };

    const onPriceChange = (event) => {
        if (event.target.value !== null) {
            setPriceErrorMessage('');
        } else {
            setPriceErrorMessage(requiredField);
        }

        setPrice(event.target.value);
    }
    const onContractNameChange = (event) => {
        setContract(event.target.value);
    }

    const onQuantityChange = (event) => {
        setQuantity(event.target.value);

        if (event.target.value == 0 || event.target.value < 0) {
            props.handleQuantityError();
        } else {
            setQuantityErrorMessage('');
        }
    }

    const onVatPercentChange = (event) => {
        setVatPercent(event.target.value);

        if (event.target.value < 0) {
            props.handleVatError();
            setVatPercentErrorMessage("La TVA est erronée.");
        } else {
            setVatPercentErrorMessage('');
        }
    }

    const onPaymentModeChange = (event, value) => {
        if (event.target.value) {
            setPaymentModeErrorMessage('');
            setPaymentMode(event.target.value);
            return;
        }

        setPaymentModeErrorMessage(requiredField);
    }

    const onCityChange = (event, value) => {
        if (value === null) {
            setCityErrorMessage(requiredField);
        } else {
            setCityErrorMessage('');
        }

        setCity(value.description);
    }
    const onCurrencyChange = (event, value) => {
        setCurrency(value.label);
    }

    async function updateInvoice() {
        if (sender === null) {
            setSenderErrorMessage(requiredField);
            return;
        }

        if (clientName === undefined) {
            setClientNameErrorMessage(requiredField);
            return;
        }

        if (clientAddress === '') {
            setClientAddressErrorMessage(requiredField);
            return;
        }

        if (month === null) {
            setMonthErrorMessage(requiredField);
            return;
        }

        if (year === null) {
            setEndDateErrorMessage(requiredField);
            return;
        }

        if (description === '') {
            setDescriptionErrorMessage(requiredField);
            return;
        }

        if (quantity == 0 || quantity < 0) {
            setQuantityErrorMessage(requiredField);
            return;
        }

        if (price === null) {
            setPriceErrorMessage(requiredField);
            return;
        }

        if (vatPercent < 0) {
            setVatPercentErrorMessage("La TVA est erronée.");
            return;
        }

        if (endPaymentDate === null) {
            setEndPaymentDateErrorMessage(requiredField);
            return;
        }

        if (paymentMode === '') {
            setPaymentModeErrorMessage(requiredField);
            return;
        }

        if (city === null) {
            //setCityErrorMessage(requiredField);
            //return;
        }

        if (creationDate === null) {
            setCreationDateErrorMessage(requiredField);
            return;
        }

        // Loading
        setWaitingLoading(true);

        let data = {};
        data.idCorporation = sender.id;
        data.clientName = clientName;
        data.clientAddress = clientAddress;
        data.clientEmail = clientEmail;
        data.month = month && dayjs(month.toLocaleString()).format('MMMM');
        data.year = year && dayjs(year.toLocaleString()).format('YYYY');
        data.endPaymentDate = endPaymentDate && dayjs(endPaymentDate.toLocaleString()).format('YYYY-MM-DD');
        data.creationDate = dayjs(creationDate.toLocaleString()).format('YYYY-MM-DD');
        data.price = price;
        data.quantity = quantity;
        data.vatPercent = vatPercent;
        data.total = total;
        data.subTotal = subTotal;
        data.currency = currency;
        data.description = description;
        data.paymentMode = paymentMode;
        data.contract = contract;
        data.city = city;
        //console.log('data', data);return

        await fetch(process.env.REACT_APP_API_HOST + '/invoice/update/' + props.invoice.id, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(result => {
            if (result.status === 204) {
                result.json().then(response => {
                    // Hide dialog
                    props.onClose();

                    // Hide waiting
                    setWaitingLoading(false);

                    // Refresh
                    props.invoiceUpdated();
                })
            } else {
                //console.log(result);
                setWaitingLoading(false);
                props.invoiceNotUpdated();
                /*props.handleMessage("Votre facture n'a pas pu être modifiée suite à une erreur");
                props.handleSeverity('error');
                props.handleOpenAlert();*/
            }
        }).catch((error) => {
            console.log(error);
            setWaitingLoading(false);
            props.invoiceNotUpdated();
            /*props.handleMessage("Votre facture n'a pas pu être modifiée suite à une erreur");
            props.handleSeverity('error');
            props.handleOpenAlert();*/
        });
    }

    const handleCloseWaiting = () => {
        setWaitingLoading(false);
    };

    const setInseeAddress = (value) => {
        if (value) {
            setClientAddressErrorMessage('');
        }
        setClientAddress(value);
    }
    const setInseeName = (value) => {
        setClientName(value);
    }
    const setInseeNameErrorHandler = (value) => {
        setClientNameErrorMessage(value);
    }

    const onSenderChange = (event, value) => {
        if (value) {
            setSenderErrorMessage('');
        }
        setSender(value);
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 3 }}>
                <Typography component="h1" variant="h4">
                    Modifier une facture
                </Typography>
            </Box>
            <Divider />
            <Container component="" maxWidth="xs" tabIndex={-1} sx={{ mb: 3, mt: 3 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Autocomplete required
                                      options={props.options}
                                      label="Emetteur"
                                      value={sender}
                                      onChange={(event, newValue) => {
                                          onSenderChange(event, newValue);
                                      }}
                                      getOptionLabel={(option) => option !== undefined && option.name !== undefined ? option.name : ''}
                                      renderOption={(props, option) => {
                                          return (
                                              <li {...props} key={option.id}>
                                                  <Grid container alignItems="center">
                                                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                          <Box component="span" sx={{ fontWeight: option.highlight ? 'bold' : 'regular' }}>
                                                              {option.name}
                                                          </Box>
                                                      </Grid>
                                                  </Grid>
                                              </li>
                                          );
                                      }}
                                      renderInput={(params) => (
                                          <TextField
                                              required
                                              {...params}
                                              label="Emetteur"
                                              fullWidth
                                              error={senderErrorMessage !== ""}
                                              helperText={senderErrorMessage}
                                          />
                                      )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Insee label="Destinataire"
                               required="true"
                               address={setInseeAddress}
                               name={setInseeName}
                               error={clientNameErrorMessage !== ""}
                               helperText={setInseeNameErrorHandler}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Adresse du destinataire"
                            id="clientAddress"
                            fullWidth
                            value={clientAddress}
                            onChange={onClientAddressChange}
                            error={clientAddressErrorMessage !== ""}
                            helperText= {clientAddressErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email du destinataire"
                            id="clientEmail"
                            fullWidth
                            value={clientEmail}
                            onChange={onClientEmailChange}
                            error={clientEmailErrorMessage !== ""}
                            helperText= {clientEmailErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                required
                                label="Mois concerné"
                                id="month"
                                inputFormat="MMMM"
                                value={month}
                                view='month'
                                onChange={(newValue) => {
                                    onMonthChange(null, newValue)
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth required error={monthErrorMessage !== ""}
                                                                    helperText= {monthErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                required
                                label="Année concernée"
                                id="year"
                                inputFormat="YYYY"
                                value={year}
                                view='year'
                                onChange={(newValue) => {
                                    onYearChange(null, newValue)
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth required error={yearErrorMessage !== ""}
                                                                    helperText= {yearErrorMessage}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Description"
                            id="description"
                            fullWidth
                            rows={5}
                            multiline
                            value={description}
                            onChange={onDescriptionChange}
                            inputProps={{
                                maxlength: DESCRIPTION_CHARACTER_LIMIT
                            }}
                            error={descriptionErrorMessage !== ""}
                            helperText={`${description.length}/${DESCRIPTION_CHARACTER_LIMIT} ${descriptionErrorMessage}`}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Currency value={currency} onChange={onCurrencyChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="N° de contrat"
                            id="contract"
                            fullWidth
                            value={contract}
                            onChange={onContractNameChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label="Quantité"
                            id="quantity"
                            fullWidth
                            type="number"
                            value={quantity}
                            onChange={onQuantityChange}
                            error={quantityErrorMessage !== ""}
                            helperText= {quantityErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label="Prix Unitaire HT"
                            id="price"
                            fullWidth
                            type="number"
                            value={price}
                            onChange={onPriceChange}
                            error={priceErrorMessage !== ""}
                            helperText= {priceErrorMessage}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="TVA"
                            id="vatPercent"
                            fullWidth
                            type="number"
                            value={vatPercent}
                            onChange={onVatPercentChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            error={vatPercentErrorMessage !== ''}
                            helperText={vatPercentErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Sous-total"
                            id="subTotal"
                            disabled
                            fullWidth
                            type="number"
                            value={subTotal}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Total"
                            id="total"
                            disabled
                            fullWidth
                            type="number"
                            value={total}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Date limite de règlement"
                                id="endPaymentDate"
                                inputFormat="DD/MM/YYYY"
                                value={endPaymentDate}
                                onChange={(newValue) => {
                                    onEndPaymentDateChange(null, newValue)
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               fullWidth
                                               required
                                               error={paymentDateErrorMessage !== ""}
                                               helperText= {paymentDateErrorMessage}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={paymentModeErrorMessage !== ''}>
                            <InputLabel id="paymentMode">Mode de réglement</InputLabel>
                            <Select
                                labelId="paymentMode"
                                id="paymentMode"
                                label="Mode de réglement"
                                value={paymentMode}
                                onChange={(event, newValue) => onPaymentModeChange(event, newValue)}
                            >
                                <MenuItem value={1}>Carte bancaire</MenuItem>
                                <MenuItem value={2}>Chèque</MenuItem>
                                <MenuItem value={3}>Espèces</MenuItem>
                                <MenuItem value={4}>Virement bancaire</MenuItem>
                                <MenuItem value={5}>Prélèvement</MenuItem>
                                <MenuItem value={6}>Autre</MenuItem>
                            </Select>
                            <FormHelperText>{paymentModeErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Location label="Fait à"
                                  required="required"
                                  value={city}
                                  onChange={(event, newValue) => onCityChange(event, newValue)}
                                  noOptionsText="Aucune ville saisie"
                                  error={cityErrorMessage !== ""}
                                  helperText= {cityErrorMessage}
                                  />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Le"
                                required
                                id="creationDate"
                                inputFormat="DD/MM/YYYY"
                                value={creationDate}
                                onChange={(newValue) => {
                                    onCreationDateChange(null, newValue)
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               fullWidth
                                               required
                                               error={creationDateErrorMessage !== ""}
                                               helperText= {creationDateErrorMessage}
                                    />
                            }
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Container>
            <Divider />
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                    <MercureContainedButton
                        label="Valider"
                        onClick={updateInvoice}
                        disabled={waitingLoading} />
                </Box>
            </DialogActions>
            {waitingLoading && (
                <Waiting loading={waitingLoading} title="Sauvegarde" message="Merci de patienter, nous sauvegardons votre document..."/>
            )}
        </div>
    )
}