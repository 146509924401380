import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from "@mui/material/Typography";

const heatings = [
    {label: 'Individuel électrique', value: 1},
    {label: 'Individuel gaz', value: 2},
    {label: 'Collectif', value: 3}
];

const waters = [
    {label: 'Individuelle électrique', value: 1},
    {label: 'Individuelle gaz', value: 2},
    {label: 'Collective', value: 3}
];

const timers = [
    {label: 'Oui', value: 1},
    {label: 'Non', value: 2}
];

export default function Equipments(props) {
    const {
        heating,
        water,
        counters,
        cellar,
        parking,
        balcony,
        elevator,
        fiber,
        playground,
        guardian,
        antenna,
        bikeRoom,
        intercom,
        greenArea,
        washRoom,
        fridge,
        freezer,
        court,
        pool,
        hotTub,
        sauna,
        chimney,
        kitchenFurniture,
        oven,
        microwave,
        dishWasher,
        hotPlates,
        hood,
        alarm
    } = props.data;

    return (
        <div>
            <Box component="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            disablePortal
                            options={heatings}
                            id="heating"
                            autoHighlight
                            value={heating}
                            onChange={(event, newValue) => {
                                props.onEquipmentsChange(event, newValue)
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label="Chauffage"
                                                                fullWidth/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            disablePortal
                            options={waters}
                            id="water"
                            autoHighlight
                            value={water}
                            onChange={(event, newValue) => {
                                props.onEquipmentsChange(event, newValue)
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label="Eau"
                                                                fullWidth/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            options={timers}
                            id="counters"
                            autoHighlight
                            value={counters}
                            onChange={(event, newValue) => {
                                props.onEquipmentsChange(event, newValue)
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label="Présence des compteurs"
                                                                fullWidth/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            Equipements
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={cellar} onChange={props.onEquipmentsChange} name="cellar" />
                                        }
                                        label="Cave"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={parking} onChange={props.onEquipmentsChange} name="parking" />
                                        }
                                        label="Parking"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={balcony} onChange={props.onEquipmentsChange} name="balcony" />
                                        }
                                        label="Balcon"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={elevator} onChange={props.onEquipmentsChange} name="elevator" />
                                        }
                                        label="Ascenseur"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={fiber} onChange={props.onEquipmentsChange} name="fiber" />
                                        }
                                        label="Fibre optique"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={playground} onChange={props.onEquipmentsChange} name="playground" />
                                        }
                                        label="Aire des jeux"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={dishWasher} onChange={props.onEquipmentsChange} name="dishWasher" />
                                        }
                                        label="Lave-vaisselle"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={hotPlates} onChange={props.onEquipmentsChange} name="hotPlates" />
                                        }
                                        label="Plaques de cuisson"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={hood} onChange={props.onEquipmentsChange} name="hood" />
                                        }
                                        label="Hotte"
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl
                                component="fieldset"
                                sx={{ m: 3 }}
                                variant="standard"
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={guardian} onChange={props.onEquipmentsChange} name="guardian" />
                                        }
                                        label="Gardien"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={antenna} onChange={props.onEquipmentsChange} name="antenna" />
                                        }
                                        label="Antenne TV"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={bikeRoom} onChange={props.onEquipmentsChange} name="bikeRoom" />
                                        }
                                        label="Local vélo"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={intercom} onChange={props.onEquipmentsChange} name="intercom" />
                                        }
                                        label="Interphone"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={greenArea} onChange={props.onEquipmentsChange} name="greenArea" />
                                        }
                                        label="Espaces verts"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={washRoom} onChange={props.onEquipmentsChange} name="washRoom" />
                                        }
                                        label="Buanderie"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={fridge} onChange={props.onEquipmentsChange} name="fridge" />
                                        }
                                        label="Réfrigérateur"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={freezer} onChange={props.onEquipmentsChange} name="freezer" />
                                        }
                                        label="Congélateur"
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl
                                component="fieldset"
                                sx={{ m: 3 }}
                                variant="standard"
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={court} onChange={props.onEquipmentsChange} name="court" />
                                        }
                                        label="Cour"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={pool} onChange={props.onEquipmentsChange} name="pool" />
                                        }
                                        label="Piscine"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={hotTub} onChange={props.onEquipmentsChange} name="hotTub" />
                                        }
                                        label="Jacuzzi"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={sauna} onChange={props.onEquipmentsChange} name="sauna" />
                                        }
                                        label="Sauna"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={chimney} onChange={props.onEquipmentsChange} name="chimney" />
                                        }
                                        label="Cheminée"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={kitchenFurniture} onChange={props.onEquipmentsChange} name="kitchenFurniture" />
                                        }
                                        label="Meubles de cuisine"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={oven} onChange={props.onEquipmentsChange} name="oven" />
                                        }
                                        label="Four"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={microwave} onChange={props.onEquipmentsChange} name="microwave" />
                                        }
                                        label="Micro-ondes"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={alarm} onChange={props.onEquipmentsChange} name="alarm" />
                                        }
                                        label="Alarme"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}