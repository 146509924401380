import * as React from 'react';
import BootstrapDialogTitle from "../Components/BootstrapDialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {createTheme} from "@mui/material/styles";
import BootstrapDialog from "../Components/BootstrapDialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from "@mui/material/Box";
import MercureContainedButton from "../Components/MercureContainedButton";

export default function Notice(props) {
    const theme = createTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <BootstrapDialog
                open={props.open}
                onClose={props.onClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Mentions légales
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    Merci de lire avec attention les différentes modalités d’utilisation de la présente application
                    avant d’y parcourir ses pages. En vous connectant à cette application, vous acceptez sans réserves
                    les présentes modalités. Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004
                    pour la confiance dans l’économie numérique, les responsables de la présente application sont :<br/><br/>

                    <b>Editeur :</b><br/><br/>

                    GAUSSMSQUARE<br/>
                    Numéro de SIRET : 849788153<br/>
                    Responsable editorial : Gaussard MATANGA MAVUNGU<br/>
                    Rue Jean Prouvé<br/>
                    Téléphone : 0679754870<br/>
                    Email : contact@terra-saas.fr<br/>
                    Site Web : www.terra-saas.fr<br/><br/>

                    <b>Hébergement :</b><br/><br/>

                    Hébergeur : OVH<br/>
                    2 Rue Kellermann 59100 Roubaix<br/>
                    Site Web : www.ovh.com<br/><br/>

                    <b>Développement :</b><br/><br/>

                    Gaussard MATANGA MAVUNGU<br/>
                    Adresse : Rue Jean Prouvé 95200 Sarcelles<br/>
                    Site Web : www.gaussmsquare.com<br/><br/>

                    <b>Conditions d'utilisation :</b><br/><br/>

                    Cette application est proposée en différents langages web (HTML5, Javascript, CSS, etc…) pour un
                    meilleur confort d'utilisation et un graphisme plus agréable, nous vous recommandons de recourir à
                    des navigateurs modernes comme Internet explorer, Safari, Firefox, Google Chrome, etc<br/><br/>

                    GAUSSMSQUARE met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et
                    une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir.
                    L'internaute devra donc s'assurer de l'exactitude des informations auprès de GAUSSMSQUARE, et
                    signaler toutes
                    modifications de l'application qu'il jugerait utile. GAUSSMSQUARE n'est en aucun cas responsable de
                    l'utilisation faite de
                    ces informations, et de tout préjudice direct ou indirect pouvant en découler.<br/><br/>

                    <b>Cookies :</b><br/><br/>
                    L'application peut-être amené à vous demander l’acceptation des cookies pour des besoins de
                    statistiques et d'affichage. Un cookie est une information déposée sur votre disque dur par le
                    serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre
                    ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des
                    informations. Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de
                    cookies.<br/><br/>

                    <b>Liens hypertextes :</b><br/><br/>
                    Les sites internet de peuvent offrir des liens vers d’autres sites internet ou d’autres ressources
                    disponibles sur Internet. GAUSSMSQUARE ne dispose d'aucun moyen pour contrôler les sites en
                    connexion avec ses sites internet. GAUSSMSQUARE ne répond pas de la disponibilité de tels sites et
                    sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de
                    quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des
                    informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces
                    éléments. Les risques liés à cette utilisation incombent pleinement à l'internaute, qui doit se
                    conformer à leurs conditions d'utilisation.<br/><br/>

                    Les utilisateurs, les abonnés et les visiteurs des sites internet ne peuvent mettre en place un
                    hyperlien en direction de ce site sans l'autorisation expresse et préalable de
                    GAUSSMSQUARE.<br/><br/>

                    Dans l'hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en
                    direction d’un des sites internet de GAUSSMSQUARE, il lui appartiendra d'adresser un email
                    accessible sur le site afin de formuler sa demande de mise en place d'un hyperlien. GAUSSMSQUARE se
                    réserve le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier sa
                    décision.<br/><br/>

                    <b>Services fournis :</b><br/><br/>
                    L'ensemble des activités de la société ainsi que ses informations sont présentés sur notre site
                    www.gaussmsquare.com.<br/><br/>

                    GAUSSMSQUARE s’efforce de fournir sur le site www.gaussmsquare.com des informations aussi précises
                    que possible. les renseignements figurant sur le site www.gaussmsquare.com ne sont pas exhaustifs et
                    les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées
                    depuis leur mise en ligne. Par ailleurs, tous les informations indiquées sur le site
                    www.gaussmsquare.com sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer
                    sans préavis.<br/><br/>

                    <b>Limitation contractuelles sur les données :</b><br/><br/>
                    Les informations contenues sur cette application sont aussi précises que possible et l'application
                    remise à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou
                    des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement,
                    merci de bien vouloir le signaler par email, à l’adresse contact@terra-saas.fr, en décrivant le
                    problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de
                    navigateur utilisé, …).<br/><br/>

                    Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule
                    responsabilité. En conséquence, GAUSSMSQUARE ne saurait être tenue responsable d'un quelconque
                    dommage subi par
                    l'ordinateur de l'utilisateur ou d'une quelconque perte de données consécutives au téléchargement.
                    De plus, l’utilisateur de l'application s’engage à accéder à celle-ci en utilisant un matériel
                    récent, ne
                    contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.<br/><br/>

                    Les liens hypertextes mis en place dans le cadre de la présente application en direction d'autres
                    ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de
                    GAUSSMSQUARE.<br/><br/>

                    <b>Propriété intellectuelle :</b><br/><br/>
                    Tout le contenu présent sur l'application, incluant, de façon non limitative, les graphismes,
                    images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la
                    propriété exclusive de la société à l'exception des marques, logos ou contenus appartenant à
                    d'autres sociétés partenaires ou auteurs.<br/><br/>

                    Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même
                    partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de
                    GAUSSMSQUARE. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une
                    contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.
                    Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité
                    civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient
                    intenter une action en justice à votre encontre.<br/><br/>

                    <b>Déclaration à la CNIL :</b><br/><br/>
                    Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative
                    à la protection des personnes physiques à l'égard des traitements de données à caractère personnel)
                    relative à l'informatique, aux fichiers et aux libertés, cette application n'a pas fait l'objet
                    d'une déclaration auprès de la Commission nationale de l'informatique et des libertés (www.cnil.fr).<br/><br/>

                    <b>Litiges :</b><br/><br/>
                    Les présentes conditions de l'application sont régies par les lois françaises et toute contestation
                    ou litiges qui pourraient naître de l'interprétation ou de l'exécution de celles-ci seront de la
                    compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de
                    référence, pour le règlement de contentieux éventuels, est le français.<br/><br/>

                    <b>Données personnelles :</b><br/><br/>
                    De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous
                    visitez notre application.<br/><br/>

                    Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par
                    notre site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom,
                    votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone.
                    Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique
                    « contact ». Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce
                    cas, vous ne pourrez pas utiliser les services de l'application, notamment celui de solliciter des
                    renseignements sur notre société, ou de recevoir les lettres d’information.<br/><br/>

                    Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors
                    d’une simple navigation sur notre application, notamment : des informations concernant
                    l’utilisation de notre application, comme les zones que vous visitez et les services auxquels vous
                    accédez,
                    votre adresse IP, le type de votre navigateur, vos temps d'accès. De telles informations sont
                    utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des
                    services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi
                    du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique
                    des bases de données.
                </DialogContent>
                <DialogActions>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <MercureContainedButton onClick={props.onClose} label="Accepter" />
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}