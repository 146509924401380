import BootstrapDialogTitle from "./BootstrapDialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import BootstrapDialog from "./BootstrapDialog";
import * as React from "react";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Waiting(props) {
    //const [title, setTitle] = React.useState(props.title);
    //const [message, setMessage] = React.useState(props.message);
    //const [loading, setLoading] = React.useState(props.loading);

    return (
        <BootstrapDialog
            open={props.loading}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <BootstrapDialogTitle sx={{ backgroundColor: '#ff9800', color: '#ffffff' }}>{props.title}</BootstrapDialogTitle>
            <DialogContent dividers>
                <Container component="" maxWidth="xs" tabIndex={-1}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography>{props.message}</Typography>
                        </Grid>
                        <Grid item>
                            <CircularProgress
                                size={48}
                                color="secondary"
                                sx={{
                                    position: 'relative',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '10px',
                                    marginLeft: '100px',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
        </BootstrapDialog>
    )
}