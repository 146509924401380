import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {useNavigate} from 'react-router-dom';

function Header(props) {
	const navigate = useNavigate();

	const toSignIn = () => {
		navigate('/sign-in');
	}

	const toSignUp = () => {
		navigate('/sign-up');
	}

	const goTo = (id) => {
		props.handleAnchor(id);
	}

	return (
		<section className="menu menu2 cid-tuSnZj2qfA" id="menu2-2">
		    <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
		        <div className="container-fluid">
		            <div className="navbar-brand">
		                <span className="navbar-logo">
		                    <a href="#">
		                        <img src="assets/images/terra_logo.png" alt="logo" style={{ height: '3rem' }} />
		                    </a>
		                </span>
		                {/*<span className="navbar-caption-wrap">
		                	<a className="navbar-caption text-black display-7" href="#">Terra</a>
		                </span>*/}
		            </div>
		            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		                <div className="hamburger">
		                    <span></span>
		                    <span></span>
		                    <span></span>
		                    <span></span>
		                </div>
		            </button>
		            <div className="collapse navbar-collapse" id="navbarSupportedContent">
						{props.showMenu && props.showMenu !== false &&
							<ul className="navbar-nav nav-dropdown" data-app-modern-menu="true">
								<li className="nav-item">
									<a className="nav-link link text-black text-primary display-4"
									   onClick={() => goTo('services')}>Services</a>
								</li>
								<li className="nav-item">
									<a className="nav-link link text-black text-primary display-4"
									   onClick={() => goTo('about')}>A propos</a>
								</li>
								<li className="nav-item">
									<a className="nav-link link text-black text-primary display-4"
									   onClick={() => goTo('contact')}>Contact</a>
								</li>
							</ul>
						}

						<div className="navbar-buttons mbr-section-btn">
							<Stack spacing={2} direction="row">
								<Button color="secondary" variant="outlined" size="large" onClick={toSignIn}>Se
									connecter</Button>
								<Button color="secondary" variant="contained" size="large"
										onClick={toSignUp}>S'inscrire</Button>
							</Stack>
						</div>
					</div>
				</div>
			</nav>
		</section>
	)
}

export default Header;