import * as React from 'react';

function About() {
	return (
		<section className="features6 cid-tvJhugWmOA container-fluid" id="about">
		    <div className="">
		        <div className="card-wrapper">
		            <div className="row align-items-center">
		                <div className="col-12 col-lg-6">
		                    <div className="image-wrapper">
		                        <img src="assets/images/pexels-thisisengineering-3861958.jpg" alt="Mobirise" />
		                    </div>
		                </div>
		                <div className="col-12 col-lg">
		                    <div className="text-box">
		                        <h5 className="mbr-title mbr-fonts-style display-2"><strong>A propos</strong></h5>
		                        <p className="mbr-text mbr-fonts-style display-7">Terra est une application de gestion des biens immobiliers. Il est proposé aux utilisateurs en mode SaaS (Software as a Service). Il est destiné aux propriétaires des biens immobiliers. Terra permet de suivre l'évolution de son patrimoine immobilier partout dans le monde, de le gérer éfficacement et d'automatiser certaines tâches afin de gagner du temps à consacrer à d'autres activités.</p>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>
		</section>
	)
}

export default About;