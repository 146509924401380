import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    typography: {
        subtitle1: {
            fontSize: 12,
        },
        subtitle2: {
            fontSize: 12,
        },
    },
    MuiAvatar: {
        styleOverrides: {
            root: {
                width: 32,
                height: 32,
            },
        }
    }
});

export default theme;