import * as React from 'react';
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Phone from "../../Components/Phone";
import Box from "@mui/material/Box";
import InputAdornment from '@mui/material/InputAdornment';

export default function Identity(props) {
    const {
        civility,
        firstName,
        lastName,
        phonePrefix,
        phonePrefixLabel,
        phoneCode,
        phone,
        mobilePhonePrefix,
        mobilePhonePrefixLabel,
        mobilePhoneCode,
        mobilePhone,
        email,
        emailErrorMessage,
        declaration
    } = props.data;

    return (
        <Box component="form" noValidate>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="civility">Civilité</InputLabel>
                        <Select
                            autoFocus
                            labelId="civility"
                            id="civility"
                            value={civility}
                            name="civility"
                            label="Civilité"
                            onChange={(event, newValue) => {
                                props.onIdentityChange(event, newValue)
                            }}
                        >
                            <MenuItem value={1}>Madame</MenuItem>
                            <MenuItem value={2}>Mademoiselle</MenuItem>
                            <MenuItem value={3}>Monsieur</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="firstName"
                        label="Prénom"
                        autoComplete="given-name"
                        value={firstName}
                        onChange={props.onIdentityChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="lastName"
                        label="Nom"
                        autoComplete="family-name"
                        value={lastName}
                        onChange={props.onIdentityChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        autoComplete="email"
                        value={email}
                        error={emailErrorMessage !== ""}
                        helperText= {emailErrorMessage}
                        onChange={props.onIdentityChange}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Phone
                        id="phonePrefix"
                        value={phonePrefixLabel}
                        onChange={(event, newValue) => {
                            props.onIdentityChange(event, newValue)
                        }}/>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <TextField
                        id="phone"
                        name="phone"
                        fullWidth
                        label="Téléphone fixe"
                        type="number"
                        value={phone}
                        onChange={props.onIdentityChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+{phonePrefix}</InputAdornment>,
                            endAdornment: <InputAdornment position="end"><img alt={phoneCode} src={`https://flagcdn.com/w40/${phoneCode.toLowerCase()}.png`}/></InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Phone
                        id="mobilePhonePrefixLabel"
                        value={mobilePhonePrefixLabel}
                        onChange={(event, newValue) => {
                            props.onIdentityChange(event, newValue)
                        }}/>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <TextField
                        id="mobilePhone"
                        name="mobilePhone"
                        fullWidth
                        label="Téléphone mobile"
                        type="number"
                        value={mobilePhone}
                        onChange={props.onIdentityChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+{mobilePhonePrefix}</InputAdornment>,
                            endAdornment: <InputAdornment position="end"><img alt={mobilePhoneCode} src={`https://flagcdn.com/w40/${mobilePhoneCode.toLowerCase()}.png`}/></InputAdornment>
                        }}
                    />
                </Grid>
                {/*<Grid item xs={12}>
                    <Tooltip title="En France, la loi oblige les propriétaires à déclarer leurs locataires auprès de l'administration">
                        <FormControlLabel
                            control={
                                <Checkbox id="declaration" name="declaration" checked={declaration} onChange={props.onIdentityChange}/>
                            }
                            label="Déclarer à l'administration"
                        />
                    </Tooltip>
                </Grid>*/}
            </Grid>
        </Box>
    )
}