import * as React from 'react';
import Header from './Home/Header';
import Footer from './Home/Footer';
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "./Components/Alert";

function Confirm(props) {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const resend = () => {
        const id = localStorage.getItem('userId');
        //console.log('id', id)

        fetch(process.env.REACT_APP_API_HOST + '/user/send-link/' + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 204) {
                //console.log('ok');
                setMessage("Le lien d'activation de votre compte a été renvoyé par e-mail");
                setSeverity('success');
                handleClick();

                //props.onClose();
            }
        }).catch((error) => {
            console.log('error', error);
            setMessage("Le lien d'activation de votre compte n'a pas pu être renvoyé suite à une erreur");
            setSeverity('error');
            handleClick();
        });
    }

    return (
        <div>
            <Header showMenu={false}/>
            <section className="header7 cid-twx9k13Tog mbr-fullscreen" id="header7-h">
                <div className="text-right container">
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-5">
                            <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>Vous y êtes presque !</strong></h1>
                            
                            <p className="mbr-text mbr-fonts-style display-7">Votre compte est en cours de création. Un e-mail vous a été envoyé afin de confirmer votre adresse e-mail.<br />Si vous ne l'avez pas reçu, merci de cliquer sur le bouton ci-dessous :&nbsp;</p>
                            <div className="mt-3">
                                <Button variant="contained" color="secondary" onClick={resend}>Renvoyer le lien</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer showMenu={false}/>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Confirm;